import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { AssessmentType } from "../../../redux/types/generic-constant";

const FinishContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 85vh;
  background: #ffffff;
  justify-content: center;
  align-items: center;
`;
const Img = styled.img`
  display: flex;
  width: 100%;
  max-width: 238px;
`;
const ThankText = styled.label`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-weight: 600;
  align-items: center;
  font-size: 36px;
  margin-top: 36px;
`;
const SubmissionText = styled.label`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  margin-top: 22px;
`;
// interface Props{
//   testId:string
//   assessmentType:AssessmentType
// }
const FinishPage = ({ testId, assessmentType }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!testId || !assessmentType) return;
    setTimeout(
      () =>
        navigate(`/assessment/score-card?id=${testId}&type=${assessmentType}`),
      2000
    );
  }, []);

  useEffect(() => {
    document
      .exitFullscreen()
      .then((response) => console.log("response", response))
      .catch((err) => console.log("response", err));
  }, []);

  return (
    <>
      <FinishContainer>
        <Img src={"/images/finish.svg"} />
        <ThankText>Thank You !</ThankText>
        <SubmissionText>Your submission has been sent.</SubmissionText>
        <SubmissionText>Please wait for your Scorecard.....</SubmissionText>
      </FinishContainer>
    </>
  );
};

export default FinishPage;
