import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";

const ShippingAndDeliveryPolicy = () => {
  return (
    <Wrapper>
      <SEO pageTitle={"Privacy Policy"} />
      <div className="page__title-shape">
        <img
          className="page-title-shape-5 d-none d-sm-block"
          src="/assets/img/page-title/page-title-shape-1.png"
          alt=""
        />
        <img
          className="page-title-shape-6"
          src="/assets/img/page-title/page-title-shape-6.png"
          alt=""
        />
        <img
          className="page-title-shape-7"
          src="/assets/img/page-title/page-title-shape-4.png"
          alt=""
        />
      </div>

      <div style={{ marginTop: 20, height: 600 }} className="container">
        <h2>
          Shipping and Delivery Policy for Mygreenhorn Technology Private
          Limited
        </h2>
        <p>
          We are an online services platform. There is no need for physical
          shipping or delivery of any goods or services.
        </p>
      </div>
    </Wrapper>
  );
};

export default ShippingAndDeliveryPolicy;
