import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";

import Table from "react-bootstrap/Table";

import { CSVLink } from "react-csv";
import "./admin.css";
import { viewAllPremiumUsers } from "../../services/jobs-service";
import Wrapper from "../../layout/wrapper";

const AdminViewAllPremiumUsers = () => {
  const csvLink = useRef();
  const getStudentData = async () => {
    csvLink.current.link.click();
  };

  let ArrayFormat = [];

  let today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = mm + "/" + dd + "/" + yyyy;

  // const [appliedbylist,setAppliedbyList] = useState({job_code: '', job_title: '', number_of_opening: '', listed_by: '', job_function: '', qualification: '', type: '', location: '', start_date: '', salary: '', job_description: '', isremote: '',})
  const [appliedbylist, setAppliedbyList] = useState([]);

  useEffect(() => {
    const response = viewAllPremiumUsers().then((resp) => {
      console.log(resp);
      console.log(resp.username);
      let onlyUsername = [];
      for (let resps of resp) {
        onlyUsername.push(resps.username);
      }
      console.log("onlyUsername", onlyUsername);

      for (let userdetials of resp) {
        let userMasterData = userdetials.user_score_master;
        console.log("userMasterData", userMasterData);

        let aptitudescore = userdetials.aptitudescore["rightAns"];
        let softskillscore = userdetials.softskillscore["score"];
        let userresumeUrl = "";
        if (userdetials.userresumeUrl) {
          userresumeUrl =
            "https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/" +
            userdetials.userresumeUrl;
        }

        ArrayFormat.push({
          uid: userdetials.uid,
          username: userdetials.username,
          userfullname: userdetials.userfullname,
          mobile: userdetials.mobile,
          collegename: userdetials.collegename,
          degreecode: userdetials.degreecode,
          softskillscore: softskillscore,
          aptitudescore: aptitudescore,
          mentorscore: userdetials.ScoreGivenByMentor,
          aiinterviewscore: userdetials.aiinterviewscore,
          mentorremark: userdetials.RemarkGivenByMentor,
          userresumeUrl: userresumeUrl,
        });
      }

      console.log("ArrayFormat", ArrayFormat);
      setAppliedbyList(ArrayFormat);
    });

    console.log("respose", response);
  }, []);

  return (
    <>
      <Wrapper>
        <Container>
          <button className="e-btn" onClick={getStudentData}>
            Download Student List in CSV
          </button>
          <CSVLink
            data={appliedbylist}
            filename="students.csv"
            className="hidden"
            ref={csvLink}
            target="_blank"
          />
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>No.</th>
                <th>Username</th>
                <th>Full Name</th>
                <th>Mobile</th>
                <th>College</th>
                <th>Degree</th>
                <th>Aptitude Score</th>
                <th>Soft skills Score</th>
                <th>Mentor Score</th>
                <th>AI Interview</th>
                <th>Mentor Remark</th>
                <th>Resume</th>
              </tr>
            </thead>
            <tbody>
              {appliedbylist.map((item, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.username}</td>
                    <td>{item.userfullname}</td>
                    <td>{item.mobile}</td>
                    <td>{item.collegename}</td>
                    <td>{item.degreecode}</td>
                    <td>{item.aptitudescore}</td>
                    <td>{item.softskillscore}</td>
                    <td>{item.mentorscore}</td>
                    <td>{item.aiinterviewscore}</td>
                    <td>{item.mentorremark}</td>
                    <td>
                      {item.userresumeUrl ? (
                        <a href={item.userresumeUrl}>Download</a>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Container>
      </Wrapper>
    </>
  );
};
export default AdminViewAllPremiumUsers;
