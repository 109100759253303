import React, { useEffect, useState, useRef, useMemo } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

import "./admin.css";
import {
  AddAdminAIInterview,
  EditAdminAInterview,
  getAIListings,
  DeleteAIInterviewListing,
} from "../../services/ai-interview";

import config from "../../services/config";
import {
  setDataInLocalStorage,
  getDataFromLocalStorage,
} from "../../browser-storage";

import { toast } from "react-toastify";

import AdminExportAppliedbyJobs from "./adminExportAppliedJobs";
//Later admined to seprate roles based dashboard

import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
const ref = React.createRef();
// const htmlpurify = DOMPurify()
const AdminAIManager = () => {
  const editor = useRef(null);

  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = dd + "/" + mm + "/" + yyyy;
  const [AIInterviewDetails, setAIInterviewDetails] = useState({
    interviewId: "",
    interviewTitle: "",
    interviewCategory: "",
    interviewStatus: true,
  });
  const [editMode, setEditMode] = useState(false);

  const [AIInterviewList, setAIInterviewList] = useState([]);
  const [appliedbylist, setAppliedbyList] = useState([]);
  const [selectedjob, setSelectedJob] = useState({ jobCode: "", jobTitle: "" });
  useEffect(() => {
    getALLAIListing();
  }, []);

  const getALLAIListing = () => {
    const resp = getAIListings()
      .then((res) => {
        let reverseres = res.reverse();
        setAIInterviewList(reverseres);
        console.log(AIInterviewList);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(AIInterviewList);
  };

  let AIInterviewDetailsonChange = (evt) => {
    console.log("evt", evt.target.value);
    setAIInterviewDetails({
      ...AIInterviewDetails,
      [evt.target.name]: evt.target.value,
    });
  };

  let AIInterviewsubmit = () => {
    console.log(AIInterviewDetails);
    const AIresp = AddAdminAIInterview(AIInterviewDetails)
      .then((res) => {
        console.log(res);

        toast.success(`AI Interview Added Successfully `, {
          position: "top-left",
        });
        getALLAIListing();
        setAIInterviewDetails({
          interviewId: "",
          interviewTitle: "",
          interviewCategory: "",
          interviewStatus: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(AIresp);
  };

  let UpdateAIInterviewHandler = (item) => {
    console.log(item.interviewId);
    setEditMode(true);
    setAIInterviewDetails({
      interviewId: item.interviewId,
      interviewTitle: item.interviewTitle,
      interviewCategory: item.interviewCategory,
      interviewStatus: item.interviewStatus,
    });
  };
  let UpdateAIInterviewSubmit = () => {
    const UpdateAIInterviewSubmit = EditAdminAInterview(AIInterviewDetails)
      .then((res) => {
        console.log(res);

        toast.success(`Interview Updated Successfully `, {
          position: "top-left",
        });
        getALLAIListing();
        setAIInterviewDetails({
          interviewId: "",
          interviewTitle: "",
          interviewCategory: "",
          interviewStatus: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let DeleteAIInterviewHandler = (item) => {
    console.log(item);
    const InterviewId = item.interviewId;
    DeleteAIInterviewListing(InterviewId).then((resp) => {
      console.log(resp);
    });
  };

  let switchtoaddmode = () => {
    setEditMode(false);
  };

  return (
    <>
      <Wrapper>
        <Container>
          <Row style={{ marginTop: "50px" }}>
            <Col className="divscroll">
              <Card className="MainCard">
                <h1>All AI Interviews</h1>
                {AIInterviewList.map((item) => {
                  return (
                    <Card className="carddesign">
                      <Container className="jobcontainer">
                        <Row>
                          <Col sm={9}>
                            <p>Interview ID : {item.interviewId}</p>
                            <p>Interview Title : {item.interviewTitle}</p>
                            <p>Interview Category : {item.interviewCategory}</p>
                            <p>
                              Interview Status :{" "}
                              {item.interviewStatus.toString()}
                            </p>
                          </Col>
                          <Col sm={3}>
                            <button
                              className="e-btn mt-5"
                              onClick={() => UpdateAIInterviewHandler(item)}
                            >
                              Edit
                            </button>
                            <button
                              className="e-btn mt-5"
                              onClick={() => DeleteAIInterviewHandler(item)}
                            >
                              Delete
                            </button>
                          </Col>
                        </Row>

                        <Row>
                          <Col></Col>
                          <Col></Col>
                        </Row>
                      </Container>
                    </Card>
                  );
                })}
              </Card>
            </Col>
            <Col>
              <Card className="MainCard">
                {editMode == false ? (
                  <>
                    <Col>
                      <h1>Add AI Interview</h1>
                    </Col>
                  </>
                ) : (
                  <>
                    <Container>
                      <Row>
                        <Col>
                          <h1>Update AI Interview </h1>
                        </Col>
                        <Col>
                          <button
                            className="btn btn-success marginTop5"
                            onClick={switchtoaddmode}
                          >
                            Switch To Add Mode
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </>
                )}

                <Container>
                  <input
                    placeholder="AI Interview ID"
                    className="form-control marginTop5"
                    type="text"
                    name="interviewId"
                    value={AIInterviewDetails.interviewId}
                    onChange={AIInterviewDetailsonChange}
                  />

                  <input
                    placeholder="AI Interview Title"
                    className="form-control marginTop5"
                    type="text"
                    name="interviewTitle"
                    value={AIInterviewDetails.interviewTitle}
                    onChange={AIInterviewDetailsonChange}
                  />
                  <input
                    placeholder="Category"
                    className="form-control marginTop5"
                    type="text"
                    name="interviewCategory"
                    value={AIInterviewDetails.interviewCategory}
                    onChange={AIInterviewDetailsonChange}
                  />

                  <select
                    name="interviewStatus"
                    className="form-control"
                    value={AIInterviewDetails.interviewStatus}
                    onChange={AIInterviewDetailsonChange}
                    placeholder="Interview Status"
                  >
                    <option name="" value="" style={{ display: "none" }}>
                      is Interview Active
                    </option>
                    <option name="true" value="true">
                      True (Active - AutoFilled)
                    </option>
                    <option name="false" value="false">
                      False
                    </option>
                  </select>
                </Container>
                {editMode == false ? (
                  <>
                    <button
                      className="btn btn-success marginTop5"
                      onClick={AIInterviewsubmit}
                    >
                      Add Submit
                    </button>
                  </>
                ) : (
                  <button
                    className="btn btn-success marginTop5"
                    onClick={UpdateAIInterviewSubmit}
                  >
                    Edit Submit
                  </button>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </>
  );
};
export default AdminAIManager;
