import { memo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  UserProfileEvent,
  AppConfigEvent,
} from "../../../../../redux/types/redux-event";
import { updateUserProfile } from "../../../../../services/user-service";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import {
  ModalBackground,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "../../common/modal";
import {
  RequiredText,
  FieldGroupContainer,
  DateGroupContainer,
  InputFiled,
  Error,
  ButtonGroup,
  ActionButton,
  RemoveAction,
} from "../../common";
import {
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
} from "../../../../../redux/features/profile-slice";

// interface EducationFrom {
//   instituteName: string;
//   degree: string;
//   fieldOfStudy: string;
//   startDate: number;
//   endDate: number;
//   grade?: string;
// }
// interface EducationFromError {
//   instituteName: string;
//   degree: string;
//   fieldOfStudy: string;
//   startDate: string;
//   endDate: string;
// }
// interface Props {
//   closeAction: VoidFunction;
//   forEdit: boolean;
//   selectedIndex: number;
// }

const AddEditEducationModal = ({ closeAction, forEdit, selectedIndex }) => {
  const dispatch = useDispatch();
  // const { academics } = useSelector(
  //   ({ userProfile: { academics } }: RootState) => ({
  //     academics,
  //   })
  // );

  const academics = useSelector((state) => state.profile.academics);
  console.log("academics", academics);

  const [selectedEducationData, setSelectedEducationData] = useState(
    !academics?.length || selectedIndex < 0 || !academics[selectedIndex]
      ? {
          instituteName: "",
          degree: "",
          fieldOfStudy: "",
          startDate: 0,
          endDate: 0,
          grade: "",
        }
      : [...academics][selectedIndex]
  );

  const [formError, setFormError] = useState({
    instituteName: "",
    degree: "",
    fieldOfStudy: "",
    startDate: "",
    endDate: "",
  });

  const [showErrors, setShowErrors] = useState(false);
  const validateData = () => {
    const formErrorData = { ...formError };
    formErrorData.instituteName = selectedEducationData.instituteName
      ? ""
      : "Please enter institution name.";
    formErrorData.degree = selectedEducationData.degree
      ? ""
      : "Please enter degree.";
    formErrorData.fieldOfStudy = selectedEducationData.fieldOfStudy
      ? ""
      : "Please enter field of study.";
    formErrorData.startDate = selectedEducationData.startDate
      ? selectedEducationData.endDate &&
        Number(selectedEducationData.startDate) >
          Number(selectedEducationData.endDate)
        ? `Start date can't be greater than or end date.`
        : ""
      : "Please enter education start date.";
    formErrorData.endDate = selectedEducationData.endDate
      ? ""
      : "Please enter education end/expected date.";

    setFormError(formErrorData);
    return !(
      formErrorData.instituteName ||
      formErrorData.degree ||
      formErrorData.fieldOfStudy ||
      formError.startDate ||
      formError.endDate
    );
  };

  const updateFromData = (fromData) => {
    setSelectedEducationData(fromData);
    validateData();
    if (showErrors) {
      setShowErrors(false);
    }
  };

  const addEducation = () => {
    setShowErrors(true);
    if (!validateData()) {
      return;
    }
    const academicsData = [...academics, selectedEducationData];
    dispatch({ type: AppConfigEvent.START_LOADING });
    updateUserProfile({ academics: academicsData })
      .then((data) => {
        toast.success(`Education added successfully`, {
          position: "top-left",
        });
        // dispatch({
        //   type: UserProfileEvent.UPDATE_USER_PROFILE,
        //   data: { academics: data?.academics },
        // });
        dispatch(UPDATE_USER_PROFILE({ academics: data?.academics }));
      })
      .catch(() =>
        toast.error(`Unable to add education`, {
          position: "top-left",
        })
      )
      .finally(() => dispatch({ type: AppConfigEvent.STOP_LOADING }));
    closeAction();
  };

  const updateEducation = () => {
    setShowErrors(true);
    if (!validateData()) {
      return;
    }
    const academicsData = [...academics];
    academicsData[selectedIndex] = selectedEducationData;
    dispatch({ type: AppConfigEvent.START_LOADING });
    updateUserProfile({ academics: academicsData })
      .then((data) => {
        toast.success(`Education updated successfully`, {
          position: "top-left",
        });
        // dispatch({
        //   type: UserProfileEvent.UPDATE_USER_PROFILE,
        //   data: { academics: data?.academics },
        // });
        dispatch(UPDATE_USER_PROFILE({ academics: data?.academics }));
      })
      .catch(() =>
        toast.error(`Unable to update education`, {
          position: "top-left",
        })
      )
      .finally(() => dispatch({ type: AppConfigEvent.STOP_LOADING }));
    closeAction();
  };

  const deleteEducation = () => {
    const academicsData = [...academics];
    academicsData.splice(selectedIndex, 1);
    dispatch({ type: AppConfigEvent.START_LOADING });
    updateUserProfile({ academics: academicsData })
      .then((data) => {
        toast.success(`Education deleted successfully`, {
          position: "top-left",
        });
        // dispatch({
        //   type: UserProfileEvent.UPDATE_USER_PROFILE,
        //   data: { academics: data?.academics },
        // });
        dispatch(UPDATE_USER_PROFILE({ academics: data?.academics }));
      })
      .catch(() =>
        toast.error(`Unable to delete education`, {
          position: "top-left",
        })
      )
      .finally(() => dispatch({ type: AppConfigEvent.STOP_LOADING }));
    closeAction();
  };

  return (
    <ModalBackground>
      <Modal>
        <ModalHeader>
          Education{" "}
          <img
            src={"/assets/img/ghimages/close-icon.svg"}
            alt="X"
            onClick={closeAction}
          />
        </ModalHeader>
        <ModalBody>
          <RequiredText>* Indicates required</RequiredText>
          <FieldGroupContainer>
            School/College/University *
            <InputFiled
              value={selectedEducationData.instituteName}
              onChange={({ target: { value } }) =>
                updateFromData({
                  ...selectedEducationData,
                  instituteName: value,
                })
              }
              type="text"
            />
            {showErrors && formError.instituteName && (
              <Error>{formError.instituteName}</Error>
            )}
          </FieldGroupContainer>
          <FieldGroupContainer>
            Degree *
            <InputFiled
              value={selectedEducationData.degree}
              onChange={({ target: { value } }) =>
                updateFromData({ ...selectedEducationData, degree: value })
              }
              type="text"
            />
            {showErrors && formError.degree && (
              <Error>{formError.degree}</Error>
            )}
          </FieldGroupContainer>
          <FieldGroupContainer>
            Field of study *
            <InputFiled
              value={selectedEducationData.fieldOfStudy}
              onChange={({ target: { value } }) =>
                updateFromData({
                  ...selectedEducationData,
                  fieldOfStudy: value,
                })
              }
              type="text"
            />
            {showErrors && formError.fieldOfStudy && (
              <Error>{formError.fieldOfStudy}</Error>
            )}
          </FieldGroupContainer>
          <DateGroupContainer>
            <FieldGroupContainer>
              Start Date *
              <DatePicker
                selected={
                  selectedEducationData.startDate
                    ? new Date(selectedEducationData.startDate)
                    : null
                }
                maxDate={new Date()}
                onChange={(date) => {
                  if (date)
                    updateFromData({
                      ...selectedEducationData,
                      startDate: Date.parse(`${date}`),
                    });
                }}
                customInput={<InputFiled type="text" />}
                showYearDropdown
                showMonthDropdown
              />
              {showErrors && formError.startDate && (
                <Error>{formError.startDate}</Error>
              )}
            </FieldGroupContainer>
            <FieldGroupContainer>
              End Date *
              <DatePicker
                selected={
                  selectedEducationData.endDate
                    ? new Date(selectedEducationData.endDate)
                    : null
                }
                onChange={(date) => {
                  if (date)
                    updateFromData({
                      ...selectedEducationData,
                      endDate: Date.parse(`${date}`),
                    });
                }}
                customInput={<InputFiled type="text" />}
                showYearDropdown
                showMonthDropdown
              />
              {showErrors && formError.endDate && (
                <Error>{formError.endDate}</Error>
              )}
            </FieldGroupContainer>
          </DateGroupContainer>
          <FieldGroupContainer>
            Grade *
            <InputFiled
              value={selectedEducationData.grade}
              onChange={({ target: { value } }) =>
                updateFromData({ ...selectedEducationData, grade: value })
              }
              type="text"
            />
          </FieldGroupContainer>
        </ModalBody>
        <ModalFooter>
          {forEdit ? (
            <ButtonGroup forEdit={true}>
              <RemoveAction onClick={deleteEducation}>
                Delete education
              </RemoveAction>
              <ActionButton onClick={updateEducation}>Update</ActionButton>
            </ButtonGroup>
          ) : (
            <ButtonGroup forEdit={false}>
              <ActionButton onClick={addEducation}>Add</ActionButton>
            </ButtonGroup>
          )}
        </ModalFooter>
      </Modal>
    </ModalBackground>
  );
};

export default memo(AddEditEducationModal);
