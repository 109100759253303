import moment from "moment";

/**
 * Convert file Base64
 * @param file
 * @returns {Promise<string>}
 */
const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const formatDate = (
  date: Date | number | string,
  formatString = "MMM - YYYY"
) => moment(date).format(formatString);

const getQueryParams = (key: string) => {
  if (!key) return;
  const params = new URLSearchParams(window.location.search);
  return params.get(key);
};

const validateEmail = (email: string): boolean => {
  const regex = new RegExp(`[a-z0-9]+@[a-z]+\.[a-z]{2,3}`);
  return regex.test(email);
};

export { toBase64, formatDate, getQueryParams, validateEmail };
