import axios from "axios";
import config from "./config";
import { getDataFromLocalStorage } from "../browser-storage";
import { LocalStorageConstants } from "./localstorageconstants";

const getToken = () => getDataFromLocalStorage(LocalStorageConstants.TOKEN);

const uploadresumeBuilder = async (pdf: File): Promise<any> => {
  const formData = new FormData();
  formData.append("resume", pdf);
  const headers = {
    Authorization: `Bearer ${getToken()}`,
    "content-type": "multipart/form-data",
  };
  return axios
    .put(`${config.PROTOCOL}${config.HOST}/resume/upload-resume`, formData, {
      headers,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const uploadresumeBuilderforGuest = async (pdf: File): Promise<any> => {
  const formData = new FormData();
  formData.append("resume", pdf);
  const headers = {
    Authorization: `Bearer ${getToken()}`,
    "content-type": "multipart/form-data",
  };
  return axios
    .put(
      `${config.PROTOCOL}${config.HOST}/resume/upload-resume-for-guest`,
      formData,
      {
        headers,
      }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

export { uploadresumeBuilder, uploadresumeBuilderforGuest };
