import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import "./jobs.css";
import { getJoblisting, applyJobListing } from "../../services/jobs-service";
import { toBase64 } from "../../helpers/index";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "react-modal";
import Dropzone from "react-dropzone";
import { uploadresumeBuilder } from "../../services/resume-builder";
import config from "../../services/config";
import { getDataFromLocalStorage } from "../../browser-storage";

import JobsPopupLoginForm from "./jobs-popup-login-form";
const JobDescription = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "600px",
    },
  };
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const location = useLocation();
  const [jobList, setjobList] = useState([]);
  const [currentAppliedJob, setcurrentAppliedJob] = useState();
  const [jobDetails, setJobDetails] = useState({
    job_code: "",
    job_title: "",
    number_of_opening: "",
    listed_by: "",
    companyprofileImgUrl: "",
    recuiter_email_id: "",
    job_function: "",
    qualification: "",
    type: "",
    location: "",
    start_date: "",
    salary: "",
    job_description: "",
    isremote: "",
    isjobnew: "",
  });

  const [singleJobobj, setsingleJobobj] = useState({
    job_code: "",
    job_title: "",
    companyprofileImgUrl: "",
    recuiter_email_id: "",
    job_description: "",
    job_function: "",
    salary: "",
    location: "",
    number_of_opening: "",
    listed_by: "",
    type: "",
    start_date: "",
    isremote: "",
    isjobnew: "",
  });

  useEffect(() => {
    const SingleJob = location.state?.data;
    console.log("singleJob", SingleJob);
    //setsingleJobobj()
    let jobCode = SingleJob.job_code;
    let jobTitle = SingleJob.job_title;
    let CompanyLogo = SingleJob.companyprofileImgUrl;
    let recuiter_email_id = SingleJob.recuiter_email_id;
    let jobFunction = SingleJob.job_function;
    let jobpassoutyear = SingleJob.passoutyear;
    let jobLocation = SingleJob.location;
    let jobqualification = SingleJob.qualification;
    let jobSalary = SingleJob.salary;
    let jobOpening = SingleJob.number_of_opening;
    let jobListedby = SingleJob.listed_by;
    let jobType = SingleJob.type;
    let jobStartDate = SingleJob.start_date;
    let jobDesciption = SingleJob.job_description;
    let jobisRemote = SingleJob.isremote;
    let jobisjobnew = SingleJob.isjobnew;
    let jobisjobactive = SingleJob.isjobactive;

    setsingleJobobj({
      job_code: jobCode,
      job_title: jobTitle,
      companyprofileImgUrl: CompanyLogo,
      job_description: jobDesciption,
      job_function: jobFunction,
      passoutyear: jobpassoutyear,
      location: jobLocation,
      qualification: jobqualification,
      salary: jobSalary,
      number_of_opening: jobOpening,
      listed_by: jobListedby,
      type: jobType,
      start_date: jobStartDate,
      isremote: jobisRemote,
      isjobnew: jobisjobnew,
      isjobactive: jobisjobactive,
    });

    console.log(jobCode);

    const resp = getJoblisting()
      .then((res) => {
        console.log(res);
        let reverseres = res.reverse();
        setjobList(reverseres);
        console.log(jobList);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(jobList);
  }, []);

  const [isLoggedInStatus, setisLoggedInStatus] = useState(false);

  let checkLoginStatus = async () => {
    const userDetials = getDataFromLocalStorage("user-details");
    console.log(userDetials);
    let token = userDetials.token;
    console.log("token", token);
    if (token) {
      setisLoggedInStatus(true);
    }
    console.log("isLoggedInStatus", isLoggedInStatus);
  };

  useEffect(() => {
    checkLoginStatus();
  }, []);

  let applyJobHandler = (e) => {
    setcurrentAppliedJob(e);
    openModal();
  };

  //functions copied from old version

  const InfoText = styled.div`
    align-items: center;
  `;

  const DragDropContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 232px;
    border: 1px dashed #c4c4c4;
    border-radius: 5px;
    gap: 8px;
    div:nth-child(1) {
      font-family: Poppins-Bold;
      font-size: 24px;
      color: rgba(61, 41, 36, 0.8);
    }
    div:nth-child(2) {
      font-family: Poppins-Regular;
      font-size: 18px;
      color: rgba(61, 41, 36, 0.8);
    }
    img {
      width: 220px;
      height: 220px;
      border-radius: 5px;
    }
  `;
  const [resume, setResume] = useState({
    uploadedFile: undefined,
    fileUrl: "",
    fullname: "",
    username: "",
  });
  const [showErrors, setShowErrors] = useState(false);

  const setupResume = async (file) => {
    console.log("setupresume", file);
    if (file?.length && file[0]?.size > 4_00_00000) {
      setShowErrors(true);
      return;
    }
    const base64 = await toBase64(file[0]).catch(() =>
      //add toast fail here
      console.log("err")
    );
    if (base64) {
      setResume({
        uploadedFile: file[0],
        fileUrl: base64,
        fullname: "",
        username: "",
      });
    }
    console.log("fileUrl", resume.fileUrl);
    setShowErrors(false);
  };

  const uploadImage = async () => {
    if (!resume.uploadedFile || showErrors) {
      return;
    }

    //this is a service function which is responsible for upload
    const uploadresume = uploadresumeBuilder(resume.uploadedFile)
      .then(async (data) => {
        const _user = localStorage.getItem("user");
        const userDetials = JSON.parse(_user);
        const email = userDetials.email;
        const e = singleJobobj;
        const payload = { e, email };
        console.log("payload", payload);
        const resp = await applyJobListing(payload)
          .then((res) => {
            console.log(res);
            toast.success(`Job Applied Successfully`, {
              position: "top-left",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch(() =>
        toast.error(`Unable to Apply for the Job`, {
          position: "top-left",
        })
      );
    // .finally();
    closeModal();
  };

  return (
    <>
      <Wrapper>
        <SEO pageTitle={"Jobs"} />
        <Row className="text-center" style={{ marginLeft: 5, marginRight: 5 }}>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            overlayClassName="Overlay"
          >
            {isLoggedInStatus === true ? (
              <>
                <Row>
                  <Col sm={11}>
                    <h3>Upload Resume</h3>
                  </Col>
                  <Col sm={1}>
                    {" "}
                    <button
                      style={{ color: "red", background: "#ffffff" }}
                      onClick={closeModal}
                    >
                      <h3 style={{ color: "red", background: "#ffffff" }}>X</h3>
                    </button>
                  </Col>
                </Row>
                <Dropzone accept="" onDrop={(file) => setupResume(file)}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <DragDropContainer>
                        {resume.fileUrl ? (
                          <img
                            src="/assets/img/ghimages/uploadimg.png"
                            alt=""
                          />
                        ) : (
                          <>
                            <InfoText>Drag & Drop</InfoText>
                            <InfoText>Or</InfoText>
                            <button className="e-btn">Upload</button>
                          </>
                        )}
                      </DragDropContainer>
                    </div>
                  )}
                </Dropzone>
                <Row>
                  <Col sm={9}></Col>
                  <Col className="text-center" sm={3}>
                    <button
                      style={{ marginTop: 20 }}
                      className="e-btn"
                      onClick={uploadImage}
                    >
                      Submit
                    </button>
                  </Col>
                </Row>

                {resume.fileUrl && (
                  <img
                    src="/assets/img/ghimages/close.svg"
                    alt="X"
                    onClick={() =>
                      setResume({
                        uploadedFile: undefined,
                        fileUrl: "",
                        fullname: "",
                        username: "",
                      })
                    }
                  />
                )}
              </>
            ) : null}
            {isLoggedInStatus === false ? (
              <>
                <JobsPopupLoginForm />
              </>
            ) : null}
          </Modal>
          <Row className="text-center">
            <Col sm={6}>
              {/* <Alert style={{ marginTop: "10px" }} variant="success">
                <Alert.Heading>
                  Please sign up before applying for jobs
                </Alert.Heading>
                <hr />

                <b>Good luck!</b>
              </Alert> */}
              <img
                style={{ marginTop: 10 }}
                src="/assets/img/ghimages/resume_illustration.png"
              />
            </Col>
            <Col className="text-center" sm={6}>
              <Card style={{ margin: 10 }} className="carddesign">
                <Container className="jobcontainer">
                  <Row>
                    {/* <p className="companytitle text-center">
                      <img
                        width="50px"
                        src={`${config.PROTOCOL}${config.IMAGE_HOST}/${singleJobobj.companyprofileImgUrl}`}
                        style={{ borderRadius: "50%" }}
                      />
                    </p> */}
                    <p className="companytitle text-center">
                      {" "}
                      <p
                        style={{ fontSize: 20, marginTop: 20 }}
                        class="companynamestyle"
                      >
                        {singleJobobj.listed_by}
                      </p>
                    </p>
                    <p className="companytitle text-center">
                      <div className="flexcontainer">
                        <b className="boldtitle"> {singleJobobj.job_title}</b>
                        &nbsp;
                        {singleJobobj.isjobactive == "true" ? (
                          <div className="labelitem">
                            {singleJobobj.start_date}
                          </div>
                        ) : null}
                        {singleJobobj.isjobactive == "false" ? (
                          <div className="disablelabelitem">
                            Position Closed
                          </div>
                        ) : null}
                      </div>
                    </p>
                  </Row>
                  <Row>
                    <div className="labelContainer">
                      {singleJobobj.number_of_opening ? (
                        <div className="transparentlabelitem">
                          {singleJobobj.number_of_opening} Openings
                        </div>
                      ) : null}
                      {singleJobobj.job_function ? (
                        <div className="transparentlabelitem">
                          <i class="fa-solid fa-business-time"></i>&nbsp;
                          {singleJobobj.job_function}
                        </div>
                      ) : null}
                      {singleJobobj.location ? (
                        <div className="transparentlabelitem">
                          <i class="fa-solid fa-location-dot"></i>&nbsp;
                          {singleJobobj.location}
                        </div>
                      ) : null}

                      {singleJobobj.qualification ? (
                        <div className="transparentlabelitem">
                          {singleJobobj.qualification}
                        </div>
                      ) : null}
                      {singleJobobj.qualification ? (
                        <div className="transparentlabelitem">
                          <i class="fa-solid fa-indian-rupee-sign"></i>&nbsp;
                          {singleJobobj.salary}&nbsp;Lakhs
                        </div>
                      ) : null}
                      {singleJobobj.qualification ? (
                        <div className="transparentlabelitem">
                          <i class="fa-solid fa-briefcase"></i>&nbsp;{" "}
                          {singleJobobj.type}
                        </div>
                      ) : null}
                      {singleJobobj.passoutyear ? (
                        <div className="transparentlabelitem">
                          <i class="fa-solid fa-business-time"></i>&nbsp;{" "}
                          {singleJobobj.passoutyear}
                        </div>
                      ) : null}
                    </div>
                  </Row>
                  <Row>
                    <p style={{ color: "black" }}>Job Description</p>
                    {/* <p>{singleJobobj.job_description}</p> */}
                    <div
                      style={{ color: "black!important" }}
                      dangerouslySetInnerHTML={{
                        __html: singleJobobj.job_description,
                      }}
                    />
                  </Row>

                  <Row>
                    <Col>
                      <div className="flexcontainer">
                        {singleJobobj.isjobactive == "true" ? (
                          <div
                            style={{ fontSize: 18, padding: 5 }}
                            className="twm-jobs-browse site-text-primary"
                            onClick={() => applyJobHandler(singleJobobj)}
                          >
                            Apply
                          </div>
                        ) : null}
                        {singleJobobj.isjobactive == "false" ? (
                          <div
                            style={{ fontSize: 18 }}
                            className="disabledstyle"
                          >
                            Apply
                          </div>
                        ) : null}
                        &nbsp;&nbsp;{" "}
                        <i
                          style={{ paddingTop: "10px", color: "black" }}
                          class="fa-solid fa-indian-rupee-sign"
                        ></i>
                        &nbsp;&nbsp;
                        <div style={{ paddingTop: "5px", color: "black" }}>
                          {singleJobobj.salary}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Card>
            </Col>
          </Row>
        </Row>
      </Wrapper>
    </>
  );
};
{
  /* To remove */
}

export default JobDescription;
