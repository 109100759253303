import { memo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  UserProfileEvent,
  AppConfigEvent,
} from "../../../../../redux/types/redux-event";
import { updateUserProfile } from "../../../../../services/user-service";
import styled from "styled-components";
import { toast } from "react-toastify";
import {
  ModalBackground,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "../../common/modal";
import Radio from "../../common/radio";
import {
  RequiredText,
  FieldGroupContainer,
  InputFiled,
  Error,
  ButtonGroup,
  ActionButton,
  RemoveAction,
} from "../../common";
import { LangProficiency } from "../../../../../redux/types/generic-constant";
import { UPDATE_USER_PROFILE } from "../../../../../redux/features/profile-slice";
const ProficiencyRadioContainer = styled.div`
  margin: 20px 0 0 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RadioInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 13px;
  div {
    font-size: 16px !important;
    color: rgba(61, 41, 36, 0.85);
  }
`;

// interface AddEditLanguageForm {
//   language: string;
//   proficiency: LangProficiency;
// }

// interface Props {
//   closeAction: VoidFunction;
//   forEdit: boolean;
//   selectedIndex: number;
// }

const AddEditLanguageModal = ({ closeAction, forEdit, selectedIndex }) => {
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.profile.languages);

  const [selectedLanguageData, setSelectedLanguageData] = useState(
    !languages?.length || selectedIndex < 0 || !languages[selectedIndex]
      ? { language: "", proficiency: LangProficiency.BASIC }
      : [...languages][selectedIndex]
  );

  const [formError, setFormError] = useState({
    language: "",
  });
  const [showErrors, setShowErrors] = useState(false);
  const validateData = () => {
    const formErrorData = {
      ...formError,
    };
    formErrorData.language = selectedLanguageData.language
      ? ""
      : "Please enter language name.";
    setFormError(formErrorData);
    return !formErrorData.language;
  };

  const addLanguage = () => {
    setShowErrors(true);
    if (!validateData()) {
      return;
    }
    const languagesData = [...languages, selectedLanguageData];
    dispatch({ type: AppConfigEvent.START_LOADING });
    updateUserProfile({ languages: languagesData })
      .then((data) => {
        toast.success(`Language updated successfully`, {
          position: "top-left",
        });
        dispatch(UPDATE_USER_PROFILE({ languages: data?.languages }));
      })
      .catch(() =>
        toast.error(`Unable to update language`, {
          position: "top-left",
        })
      )
      .finally(() => dispatch({ type: AppConfigEvent.STOP_LOADING }));
    closeAction();
  };

  const updateLanguage = () => {
    setShowErrors(true);
    if (!validateData()) {
      return;
    }
    const languagesData = [...languages];
    languagesData[selectedIndex] = selectedLanguageData;
    dispatch({ type: AppConfigEvent.START_LOADING });
    updateUserProfile({ languages: languagesData })
      .then((data) => {
        toast.success(`Language updated successfully`, {
          position: "top-left",
        });
        dispatch(UPDATE_USER_PROFILE({ languages: data?.languages }));
      })
      .catch(() =>
        toast.error(`Unable to update language`, {
          position: "top-left",
        })
      )
      .finally(() => dispatch({ type: AppConfigEvent.STOP_LOADING }));
    closeAction();
  };

  const deleteLanguage = () => {
    const languagesData = [...languages];
    languagesData.splice(selectedIndex, 1);
    dispatch({ type: AppConfigEvent.START_LOADING });
    updateUserProfile({ languages: languagesData })
      .then((data) => {
        toast.success(`Language deleted successfully`, {
          position: "top-left",
        });
        dispatch(UPDATE_USER_PROFILE({ languages: data?.languages }));
      })
      .catch(() =>
        toast.error(`Unable to delete language`, {
          position: "top-left",
        })
      )
      .finally(() => dispatch({ type: AppConfigEvent.STOP_LOADING }));
    closeAction();
  };

  return (
    <ModalBackground>
      <Modal>
        <ModalHeader>
          Languages{" "}
          <img
            src={"/assets/img/ghimages/close-icon.svg"}
            alt="X"
            onClick={closeAction}
          />
        </ModalHeader>
        <ModalBody>
          <RequiredText>* Indicates required</RequiredText>
          <FieldGroupContainer>
            Language *
            <InputFiled
              value={selectedLanguageData.language}
              type="text"
              onChange={({ target: { value } }) =>
                setSelectedLanguageData({
                  ...selectedLanguageData,
                  language: value,
                })
              }
            />
            {showErrors && formError.language && (
              <Error>{formError.language}</Error>
            )}
          </FieldGroupContainer>
          <FieldGroupContainer>
            Proficiency *
            <ProficiencyRadioContainer>
              <RadioInputContainer>
                <Radio
                  onClick={() =>
                    setSelectedLanguageData((prevState) => ({
                      ...prevState,
                      proficiency: LangProficiency.BASIC,
                    }))
                  }
                  isChecked={
                    selectedLanguageData.proficiency === LangProficiency.BASIC
                  }
                />
                Basic
              </RadioInputContainer>
              <RadioInputContainer>
                <Radio
                  onClick={() =>
                    setSelectedLanguageData((prevState) => ({
                      ...prevState,
                      proficiency: LangProficiency.INTERMEDIATE,
                    }))
                  }
                  isChecked={
                    selectedLanguageData.proficiency ===
                    LangProficiency.INTERMEDIATE
                  }
                />
                Intermediate
              </RadioInputContainer>
              <RadioInputContainer>
                <Radio
                  onClick={() =>
                    setSelectedLanguageData((prevState) => ({
                      ...prevState,
                      proficiency: LangProficiency.EXPERT,
                    }))
                  }
                  isChecked={
                    selectedLanguageData.proficiency === LangProficiency.EXPERT
                  }
                />
                Expert
              </RadioInputContainer>
            </ProficiencyRadioContainer>
          </FieldGroupContainer>
        </ModalBody>
        <ModalFooter>
          {forEdit ? (
            <ButtonGroup forEdit={true}>
              <RemoveAction onClick={deleteLanguage}>Delete skill</RemoveAction>
              <ActionButton onClick={updateLanguage}>Update</ActionButton>
            </ButtonGroup>
          ) : (
            <ButtonGroup forEdit={false}>
              <ActionButton onClick={addLanguage}>Add</ActionButton>
            </ButtonGroup>
          )}
        </ModalFooter>
      </Modal>
    </ModalBackground>
  );
};

export default memo(AddEditLanguageModal);
