// FormHandlers.js

import { useState } from "react";
import {
  initialBasicDetails,
  initialAboutMe,
  initialWorkExperience,
  initialEducation,
  initialCertification,
  initialSkills,
  initialLanguages,
  initialAwards,
} from "./InitialStates";

export const useFormHandlers = () => {
  const [BasicDetails, setBasicDetails] = useState(initialBasicDetails);
  const [Aboutme, setAboutme] = useState(initialAboutMe);
  const [WorkExprience, setWorkExprience] = useState(initialWorkExperience);
  const [Education, setEducation] = useState(initialEducation);
  const [Certification, setCertification] = useState(initialCertification);
  const [Skills, setSkills] = useState(initialSkills);
  const [Languages, setLanguages] = useState(initialLanguages);
  const [Awards, setAwards] = useState(initialAwards);

  let BasicDetailsonChange = (evt) => {
    const value = evt.target.value;
    setBasicDetails({
      ...BasicDetails,
      [evt.target.name]: value,
    });
    console.log(BasicDetails);
  };

  let AboutmeonChange = (e) => {
    setAboutme({ Aboutme: e.target.value });
    console.log(Aboutme);
  };

  //Education
  let EducationhandleChange = (i, e) => {
    let values = [...Education];
    values[i][e.target.name] = e.target.value;
    setEducation(values);
  };

  let addEducationFormFields = () => {
    setEducation([
      ...Education,
      {
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: "",
        courseName: "",
        degreeName: "",
        collegeName: "",
        score: "",
      },
    ]);
  };

  let removeEducationFormFields = (i) => {
    let values = [...Education];
    values.splice(i, 1);
    setEducation(values);
  };

  //New Work Exp

  const WorkExphandleInputChange = (index, event) => {
    const values = [...WorkExprience];
    values[index][event.target.name] = event.target.value;
    setWorkExprience(values);
  };

  const WorkExphandleSubInputChange = (parentIndex, childIndex, event) => {
    const values = [...WorkExprience];
    values[parentIndex].description[childIndex].value = event.target.value;
    setWorkExprience(values);
  };

  //Not Being Used
  const WorkExpaddInputField = (index) => {
    const values = [...WorkExprience];
    values[index].description.push({ value: "" });
    setWorkExprience(values);
  };

  const WorkExpaddSubInputField = (parentIndex) => {
    const values = [...WorkExprience];
    values[parentIndex].description.push({ value: "" });
    setWorkExprience(values);
  };
  const WorkExpremoveInputField = (index) => {
    const values = [...WorkExprience];
    values.splice(index, 1);
    setWorkExprience(values);
  };

  const WorkExpremoveSubInputField = (parentIndex, childIndex) => {
    const values = [...WorkExprience];
    values[parentIndex].description.splice(childIndex, 1);
    setWorkExprience(values);
  };

  //Certification
  let CertificationhandleChange = (i, e) => {
    let values = [...Certification];
    values[i][e.target.name] = e.target.value;
    setCertification(values);
  };

  let CertificationaddFormFields = () => {
    setCertification([
      ...Certification,
      {
        certificateName: "",
        certificateSourceName: "",
        certificateLink: "",
      },
    ]);
  };

  let CertificationremoveFormFields = (i) => {
    let values = [...Certification];
    values.splice(i, 1);
    setCertification(values);
  };

  //Skills

  let SkillshandleChange = (i, e) => {
    let values = [...Skills];
    values[i][e.target.name] = e.target.value;
    setSkills(values);
  };

  let addSkillsField = () => {
    setSkills([...Skills, { skillname: "" }]);
  };

  let removeSkillsField = (i) => {
    let values = [...Skills];
    values.splice(i, 1);
    setSkills(values);
  };

  //Languages

  let LanguagehandleChange = (i, e) => {
    let values = [...Languages];
    values[i][e.target.name] = e.target.value;
    setLanguages(values);
  };

  let addLanguageField = () => {
    setLanguages([...Languages, { langugename: "", languagerating: "" }]);
  };

  let removeLanguageField = (i) => {
    let values = [...Languages];
    values.splice(i, 1);
    setLanguages(values);
  };

  //Awards and Achivements

  let AwardsshandleChange = (i, e) => {
    let values = [...Awards];
    values[i][e.target.name] = e.target.value;
    setAwards(values);
  };

  let addAwardField = () => {
    setAwards([...Awards, { awardname: "", awardedby: "" }]);
  };

  let removeAwardsField = (i) => {
    let values = [...Awards];
    values.splice(i, 1);
    setAwards(values);
  };

  return {
    BasicDetails,
    setBasicDetails,
    Aboutme,
    setAboutme,
    WorkExprience,
    setWorkExprience,
    Education,
    setEducation,
    Certification,
    setCertification,
    Skills,
    setSkills,
    Languages,
    setLanguages,
    Awards,
    setAwards,
    BasicDetailsonChange,
    AboutmeonChange,
    EducationhandleChange,
    addEducationFormFields,
    removeEducationFormFields,
    WorkExphandleInputChange,
    WorkExphandleSubInputChange,
    WorkExpaddInputField,
    WorkExpaddSubInputField,
    WorkExpremoveInputField,
    WorkExpremoveSubInputField,
    CertificationhandleChange,
    CertificationaddFormFields,
    CertificationremoveFormFields,
    SkillshandleChange,
    addSkillsField,
    removeSkillsField,
    LanguagehandleChange,
    addLanguageField,
    removeLanguageField,
    AwardsshandleChange,
    addAwardField,
    removeAwardsField,
  };
};
