import React, { useState } from "react";
import ResumeBuilderFormChild from "./resume-builder-form-child";
import ResumeBuilderDesignChild from "./resume-builder-design-child";

const ResumeBuilderParentComponent = () => {
  const [basicDetails, setBasicDetails] = useState({
    firstname: "",
    middlename: "",
    lastname: "",
    degree: "",
    phonenumber: "",
    email: "",
    linkedin: "",
  });

  const [workExperience, setWorkExperience] = useState([
    {
      startYear: "",
      startMonth: "",
      endYear: "",
      endMonth: "",
      companyName: "",
      location: "",
      designationName: "",
      duration: "",
      description: [],
    },
  ]);

  const handleBasicDetailsChange = (field, value) => {
    setBasicDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const handleWorkExperienceChange = (index, field, value) => {
    setWorkExperience((prevExperience) => {
      const updatedExperience = [...prevExperience];
      updatedExperience[index] = {
        ...updatedExperience[index],
        [field]: value,
      };
      return updatedExperience;
    });
  };

  return (
    <div>
      <ResumeBuilderFormChild
        onBasicDetailsChange={handleBasicDetailsChange}
        basicDetails={basicDetails}
        onWorkExperienceChange={handleWorkExperienceChange}
        workExperience={workExperience}
      />
      <ResumeBuilderDesignChild
        basicDetails={basicDetails}
        workExperience={workExperience}
      />
    </div>
  );
};

export default ResumeBuilderParentComponent;
