import React, { useState, useEffect, useRef } from "react";
import "../assessment/score-card.css";

import { Overlay } from "react-bootstrap";
const ScoreBar = ({ currentScore, maxScore }) => {
  const progress = (currentScore / maxScore) * 100;

  const markerRef = useRef(null);

  const [tooltipVisible, setTooltipVisible] = useState(false);

  useEffect(() => {
    setTooltipVisible(true);
  }, []);
  useEffect(() => {
    if (markerRef.current) {
      const markerElement = markerRef.current;
      const progressBar = markerElement.closest(".progress");
      const progressBarWidth = progressBar.offsetWidth;
      const markerWidth = markerElement.offsetWidth;
      const markerPosition = (progressBarWidth * progress) / 100 - markerWidth;
      markerElement.style.transform = `translateX(${markerPosition}px)`;
    }
  }, [progress]);

  return (
    <div>
      {/* <p>
        Score: {currentScore}/{maxScore}
      </p> */}
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${progress}%` }}
          aria-valuenow={currentScore}
          aria-valuemin="0"
          aria-valuemax={maxScore}
        >
          <span
            className={`marker ${tooltipVisible ? "show" : ""}`}
            ref={markerRef}
          ></span>
          {tooltipVisible && (
            <Overlay target={markerRef.current} show placement="top">
              {({ placement, arrowProps, show: _show, popper, ...props }) => (
                <div {...props} className="tooltip">
                  <p style={{ color: "white" }}>You</p>
                </div>
              )}
            </Overlay>
          )}
        </div>
      </div>
    </div>
  );
};
export default ScoreBar;
