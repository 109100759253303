import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { getDataFromLocalStorage } from "../../browser-storage";
import { toast } from "react-toastify";
import {
  AddRecuiterCompanyProfile,
  GetRecuiterCompanyProfile,
  UpdateRecuiterCompanyProfileById,
} from "../../services/recuiter-service";
import { Col, Row } from "react-bootstrap";
import Dropzone from "react-dropzone";
import Modal from "react-modal";
import { toBase64 } from "../../helpers/index";
import styled from "styled-components";
import RecuiterSliderMenu from "./recuiterslidermenu";
import { addCompanyProfilePic } from "../../services/user-service";
import { setDataInLocalStorage } from "../../browser-storage";
import { LocalStorageConstants } from "../../services/localstorageconstants";
import config from "../../services/config";
const DragDropContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 232px;
  border: 1px dashed #c4c4c4;
  border-radius: 5px;
  gap: 8px;
  div:nth-child(1) {
    font-family: Poppins-Bold;
    font-size: 24px;
    color: rgba(61, 41, 36, 0.8);
  }
  div:nth-child(2) {
    font-family: Poppins-Regular;
    font-size: 18px;
    color: rgba(61, 41, 36, 0.8);
  }
  img {
    width: 220px;
    height: 220px;
    border-radius: 5px;
  }
`;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const RecuiterCompanyProfile = () => {
  const [profilephoto, setprofilephoto] = useState({
    isphotouploaded: false,
    photolink: "",
  });
  // Model Requirements
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  const [companyName, setCompanyName] = useState("");
  useEffect(() => {
    const userDetials = getDataFromLocalStorage("user-details");
    console.log(userDetials);
    const currentrole = userDetials.role;
    const currentemail = userDetials.email;
    console.log(currentemail, currentrole);

    setRecuiterCompanyProfileDetials({
      ...RecuiterCompanyprofiledetials,
      userEmailId: currentemail,
    });

    GetRecuiterCompanyProfileById(currentemail);
  }, []);

  let GetRecuiterCompanyProfileById = async (currentemail) => {
    const SubmitResponse = await GetRecuiterCompanyProfile(currentemail)
      .then((resp) => {
        console.log(resp);
        let checkIsProfileCreated = resp;
        if (checkIsProfileCreated.length == 0) {
          let RecuiterCompanyresp = checkIsProfileCreated[0];
          console.log("RecuiterCompanyresp", RecuiterCompanyresp);
          const getCompanyName = RecuiterCompanyresp.companyname;
          setCompanyName(getCompanyName);
          setRecuiterCompanyProfileDetials(RecuiterCompanyresp);
          setDataInLocalStorage(
            LocalStorageConstants.COMPANY_DETIALS,
            RecuiterCompanyresp
          );
          setEditMode(false);
        } else {
          let RecuiterCompanyresp = checkIsProfileCreated[0];
          console.log("RecuiterCompanyresp", RecuiterCompanyresp);
          setRecuiterCompanyProfileDetials(RecuiterCompanyresp);
          const getCompanyName = RecuiterCompanyresp.companyname;
          setCompanyName(getCompanyName);
          setEditMode(true);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Error ${err.response.data} `, {
          position: "top-left",
        });
      });
  };

  const [RecuiterCompanyprofiledetials, setRecuiterCompanyProfileDetials] =
    useState({
      userId: "",
      userEmailId: "",
      firstName: "",
      lastName: "",
      fullName: "",
      mobile: "",
      companyname: "",
      sector: "",
      location: "",
      companyprofileImgUrl: "",
      dob: "",
      aboutcompany: "",
    });
  let ProfileDetailsonChange = (evt) => {
    const value = evt.target.value;
    setRecuiterCompanyProfileDetials({
      ...RecuiterCompanyprofiledetials,
      [evt.target.name]: value,
    });
    console.log(RecuiterCompanyprofiledetials);
  };

  let SubmitRecuiterCompanyProfile = async () => {
    const SubmitResponse = await AddRecuiterCompanyProfile(
      RecuiterCompanyprofiledetials
    )
      .then((resp) => {
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Error ${err.response.data} `, {
          position: "top-left",
        });
      });
  };
  let switchtoaddmode = () => {
    setEditMode(false);
  };
  const [editMode, setEditMode] = useState(false);
  let EditProfileHandler = (item) => {
    console.log(item);
    setEditMode(true);
    setRecuiterCompanyProfileDetials({
      fullName: item.fullName,
      mobile: item.mobile,
      companyname: item.companyname,
      sector: item.sector,
      location: item.location,
      companyprofileImgUrl: item.companyprofileImgUrl,
      dob: item.dob,
      aboutcompany: item.aboutcompany,
    });
    //setCourseVideosDetials(item.course_videos);
  };

  let SubmitUpdateRecuiterCompanyProfile = async () => {
    const SubmitResponse = await UpdateRecuiterCompanyProfileById(
      RecuiterCompanyprofiledetials
    )
      .then((resp) => {
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
      })
      .catch((err) =>
        toast.error(`Error ${err} `, {
          position: "top-left",
        })
      );
  };

  const [profilePic, setProfilePic] = useState({
    uploadedFile: undefined,
    fileUrl: "",
  });
  const [showErrors, setShowErrors] = useState(false);
  const setupProfilePic = async (file) => {
    console.log("file", file);
    // if (file?.length && file[0]?.size > 4_00_000) {
    //   console.log("Inside this", file);
    //   setShowErrors(true);
    //   return;
    // }
    console.log(file[0]);
    const base64 = await toBase64(file[0]).catch(() =>
      toast.error(`Unable to Upload Image`, {
        position: "top-left",
      })
    );
    console.log("base64", base64);
    if (base64) {
      setProfilePic({ uploadedFile: file[0], fileUrl: base64 });
    }
    console.log("profilePic", profilePic.fileUrl);
    setShowErrors(false);
  };

  const uploadImage = () => {
    if (!profilePic.uploadedFile || showErrors) {
      return;
    }

    addCompanyProfilePic(profilePic.uploadedFile)
      .then((data) => {
        toast.success(`Profile picture updated successfully`, {
          position: "top-left",
        });
        window.location.reload();
      })
      .catch(() =>
        toast.error(`Unable to update your profile picture`, {
          position: "top-left",
        })
      );
  };

  return (
    <Wrapper>
      <SEO pageTitle={"RecuiterCompany Profile"} />
      <RecuiterSliderMenu />
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        contentClassName="myModel"
      >
        <div className="container">
          <div className="row">
            <div className="9">
              {" "}
              <h2></h2>
            </div>
            <div className="3"></div>
          </div>
        </div>
        <Row>
          <Col sm={11}>
            <h3>Upload Profile Image</h3>
          </Col>
          <Col sm={1}>
            {" "}
            <button
              style={{ color: "red", background: "#ffffff" }}
              onClick={closeModal}
            >
              <h3 style={{ color: "red", background: "#ffffff" }}>X</h3>
            </button>
          </Col>
        </Row>

        <Dropzone accept="" onDrop={(file) => setupProfilePic(file)}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <DragDropContainer>
                {profilePic.fileUrl ? (
                  <img src="/assets/img/ghimages/uploadimg.png" alt="" />
                ) : (
                  <>
                    <p>Drag & Drop</p>
                    <p>Or</p>
                    <button className="e-btn">Upload</button>
                  </>
                )}
              </DragDropContainer>
            </div>
          )}
        </Dropzone>
        <Row>
          <Col sm={9}></Col>
          <Col className="text-center" sm={3}>
            <button
              style={{ marginTop: 20 }}
              className="e-btn"
              onClick={uploadImage}
            >
              Submit
            </button>
          </Col>
        </Row>

        {/* {profilePic.fileUrl && (
          <img
            src="/assets/img/ghimages/close.svg"
            alt="X"
            onClick={() =>
              setProfilePic({
                uploadedFile: undefined,
                fileUrl: "",
              })
            }
          />
        )} */}
      </Modal>
      {/* <Row>
        <Col sm={9}></Col>
        <Col sm={1}>
          {" "}
          <button
            className="e-btn"
            type="button"
            onClick={() => EditProfileHandler()}
          >
            Edit Profile
          </button>
        </Col>
        <Col sm={2}>
          <button className="e-btn" onClick={switchtoaddmode}>
            Switch To Add Mode
          </button>
        </Col>
      </Row> */}
      <div className="MobTextStyle">
        <div className="row">
          <div className="col-md-3">
            <div className="text-center">
              <div>
                {RecuiterCompanyprofiledetials.companyprofileImgUrl ? (
                  <img
                    width="250px"
                    height="250px"
                    src={`${config.PROTOCOL}${config.IMAGE_HOST}/${RecuiterCompanyprofiledetials.companyprofileImgUrl}`}
                    alt=""
                  />
                ) : (
                  <img src="/assets/img/ghimages/blankprofile.png" alt="" />
                )}

                <button className="e-btn pt-10" onClick={openModal}>
                  Set Profile Picture
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <p>Full Name</p>
            <input
              placeholder="Full Name"
              className="form-control marginTop5"
              type="text"
              name="fullName"
              value={RecuiterCompanyprofiledetials.fullName}
              onChange={ProfileDetailsonChange}
            />
            <p>Mobile</p>
            <input
              placeholder="Mobile"
              className="form-control marginTop5"
              type="number"
              name="mobile"
              value={RecuiterCompanyprofiledetials.mobile}
              onChange={ProfileDetailsonChange}
            />
            <p>Company Name</p>
            {companyName == "" ? (
              <input
                placeholder="Company Name"
                className="form-control marginTop5"
                type="text"
                name="companyname"
                value={RecuiterCompanyprofiledetials.companyname}
                onChange={ProfileDetailsonChange}
              />
            ) : (
              <input
                placeholder="Company Name"
                className="form-control marginTop5"
                type="text"
                name="companyname"
                value={RecuiterCompanyprofiledetials.companyname}
                onChange={ProfileDetailsonChange}
                disabled
              />
            )}

            {/* <input
              placeholder="Sector"
              className="form-control marginTop5"
              type="text"
              name="sector"
              value={RecuiterCompanyprofiledetials.sector}
              onChange={ProfileDetailsonChange}
            /> */}
            {/* <input
              placeholder="location"
              className="form-control marginTop5"
              type="text"
              name="location"
              value={RecuiterCompanyprofiledetials.location}
              onChange={ProfileDetailsonChange}
            /> */}

            {/* <input
              placeholder="DOB"
              className="form-control marginTop5"
              type="text"
              name="dob"
              value={RecuiterCompanyprofiledetials.dob}
              onChange={ProfileDetailsonChange}
            /> */}
            {/* <input
              placeholder="About Company"
              className="form-control marginTop5"
              type="text"
              name="aboutcompany"
              value={RecuiterCompanyprofiledetials.aboutcompany}
              onChange={ProfileDetailsonChange}
            /> */}
          </div>
        </div>
      </div>
      <div className="text-center">
        {editMode == false ? (
          <>
            <button
              className="e-btn"
              type="button"
              onClick={() => SubmitRecuiterCompanyProfile()}
            >
              Submit
            </button>
          </>
        ) : (
          <button
            className="e-btn"
            onClick={SubmitUpdateRecuiterCompanyProfile}
          >
            Submit
          </button>
        )}
      </div>
    </Wrapper>
  );
};

export default RecuiterCompanyProfile;
