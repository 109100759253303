export enum AssessmentType {
  APTITUDE = 'APTITUDE',
  SOFT_SKILL = 'SOFT_SKILL',
}

export enum AssessmentSubType {
  // APTITUDE
  VERBAL = 'VERBAL',
  LOGICAL = 'LOGICAL',
  QUANTITATIVE = 'QUANTITATIVE',
  DATA_INTERPRETATION = 'DATA_INTERPRETATION',

  // SOFT-SKILL
  SELF_AWARENESS = 'SELF_AWARENESS',
  COMMUNICATION = 'COMMUNICATION',
  PEOPLE_COLLABORATION = 'PEOPLE_COLLABORATION',
  STRESS_HANDLING = 'STRESS_HANDLING',

  MIX = 'MIX',
}

export enum AssessmentDifficulty {
  HARD = 'HARD',
  MODERATE = 'MODERATE',
  EASY = 'EASY',
  NA = 'N/A',
}

export interface AvailableAssessmentResponse {
  assessmentId: string
  assessmentTitle?: string
  assessmentType: AssessmentType
  assessmentSubType: AssessmentSubType
  difficulty: AssessmentDifficulty
  testId?: string
  time: number
}
