import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import {
  GetCourseByMentorId,
  AddStudentToMentor,
  EditCourseListing,
  DeleteCourseListing,
  getMentorlisting,
  DeleteGroupListing,
  EditGroupListing,
  GetAllGroupListing,
} from "../../services/mentor-service";
import { getDataFromLocalStorage } from "../../browser-storage";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import * as DOMPurify from "dompurify";
import config from "../../services/config";
//import MentorSliderMenu from "./mentorslidermenu";
import { v4 as uuid } from "uuid";

const AdminManageStudentMentor = () => {
  const editor = useRef(null);
  const [mentorlisting, setMentorListing] = useState([]);
  const [grouplisting, setGroupListing] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [mentorname, setmentorname] = useState("");

  useEffect(() => {
    GetAllGroupListingFunction();
  }, []);

  const GetAllGroupListingFunction = async () => {
    const SubmitResponse = await GetAllGroupListing()
      .then((resp) => {
        console.log(resp);

        const respreverse = resp.reverse();
        setGroupListing(resp);
      })
      .catch((err) => console.log(err));
  };

  const [GroupStudentMentorDetails, setGroupStudentMentorDetails] = useState({
    group_code: "",
    group_title: "",
    mentor_name: "",
    mentorEmailId: "",
    student_collection: [],
    added_date: "",
  });

  let EditGroupHandler = (item) => {
    console.log(item);
    console.log(item.group_code);
    setEditMode(true);
    setGroupStudentMentorDetails({
      group_code: item.group_code,
      group_title: item.group_title,
      mentor_name: item.mentor_name,
      mentorEmailId: item.mentorEmailId,
      student_collection: item.student_collection,
      added_date: item.added_date,
    });
    setStudentMentorDetails(item.student_collection);
  };

  let DeleteGroupHandler = (item) => {
    console.log(item);
    const group_code = item.group_code;
    DeleteGroupListing(group_code).then((resp) => {
      console.log(resp);
    });
  };

  let GroupStudentMentorDetailsonChange = (evt) => {
    const value = evt.target.value;
    setGroupStudentMentorDetails({
      ...GroupStudentMentorDetails,
      [evt.target.name]: value,
    });
    console.log(GroupStudentMentorDetails);
  };

  const [StudentMentorDetails, setStudentMentorDetails] = useState([
    {
      studentEmailId: "",
      student_name: "",
      remark: "",
      score: "",
    },
  ]);

  let StudentMentorhandleChange = (i, e) => {
    let newStudentMentorDetails = [...StudentMentorDetails];
    newStudentMentorDetails[i][e.target.name] = e.target.value;
    StudentMentorDetails[i][e.target.name] =
      newStudentMentorDetails[i][e.target.name];
    setStudentMentorDetails(StudentMentorDetails);
    setStudentMentorDetails([...StudentMentorDetails]);
    console.log(StudentMentorDetails);
  };

  let addStudentMentorFormFields = () => {
    setStudentMentorDetails([
      ...StudentMentorDetails,
      {
        studentEmailId: "",
        student_name: "",
        remark: "",
        score: "",
      },
    ]);
  };

  let removeStudentMentorFormFields = (i) => {
    let newStudentMentorDetails = [...StudentMentorDetails];
    newStudentMentorDetails.splice(i, 1);
    setStudentMentorDetails(newStudentMentorDetails);
  };

  let SubmitStudentMentoreDetials = async () => {
    console.log("GroupStudentMentorDetails", GroupStudentMentorDetails);
    GroupStudentMentorDetails.student_collection = StudentMentorDetails;
    console.log(GroupStudentMentorDetails);

    const SubmitResponse = await AddStudentToMentor(GroupStudentMentorDetails)
      .then((resp) => {
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
        window.location.reload();
      })
      .catch((err) =>
        toast.error(`Error ${err} `, {
          position: "top-left",
        })
      );
  };

  let SubmitEditStudentMentoreDetials = async () => {
    const SubmitResponse = await EditGroupListing(GroupStudentMentorDetails)
      .then((resp) => {
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
      })
      .catch((err) =>
        toast.error(`Error ${err} `, {
          position: "top-left",
        })
      );
  };
  let switchtoaddmode = () => {
    setEditMode(false);
  };

  return (
    <Wrapper>
      <SEO pageTitle={"Manage Course"} />
      {/* <MentorSliderMenu /> */}

      <Container>
        <Row style={{ marginTop: "50px" }}>
          <Col className="divscroll">
            <Card className="MainCard">
              <div className="section__title-wrapper mb-45">
                <h2 className="section__title">
                  Add{" "}
                  <span className="yellow-bg">
                    Student To Mentor{" "}
                    <img src="/assets/img/shape/yellow-bg-2.png" alt="" />
                  </span>
                  Listing
                </h2>
              </div>
              {grouplisting.map((item) => {
                return (
                  <Card className="carddesign">
                    <Container className="jobcontainer">
                      <Row>
                        <p className="jobtitle">
                          <b className="boldtitle">
                            Group Title: &nbsp;&nbsp;{item.group_code}
                          </b>
                        </p>
                      </Row>
                      <Row>
                        <p className="companytitle">
                          <img
                            width="50px"
                            src="/assets/img/ghjob/blankcompany.png"
                            style={{ borderRadius: "50%" }}
                          />{" "}
                          &nbsp;&nbsp;Group title: &nbsp;
                          {item.group_title}
                        </p>
                      </Row>
                      <Row>
                        <Col>
                          <p>
                            Mentor Name:&nbsp;
                            {item.mentor_name}
                          </p>
                        </Col>
                        {/* <Col>
                          <p>
                            Videos Count&nbsp;
                            {item.course_videos_count}
                          </p>
                        </Col> */}
                      </Row>
                      {/* <Row>
                
                        <p>Description:</p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.course_description,
                          }}
                        />
                      </Row> */}
                      <Row>
                        <Col>
                          <button
                            className="e-btn"
                            onClick={() => EditGroupHandler(item)}
                          >
                            Edit
                          </button>
                        </Col>
                        <Col>
                          <button
                            className="e-btn"
                            onClick={() => DeleteGroupHandler(item)}
                          >
                            Delete
                          </button>
                        </Col>
                      </Row>
                      <Row></Row>
                    </Container>
                  </Card>
                );
              })}
            </Card>
          </Col>
          <Col>
            <Card className="MainCard">
              {editMode == false ? (
                <>
                  <Col>
                    <div className="section__title-wrapper mb-45">
                      <h2 className="section__title">
                        <span className="yellow-bg">
                          Studen{" "}
                          <img src="/assets/img/shape/yellow-bg-2.png" alt="" />
                        </span>
                        Mentor Group
                      </h2>
                    </div>
                  </Col>
                </>
              ) : (
                <>
                  <Container>
                    <Row>
                      <Col sm={8}>
                        <div className="section__title-wrapper mb-45">
                          <h2 className="section__title">
                            Edit{" "}
                            <span className="yellow-bg">
                              Course{" "}
                              <img
                                src="/assets/img/shape/yellow-bg-2.png"
                                alt=""
                              />
                            </span>
                            Listing
                          </h2>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <button className="e-btn" onClick={switchtoaddmode}>
                          Switch To Add Mode
                        </button>
                      </Col>
                    </Row>
                  </Container>
                </>
              )}

              <Container>
                <div className="MobTextStyle">
                  <input
                    placeholder="Group Title"
                    className="form-control marginTop5"
                    type="text"
                    name="group_title"
                    value={GroupStudentMentorDetails.group_title}
                    onChange={GroupStudentMentorDetailsonChange}
                  />
                  <input
                    placeholder="Mentor Name"
                    className="form-control marginTop5"
                    type="text"
                    name="mentor_name"
                    value={GroupStudentMentorDetails.mentor_name}
                    onChange={GroupStudentMentorDetailsonChange}
                  />

                  <input
                    placeholder="Mentor Email Id"
                    className="form-control marginTop5"
                    type="text"
                    name="mentorEmailId"
                    value={GroupStudentMentorDetails.mentorEmailId}
                    onChange={GroupStudentMentorDetailsonChange}
                  />
                </div>
                <Card className="carddesign marginTop5">
                  <Card.Body>
                    <Card.Title>Add Student Collection</Card.Title>
                    <Card.Text>
                      {StudentMentorDetails.map((element, index) => (
                        <div className="form-inline" key={index}>
                          <input
                            placeholder="Student Email Id"
                            className="form-control"
                            type="text"
                            name="studentEmailId"
                            value={element.studentEmailId}
                            onChange={(e) =>
                              StudentMentorhandleChange(index, e)
                            }
                          />
                          <input
                            placeholder="Student Name"
                            className="form-control"
                            type="text"
                            name="student_name"
                            value={element.student_name}
                            onChange={(e) =>
                              StudentMentorhandleChange(index, e)
                            }
                          />
                          <input
                            placeholder="Remark"
                            className="form-control"
                            type="text"
                            name="remark"
                            value={element.remark}
                            onChange={(e) =>
                              StudentMentorhandleChange(index, e)
                            }
                          />
                          <input
                            placeholder="Score"
                            className="form-control"
                            type="text"
                            name="score"
                            value={element.score}
                            onChange={(e) =>
                              StudentMentorhandleChange(index, e)
                            }
                          />

                          {index ? (
                            <button
                              type="button"
                              className="btn btn-primary button remove"
                              onClick={() =>
                                removeStudentMentorFormFields(index)
                              }
                            >
                              Remove
                            </button>
                          ) : null}
                          <hr />
                        </div>
                      ))}
                      <div className="button-section">
                        <button
                          className="e-btn"
                          type="button"
                          onClick={() => addStudentMentorFormFields()}
                        >
                          Add
                        </button>
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Container>
              {editMode == false ? (
                <>
                  <button
                    className="e-btn"
                    onClick={SubmitStudentMentoreDetials}
                  >
                    Add Submit
                  </button>
                </>
              ) : (
                <button
                  className="e-btn marginTop5"
                  onClick={SubmitEditStudentMentoreDetials}
                >
                  Edit Submit
                </button>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default AdminManageStudentMentor;
