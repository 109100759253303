import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Wrapper from "../../layout/wrapper";
import { Link, useLocation } from "react-router-dom";
import { AppConfigEvent } from "../../redux/types/redux-event";
import {
  cognicuecandidateinterview,
  addQuickCandidateInterview,
  quickInterview,
} from "../../services/quick-interview";

import {
  quickInterviewToken,
  getQuickInterviewDetailsbyId,
} from "../../services/quick-interview";
import config from "../../services/config";
import { getDataFromLocalStorage } from "../../browser-storage";
import styled from "styled-components";
import ReactPlayer from "react-player";
import "./studentinteractivedashboard.css";
import {
  GenerateAIInterviewSSOToken,
  getAIInterviewAttemptedList,
  getAIInterviewByHostname,
} from "../../services/ai-interview";
import Card from "react-bootstrap/Card";

const Video = styled.iframe`
  display: flex;
  align-items: center;
  border: none;
  max-width: 490px;
  width: 100%;
  height: 100%;
  max-height: 276px;
  min-height: 276px;
  box-shadow: 9px 14px 15px rgba(0, 0, 0, 0.25);
`;

const QuickInterview = (res) => {
  let candidateEmail = "";

  const dispatch = useDispatch();
  const [isInterviewCompleted, setisInterviewCompleted] = useState("false");
  const [current_student_details, setcurrentStudentDetails] = useState();
  const location = useLocation();

  //This is just to start the Interview Process
  const startAIinterview = () => {
    quickInterviewToken().then((token) => {
      window.location.href = config.COGNICUE_CREATE_STUDENT_INTERVIEW + token;
    });
  };
  // const startAIinterview = (currentElement) => {
  //   console.log("currentElement", currentElement);
  //   GenerateAIInterviewSSOToken(currentElement).then((token) => {
  //     window.location.href = config.COGNICUE_CREATE_STUDENT_INTERVIEW + token;
  //   });
  // };
  //New Step 1  Check if the User is new or test given
  useEffect(() => {
    if ((location.pathname = "/dashboard/quick-interview")) {
      checkAIInterviewGiven();
    }

    if (!location.search) {
      console.log("user first came");
      return;
    } else {
      // User came after completing the test
      setisInterviewCompleted("true");

      saveInterview()
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [location, setisInterviewCompleted]);

  const checkAIInterviewGiven = async () => {
    try {
      const userDetials = getDataFromLocalStorage("user");
      const email = userDetials.email;
      const student_emailId = email;

      const resp = await getQuickInterviewDetailsbyId(student_emailId);

      console.log(resp);

      if (resp && resp.length > 0) {
        const checkiscandidatethere = resp[0].candidate_interview_id;
        if (checkiscandidatethere) {
          setisInterviewCompleted("true");
        }
        setcurrentStudentDetails(resp);
      }
    } catch (err) {
      console.log(err);
    }
  };

  let saveInterview = async () => {
    try {
      const searchparam = location.search;
      const jwttoken = searchparam.slice(5);

      if (jwttoken) {
        const res = await quickInterview(jwttoken);
        console.log("res", res);

        const payloadobj = JSON.parse(JSON.stringify(res));
        const candidate_interview_id = payloadobj.candidate_interview_id;
        candidateEmail = payloadobj.candidate_email;
        console.log(candidate_interview_id);

        if (res) {
          const new_resp = await cognicuecandidateinterview(
            candidate_interview_id
          );
          console.log(new_resp);

          const candidate_email = candidateEmail;
          const interview_score = new_resp.interviews[0].interview_score;
          const candidate_id = new_resp.candidate_id;
          const candidate_analytics_url = new_resp.interviews[0].analytics_url;

          console.log("candidate_email", candidate_email);
          console.log("interview_score", interview_score);
          console.log("candidate_id", candidate_id);
          console.log("candidate_interview_id", candidate_interview_id);

          const payload = {
            candidate_email: candidate_email,
            interview_score: interview_score,
            candidate_id: candidate_id,
            candidate_interview_id: candidate_interview_id,
            candidate_analytics_url: candidate_analytics_url,
          };
          // To Store the Interview Details
          const finalresp = await addQuickCandidateInterview(payload);
          console.log(finalresp);

          toast.success(`AI Interview Successfully Completed`, {
            position: "top-left",
          });

          dispatch({ type: AppConfigEvent.STOP_LOADING });
        }
      }
    } catch (err) {
      toast.error(`Error : ${err.response.data}`, {
        position: "top-left",
      });
    }
  };

  const accessAIInterviewReport = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 4a4dc12a68044c94e6bd351a707de2efb3301692"
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://services.cognicue.in/api/token/obtain/", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        const json_result = JSON.parse(result);
        console.log("json_result", json_result);
        const access_resp = json_result.access;
        console.log("access_resp", access_resp);
        console.log("current_student_details", current_student_details);

        const final_link =
          current_student_details[0].candidate_analytics_url +
          "?access=" +
          access_resp;
        console.log("final_link", final_link);
        //window.location.href = final_link;
        window.open(
          final_link,
          "_blank" // <- This is what makes it open in a new window.
        );
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getAIInterviewByHostnameFunc();
    checkStudentAttemptedInterviewFunc();
  }, []);

  const [interviewList, setinterviewList] = useState([]);
  const [studentattemptedinterview, setAttemptedList] = useState([]);
  const [attemptedlength, setattemptedlength] = useState(0);

  const getAIInterviewByHostnameFunc = async () => {
    console.log("window.location.hostname", window.location.hostname);
    const res = await getAIInterviewByHostname(window.location.hostname)
      .then((resp) => {
        console.log(resp);
        const list = resp;
        setinterviewList(list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkStudentAttemptedInterviewFunc = async () => {
    const userDetials = getDataFromLocalStorage("user");
    const email = userDetials.email;
    const student_emailId = email;
    const res = await getAIInterviewAttemptedList(student_emailId)
      .then((resp) => {
        console.log(resp);
        const list = resp;
        const listLength = list.length;
        console.log("listLength", listLength);
        setAttemptedList(list);
        setattemptedlength(listLength);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    CompareandCreateFinalArray();
  }, [interviewList, studentattemptedinterview]);

  const [finalArray, setFinalArray] = useState([]);

  const CompareandCreateFinalArray = async () => {
    // const newArray = interviewList.map((item1) => {
    //   // Check if there's any element in studentattemptedinterview with a matching interviewId
    //   const item2 = studentattemptedinterview.find(
    //     (item2) => item2.interviewId === item1.interviewId
    //   );
    //   const interviewAttempted = !!item2;

    //   return {
    //     ...item1,
    //     ...(item2 && { InterviewAttempted: interviewAttempted }), // Add InterviewAttempted only if a match is found
    //   };
    // });

    // console.log("newArray", newArray);

    const newArray = interviewList.map((item1) => {
      // Check if there's any element in studentattemptedinterview with a matching interviewId
      const item2 = studentattemptedinterview.find(
        (item2) => item2.interviewId === item1.interviewId
      );

      if (item2) {
        // Add specific fields from item2 to item1 if a match is found
        const {
          candidate_analytics_url,
          candidate_email,
          candidate_id,
          candidate_interview_id,
          hostname_registered,
        } = item2;

        return {
          ...item1,
          InterviewAttempted: true,
          candidate_analytics_url,
          candidate_email,
          candidate_id,
          candidate_interview_id,
          hostname_registered,
        };
      } else {
        return {
          ...item1,
          InterviewAttempted: false,
        };
      }
    });

    console.log("newArray", newArray);
    setFinalArray(newArray);
  };

  return (
    <>
      <Wrapper>
        <div className="" style={{ padding: 10 }}>
          <div className="row">
            <div className="col-md-6">
              <div>
                <div className="desktop">
                  <video
                    oncontextmenu="return false;"
                    id="myVideo"
                    controls
                    controlsList="nodownload"
                    width="600px"
                    height="410px"
                  >
                    <source
                      src="https://www.mygreenhorn.in/Videos/AI.webm"
                      type="video/mp4"
                    />
                  </video>
                  {/* <ReactPlayer
                    url="https://www.mygreenhorn.in/Videos/AI.webm"
                    controls
                    light="/assets/img/ghimages/AIWhiteThumb.jpg"
                    config={{
                      file: {
                        attributes: { controlsList: "nodownload" },
                      },
                    }}
                    width="630px"
                    height="410px"
                  /> */}
                  {/* <Video src={`https://www.mygreenhorn.in/Videos/AI.webm`} /> */}
                </div>
                <div className="mobile">
                  {/* <Video src={`https://www.mygreenhorn.in/Videos/AI.webm`} /> */}
                  <video
                    oncontextmenu="return false;"
                    id="myVideo"
                    controls
                    controlsList="nodownload"
                    width="489px"
                  >
                    <source
                      src="https://www.mygreenhorn.in/Videos/AI.webm"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <p
                className="pt-40"
                style={{ paddingLeft: 20, paddingRight: 20 }}
              >
                Our AI Bot will take your video interview and analyse the result
                in details. You will receive your overall score and screen
                recording of the actual interview. You will also receive a
                detailed report on how you performed on important parameters
                such as communication, confidence, facial expression, and body
                language. Discuss the report with your mentor and take necessary
                action to fix all your critical gaps before your real interview.
              </p>
              {isInterviewCompleted == "false" ? (
                <>
                  <div className="row pt-20 pb-20 text-center">
                    <div className="col-md-4"></div>
                    <div className="col-md-3">
                      <button
                        className="e-btn"
                        onClick={() => startAIinterview()}
                      >
                        Take Interview
                      </button>
                    </div>
                  </div>
                </>
              ) : null}
              {isInterviewCompleted == "true" ? (
                <>
                  Your AI Interview is completed. please click on the button
                  below to access your analytics report.
                  <br />
                  <div className="row pt-20 pb-20">
                    <div className="col-md-3">
                      <button
                        className="e-btn"
                        onClick={() => accessAIInterviewReport()}
                      >
                        View Interview Report
                      </button>
                    </div>
                  </div>
                  <br />
                  <Link
                    style={{ marginTop: 50 }}
                    className="e-btn"
                    to={"/student-dashboard"}
                  >
                    Go To Dashboard
                  </Link>
                </>
              ) : null}
              {/* <div className="row">
                {finalArray.map((currentElement, index) => {
                  const previousElement =
                    index > 0 ? finalArray[index - 1] : null;
                  const isUnlocked =
                    index === 0 ||
                    (previousElement && previousElement.InterviewAttempted);

                  return (
                    <div className="col-md-3" key={index}>
                      <Card style={{ width: "18rem" }}>
                        <Card.Img
                          variant="top"
                          src="/assets/img/ghimages/ntg-AI_Interview.png"
                        />
                        <Card.Body>
                          <Card.Title>
                            {currentElement.interviewTitle}{" "}
                          </Card.Title>

                          {isUnlocked ? (
                            <>
                              {!currentElement.candidate_analytics_url ? (
                                <>
                                  <button
                                    className="e-btn"
                                    onClick={() =>
                                      startAIinterview(currentElement)
                                    }
                                  >
                                    Take Interview
                                  </button>
                                </>
                              ) : null}
                            </>
                          ) : (
                            <>
                              <p>
                                Please Complete Previous Interview to Unlock
                                this
                              </p>
                            </>
                          )}

                          {currentElement.candidate_analytics_url ? (
                            <a
                              className="e-btn"
                              href={currentElement.candidate_analytics_url}
                            >
                              View Report
                            </a>
                          ) : null}

                          {/* <button
                      className="e-btn"
                      onClick={() => startAIinterview()}
                    >
                      Take Interview
                    </button> */}
              {/*    </Card.Body>
                      </Card>
                    </div>
                  );
                })}
              </div> */}
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};
export default QuickInterview;
