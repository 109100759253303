import React from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Wrapper from "../../layout/wrapper";
//import "../LoginPage/login.css";
// import logo from "../../assets/images/Greenhorn.png";
// import ForgotPasswordIcon from "../../assets/images/forgot-password.svg";
// import { AppConfigEvent } from "types/redux-event";

import { resetPasswordbyAdmin } from "../../services/user-service";
import { useEffect } from "react";

export default function AdminPasswordReset() {
  const [details, setDetails] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!details.password || !details.confirmPassword) {
      toast.error(`Error : Please check your username and password `, {
        position: "top-left",
      });
      return;
    }
    if (details.password !== details.confirmPassword) {
      toast.error(`Error : Password and Confirm Password is not same `, {
        position: "top-left",
      });
      return;
    }

    const resetPasswordResponse = await resetPasswordbyAdmin(details).catch(
      (err) => {
        toast.error(`Error : Link Expired, Please create new link. ${err} `, {
          position: "top-left",
        });
      }
    );

    if (resetPasswordResponse) {
      toast.success(`Password Reset Successfully `, {
        position: "top-left",
      });
    }
  };

  return (
    <>
      <Wrapper>
        <form onSubmit={submitHandler}>
          <div className="container carddesign">
            <h2>Reset Password</h2>
            <div className="form-group p-b-15">
              {/* <input type="" placeholder=""/> */}
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Enter Email"
                onChange={(e) =>
                  setDetails({ ...details, email: e.target.value })
                }
                className="form-control"
                value={details.email}
                autoComplete="off"
              />
            </div>
            <p>Please enter new password to reset current password</p>
            <div className="form-group p-b-15">
              <input
                type="password"
                name="password"
                id="password"
                placeholder="New Password"
                onChange={(e) =>
                  setDetails({ ...details, password: e.target.value })
                }
                className="form-control"
                value={details.password}
                autoComplete="off"
              />
            </div>
            <div className="form-group p-b-15">
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Confirm Password"
                onChange={(e) =>
                  setDetails({ ...details, confirmPassword: e.target.value })
                }
                className="form-control"
                value={details.confirmPassword}
                autoComplete="off"
              />
            </div>

            <input
              style={{ margin: 20 }}
              type="submit"
              value="Submit"
              className="btn e-btn"
            />
          </div>
        </form>
      </Wrapper>
    </>
  );
}
