import React, { useRef, useEffect, useState } from "react";
import "../resumebuilder/resumebuilder.css";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { useNavigate } from "react-router-dom";
import YouTube from "react-youtube";
import styled from "styled-components";
const Video = styled.iframe`
  display: flex;
  align-items: center;
  border: none;
  max-width: 490px;
  width: 100%;
  height: 100%;
  max-height: 276px;
  min-height: 276px;
  box-shadow: 9px 14px 15px rgba(0, 0, 0, 0.25);
`;
const ResumeBuilder = () => {
  const navigate = useNavigate();

  const opts = {
    width: "450",
    height: "250",

    playerVars: {
      autoplay: 0,
    },
  };

  let _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  return (
    <>
      <Wrapper>
        <SEO pageTitle={"Resume Builder"} />

        <Row style={{ margin: "20px" }}>
          <Card className="carddesign">
            <Card.Body>
              <Row>
                <Col sm={4}></Col>
                <Col sm={8}></Col>

                <Col sm={6}>
                  <div className="text-center">
                    <embed
                      src={"/assets/img/ghimages/job_description.svg"}
                      alt={"img"}
                    />
                  </div>
                  <Row>
                    <Col sm={6}>
                      <div style={{ border: "2px solid #449399" }}>
                        <embed
                          width={320}
                          src={"/assets/img/ghimages/svg_of3.svg"}
                          alt={"img"}
                        />
                      </div>
                      <Container className="text-center">
                        <div
                          style={{
                            marginTop: 20,
                            cursor: "pointer",
                            background: "#449399",
                          }}
                          className="e-btn"
                          onClick={() =>
                            navigate("/resume-builder-tool-option-three")
                          }
                        >
                          Use Template
                        </div>
                        <div
                          style={{
                            marginTop: 20,
                            cursor: "pointer",
                            background: "#449399",
                          }}
                          className="e-btn"
                        >
                          <a
                            href="https://mygreenhorn.com/data/resume-sample-pdf/sample_resume.pdf"
                            target="_blank"
                          >
                            View / Download Sample PDF
                          </a>
                        </div>
                      </Container>
                    </Col>
                    <Col sm={6}>
                      <div style={{ border: "2px solid #449399" }}>
                        <embed
                          width={320}
                          src="/assets/img/ghimages/svg_of2.svg"
                        />
                      </div>
                      <Container className="text-center">
                        <div
                          style={{
                            marginTop: 20,
                            cursor: "pointer",
                            background: "#449399",
                          }}
                          className="e-btn"
                          onClick={() =>
                            navigate("/resume-builder-tool-option-four")
                          }
                        >
                          Use Template
                        </div>
                        <div
                          style={{
                            marginTop: 20,
                            cursor: "pointer",
                            background: "#449399",
                          }}
                          className="e-btn"
                        >
                          <a
                            href="https://mygreenhorn.com/data/resume-sample-pdf/sample_resume_with_image.pdf"
                            target="_blank"
                          >
                            View / Download Sample PDF
                          </a>
                        </div>
                      </Container>
                    </Col>
                  </Row>
                  <Row className="mt-20">
                    <Col sm={6}>
                      <div style={{ border: "2px solid #449399" }}>
                        <embed
                          width={320}
                          src={"/assets/img/ghimages/op3.svg"}
                          alt={"img"}
                        />
                      </div>
                      <Container className="text-center">
                        <div
                          style={{
                            marginTop: 20,
                            cursor: "pointer",
                            background: "#449399",
                          }}
                          className="e-btn"
                          onClick={() =>
                            navigate("/resume-builder-tool-option-five")
                          }
                        >
                          Use Template
                        </div>
                        <div
                          style={{
                            marginTop: 20,
                            cursor: "pointer",
                            background: "#449399",
                          }}
                          className="e-btn"
                        >
                          <a
                            href="https://mygreenhorn.com/data/resume-sample-pdf/op3.pdf"
                            target="_blank"
                          >
                            View / Download Sample PDF
                          </a>
                        </div>
                      </Container>
                    </Col>
                    <Col sm={6}>
                      <div style={{ border: "2px solid #449399" }}>
                        <embed
                          width={320}
                          src="/assets/img/ghimages/op3_img.svg"
                        />
                      </div>
                      <Container className="text-center">
                        <div
                          style={{
                            marginTop: 20,
                            cursor: "pointer",
                            background: "#449399",
                          }}
                          className="e-btn"
                          onClick={() =>
                            navigate("/resume-builder-tool-option-six")
                          }
                        >
                          Use Template
                        </div>
                        <div
                          style={{
                            marginTop: 20,
                            cursor: "pointer",
                            background: "#449399",
                          }}
                          className="e-btn"
                        >
                          <a
                            href="https://mygreenhorn.com/data/resume-sample-pdf/op3img.pdf"
                            target="_blank"
                          >
                            View / Download Sample PDF
                          </a>
                        </div>
                      </Container>
                    </Col>
                  </Row>
                </Col>
                <Col sm={6}>
                  {/* <div className="text-center">
                    <div className="desktop">
                      <YouTube
                        className="cusmobilevideo"
                        videoId="KdSSN5q3Vls"
                        opts={opts}
                        onReady={_onReady}
                      />
                    </div>
                    <div className="mobile">
                      <Video
                        src={`https://www.youtube.com/embed/KdSSN5q3Vls`}
                      />
                    </div>
                  </div> */}
                  <div className="text-center">
                    <embed
                      width={700}
                      src={"/assets/img/ghimages/report.svg"}
                      alt={"img"}
                    />
                    <div
                      style={{
                        marginTop: 20,
                        cursor: "pointer",
                        background: "#449399",
                        color: "white",
                      }}
                      className="btn"
                    >
                      <a
                        href="https://mygreenhorn.com/data/resume-sample-pdf/report.pdf"
                        target="_blank"
                      >
                        View / Download ATS report for sample resume
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </Wrapper>
    </>
  );
};

export default ResumeBuilder;
