import axios from "axios";
import config from "./config";
import { getDataFromLocalStorage } from "../browser-storage";
import { LocalStorageConstants } from "./localstorageconstants";

const getToken = () => getDataFromLocalStorage(LocalStorageConstants.TOKEN);

const getNewAssessmentListing = (): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .get(`${config.PROTOCOL}${config.HOST}/new-assessment/get-new-assessment`, {
      headers,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const SubmitNewAssessmentScore = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/new-assessment/submit-assessment-score`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const getNewAssessmentScorebyId = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/new-assessment/get-new-assessment-by-id`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
export {
  getNewAssessmentListing,
  SubmitNewAssessmentScore,
  getNewAssessmentScorebyId,
};
