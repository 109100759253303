import React, { useRef, useEffect, useState } from "react";
import "../resumebuilder/resumebuilder.css";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Badge from "react-bootstrap/Badge";
import { useReactToPrint } from "react-to-print";
import {
  setDataInLocalStorage,
  getDataFromLocalStorage,
} from "../../browser-storage";
import axios from "axios";
import config from "../../services/config";
import { toast } from "react-toastify";
import Accordion from "react-bootstrap/Accordion";
import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";

import "./resumebuilder.css";
import {
  MonthArray,
  MonthNumberArray,
  YearArray,
  DayArray,
  Circle,
  CirlceRow,
} from "./resume-builder-functions";
import ResumeProfileImg from "./resumeProfileImg";
import ResumeBuilderParentComponent from "./resume-builder-parent";

const ResumeBuilderToolOptionThree = () => {
  const componentRef = useRef();

  let handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => getSavedData(), []);

  let getSavedData = () => {
    const userDetials = getDataFromLocalStorage("user");
    const email = userDetials.email;
    const Createdby = { CreatedBy: email };
    axios
      .post(`${config.PROTOCOL}${config.HOST}/resume/get-resume`, Createdby)
      .then(function (response) {
        console.log(response.data);
        const resumeResp = response.data;
        let getWorkExprience = [];
        if (resumeResp) {
          setDataInLocalStorage("savedResumeData", resumeResp);

          const SavedResume = getDataFromLocalStorage("savedResumeData");
          const getBasicDetails = SavedResume.BasicDetails;
          const getEducation = SavedResume.Education;
          if (SavedResume.WorkExprience) {
            getWorkExprience = SavedResume.WorkExprience;
          }
          const getCertification = SavedResume.Certification;
          const getAboutme = SavedResume.Aboutme;
          const getSkills = SavedResume.Skills;
          const getLanguages = SavedResume.Languages;
          const getAwards = SavedResume.Awards;
          setBasicDetails(getBasicDetails);
          setEducation(getEducation);

          setCertification(getCertification);
          if (SavedResume.WorkExprience) {
            setWorkExprience(getWorkExprience);
          }
          setAboutme(getAboutme);
          setSkills(getSkills);
          setLanguages(getLanguages);
          setAwards(getAwards);
          console.log(SavedResume);
        } else {
          toast.error(`You don't have a saved Resume yet`, {
            position: "top-left",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  let savepdf = () => {
    const userDetials = localStorage.getItem("user");

    const userDetialsparsed = JSON.parse(userDetials);
    const email = userDetialsparsed.email;

    const CreatedBy = { CreatedBy: email };

    let resumedata = {
      BasicDetails,
      Education,
      Certification,
      WorkExprience,
      Aboutme,
      Skills,
      Languages,
      Awards,
      CreatedBy,
    };
    console.log(resumedata);

    setDataInLocalStorage("savedResumeData", {
      BasicDetails: BasicDetails,
      Education: Education,
      Certification: Certification,
      WorkExprience: WorkExprience,
      Aboutme: Aboutme,
      Skills: Skills,
      Languages: Languages,
      Awards: Awards,
    });

    axios
      .put(`${config.PROTOCOL}${config.HOST}/resume/resume-builder`, resumedata)
      .then(function (response) {
        console.log(response);
        toast.success(`Data Successfully Saved`, {
          position: "top-left",
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [BasicDetails, setBasicDetails] = useState({
    firstname: "",
    middlename: "",
    lastname: "",
    degree: "",
    phonenumber: "",
    profileImgUrl: "blank",
    email: "",
    city: "",
    pincode: "",
    linkedin: "",
  });
  const [Aboutme, setAboutme] = useState([{ Aboutme: "" }]);
  const [WorkExprience, setWorkExprience] = useState([
    {
      startYear: "",
      startMonth: "",
      endYear: "",
      endMonth: "",
      companyName: "",
      location: "",
      designationName: "",
      duration: "",
      description: [],
    },
  ]);
  const [Education, setEducation] = useState([
    {
      startYear: "",
      startMonth: "",
      endYear: "",
      endMonth: "",
      courseName: "",
      degreeName: "",
      collegeName: "",
      score: "",
    },
  ]);

  const [Certification, setCertification] = useState([
    {
      certificateName: "",
      certificateSourceName: "",
      certificateLink: "",
    },
  ]);

  let BasicDetailsonChange = (evt) => {
    const value = evt.target.value;
    setBasicDetails({
      ...BasicDetails,
      [evt.target.name]: value,
    });
    console.log(BasicDetails);
  };

  let AboutmeonChange = (e) => {
    setAboutme({ Aboutme: e.target.value });
    console.log(Aboutme);
  };

  //Education
  let EducationhandleChange = (i, e) => {
    let values = [...Education];
    values[i][e.target.name] = e.target.value;
    setEducation(values);
  };

  let addEducationFormFields = () => {
    setEducation([
      ...Education,
      {
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: "",
        courseName: "",
        degreeName: "",
        collegeName: "",
        score: "",
      },
    ]);
  };

  let removeEducationFormFields = (i) => {
    let values = [...Education];
    values.splice(i, 1);
    setEducation(values);
  };

  //New Work Exp

  const WorkExphandleInputChange = (index, event) => {
    const values = [...WorkExprience];
    values[index][event.target.name] = event.target.value;
    setWorkExprience(values);
  };

  const WorkExphandleSubInputChange = (parentIndex, childIndex, event) => {
    const values = [...WorkExprience];
    values[parentIndex].description[childIndex].value = event.target.value;
    setWorkExprience(values);
  };

  //Not Being Used
  const WorkExpaddInputField = (index) => {
    const values = [...WorkExprience];
    values[index].description.push({ value: "" });
    setWorkExprience(values);
  };

  const WorkExpaddSubInputField = (parentIndex) => {
    const values = [...WorkExprience];
    values[parentIndex].description.push({ value: "" });
    setWorkExprience(values);
  };
  const WorkExpremoveInputField = (index) => {
    const values = [...WorkExprience];
    values.splice(index, 1);
    setWorkExprience(values);
  };

  const WorkExpremoveSubInputField = (parentIndex, childIndex) => {
    const values = [...WorkExprience];
    values[parentIndex].description.splice(childIndex, 1);
    setWorkExprience(values);
  };

  //Certification
  let CertificationhandleChange = (i, e) => {
    let values = [...Certification];
    values[i][e.target.name] = e.target.value;
    setCertification(values);
  };

  let CertificationaddFormFields = () => {
    setCertification([
      ...Certification,
      {
        certificateName: "",
        certificateSourceName: "",
        certificateLink: "",
      },
    ]);
  };

  let CertificationremoveFormFields = (i) => {
    let values = [...Certification];
    values.splice(i, 1);
    setCertification(values);
  };

  //Skills
  const [Skills, setSkills] = useState([{ skillname: "" }]);

  let SkillshandleChange = (i, e) => {
    let values = [...Skills];
    values[i][e.target.name] = e.target.value;
    setSkills(values);
  };

  let addSkillsField = () => {
    setSkills([...Skills, { skillname: "" }]);
  };

  let removeSkillsField = (i) => {
    let values = [...Skills];
    values.splice(i, 1);
    setSkills(values);
  };

  //Languages
  const [Languages, setLanguages] = useState([
    { langugename: "", languagerating: "" },
  ]);

  let LanguagehandleChange = (i, e) => {
    let values = [...Languages];
    values[i][e.target.name] = e.target.value;
    setLanguages(values);
  };

  let addLanguageField = () => {
    setLanguages([...Languages, { langugename: "", languagerating: "" }]);
  };

  let removeLanguageField = (i) => {
    let values = [...Languages];
    values.splice(i, 1);
    setLanguages(values);
  };

  //Awards and Achivements

  const [Awards, setAwards] = useState([{ awardname: "", awardedby: "" }]);
  let AwardsshandleChange = (i, e) => {
    let values = [...Awards];
    values[i][e.target.name] = e.target.value;
    setAwards(values);
  };

  let addAwardField = () => {
    setAwards([...Awards, { awardname: "", awardedby: "" }]);
  };

  let removeAwardsField = (i) => {
    let values = [...Awards];
    values.splice(i, 1);
    setAwards(values);
  };

  const [descriptionfontsize, setdescriptionfontsize] = useState(11);
  const [titlefontsize, settitlefontsize] = useState(13);

  // Variable value out of 5
  const value = 3;

  // Fill circles based on the value
  const circles = Array.from(Array(5), (_, index) => index < value);

  return (
    <Wrapper>
      <SEO pageTitle={"Resume Builder Tool"} />
      <Row>
        <Accordion
          style={{ marginTop: "20px" }}
          defaultActiveKey={["0"]}
          alwaysOpen
        >
          <Accordion.Item eventKey="0">
            <Accordion.Header>Advanced Customizations</Accordion.Header>
            <Accordion.Body>
              <Card>
                <div>
                  <div style={{ padding: 10 }}>
                    Change Title Font Size &nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={() => settitlefontsize(titlefontsize - 1)}
                    >
                      -
                    </button>
                    &nbsp; [{titlefontsize}] &nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={() => settitlefontsize(titlefontsize + 1)}
                    >
                      +
                    </button>
                  </div>
                  <div style={{ padding: 10 }}>
                    Change Description Font Size &nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        setdescriptionfontsize(descriptionfontsize - 1)
                      }
                    >
                      -
                    </button>
                    &nbsp; [{descriptionfontsize}] &nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        setdescriptionfontsize(descriptionfontsize + 1)
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Col className="divscroll" sm={4}>
          <div style={{ margin: 10, color: "#000000" }}>
            <Badge style={{ width: "100%" }} bg="secondary">
              Form
            </Badge>

            <Card className="carddesign">
              <Card.Body>
                <Card.Text>
                  <div className="MobTextStyle">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>BASIC DETAILS</Accordion.Header>
                        <Accordion.Body>
                          <div className="MobTextStyle">
                            <input
                              placeholder="First Name"
                              className="form-control marginTop5"
                              type="text"
                              name="firstname"
                              value={BasicDetails.firstname}
                              onChange={BasicDetailsonChange}
                            />
                            <input
                              placeholder="Middle Name"
                              className="form-control marginTop5"
                              type="text"
                              name="middlename"
                              value={BasicDetails.middlename}
                              onChange={BasicDetailsonChange}
                            />
                            <input
                              placeholder="Last Name"
                              className="form-control marginTop5"
                              type="text"
                              name="lastname"
                              value={BasicDetails.lastname}
                              onChange={BasicDetailsonChange}
                            />
                            <input
                              placeholder="Degree"
                              className="form-control marginTop5"
                              type="text"
                              name="degree"
                              value={BasicDetails.degree}
                              onChange={BasicDetailsonChange}
                            />
                            <input
                              placeholder="Phone"
                              className="form-control marginTop5"
                              type="text"
                              name="phonenumber"
                              value={BasicDetails.phonenumber}
                              onChange={BasicDetailsonChange}
                            />
                            <input
                              placeholder="Email"
                              className="form-control marginTop5"
                              type="text"
                              name="email"
                              value={BasicDetails.email}
                              onChange={BasicDetailsonChange}
                            />
                            <input
                              placeholder="City"
                              className="form-control marginTop5"
                              type="text"
                              name="city"
                              value={BasicDetails.city}
                              onChange={BasicDetailsonChange}
                            />
                            <input
                              placeholder="Pin Code"
                              className="form-control marginTop5"
                              type="number"
                              name="pincode"
                              value={BasicDetails.pincode}
                              onChange={BasicDetailsonChange}
                            />
                            <input
                              placeholder="Linkedin"
                              className="form-control marginTop5"
                              type="text"
                              name="linkedin"
                              value={BasicDetails.linkedin}
                              onChange={BasicDetailsonChange}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="carddesign marginTop5">
              <Card.Body>
                <Card.Text>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>EDUCATION</Accordion.Header>
                      <Accordion.Body>
                        <p style={{ color: "#ffc107", fontWeight: 600 }}>
                          Note: Start with your latest education
                        </p>
                        {Education.map((element, index) => (
                          <div className="form-inline" key={index}>
                            <Row className="marginTop5">
                              <Col>
                                <select
                                  name="startMonth"
                                  className="form-control"
                                  value={Education.startMonth}
                                  onChange={(e) =>
                                    EducationhandleChange(index, e)
                                  }
                                  placeholder="StartMonth"
                                >
                                  <option
                                    name=""
                                    value=""
                                    style={{ display: "none" }}
                                  >
                                    Start Month
                                  </option>

                                  {MonthNumberArray.map((element) => {
                                    return (
                                      <>
                                        <option
                                          name={element.name}
                                          value={element.value}
                                        >
                                          {element.name}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select>
                              </Col>
                              <Col>
                                <select
                                  name="startYear"
                                  className="form-control"
                                  value={Education.startYear}
                                  onChange={(e) =>
                                    EducationhandleChange(index, e)
                                  }
                                  placeholder="StartYear"
                                >
                                  <option
                                    name=""
                                    value=""
                                    style={{ display: "none" }}
                                  >
                                    Start Year
                                  </option>
                                  {YearArray.map((element) => {
                                    return (
                                      <>
                                        <option name={element} value={element}>
                                          {element}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select>
                              </Col>
                            </Row>
                            <Row className="marginTop5">
                              <Col>
                                <select
                                  name="endMonth"
                                  className="form-control"
                                  value={element.endMonth}
                                  onChange={(e) =>
                                    EducationhandleChange(index, e)
                                  }
                                  placeholder="EndMonth"
                                >
                                  <option
                                    name=""
                                    value=""
                                    style={{ display: "none" }}
                                  >
                                    End Month
                                  </option>
                                  {MonthNumberArray.map((element) => {
                                    return (
                                      <>
                                        <option
                                          name={element.name}
                                          value={element.value}
                                        >
                                          {element.name}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select>
                              </Col>
                              <Col>
                                <select
                                  name="endYear"
                                  className="form-control"
                                  value={element.endYear}
                                  onChange={(e) =>
                                    EducationhandleChange(index, e)
                                  }
                                  placeholder="EndYear"
                                >
                                  <option
                                    name=""
                                    value=""
                                    style={{ display: "none" }}
                                  >
                                    End Year
                                  </option>
                                  {YearArray.map((element) => {
                                    return (
                                      <>
                                        <option name={element} value={element}>
                                          {element}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select>
                              </Col>
                            </Row>
                            <input
                              placeholder="School / College Name"
                              className="form-control"
                              type="text"
                              name="collegeName"
                              value={element.collegeName || ""}
                              onChange={(e) => EducationhandleChange(index, e)}
                            />
                            <input
                              placeholder="Class / Degree Name"
                              className="form-control"
                              type="text"
                              name="degreeName"
                              value={element.degreeName || ""}
                              onChange={(e) => EducationhandleChange(index, e)}
                            />
                            <input
                              placeholder="Board / Course Name"
                              className="form-control"
                              type="text"
                              name="courseName"
                              value={element.courseName || ""}
                              onChange={(e) => EducationhandleChange(index, e)}
                            />
                            <input
                              placeholder="Score"
                              className="form-control"
                              type="text"
                              name="score"
                              value={element.score || ""}
                              onChange={(e) => EducationhandleChange(index, e)}
                            />
                            {index ? (
                              <button
                                type="button"
                                className="btn btn-primary button remove"
                                onClick={() => removeEducationFormFields(index)}
                              >
                                Remove
                              </button>
                            ) : null}
                          </div>
                        ))}
                        <div className="button-section">
                          <button
                            className="btn btn-primary button add"
                            type="button"
                            onClick={() => addEducationFormFields()}
                          >
                            Add
                          </button>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="carddesign marginTop5">
              <Card.Body>
                <Card.Text>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>WORK EXPERIENCE</Accordion.Header>
                      <Accordion.Body>
                        <p style={{ color: "#ffc107", fontWeight: 600 }}>
                          Start with your latest work experience
                        </p>
                        {WorkExprience.map((field, index) => (
                          <div key={index}>
                            <div className="row">
                              <div className="col-md-6">
                                <select
                                  name="startMonth"
                                  className="form-control dateHeight"
                                  value={field.startMonth}
                                  onChange={(event) =>
                                    WorkExphandleInputChange(index, event)
                                  }
                                  placeholder="StartMonth"
                                >
                                  <option
                                    name=""
                                    value=""
                                    style={{ display: "none" }}
                                  >
                                    Start Month
                                  </option>
                                  {MonthNumberArray.map((element) => {
                                    return (
                                      <>
                                        <option
                                          name={element.name}
                                          value={element.value}
                                        >
                                          {element.name}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-md-6">
                                <select
                                  name="startYear"
                                  className="form-control dateHeight"
                                  value={field.startYear}
                                  onChange={(event) =>
                                    WorkExphandleInputChange(index, event)
                                  }
                                  placeholder="StartYear"
                                >
                                  <option
                                    name=""
                                    value=""
                                    style={{ display: "none" }}
                                  >
                                    Start Year
                                  </option>
                                  {YearArray.map((element) => {
                                    return (
                                      <>
                                        <option name={element} value={element}>
                                          {element}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-md-6">
                                <select
                                  name="endMonth"
                                  className="form-control"
                                  value={field.endMonth}
                                  onChange={(event) =>
                                    WorkExphandleInputChange(index, event)
                                  }
                                  placeholder="EndMonth"
                                >
                                  <option
                                    name=""
                                    value=""
                                    style={{ display: "none" }}
                                  >
                                    End Month
                                  </option>
                                  {MonthNumberArray.map((element) => {
                                    return (
                                      <>
                                        <option
                                          name={element.name}
                                          value={element.value}
                                        >
                                          {element.name}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-md-6">
                                <select
                                  name="endYear"
                                  className="form-control"
                                  value={field.endYear}
                                  onChange={(event) =>
                                    WorkExphandleInputChange(index, event)
                                  }
                                  placeholder="EndYear"
                                >
                                  <option
                                    name=""
                                    value=""
                                    style={{ display: "none" }}
                                  >
                                    End Year
                                  </option>
                                  {YearArray.map((element) => {
                                    return (
                                      <>
                                        <option name={element} value={element}>
                                          {element}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>

                            <input
                              placeholder="Company Name"
                              className="form-control"
                              type="text"
                              name="companyName"
                              value={field.companyName}
                              onChange={(event) =>
                                WorkExphandleInputChange(index, event)
                              }
                            />
                            <input
                              placeholder="Location"
                              className="form-control"
                              type="text"
                              name="location"
                              value={field.location}
                              onChange={(event) =>
                                WorkExphandleInputChange(index, event)
                              }
                            />
                            <input
                              placeholder="Designation Name"
                              className="form-control"
                              type="text"
                              name="designationName"
                              value={field.designationName}
                              onChange={(event) =>
                                WorkExphandleInputChange(index, event)
                              }
                            />
                            {field.description.map((subField, subIndex) => (
                              <div key={subIndex}>
                                <div className="row">
                                  <div
                                    style={{ padding: 2 }}
                                    className="col-md-10"
                                  >
                                    {" "}
                                    <textarea
                                      placeholder="Add Work Tasks"
                                      className="form-control"
                                      id="description"
                                      name="description"
                                      rows="10"
                                      cols="35"
                                      value={subField.value}
                                      onChange={(event) =>
                                        WorkExphandleSubInputChange(
                                          index,
                                          subIndex,
                                          event
                                        )
                                      }
                                    ></textarea>
                                  </div>
                                  <div
                                    style={{ padding: 3, marginTop: 5 }}
                                    className="col-md-2"
                                  >
                                    <button
                                      type="button"
                                      className="e-btn"
                                      style={{
                                        color: "white",
                                        background: "red",
                                      }}
                                      onClick={() =>
                                        WorkExpremoveSubInputField(
                                          index,
                                          subIndex
                                        )
                                      }
                                    >
                                      X
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="row">
                              <div className="col-md-6">
                                <button
                                  className="e-btn"
                                  type="button"
                                  style={{
                                    height: 75,
                                    lineHeight: 1,
                                    color: "white",
                                    padding: 5,
                                  }}
                                  onClick={() => WorkExpaddSubInputField(index)}
                                >
                                  Add work experience bullets
                                </button>
                              </div>
                              <div className="col-md-6">
                                <button
                                  className="e-btn"
                                  style={{
                                    color: "white",
                                    background: "red",
                                    height: 75,
                                    lineHeight: 1,
                                    padding: 5,
                                  }}
                                  type="button"
                                  onClick={() => WorkExpremoveInputField(index)}
                                >
                                  Delete work experience
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                        <button
                          type="button"
                          className="e-btn"
                          style={{ marginTop: 5 }}
                          onClick={() =>
                            setWorkExprience([
                              ...WorkExprience,
                              {
                                startYear: "",
                                startMonth: "",
                                endYear: "",
                                endMonth: "",
                                companyName: "",
                                designationName: "",
                                duration: "",
                                description: [],
                              },
                            ])
                          }
                        >
                          Add next work experience
                        </button>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="carddesign marginTop5">
              <Card.Body>
                <Card.Text>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>CERTIFICATION</Accordion.Header>
                      <Accordion.Body>
                        {Certification.map((element, index) => (
                          <div className="form-inline" key={index}>
                            <input
                              placeholder="Certificate Name"
                              className="form-control"
                              type="text"
                              name="certificateName"
                              value={element.certificateName}
                              onChange={(e) =>
                                CertificationhandleChange(index, e)
                              }
                            />
                            {/* <input
                              placeholder="Certificate Source"
                              className="form-control"
                              type="text"
                              name="certificateSourceName"
                              value={element.certificateSourceName}
                              onChange={(e) =>
                                CertificationhandleChange(index, e)
                              }
                            />
                            <input
                              placeholder="Certificate Link"
                              className="form-control"
                              type="text"
                              name="certificateLink"
                              value={element.certificateLink}
                              onChange={(e) =>
                                CertificationhandleChange(index, e)
                              }
                            /> */}
                            {index ? (
                              <button
                                type="button"
                                className="btn btn-primary button remove"
                                onClick={() =>
                                  CertificationremoveFormFields(index)
                                }
                              >
                                Remove
                              </button>
                            ) : null}
                          </div>
                        ))}
                        <div className="button-section">
                          <button
                            className="btn btn-primary button add"
                            type="button"
                            onClick={() => CertificationaddFormFields()}
                          >
                            Add
                          </button>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="carddesign marginTop5">
              <Card.Body>
                <Card.Text>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>ABOUT ME</Accordion.Header>
                      <Accordion.Body>
                        <textarea
                          id="Aboutme"
                          rows="10"
                          cols="35"
                          className="form-control"
                          value={Aboutme.Aboutme}
                          onChange={(e) => AboutmeonChange(e)}
                        ></textarea>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="carddesign marginTop5">
              <Card.Body>
                <Card.Text>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>SKILLS</Accordion.Header>
                      <Accordion.Body>
                        {Skills.map((element, index) => (
                          <div className="form-inline" key={index}>
                            <Row className="marginTop5">
                              <Col>
                                <input
                                  placeholder="Skill Name"
                                  className="form-control"
                                  type="text"
                                  name="skillname"
                                  value={element.skillname}
                                  onChange={(e) => SkillshandleChange(index, e)}
                                />
                              </Col>
                            </Row>
                            {index ? (
                              <button
                                type="button"
                                className="btn btn-primary button remove"
                                onClick={() => removeSkillsField(index)}
                              >
                                Remove
                              </button>
                            ) : null}
                          </div>
                        ))}
                        <div className="button-section">
                          <button
                            className="btn btn-primary button add"
                            type="button"
                            onClick={() => addSkillsField()}
                          >
                            Add
                          </button>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="carddesign marginTop5">
              <Card.Body>
                <Card.Text>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>LANGUAGES</Accordion.Header>
                      <Accordion.Body>
                        {Languages.map((element, index) => (
                          <div className="form-inline" key={index}>
                            <Row className="marginTop5">
                              <Col>
                                <input
                                  placeholder="Languages"
                                  className="form-control"
                                  type="text"
                                  name="langugename"
                                  value={element.langugename}
                                  onChange={(e) =>
                                    LanguagehandleChange(index, e)
                                  }
                                />
                              </Col>
                              <Col>
                                <select
                                  name="languagerating"
                                  className="form-control"
                                  value={element.languagerating}
                                  onChange={(e) =>
                                    LanguagehandleChange(index, e)
                                  }
                                  placeholder="Language Rating"
                                >
                                  <option
                                    name=""
                                    value=""
                                    style={{ display: "none" }}
                                  >
                                    Rate Yourself
                                  </option>
                                  <option name="1" value="1">
                                    1
                                  </option>
                                  <option name="2" value="2">
                                    2
                                  </option>
                                  <option name="3" value="3">
                                    3
                                  </option>
                                  <option name="4" value="4">
                                    4
                                  </option>
                                  <option name="5" value="5">
                                    5
                                  </option>
                                </select>
                              </Col>
                            </Row>
                            {index ? (
                              <button
                                type="button"
                                className="btn btn-primary button remove"
                                onClick={() => removeLanguageField(index)}
                              >
                                Remove
                              </button>
                            ) : null}
                          </div>
                        ))}
                        <div className="button-section">
                          <button
                            className="btn btn-primary button add"
                            type="button"
                            onClick={() => addLanguageField()}
                          >
                            Add
                          </button>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="carddesign marginTop5 marginBottom20">
              <Card.Body>
                <Card.Text>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        AWARDS and ACHIVEMENTS
                      </Accordion.Header>
                      <Accordion.Body>
                        {Awards.map((element, index) => (
                          <div className="form-inline" key={index}>
                            <Row className="marginTop5">
                              <input
                                placeholder="Awards"
                                className="form-control"
                                type="text"
                                name="awardname"
                                value={element.awardname}
                                onChange={(e) => AwardsshandleChange(index, e)}
                              />
                            </Row>
                            {index ? (
                              <button
                                type="button"
                                className="btn btn-primary button remove"
                                onClick={() => removeAwardsField(index)}
                              >
                                Remove
                              </button>
                            ) : null}
                          </div>
                        ))}
                        <div className="button-section">
                          <button
                            className="btn btn-primary button add"
                            type="button"
                            onClick={() => addAwardField()}
                          >
                            Add
                          </button>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Col>
        <Col sm={8}>
          <div style={{ margin: 10, marginBottom: 30, color: "#000000" }}>
            <Container>
              <Row>
                <Col>
                  <button
                    style={{
                      height: "25px",
                      width: "100%",
                      marginTop: "2px",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }}
                    className="buttondesign"
                    onClick={getSavedData}
                  >
                    Get Saved Data <i class="fas fa-file-pdf"></i>
                  </button>
                </Col>
                <Col>
                  <button
                    style={{
                      height: "25px",
                      width: "100%",
                      marginTop: "2px",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }}
                    className="buttondesign"
                    onClick={savepdf}
                  >
                    Save PDF <i class="fa fa-save"></i>
                  </button>
                </Col>
                <Col>
                  <button
                    style={{
                      height: "25px",
                      width: "100%",
                      marginTop: "2px",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }}
                    className="buttondesign"
                    onClick={handlePrint}
                  >
                    Download PDF <i class="fas fa-download"></i>
                  </button>
                </Col>
              </Row>
            </Container>

            <div style={{ marginTop: 20 }} className="App" id="App">
              {/* <button onClick={handlePrint} className="print__button">  Print </button> */}

              <div ref={componentRef}>
                <div
                  id="toprint"
                  style={{
                    marginLeft: "15px",
                    marginRight: "15px",
                    border: "0px",
                  }}
                  className="toprint"
                >
                  <div className="row">
                    <div
                      style={{
                        background: "#28b4a3",
                        borderRadius: "15px",
                      }}
                    >
                      <div style={{ padding: 10 }}>
                        <h2
                          style={{
                            margin: "0px!important",
                            fontWeight: 400,
                            fontStyle: "normal",
                            color: "#fff",
                            fontSize: 24,
                          }}
                        >
                          {BasicDetails.firstname} {BasicDetails.middlename}
                          {BasicDetails.lastname}
                        </h2>
                        <h3
                          style={{
                            margin: "0px!important",
                            textDecoration: "underline",
                            fontWeight: 400,
                            fontStyle: "normal",
                            color: "#FFFFFF",
                            fontSize: 20,
                          }}
                        >
                          {BasicDetails.degree}
                        </h3>

                        <p
                          className="descriptionfontsize aboutmeresume"
                          style={{
                            margin: "0px!important",
                            color: "#FFFFFF",
                            fontSize: `${descriptionfontsize}px`,
                          }}
                        >
                          {Aboutme.Aboutme}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: 20 }} className="row">
                    <div className="col-md-4">
                      <p
                        className="descriptionfontsize"
                        style={{
                          fontSize: `${descriptionfontsize}px`,
                        }}
                      >
                        {!BasicDetails.email ? (
                          BasicDetails.email
                        ) : (
                          <img
                            width={descriptionfontsize}
                            src="https://mygreenhorn.com/images/green-mail.svg"
                            alt="message icon"
                          />
                        )}
                      </p>
                      <p
                        className="descriptionfontsize"
                        style={{
                          color: "#000000",
                          fontSize: `${descriptionfontsize}px`,
                        }}
                      >
                        {BasicDetails.email}
                      </p>
                      <p
                        className="descriptionfontsize"
                        style={{
                          fontSize: `${descriptionfontsize}px`,
                        }}
                      >
                        {!BasicDetails.phonenumber ? (
                          BasicDetails.phonenumber
                        ) : (
                          <img
                            width={descriptionfontsize}
                            src="https://mygreenhorn.com/images/green-phone.svg"
                            alt="phone icon"
                          />
                        )}
                      </p>

                      <p
                        className="descriptionfontsize"
                        style={{
                          color: "#000000",
                          fontSize: `${descriptionfontsize}px`,
                        }}
                      >
                        {BasicDetails.phonenumber}
                      </p>
                      <p
                        className="descriptionfontsize"
                        style={{
                          fontSize: `${descriptionfontsize}px`,
                        }}
                      >
                        {!BasicDetails.city ? (
                          BasicDetails.city
                        ) : (
                          <img
                            width={descriptionfontsize}
                            src="https://mygreenhorn.com/images/address.svg"
                            alt="address icon"
                          />
                        )}
                      </p>

                      <p
                        className="descriptionfontsize"
                        style={{
                          color: "#000000",
                          fontSize: `${descriptionfontsize}px`,
                        }}
                      >
                        {BasicDetails.city}
                        {BasicDetails.pincode !== "" ? "," : null}&nbsp;
                        {BasicDetails.pincode}
                      </p>
                      <p
                        className="descriptionfontsize"
                        style={{
                          fontSize: `${descriptionfontsize}px`,
                        }}
                      >
                        {!BasicDetails.linkedin ? (
                          BasicDetails.linkedin
                        ) : (
                          <img
                            width={descriptionfontsize}
                            src="https://mygreenhorn.com/images/green-linkedin.svg"
                            alt="linked icon"
                          />
                        )}
                      </p>
                      <p
                        className="descriptionfontsize"
                        style={{
                          color: "#000000",
                          fontSize: `${descriptionfontsize}px`,
                        }}
                      >
                        {BasicDetails.linkedin}
                      </p>

                      <div style={{ marginBottom: 20, marginTop: 30 }}>
                        <p
                          className="titleheading"
                          style={{
                            marginTop: "10px",
                            color: "#28b4a3",
                            fontWeight: "700",
                            fontSize: `${titlefontsize}px`,
                          }}
                        >
                          SKILLS
                        </p>
                        <hr className="hrstyle" />

                        <div style={{ marginBottom: 10 }}>
                          {Skills.map((item) => {
                            return (
                              <>
                                {item.skillname ? (
                                  <>
                                    <div
                                      style={{ margin: 0 }}
                                      className="labelContainer"
                                    >
                                      <div
                                        style={{
                                          fontSize: `${descriptionfontsize}px`,
                                        }}
                                        className="resumetransparentlabelitem"
                                      >
                                        {item.skillname}
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </>
                            );
                          })}
                        </div>
                      </div>
                      <div style={{ marginBottom: 20 }}>
                        <p
                          className="titleheading"
                          style={{
                            marginTop: "10px",
                            color: "#28b4a3",
                            fontWeight: "700",
                            fontSize: `${titlefontsize}px`,
                          }}
                        >
                          LANGUAGES
                        </p>
                        <hr className="hrstyle" />

                        <div style={{ marginBottom: 10 }}>
                          {Languages.map((item, index) => {
                            return (
                              <>
                                <p
                                  className="descriptionfontsize"
                                  style={{
                                    color: "#000000",
                                    fontSize: `${descriptionfontsize}px`,
                                  }}
                                >
                                  {item.langugename}
                                </p>

                                {item.languagerating !== "" &&
                                item.languagerating !== "" ? (
                                  <div>
                                    <CirlceRow
                                      key={index}
                                      value={item.languagerating}
                                    />
                                  </div>
                                ) : null}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-md-8"
                      style={{
                        height: "auto",
                        paddingRight: 0,
                      }}
                    >
                      <div>
                        <p
                          className="titleheading"
                          style={{
                            marginTop: "10px",
                            color: "#28b4a3",
                            fontWeight: "700",
                            fontSize: `${titlefontsize}px`,
                          }}
                        >
                          WORK EXPERIENCE
                        </p>
                        <hr className="hrstyle" />
                        {WorkExprience.map((item) => {
                          console.log(item);
                          return (
                            <div style={{ marginBottom: "10px" }}>
                              <div className="row">
                                <p
                                  className="subtitleheading"
                                  style={{
                                    color: "#28b4a3",
                                    marginBottom: "0px!important",
                                    marginTop: "0px",
                                    fontSize: `${descriptionfontsize}px`,
                                    fontWeight: 700,
                                  }}
                                >
                                  {item.designationName}
                                </p>
                                <p
                                  className="descriptionfontsize"
                                  style={{
                                    marginBottom: "0px!important",
                                    marginTop: "0px",
                                    fontSize: `${descriptionfontsize}px`,
                                    fontWeight: 300,
                                    color: "#000000",
                                  }}
                                >
                                  {item.companyName}
                                </p>
                                <div className="row">
                                  <div className="col-md-9">
                                    {" "}
                                    <p
                                      className="descriptionfontsize"
                                      style={{
                                        color: "#28b4a3",

                                        fontSize: `${descriptionfontsize}px`,
                                      }}
                                    >
                                      {item.startMonth}
                                      {item.startYear !== "" ? "/" : null}
                                      {item.startYear}{" "}
                                      {item.startYear !== "" ? "-" : null}{" "}
                                      {item.endMonth}
                                      {item.endYear !== "" ? "/" : null}
                                      {item.endYear}
                                    </p>
                                  </div>
                                  <div className="col-md-3">
                                    {" "}
                                    <p
                                      className="descriptionfontsize"
                                      style={{
                                        color: "#28b4a3",
                                        overflowWrap: "break-word",
                                        fontSize: `${descriptionfontsize}px`,
                                        float: "right",
                                      }}
                                    >
                                      {item.location}
                                    </p>
                                  </div>
                                </div>

                                <ul>
                                  {item.description.map(
                                    (subField, subIndex) => (
                                      <li
                                        className="circlestyle"
                                        style={{
                                          listStyleType: "circle",
                                          color: "#28b4a3",
                                          fontSize: 11,
                                          marginLeft: 20,
                                          bottom: 0,
                                        }}
                                      >
                                        <p
                                          className="descriptionfontsize pcircle"
                                          style={{
                                            color: "#000000",
                                            overflowWrap: "break-word",
                                            fontSize: `${descriptionfontsize}px`,
                                          }}
                                        >
                                          {subField.value}
                                        </p>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      <div style={{ marginTop: 16 }}>
                        <p
                          className="titleheading"
                          style={{
                            marginTop: "20px",
                            color: "#28b4a3",
                            fontWeight: "700",
                            fontSize: `${titlefontsize}px`,
                          }}
                        >
                          EDUCATION
                        </p>
                        <hr className="hrstyle" />
                        {Education.map((item) => {
                          return (
                            <div
                              className="educationrow row"
                              style={{ marginBottom: 10 }}
                            >
                              <p
                                className="subtitleheading"
                                style={{
                                  color: "#28b4a3",
                                  marginBottom: "0px!important",
                                  marginTop: "0px",
                                  fontSize: `${descriptionfontsize}px`,
                                  fontWeight: 700,
                                }}
                              >
                                {item.degreeName}
                              </p>
                              <p
                                className="descriptionfontsize"
                                style={{
                                  marginBottom: "0px!important",
                                  marginTop: "0px",
                                  fontSize: `${descriptionfontsize}px`,
                                  color: "#000000",
                                }}
                              >
                                {item.collegeName}
                              </p>
                              <p
                                className="descriptionfontsize"
                                style={{
                                  color: "#28b4a3",

                                  fontSize: `${descriptionfontsize}px`,
                                }}
                              >
                                {item.startMonth}
                                {item.startYear !== "" ? "/" : null}
                                {item.startYear}{" "}
                                {item.startYear !== "" ? "-" : null}{" "}
                                {item.endMonth}
                                {item.endYear !== "" ? "/" : null}
                                {item.endYear}
                              </p>

                              <p
                                className="descriptionfontsize"
                                style={{
                                  marginBottom: "0px!important",
                                  marginTop: "0px",
                                  color: "#000000",
                                  fontSize: `${descriptionfontsize}px`,
                                }}
                              >
                                {item.score !== "" ? "Score : " : null}
                                {item.score}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                      <div style={{ marginTop: 16 }}>
                        <p
                          className="titleheading"
                          style={{
                            marginTop: "10px",
                            color: "#28b4a3",
                            fontWeight: "700",
                            fontSize: `${titlefontsize}px`,
                          }}
                        >
                          CERTIFICATION
                        </p>
                        <hr className="hrstyle" />
                        {Certification.map((item) => {
                          return (
                            <div className="">
                              <div style={{ marginBottom: "0px" }}>
                                <div className="row">
                                  <p
                                    className="subtitleheading"
                                    style={{
                                      marginBottom: "0px!important",
                                      marginTop: "0px",
                                      fontSize: `${descriptionfontsize}px`,
                                      color: "#28b4a3",
                                      fontWeight: "700",
                                    }}
                                  >
                                    {item.certificateName}
                                  </p>
                                  {/* <p
                                    className="descriptionfontsize"
                                    style={{
                                      fontSize: `${descriptionfontsize}px`,
                                    }}
                                  >
                                    {item.certificateName ? (
                                      <a
                                        style={{ color: "black" }}
                                        href={item.certificateLink}
                                      >
                                        View Certificate
                                      </a>
                                    ) : null}
                                  </p> */}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div style={{ marginTop: 16 }}>
                        <p
                          className="titleheading"
                          style={{
                            marginTop: "10px",
                            color: "#28b4a3",
                            fontWeight: "700",
                            fontSize: `${titlefontsize}px`,
                          }}
                        >
                          AWARDS AND ACHIVEMENTS
                        </p>
                        <hr className="hrstyle" />
                        {Awards.map((item) => {
                          console.log(item);
                          return (
                            <>
                              <p
                                className="descriptionfontsize"
                                style={{
                                  color: "#000000",
                                  fontSize: `${titlefontsize}px`,
                                }}
                              >
                                {item.awardname}
                              </p>
                              {/* <p
                                className="descriptionfontsize"
                                style={{
                                  color: "#000000",
                                  fontSize: `${descriptionfontsize}px`,
                                }}
                              >
                                {item.awardedby}
                              </p> */}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default ResumeBuilderToolOptionThree;
