import { useState } from "react";
import { useSelector } from "react-redux";

import { ModalType } from "../../../../../redux/types/generic-constant";
import { formatDate } from "../../../../../helpers";
import { DetailTab, OtherDetailTabContent } from "../../common";

import AddEditInternshipModal from "./add-edit-internship-modal";

const Internship = () => {
  // const { internships } = useSelector(
  //   ({ userProfile: { internships } }: RootState) => ({
  //     internships,
  //   })
  // );
  const internships = useSelector((state) => state.profile.internships);
  //working commented for debugging
  //console.log("internships", internships);
  const [openModalType, setOpenModalType] = useState(undefined);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  return (
    <>
      <DetailTab
        title="Experience"
        actionIcon={"/assets/img/ghimages/add-icon.svg"}
        actionMethod={() => setOpenModalType(ModalType.ADD)}
        children={
          <>
            {internships.map(
              ({ companyName, designation, startDate, endDate }, idx) => (
                <>
                  <OtherDetailTabContent idx={idx}>
                    <img
                      src={"/assets/img/ghimages/internship-icon.svg"}
                      alt=""
                    />
                    <div>
                      <div>{companyName}</div>
                      <div>{designation}</div>
                      <div>
                        {formatDate(startDate)} to {formatDate(endDate)}
                      </div>
                    </div>
                    <span>
                      <img
                        src={"/assets/img/ghimages/edit-icon.svg"}
                        alt="edit"
                        onClick={() => {
                          setSelectedIndex(idx);
                          setOpenModalType(ModalType.EDIT);
                        }}
                      />
                    </span>
                  </OtherDetailTabContent>
                </>
              )
            )}
          </>
        }
      />
      {openModalType && (
        <AddEditInternshipModal
          closeAction={() => {
            setOpenModalType(undefined);
            setSelectedIndex(-1);
          }}
          forEdit={openModalType === ModalType.EDIT}
          selectedIndex={selectedIndex}
        />
      )}
    </>
  );
};

export default Internship;
