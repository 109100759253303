import SEO from '../components/seo';
import Wrapper from '../layout/wrapper';
import { Link } from 'react-router-dom';

const Denied = () => {
   
  return (
    <Wrapper>
      <SEO pageTitle={'Access Dennied'} />
      <h1>Access Denied</h1>
    </Wrapper>
  );
};

export default Denied;