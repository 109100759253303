import React, { useState, useEffect } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import { Link } from "react-router-dom";
import { getDataFromLocalStorage } from "../../browser-storage";
import config from "../../services/config";
import "./pdfViewer.css";

//this is required to load the pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PdfFullScreenViewer = ({ pdfUrl }) => {
  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [style, setStyle] = useState({ display: "block" });
  const [currentPDF, setCurrentPDF] = useState(null);

  useEffect(() => {
    const getPDFLink = getDataFromLocalStorage("currentLocalPDF");
    console.log("localstorage", getPDFLink);
    console.log("getPDFLink", getPDFLink);
    const currentUrl = `${config.PROTOCOL}${config.IMAGE_HOST}/` + getPDFLink;
    console.log("currentUrl", currentUrl);
    setCurrentPDF(currentUrl);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 2.0;

  const zoomOutClass = isMinZoom ? "disabled" : "clickable";
  const zoomInClass = isMaxZoom ? "disabled" : "clickable";

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };
  return (
    <div
      style={{
        backgroundColor: "#525659",
        height: "100%",
        minHeight: "100vh",
        //marginTop: "-16px",
        overflow: "hidden",
      }}
    >
      <div className="Example__container__document">
        <div class="d-flex justify-content-center">
          <div className="control-panel m-3 p-3 d-flex align-items-baseline justify-content-between">
            <div class="p-2 bd-highlight">
              <button onClick={handlePrevPage} disabled={pageNumber === 1}>
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </button>
              <span style={{ marginLeft: 10, marginRight: 10 }}>
                {pageNumber} of {numPages}
              </span>
              <button
                onClick={handleNextPage}
                disabled={pageNumber === numPages}
              >
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
              </button>
            </div>
            <div className="d-flex justify-content-between align-items-baseline">
              <i
                className={`fas fa-search-minus mx-3 ${zoomOutClass}`}
                onClick={zoomOut}
              />
              <span>{(scale * 100).toFixed()}%</span>
              <i
                className={`fas fa-search-plus mx-3 ${zoomInClass}`}
                onClick={zoomIn}
              />
            </div>
            <div class="p-2 bd-highlight">
              <Link
                style={{ marginLeft: 1, marginRight: 1 }}
                to={`/mentor-course`}
              >
                Exit FullScreen
              </Link>
            </div>
          </div>
        </div>

        <div style={style} className="">
          <Document
            file={currentPDF}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={console.error}
          >
            <Page pageNumber={pageNumber} scale={scale} />
          </Document>
        </div>
      </div>
    </div>
  );
};

export default PdfFullScreenViewer;
