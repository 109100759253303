import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import AddJob from "./addjobs";
import RecuiterSliderMenu from "./recuiterslidermenu";

const EmployerDashboard = () => {
  return (
    <Wrapper>
      <SEO pageTitle={"Recuiter Dashboard"} />
      <RecuiterSliderMenu />
      {/* <AddJob /> */}
    </Wrapper>
  );
};

export default EmployerDashboard;
