import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  GetMentorProfile,
  GetCourseByMentorId,
  GetMentorPDFbyIdService,
} from "../../services/mentor-service";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useLocation } from "react-router-dom";
import YouTube from "react-youtube";
import styled from "styled-components";
import "./mentorlanding.css";
import PdfViewer from "./PdfViewer";
const Video = styled.iframe`
  display: flex;
  align-items: center;
  border: none;
  max-width: 490px;
  width: 100%;
  height: 100%;
  max-height: 276px;
  min-height: 276px;
  box-shadow: 9px 14px 15px rgba(0, 0, 0, 0.25);
`;
const url =
  "https://s3.ap-south-1.amazonaws.com/qa.mygreenhorn.com/data/mentorPDFs/lmentor2%40mygreenhorn.com/jobdescription.pdf";
const MentorToStudentCourse = ({ instructor }) => {
  const location = useLocation();

  const [courselisting, setCourseListing] = useState([]);
  const [mentorpdfdetials, setMentorPDFDetials] = useState([]);
  useEffect(() => {
    console.log(location, " useLocation Hook");

    const SingleMentorDetials = location.state?.data;
    const currentemail = SingleMentorDetials.getReferredMentorEmailId;
    console.log("SingleMentorDetials", SingleMentorDetials);
    console.log("currentemail", currentemail);
    // const userDetials = getDataFromLocalStorage("user-details");
    // console.log(userDetials);
    // const currentrole = userDetials.role;
    // const currentemail = userDetials.email;
    // console.log(currentemail, currentrole);
    // if (currentrole === "MENTOR") {
    //   setMentorProfileDetials({
    //     ...mentorprofiledetials,
    //     userEmailId: currentemail,
    //   });
    // }

    GetMentorProfileById(currentemail);
    GetMentorPDFById(currentemail);
    getCourseListingByMentor(currentemail);
  }, []);
  let getCourseListingByMentor = async (currentemail) => {
    const SubmitResponse = await GetCourseByMentorId(currentemail)
      .then((resp) => {
        console.log(resp);
        setCourseListing(resp);
      })
      .catch((err) => console.log(err));
  };

  let GetMentorProfileById = async (currentemail) => {
    const SubmitResponse = await GetMentorProfile(currentemail)
      .then((resp) => {
        console.log(resp);
        let mentorresp = resp[0];
        console.log("mentorresp", mentorresp);
        let MentorCourse = mentorresp.course_created;
        console.log(MentorCourse);
        setMentorProfileDetials(mentorresp);
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Error ${err.response.data} `, {
          position: "top-left",
        });
      });
  };

  let GetMentorPDFById = async (currentemail) => {
    const SubmitResponse = await GetMentorPDFbyIdService(currentemail)
      .then((resp) => {
        console.log(resp);
        const currentResp = resp;
        setMentorPDFDetials(currentResp);
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Error ${err.response.data} `, {
          position: "top-left",
        });
      });
  };

  const [mentorprofiledetials, setMentorProfileDetials] = useState({
    userId: "",
    userEmailId: "",
    firstName: "",
    lastName: "",
    fullName: "",
    mobile: "",
    education: "",
    profession: "",
    location: "",
    profileImgUrl: "",
    dob: "",
    aboutme: "",
    linkedinlink: "",
    mentor_intro_video: "",
    sector: "",

    course_created: [],
  });
  const opts = {
    width: "305",
    height: "550",

    playerVars: {
      autoplay: 0,
    },
  };

  let _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  return (
    <Wrapper>
      {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"></Worker> */}
      <>
        <section className="teacher__area pt-180 pb-110">
          <div className="page__title-shape">
            <img
              className="page-title-shape-5 d-none d-sm-block"
              src="/assets/img/page-title/page-title-shape-1.png"
              alt=""
            />
            <img
              className="page-title-shape-6"
              src="/assets/img/page-title/page-title-shape-6.png"
              alt=""
            />
            <img
              className="page-title-shape-3"
              src="/assets/img/page-title/page-title-shape-3.png"
              alt=""
            />
            <img
              className="page-title-shape-7"
              src="/assets/img/page-title/page-title-shape-4.png"
              alt=""
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                <div className="teacher__details-thumb p-relative w-img pr-30">
                  {mentorprofiledetials.profileImgUrl !== "" &&
                  mentorprofiledetials.profileImgUrl !== undefined ? (
                    <img
                      src={mentorprofiledetials.profileImgUrl}
                      alt="Mentor Image"
                    />
                  ) : null}
                  {mentorprofiledetials.profileImgUrl == "" ||
                  mentorprofiledetials.profileImgUrl == undefined ? (
                    <>
                      {" "}
                      <img
                        src={"/assets/img/ghimages/blankprofile.png"}
                        alt="Blank Image"
                      />
                    </>
                  ) : null}

                  <div className="teacher__details-shape">
                    <img
                      className="teacher-details-shape-1"
                      src="/assets/img/teacher/details/shape/shape-1.png"
                      alt=""
                    />
                    <img
                      className="teacher-details-shape-2"
                      src="/assets/img/teacher/details/shape/shape-2.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-xxl-5 col-xl-5 col-lg-5">
                <div className="teacher__wrapper">
                  <div className="teacher__top d-md-flex align-items-end justify-content-between">
                    <div style={{ marginTop: 30 }} className="teacher__info">
                      <div className="labelContainer">
                        <div className="mentorlabelitem">
                          {" "}
                          <h4 style={{ fontSize: 30 }}>
                            {mentorprofiledetials.fullName}&nbsp;
                          </h4>
                        </div>
                        <div className="mentorlabelitem">
                          <a href={mentorprofiledetials.linkedinlink}>
                            <img
                              width={"30px"}
                              src="/assets/img/ghimages/linkedin-icon.svg"
                            />
                          </a>
                        </div>
                      </div>

                      <span>{mentorprofiledetials.profession}</span>
                    </div>
                    {/* 
                    <div className="teacher__social-2">
                      <h4>
                        Follow Us:
                        LinkedIn
                      </h4>
                    </div> */}
                  </div>
                  <div className="teacher__bio">
                    <h3>Bio</h3>
                    {/* <p>{mentorprofiledetials.aboutme}</p> */}
                    <div
                      style={{ color: "black!important" }}
                      dangerouslySetInnerHTML={{
                        __html: mentorprofiledetials.aboutme,
                      }}
                    />
                  </div>
                  {/* Courses Tab was Here */}
                  <div className="section__title-wrapper mb-30">
                    {/* <h3 className="section__title">
                      {" "}
                      <span
                        style={{ fontSize: 25 }}
                        className="yellow-bg yellow-bg-big"
                      >
                        Enquire
                        <img src="/assets/img/shape/yellow-bg.png" alt="" />
                      </span>
                    </h3> */}
                  </div>
                  <Link
                    to="/student-to-mentor-enquiry-form"
                    state={{ data: mentorprofiledetials }}
                  >
                    <button className="e-btn">Enquire</button>
                  </Link>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                <div className="teacher__details-thumb p-relative w-img pr-30">
                  {mentorprofiledetials.mentor_intro_video !== "" ? (
                    <div style={{ marginTop: 20 }}>
                      <div className="desktop">
                        <YouTube
                          className="cusmobilevideo"
                          videoId={mentorprofiledetials.mentor_intro_video}
                          opts={opts}
                          onReady={_onReady}
                        />
                      </div>
                      <div className="mobile">
                        <Video
                          src={`https://www.youtube.com/embed/${mentorprofiledetials.mentor_intro_video}`}
                        />
                      </div>
                    </div>
                  ) : null}

                  <div className="teacher__details-shape">
                    <img
                      className="teacher-details-shape-1"
                      src="/assets/img/teacher/details/shape/shape-1.png"
                      alt=""
                    />
                    <img
                      className="teacher-details-shape-2"
                      src="/assets/img/teacher/details/shape/shape-2.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="teacher__courses pt-10">
                {courselisting.length > 0 ? (
                  <>
                    <div className="section__title-wrapper">
                      <h2 className="section__title">
                        Mentor{" "}
                        <span className="yellow-bg yellow-bg-big">
                          Videos
                          <img src="/assets/img/shape/yellow-bg.png" alt="" />
                        </span>
                      </h2>
                    </div>
                  </>
                ) : null}

                <div className="learnlabelContainer" style={{ marginTop: 0 }}>
                  <PdfViewer pdfUrl={url} />
                  {/* {mentorpdfdetials.map((item, index) => {
                    return (
                      <>
                        {item.pdfName}
                        {item.pdfDescription}

                        {item.pdfLink}
                      </>
                    );
                  })} */}
                </div>

                <div className="learnlabelContainer" style={{ marginTop: 0 }}>
                  {courselisting.map((item, index) => {
                    return (
                      <>
                        {item.iscourseapproved !== "false" ? (
                          <>
                            <div className="flexitem">
                              <Link
                                to="/view-course"
                                state={{ data: item }}
                                className="link"
                              >
                                <Card
                                  style={{ width: "400px", cursor: "pointer" }}
                                >
                                  <Card.Img
                                    variant="top"
                                    src={item.course_banner_link}
                                  />
                                  <Card.Body>
                                    <Row>
                                      <Col sm={7}>
                                        <div className="mentorname">
                                          {/* {item.course_createdby_mentor} */}
                                          {item.course_title}
                                        </div>
                                      </Col>
                                      <Col sm={5}>
                                        <div style={{ float: "right" }}>
                                          <div className="learnlabelContainer">
                                            {item.course_price ? (
                                              <div className="smalltransparentlabelitem">
                                                <i class="fa-solid fa-users-line"></i>
                                                &nbsp; {item.course_price}
                                              </div>
                                            ) : null}
                                          </div>
                                          <img
                                            width="25px"
                                            src="/assets/img/ghimages/graphic-icons/redplaybtn.svg"
                                          />
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row></Row>
                                  </Card.Body>
                                </Card>
                              </Link>
                            </div>
                          </>
                        ) : null}
                      </>
                    );
                  })}
                </div>
                {/* 
                  <div className="teacher__course-wrapper">
                    <div className="row">
                      {instructorCourses.map((course, i) => (
                        <div
                          key={i}
                          className="col-xxl-6 col-xl-6 col-lg-6 col-md-6"
                        >
                          <div className="course__item white-bg mb-30 fix">
                            <div className="course__thumb w-img p-relative fix">
                              <Link to={`/course-details/${course.id}`}>
                                <img src={course.courseImage} alt="" />
                              </Link>
                              <div className="course__tag">
                                <a href="#" className={course.color}>
                                  {course.category}
                                </a>
                              </div>
                            </div>
                            <div className="course__content">
                              <div className="course__meta d-flex align-items-center justify-content-between">
                                <div className="course__lesson">
                                  <span>
                                    <i className="far fa-book-alt"></i>
                                    {course.lesson} Lesson
                                  </span>
                                </div>
                                <div className="course__rating">
                                  <span>
                                    <i className="icon_star"></i>
                                    {course.rating} ({course.total_rating})
                                  </span>
                                </div>
                              </div>
                              <h3 className="course__title">
                                <Link to={`/course-details/${course.id}`}>
                                  {course.title}
                                </Link>
                              </h3>
                              <div className="course__teacher d-flex align-items-center">
                                <div className="course__teacher-thumb mr-15">
                                  <img src={course.teacherImg} alt="" />
                                </div>
                                <h6>
                                  <Link to="/instructor-details">
                                    {course.teacherName}
                                  </Link>
                                </h6>
                              </div>
                            </div>
                            <div className="course__more d-flex justify-content-between align-items-center">
                              {course.price === "free" && (
                                <div className="course__status">
                                  <span>Free</span>
                                </div>
                              )}
                              {course.price !== "free" && (
                                <div className="course__status d-flex align-items-center">
                                  <span className={course.color}>
                                    ${course.price}
                                  </span>
                                  {course.oldPrice && (
                                    <span className="old-price">
                                      ${course.oldPrice}
                                    </span>
                                  )}
                                </div>
                              )}
                              <div className="course__btn">
                                <Link
                                  to={`/course-details/${course.id}`}
                                  className="link-btn"
                                >
                                  Know Details
                                  <i className="far fa-arrow-right"></i>
                                  <i className="far fa-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div> */}
              </div>
            </div>
          </div>
        </section>
      </>
    </Wrapper>
  );
};

export default MentorToStudentCourse;
