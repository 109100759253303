import axios from "axios";
import config from "./config";
import { getDataFromLocalStorage } from "../browser-storage";
import { LocalStorageConstants } from "./localstorageconstants";
const getToken = () => getDataFromLocalStorage(LocalStorageConstants.TOKEN);

const getDynamicTabsDatabyMentorId = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/dynamictabs/get-dynamictabs-by-mentorId`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const AddMentorDynamicTabs = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/dynamictabs/add-dynamictabs-by-mentorId`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const UpdateDynamicTabsListing = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .put(
      `${config.PROTOCOL}${config.HOST}/dynamictabs/update-dynamictabs`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const UpdateStudentDynamicPermissions = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .put(
      `${config.PROTOCOL}${config.HOST}/coupon/mentor-set-dynamic-access`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

export {
  getDynamicTabsDatabyMentorId,
  AddMentorDynamicTabs,
  UpdateDynamicTabsListing,
  UpdateStudentDynamicPermissions,
};
