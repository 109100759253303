import React, { useEffect, useState, useRef } from "react";
import { Card, Form, Table, Row, Col, Badge, Container } from "react-bootstrap";
//import ReactExport from "react-export-excel";
import {
  getJoblisting,
  AppliedbyJobListing,
  ViewAllAppliedListing,
} from "../../services/jobs-service";
import { ScaleLoader } from "react-spinners";
import { useReactToPrint } from "react-to-print";
import { useSelect } from "react-select-search";
import Select from "react-select";
import "react-select-search/style.css";
import "./print.css";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { CSVLink, CSVDownload } from "react-csv";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import {
  setDataInLocalStorage,
  getDataFromLocalStorage,
} from "../../browser-storage";
const ExportAppliedbyJobs = () => {
  const csvLink = useRef();

  const CompanyOptionsArray = [];

  const [jobList, setjobList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [appliedbylist, setAppliedbyList] = useState([]);
  const [appliedbyAllUser, setAppliedbyAllUsers] = useState([]);
  const [currentselectedjob, setCurrentSelectedJob] = useState();

  const [selectedOption, setSelectedOption] = useState(null);

  const [companyOptionsArrayState, setcompanyOptionsArrayState] = useState([]);

  const [selectedFieldCategory, SetFieldCategory] = useState(0);
  const [filteredjoblist, setfilteredjoblist] = useState([]);

  const [checked, setChecked] = useState(false);
  const [radioValue, setRadioValue] = useState("1");

  const radios = [
    { name: "By Title-Company-Location", value: "1" },
    { name: "By Field Category", value: "2" },
    { name: "By Company Name Search", value: "3" },
  ];

  let ArrayFormat = [];
  let today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = mm + "/" + dd + "/" + yyyy;

  let currentDate = today;

  //Step 1 Get ALL Applied Jobs

  useEffect(() => {
    const userDetials = getDataFromLocalStorage("user-details");
    console.log(userDetials);
    const currentrole = userDetials.role;
    const currentemail = userDetials.email;
    const response = ViewAllAppliedListing().then((resp) => {
      console.log("ALL Resp", resp);
      const arrUniq = [
        ...new Map(
          resp.map((v) => [JSON.stringify([v.job_code, v.appliedby]), v])
        ).values(),
      ];
      console.log("Test arrUniq", arrUniq);

      for (let userdetials of arrUniq) {
        let userMasterData = userdetials.user_score_master;
        console.log("userMasterData", userMasterData);

        let checkmaster = userMasterData[0];
        console.log("checkmaster", checkmaster);

        let mobile = "";
        let collegename = "";
        let degreecode = "";
        let userresumeUrl = "";

        if (checkmaster) {
          mobile = checkmaster.mobile;
          collegename = checkmaster.collegename;
          degreecode = checkmaster.degreecode;
          if (checkmaster.userresumeUrl) {
            userresumeUrl =
              "https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/" +
              checkmaster.userresumeUrl;
          }
        }

        ArrayFormat.push({
          job_code: userdetials.job_code,
          job_title: userdetials.job_title,
          appliedby: userdetials.appliedby,
          appliedbydate: userdetials.appliedbydate,
          mobile: mobile,
          collegename: collegename,
          degreecode: degreecode,
          softskillscore: userdetials.softskillscore,
          aptitudescore: userdetials.aptitudescore,
          aiinterviewscore: userdetials.aiinterviewscore,
          userresumeUrl: userresumeUrl,
        });
      }

      console.log("ArrayFormat", ArrayFormat);
      setAppliedbyAllUsers(ArrayFormat);
      // let result = ArrayFormat.filter(function (item) {
      //   console.log(item.job_code, selectedJob);
      //   return item.job_code == selectedJob;
      // });

      //console.log("result", result);
      //setAppliedbyList(result);
      //setAppliedbyAllUsers(result);
    });
  }, []);

  useEffect(() => {
    const resp = getJoblisting()
      .then((res) => {
        let jobid = res[0]._id;
        console.log("jobid", jobid);
        let responserev = res.reverse();
        setjobList(responserev);
        console.log(jobList);
        for (let joblist of responserev) {
          CompanyOptionsArray.push({
            value: joblist.job_code,
            label: joblist.listed_by,
          });
        }
        console.log("CompanyOptionsArray", CompanyOptionsArray);
        setcompanyOptionsArrayState(CompanyOptionsArray);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(jobList);
  }, []);

  const jobChangeHandler = async (e) => {
    console.log("e.target.value", e.target.value);
    let selectedJob = e.target.value;
    console.log("appliedbyAllUser", appliedbyAllUser);
    setCurrentSelectedJob(selectedJob);
    let result = appliedbyAllUser.filter(function (item) {
      console.log(item.job_code, selectedJob);
      return item.job_code == selectedJob;
    });

    console.log("result", result);
    setAppliedbyList(result);
  };
  const componentRef = useRef();
  let handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onSelectfilter = (selected) => {
    let selectedJob = selected.value;
    console.log("selectedJob", selectedJob);
    // console.log('appliedbyAllUser', appliedbyAllUser)
    setCurrentSelectedJob(selectedJob);
    let result = appliedbyAllUser.filter(function (item) {
      console.log(item.job_code, selectedJob);
      return item.job_code == selectedJob;
    });

    console.log("result", result);
    setAppliedbyList(result);
  };

  const jobCategoryFilterHandler = (e) => {
    SetFieldCategory(e.target.value);
    let joblistarr = [];
    joblistarr = jobList;
    const result = joblistarr.filter((item) =>
      item.job_function.includes(e.target.value)
    );
    console.log("result", result);
    setfilteredjoblist(result);
  };

  const getappliedbyfromcategory = (e) => {
    console.log("e", e);
    const getJobCode = e.job_code;
    let selectedJob = getJobCode;
    console.log("getJobCode", getJobCode);
    setCurrentSelectedJob(selectedJob);
    let result = appliedbyAllUser.filter(function (item) {
      console.log(item.job_code, selectedJob);
      return item.job_code == selectedJob;
    });

    console.log("result", result);
    setAppliedbyList(result);
  };

  const getTransactionData = async () => {
    csvLink.current.link.click();
  };

  return (
    <>
      {/* <Row style={{ marginTop: 50 }}>
        <Col sm={2}>
          <p className="form-control">Select Filter</p>
        </Col>
        <Col>
          <ButtonGroup style={{ marginTop: 5 }}>
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={idx % 2 ? "outline-success" : "outline-danger"}
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(e) => setRadioValue(e.currentTarget.value)}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </Col>
      </Row> */}
      {/* {radioValue == 1 ? (
        <Form style={{ marginTop: 0, width: "100%" }}>
          <Form.Control
            as="select"
            onChange={(e) => jobChangeHandler(e)}
            defaultValue="Choose....."
          >
            {jobList.map((job, i) => (
              <option key={i} value={job.job_code}>
                {job.job_title} - {job.listed_by} - {job.location}
              </option>
            ))}
          </Form.Control>
        </Form>
      ) : null}
      {radioValue == 2 ? (
        <select
          name="job_function"
          className="form-control"
          value={selectedFieldCategory}
          onChange={(e) => jobCategoryFilterHandler(e)}
          placeholder="Job Function (Categories)"
        >
          <option name="" value="" style={{ display: "none" }}>
            Choose Category
          </option>
          <option name="Business Operations" value="Business Operations">
            Business Operations
          </option>
          <option name="Core Engineering" value="Core Engineering">
            Core Engineering
          </option>
          <option name="Information Technology" value="Information Technology">
            Information Technology
          </option>
          <option name="Human Resources" value="Human Resources">
            Human Resources
          </option>
          <option name="Marketing" value="Marketing ">
            Marketing
          </option>
          <option name="Sales" value="Sales">
            Sales
          </option>
          <option name="Others" value="Others">
            Others
          </option>
        </select>
      ) : null}
      {radioValue == 3 ? (
        <Select
          defaultValue={selectedOption}
          onChange={onSelectfilter}
          options={companyOptionsArrayState}
        />
      ) : null}
      {radioValue == 2 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>No.</th>
              <th>Job Title</th>
              <th>Company Name</th>
              <th>Location</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredjoblist.map((item, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.job_title}</td>
                  <td>{item.listed_by}</td>
                  <td>{item.location}</td>
                  <td>
                    <button
                      className="btn btn-success"
                      onClick={() => getappliedbyfromcategory(item)}
                    >
                      Get Applied by
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : null} */}
      {/* <ExcelFile
        filename={"Job-Code-" + currentselectedjob + "-" + currentDate}
      >
        <ExcelSheet data={appliedbylist} name="Jobs">
          <ExcelColumn label="Job Title" value="job_title" />
          <ExcelColumn label="Applied By" value="appliedby" />
          <ExcelColumn label="Applied Date" value="appliedbydate" />
          <ExcelColumn label="Mobile No." value="mobile" />
          <ExcelColumn label="College Name" value="collegename" />
          <ExcelColumn label="Degree" value="degreecode" />
          <ExcelColumn label="Soft Skill score" value="appliedbysoftscore" />
          <ExcelColumn label="Aptitude score" value="appliedbyaptitudescore" />
          <ExcelColumn label="AI interview Score" value="appliedbyaiscore" />
          <ExcelColumn label="Resume" value="userresumeUrl" />
        </ExcelSheet>
      </ExcelFile> */}
      <button className="e-btn" onClick={getTransactionData}>
        Download transactions to csv
      </button>
      <CSVLink
        data={appliedbyAllUser}
        filename="transactions.csv"
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
      {/* <CSVLink data={appliedbylist}>Download me</CSVLink>; */}
      <button className="e-btn" onClick={handlePrint}>
        Download PDF <i class="fa-solid fa-file-pdf"></i>
      </button>
      <Table ref={componentRef} striped bordered hover>
        <thead>
          <tr>
            {/* <th>Job Code</th> */}
            <th>Job Title</th>
            <th>Applied by</th>
            <th>Applied Date</th>
            <th>Mobile No.</th>
            <th>College Name</th>
            <th>Degree</th>
            <th>Soft Skill score</th>
            <th>Aptitude score</th>
            <th>AI interview Score</th>
            <th>Resume Link</th>
          </tr>
        </thead>
        <tbody>
          {appliedbyAllUser.map((item) => {
            return (
              <tr>
                {/* <td>{item.job_code}</td> */}
                <td>{item.job_title}</td>
                <td>{item.appliedby}</td>
                <td>{item.appliedbydate}</td>
                <td>{item.mobile}</td>
                <td>{item.collegename}</td>
                <td>{item.degreecode}</td>
                <td>{item.appliedbysoftscore}</td>
                <td>{item.appliedbyaptitudescore}</td>
                <td>{item.appliedbyaiscore}</td>
                <td>
                  {item.userresumeUrl ? (
                    <a href={item.userresumeUrl}>Download</a>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default ExportAppliedbyJobs;
