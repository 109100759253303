import { useEffect, memo } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import styled from "styled-components";

import { AssessmentDifficulty } from "../../../../../redux/types/model/assessment/assessment";
import { useNavigate } from "react-router-dom";
import Wrapper from "../../../../../layout/wrapper";
import { getModifiedAvailableAssessment } from "../../../../../services/assessment";

import { GET_AVAILABLE_ASSESSMENT } from "../../../../../redux/features/available-assessment-slice";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 10px 30px 40px rgba(221, 219, 214, 0.46);
  border-radius: 3px;
`;

const Header = styled.div`
  width: 100%;
  height: 105px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 60px;
  background: #016738;
  font-family: Poppins-Bold;
  font-size: 24px;
  line-height: 83px;
  color: #ffffff;
  border-radius: 3px 3px 0 0;
`;

const AvailableTestTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  td {
    padding: 15px;
    border-bottom: 0.8px solid #c4c4c4;
  }
  th {
    padding: 30px 15px 15px 15px;
    border-bottom: 0.8px solid #c4c4c4;
  }
`;

const AvailableTestTableHeading = styled.th`
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 16.2425px;
  line-height: 23px;
  color: #3e3d43;
  text-align: center;
`;

const TitleCol = styled.td`
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #3e3d43;
  text-transform: capitalize;
`;

const DifficultyLevelCol = styled.td`
  font-family: Poppins-Regular;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: ${({ difficulty }) => {
    switch (difficulty) {
      case AssessmentDifficulty.HARD:
        return "#DC0606";
      case AssessmentDifficulty.MODERATE:
        return "#FFC32B";
      case AssessmentDifficulty.EASY:
        return "#219653";
      default:
        return "#3E3D43";
    }
  }};
`;

const DurationCol = styled.td`
  font-family: Poppins-Regular;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #3e3d43;
`;

const ButtonCol = styled.td`
  text-align: right;
`;

const Button = styled.button`
  width: 125px;
  height: 43.37px;
  background: ${({ buttonType }) =>
    buttonType === "START" ? "#016738" : "rgba(38, 50, 56, 0.82)"};
  border: none;
  outline: none;
  border-radius: 42.7339px;
  font-family: Poppins-Bold;
  font-size: 14.145px;
  line-height: 47px;
  color: #ffffff;
  cursor: pointer;
  outline: none;
`;

const AssessmentRoute = {
  APTITUDE: "aptitude",
  SOFT_SKILL: "soft-skill",
};

const BehavioralAssessmentList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    getModifiedAvailableAssessment()
      .then((data) => {
        console.log(data);
        dispatch(GET_AVAILABLE_ASSESSMENT(data));
      })
      .catch(() =>
        toast.error(`Unable to get your assessments `, {
          position: "top-left",
        })
      );
  }, []);

  const availableAssessment = useSelector(
    (state) => state.available_assessments
  );

  const modifiedavailableAssessment = useSelector((state) => state);

  console.log("availableAssessment", availableAssessment);
  console.log("modifiedavailableAssessment", modifiedavailableAssessment);
  return (
    <>
      <Wrapper>
        <Container>
          <Header>Your available online test series</Header>
          <AvailableTestTable>
            <thead>
              <tr>
                <AvailableTestTableHeading>Test name</AvailableTestTableHeading>

                <AvailableTestTableHeading>
                  Total duration
                </AvailableTestTableHeading>
                <AvailableTestTableHeading />
              </tr>
            </thead>
            <tbody>
              {availableAssessment.map((item) => {
                return (
                  <>
                    {item.assessmentType == "SOFT_SKILL" ? (
                      <tr key={item.assessmentId}>
                        <TitleCol>{item.assessmentTitle}</TitleCol>

                        <DurationCol>
                          {item.time ? `${item.time} minutes` : "-"}
                        </DurationCol>
                        <ButtonCol>
                          {!item.testId ? (
                            <Button
                              onClick={() =>
                                navigate(
                                  `/assessment/${
                                    AssessmentRoute[item.assessmentType]
                                  }?id=${item.assessmentId}`
                                )
                              }
                              buttonType="START"
                            >
                              Start
                            </Button>
                          ) : (
                            <Button
                              onClick={() =>
                                navigate(
                                  `/assessment/score-card?id=${item.testId}&type=${item.assessmentType}`
                                )
                              }
                            >
                              Get Report
                            </Button>
                          )}
                        </ButtonCol>
                      </tr>
                    ) : null}
                  </>
                );
              })}
              {availableAssessment.map((item) => {
                return (
                  <>
                    {item.assessmentType == "MIX" ? (
                      <tr key={item.assessmentId}>
                        <TitleCol>{item.assessmentTitle}</TitleCol>

                        <DurationCol>
                          {item.time ? `${item.time} minutes` : "-"}
                        </DurationCol>
                        <ButtonCol>
                          {!item.testId ? (
                            <Button
                              onClick={() =>
                                navigate(
                                  `/assessment/mix?id=${item.assessmentId}`
                                )
                              }
                              buttonType="START"
                            >
                              Start
                            </Button>
                          ) : (
                            <Button
                              onClick={() =>
                                navigate(
                                  `/assessment/mix-score-card?id=${item.testId}&type=${item.assessmentType}`
                                )
                              }
                            >
                              Get Report
                            </Button>
                          )}
                        </ButtonCol>
                      </tr>
                    ) : null}
                  </>
                );
              })}
            </tbody>
          </AvailableTestTable>
        </Container>
      </Wrapper>
    </>
  );
};

export default BehavioralAssessmentList;
