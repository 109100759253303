import styled from "styled-components";

const Container = styled.div`
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
`;
const ConfirmationBlock = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid white;
  background-color: #ffffff;
  padding: 60px;
  color: #3e3d43;
  max-width: 600px;
  width: 100%;
`;
const BtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 70px;
`;
const Button = styled.span`
  cursor: pointer;
  display: flex;
  color: ${(props) => (props.btn === "ok" ? "#013E22" : "#3E3D43")};
  font-size: 20px;
  margin-left: 70px;
`;
const Text = styled.span`
  width: 100%;
  display: flex;
  font-size: 20px;
  justify-content: center;
  color: #3e3d43;
  cursor: default;
`;

const ConfirmationModal = (props) => (
  <>
    {props.isOpen && (
      <Container>
        <ConfirmationBlock>
          <Text>{props.headerText}</Text>
          <BtnContainer>
            {props.isCancelBtnShow && (
              <Button onClick={props.onCancelClick}>cancel</Button>
            )}
            {props.isOkBtnShow && (
              <Button onClick={props.onOkBtnClick} btn={"ok"}>
                ok
              </Button>
            )}
          </BtnContainer>
        </ConfirmationBlock>
      </Container>
    )}
  </>
);

export default ConfirmationModal;
