import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import Carousel from "react-multi-carousel";

const Brands = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      slidesToSlide: 6, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <>
      <section className="brand__area pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="brand__content text-center">
                <h3
                  style={{ marginBottom: 0, marginTop: 30 }}
                  className="brand__title"
                >
                  Our Hiring Partners
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-12">
              <Carousel
                swipeable={false}
                draggable={false}
                showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={false}
                autoPlay={false}
                autoPlaySpeed={4000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
              >
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/1.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/2.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/3.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/4.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/5.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/6.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/7.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/8.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/9.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/10.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/11.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/12.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/13.png" alt="" />
                </div>
                {/* <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/14.png"
                            alt=""
                          />
                        </div> */}
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/15.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/16.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/17.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/18.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/19.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/20.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/21.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/22.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/23.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/24.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/25.png" alt="" />
                </div>
                {/* <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/26.png"
                            alt=""
                          />
                        </div> */}
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/27.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/28.png" alt="" />
                </div>
                <div className="item">
                  <img src="/assets/img/ghjob/client-logo/29.png" alt="" />
                </div>
              </Carousel>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-xxl-12">
              <div className="brand__more text-center">
                <Link to="/about" className="link-btn">
                  View all partners
                  <i className="far fa-arrow-right"></i>
                  <i className="far fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default Brands;
