import { useState } from "react";
import { useSelector } from "react-redux";
//import { RootState } from 'redux/store'
import { ModalType } from "../../../../../redux/types/generic-constant";
import { formatDate } from "../../../../../helpers";
import { DetailTab, OtherDetailTabContent } from "../../common";

import AddEditCertificationsModal from "./add-edit-certifications-modal";

const Certifications = () => {
  const certifications = useSelector((state) => state.profile.certifications);
  console.log("certifications", certifications);
  const [openModalType, setOpenModalType] = useState(undefined);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  return (
    <>
      <DetailTab
        title="Certifications"
        actionIcon={"/assets/img/ghimages/add-icon.svg"}
        actionMethod={() => setOpenModalType(ModalType.ADD)}
        children={
          <>
            {certifications.map(
              (
                { companyName, certificationCompletionId, startDate, endDate },
                idx
              ) => (
                <OtherDetailTabContent idx={idx}>
                  <img
                    src={"/assets/img/ghimages/certifications-icon.svg"}
                    alt=""
                  />
                  <div>
                    <div>{companyName}</div>
                    <div>{certificationCompletionId || "-"}</div>
                    <div>
                      {formatDate(startDate)} to {formatDate(endDate)}
                    </div>
                  </div>
                  <span>
                    <img
                      src={"/assets/img/ghimages/edit-icon.svg"}
                      alt="edit"
                      onClick={() => {
                        setOpenModalType(ModalType.EDIT);
                        setSelectedIndex(idx);
                      }}
                    />
                  </span>
                </OtherDetailTabContent>
              )
            )}
          </>
        }
      />
      {openModalType && (
        <AddEditCertificationsModal
          closeAction={() => {
            setOpenModalType(undefined);
            setSelectedIndex(-1);
          }}
          forEdit={openModalType === ModalType.EDIT}
          selectedIndex={selectedIndex}
        />
      )}
    </>
  );
};

export default Certifications;
