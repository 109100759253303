export const HeroOne = {
  // sm_text: "Access 2000 +",
  sm_text: "",
  shape_text: "Job",
  title:
    " Openings every week and get coached by top industry mentors to land your dream career.",
  subtitle:
    "Meet university,and cultural institutions, who'll share their experience.",
  btn_text: "Enquire Now",
  hero_img: "/assets/img/hero/63.png",
  hero_sm_img: "",
  hero__quote: "Are you ready for",
  hero__quote_2: "your next career milestone?",
};

export const hero_two = {
  title: (
    <>
      Launch your <br /> Own
    </>
  ),
  img: "/assets/img/hero/24nc.png",
  shape_text: "online",
  title_2: "learning Platform",
  subtitle: "Unlimited access to all 60+ instructors.",
  sm_text: "2 passes (with access to all classes) for $240",
  hero_thumb_title: "Congratulations",
  hero_thumb_text: "Ready for your next career move",
  hero_thumb_number: "",
  hero_thumb_text_2: "AI Assessment",
  hero_thumb_title_2: "GreenHorn Score",
  hero_thumb_text_3: "Your Score is 90. Awesome!",
};
