import axios from 'axios'
import config from './config'
import { getDataFromLocalStorage } from '../browser-storage'
import { LocalStorageConstants } from './localstorageconstants'
const getToken = () => getDataFromLocalStorage(LocalStorageConstants.TOKEN)


const addserviceScheduler= (payload:any): Promise<any> => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
    }
    return axios
      .post(`${config.PROTOCOL}${config.HOST}/scheduler/add-scheduler`,{payload}, { headers })
      .then(({ status, data }) => (status === 200 && data ? Promise.resolve(data) : Promise.reject()))
      .catch((err) => Promise.reject(err))
  }

  const getSchedulerlisting = (): Promise<any> => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
    }
    return axios
      .get(`${config.PROTOCOL}${config.HOST}/scheduler/get-scheduler-listing`, { headers })
      .then(({ status, data }) => (status === 200 && data ? Promise.resolve(data) : Promise.reject()))
      .catch((err) => Promise.reject(err))
  }

export {
    addserviceScheduler,
    getSchedulerlisting
}