import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
`;
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 50px 70px;
`;
const PageHeader = styled.label`
  display: flex;
  width: 100%;
  font-size: 22px;
  color: #3e3d43;
  margin-bottom: 70px;
`;
const Span = styled.span`
  display: flex;
  width: 100%;
  max-width: 1160px;
  padding-bottom: 30px;
`;
const CheckBoxBlock = styled.div`
  display: flex;
  align-items: flex-start;
`;
const Checkbox = styled.input`
  display: flex;
  margin: 4px 10px 0 0;
  cursor: pointer;
`;
const AcceptText = styled.span`
  display: flex;
  font-size: 14px;
  flex-direction: column;
  color: ${(props) => (props.color === "red" ? "#FF0000" : "#1F1534")};
`;

const BeginBtn = styled.button`
  display: flex;
  max-width: 176px;
  justify-content: center;
  padding: 20px;
  align-items: center;
  border-radius: 60px;
  background: #016738;
  color: #ffffff;
  border: 1px;
  font-weight: 900;
  margin-top: 25px;
  cursor: ${(props) => (props.assessmentId ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.assessmentId ? "40%" : "100%")};
`;

export {
  Container,
  PageContainer,
  PageHeader,
  Span,
  CheckBoxBlock,
  Checkbox,
  AcceptText,
  BeginBtn,
};
