import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";

const TermsAndServicePolicy = () => {
  return (
    <Wrapper>
      <SEO pageTitle={"Privacy Policy"} />
      <div className="page__title-shape">
        <img
          className="page-title-shape-5 d-none d-sm-block"
          src="/assets/img/page-title/page-title-shape-1.png"
          alt=""
        />
        <img
          className="page-title-shape-6"
          src="/assets/img/page-title/page-title-shape-6.png"
          alt=""
        />
        <img
          className="page-title-shape-7"
          src="/assets/img/page-title/page-title-shape-4.png"
          alt=""
        />
      </div>

      <div style={{ marginTop: 20 }} className="container">
        <h2>Terms of Use</h2>
        <p>
          1. Important: The usage of this website (www.mygreenhorn.com) is
          governed by the following terms ("Terms"). 2. Acceptance: Please read
          the Terms, carefully before using this website, since using this
          website implies your implicit and binding acceptance of these Terms.
          3. Definitions: "Mygreenhorn" refers to Mygreenhorn Technology Private
          Limited, India. "Site" refers to www.mygreenhorn.com owned and
          monitored by Mygreenhorn. "User" refers to anyone who is browsing this
          site. 4. General: Mygreenhorn reserves the right to change these Terms
          at any time without prior notice. Users should periodically visit this
          webpage to review the prevailing, applicable Terms, as they are
          binding on all Users. Particular areas of the Site, which have
          expressly designated specific terms of use, supersede these general
          Terms. 5. Usage: The usage of this Site is governed by these Terms.
          Breach of any of these Terms automatically terminates the User's
          authorization to use this Site. The provisions regarding terms of use
          shall survive such termination. 6. Copyright & Trademarks: All
          materials present on this Site are the exclusive property of
          Mygreenhorn; however, Mygreenhorn does not claim any rights of
          ownership on materials or their trademarks, which are reproduced
          containing a direct or an indirect reference to their respective
          owners. Mygreenhorn is trademarks of Mygreenhorn Tech Private Limited.
          All other registered trademarks and trademarks are the property of
          their respective owners as acknowledged in the annexure herein. The
          User is authorized to view and download the materials on this Site
          only for personal, non-commercial use, provided that all copyright and
          proprietary/disclaimer notices contained in the original materials of
          Mygreenhorn are retained. You may not modify, reproduce, republish,
          upload, publicly display, perform, distribute or otherwise use them
          for commercial purposes without the prior written permission of
          Mygreenhorn. All rights not expressly granted herein are reserved.
          Unauthorized use of any materials on this site may violate copyright,
          trademark and other laws, resulting in civil or criminal liabilities.
          7. Links to Other Websites: Links to the websites of various third
          parties are provided on this Site solely as a convenience to the User.
          It is explicitly agreed and understood by the User that Mygreenhorn
          has not reviewed all of these third-party websites and does not
          control and is not responsible for the accuracy of the content
          provided on any of these third party websites. If you use these links,
          the User will leave this Site and will be governed by the respective
          policies of these third-party websites. Mygreenhorn does not endorse
          or make any representation about the materials and services found on
          third-party websites. The User shall bear all risks associated with
          the access of the third-party websites linked to this Site, and shall
          not hold Mygreenhorn responsible for any loss or damage incurred from
          dealing with such third-party site and materials. 8. Privacy, User
          Submissions & Collection of Information: Mygreenhorn recognizes that
          Users value their privacy. In general you can visit our Site without
          divulging personal information. Where requested, your response to
          these enquiries is strictly voluntary. However, there may be areas in
          this Site that need personal information to provide a customized
          experience or fulfil a requested service, which may not be available
          to Users not choosing to reveal the information requested. If you
          choose to provide Mygreenhorn with personal information, you can be
          assured that it will be used to support your business relationship
          with Mygreenhorn. Mygreenhorn also collects certain information
          automatically as part of its analysis to determine and characterize
          the usage of the materials and services in the Site and improve the
          overall Site functionality. Some webpages on this Site may use
          "cookies", which are small files placed on your hard drive for
          identification purposes. These files are used for Site registration
          and customization the next time you visit us; cookies cannot read data
          from your hard drive. You may configure your web browser to notify you
          and request your acceptance to receive a cookie. However, by not
          accepting cookies, some areas in this Site may not function properly
          and you may not be able to access certain information on this Site.
          All personal and non-personal information gathered by Mygreenhorn is
          governed by the Mygreenhorn Tech Private Limited Privacy Policy. 9.
          Disclaimer: The materials and services at this Site are provided on an
          "as is" and "as available" basis without any warranties of any kind,
          whether express or implied, including, but not limited to, warranties
          of merchantability, fitness for a particular purpose or
          non-infringement of intellectual property. Mygreenhorn does not
          warrant the accuracy, completeness and adequacy of the materials or
          services at this Site. Mygreenhorn may discontinue or make changes to
          the materials and services at this Site or to the products and prices
          described in them at any time without any notice. The materials and
          services at this Site may be out of date and Mygreenhorn makes no
          commitment to update the materials and services at this Site.
          Information published at this Site may refer to products, programs or
          services that may not be available in your geography. Such references
          do not imply that Mygreenhorn intends to announce such products,
          programs or services in your geography. 10. Limitation of Liability:
          Under no circumstance will Mygreenhorn or other third parties
          mentioned at this Site be liable for any damages whatsoever
          (including, but not limited to, those resulting from lost profits,
          lost data or programs or information, and business interruption)
          arising out of the use, inability to use or the results of use of this
          Site, any websites linked to this Site or the materials or information
          or services contained at any or all such sites, whether based on
          warranty, contracts, tort or any other legal theory and whether or not
          advised of the possibility of such damages. Mygreenhorn shall not be
          held responsible for any damage to User's equipment or computer system
          or loss of data that results from the use of the materials,
          information or services from this Site. The User agrees to indemnify,
          defend and hold Mygreenhorn harmless from and against all losses,
          expenses, damages and costs, including reasonable attorneys' fees,
          arising out of or relating to any misuse by the User of the materials
          and services provided on this Site. 11. Applicable Laws: Mygreenhorn
          administers this Site from Bangalore, India and makes no
          representation that the materials or services at this Site are
          appropriate or available for use outside India and access to them from
          territories where their contents are illegal is prohibited. Users
          accessing this Site from outside India do so, on their own initiative
          and are responsible for compliance with local laws. These Terms will
          be governed by and construed in accordance with the laws of India,
          without giving effect to any principles of conflicts of laws. Annexure
          Third Party Trademarks Apple, iPhone, iPad, Bonjour, iTunes,
          AppleScript, QuickTime, Mac, Xcode, Carbon, TrueType, Objective-C,
          QuickDraw and Safari are registered trademarks of Apple, Inc.
          Microsoft, Microsoft Windows, Azure and SQL Azure are trademarks or
          registered trademarks of Microsoft Corporation. Intel is the
          registered trademark of Intel Corporation. Linux is the registered
          trademark of Linus Torvalds in the U.S. and other countries. Adobe,
          Adobe AIR, PostScript and ActionScript are registered trademarks of
          Adobe Systems Inc. JavaScript is a trademark of the Oracle
          Corporation. Amazon, Amazon Web Services (AWS), Amazon EC2, Amazon
          CloudWatch, AWS Elastic Beanstalk, AWS CloudFormation, Amazon RDS,
          Amazon DynamoDB, Amazon CloudFront and Amazon SimpleDB are trademarks
          or registered trademarks of Amazon.com Google, Android and Google App
          Engine are trademarks or registered trademarks of Google, Inc.
          LinkedIn is a registered trademark of LinkedIn Corporation. Twitter is
          a registered trademark of Twitter, Inc. Facebook is a registered
          trademark of Facebook, Inc. Apple, iPhone, iPad, Bonjour, iTunes,
          AppleScript, QuickTime, Mac, Xcode, Carbon, TrueType, Objective-C,
          QuickDraw and Safari are registered trademarks of Apple, Inc.
          Microsoft, Microsoft Windows, Azure and SQL Azure are trademarks or
          registered trademarks of Microsoft Corporation. Intel is the
          registered trademark of Intel Corporation. Linux is the registered
          trademark of Linus Torvalds in the U.S. and other countries. Adobe,
          Adobe AIR, PostScript and ActionScript are registered trademarks of
          Adobe Systems Inc. JavaScript is a trademark of the Oracle
          Corporation. Amazon, Amazon Web Services (AWS), Amazon EC2, Amazon
          CloudWatch, AWS Elastic Beanstalk, AWS CloudFormation, Amazon RDS,
          Amazon DynamoDB, Amazon CloudFront and Amazon SimpleDB are trademarks
          or registered trademarks of Amazon.com Google, Android and Google App
          Engine are trademarks or registered trademarks of Google, Inc.
          LinkedIn is a registered trademark of LinkedIn Corporation. Twitter is
          a registered trademark of Twitter, Inc. Facebook is a registered
          trademark of Facebook, Inc. All other registered trademarks and
          trademarks are the property of their respective owners.
        </p>
      </div>
    </Wrapper>
  );
};

export default TermsAndServicePolicy;
