import { memo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { toast } from "react-toastify";

import {
  UserProfileEvent,
  AppConfigEvent,
} from "../../../../../redux/types/redux-event";
import { updateUserProfile } from "../../../../../services/user-service";
import DatePicker from "react-datepicker";

import {
  ModalBackground,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "../../common/modal";
import {
  RequiredText,
  FieldGroupContainer,
  DateGroupContainer,
  InputFiled,
  Error,
  ButtonGroup,
  ActionButton,
  RemoveAction,
} from "../../common";
import { UPDATE_USER_PROFILE } from "../../../../../redux/features/profile-slice";
// interface InternshipsForm {
//   companyName: string;
//   designation: string;
//   internshipDuration: string;
//   startDate: number;
//   endDate: number;
// }

// interface InternshipsFormError {
//   companyName: string;
//   designation: string;
//   internshipDuration: string;
//   startDate: string;
//   endDate: string;
// }

// interface Props {
//   closeAction: VoidFunction;
//   forEdit: boolean;
//   selectedIndex: number;
// }

const AddEditInternshipModal = ({ closeAction, forEdit, selectedIndex }) => {
  const dispatch = useDispatch();
  // const { internships } = useSelector(
  //   ({ userProfile: { internships } }: RootState) => ({
  //     internships,
  //   })
  // );
  const internships = useSelector((state) => state.profile.internships);
  console.log("internships", internships);

  const [selectedInternshipsData, setSelectedInternshipsData] = useState(
    !internships?.length || selectedIndex < 0 || !internships[selectedIndex]
      ? {
          companyName: "",
          designation: "",
          internshipDuration: "",
          startDate: 0,
          endDate: 0,
        }
      : [...internships][selectedIndex]
  );

  const [formError, setFormError] = useState({
    companyName: "",
    designation: "",
    internshipDuration: "",
    startDate: "",
    endDate: "",
  });
  const [showErrors, setShowErrors] = useState(false);
  const validateData = () => {
    const formErrorData = { ...formError };
    formErrorData.companyName = selectedInternshipsData.companyName
      ? ""
      : "Please enter company name.";
    formErrorData.designation = selectedInternshipsData.designation
      ? ""
      : "Please enter your designation.";
    formErrorData.internshipDuration =
      selectedInternshipsData.internshipDuration
        ? ""
        : "Please enter certification duration.";
    formErrorData.startDate = selectedInternshipsData.startDate
      ? selectedInternshipsData.endDate &&
        Number(selectedInternshipsData.startDate) >
          Number(selectedInternshipsData.endDate)
        ? `Start date can't be greater than or end date.`
        : ""
      : "Please enter internship start date.";
    formErrorData.endDate = selectedInternshipsData.endDate
      ? ""
      : "Please enter internship end/expected date.";

    setFormError(formErrorData);
    return !(
      formErrorData.companyName ||
      formErrorData.designation ||
      formErrorData.internshipDuration ||
      formError.startDate ||
      formError.endDate
    );
  };

  const updateFromData = (fromData) => {
    setSelectedInternshipsData(fromData);
    validateData();
    if (showErrors) {
      setShowErrors(false);
    }
  };

  const addInternship = () => {
    setShowErrors(true);
    if (!validateData()) {
      return;
    }
    const internshipsData = [...internships, selectedInternshipsData];
    dispatch({ type: AppConfigEvent.START_LOADING });
    updateUserProfile({ internships: internshipsData })
      .then((data) => {
        toast.success(`Internship added successfully`, {
          position: "top-left",
        });
        // dispatch({
        //   type: UserProfileEvent.UPDATE_USER_PROFILE,
        //   data: { internships: data?.internships },
        // });
        dispatch(UPDATE_USER_PROFILE({ internships: data?.internships }));
      })
      .catch(() =>
        toast.error(`Unable to add internship`, {
          position: "top-left",
        })
      )
      .finally(() => dispatch({ type: AppConfigEvent.STOP_LOADING }));
    closeAction();
  };

  const updateInternship = () => {
    setShowErrors(true);
    if (!validateData()) {
      return;
    }
    const internshipsData = [...internships];
    internshipsData[selectedIndex] = selectedInternshipsData;
    dispatch({ type: AppConfigEvent.START_LOADING });
    updateUserProfile({ internships: internshipsData })
      .then((data) => {
        toast.success(`Internship updated successfully`, {
          position: "top-left",
        });
        // dispatch({
        //   type: UserProfileEvent.UPDATE_USER_PROFILE,
        //   data: { internships: data?.internships },
        // });
        dispatch(UPDATE_USER_PROFILE({ internships: data?.internships }));
      })
      .catch(() =>
        toast.error(`Unable to update internship`, {
          position: "top-left",
        })
      )
      .finally(() => dispatch({ type: AppConfigEvent.STOP_LOADING }));
    closeAction();
  };

  const deleteInternship = () => {
    const internshipsData = [...internships];
    internshipsData.splice(selectedIndex, 1);
    dispatch({ type: AppConfigEvent.START_LOADING });
    updateUserProfile({ internships: internshipsData })
      .then((data) => {
        toast.success(`Internship deleted successfully`, {
          position: "top-left",
        });
        // dispatch({
        //   type: UserProfileEvent.UPDATE_USER_PROFILE,
        //   data: { internships: data?.internships },
        // });
        dispatch(UPDATE_USER_PROFILE({ internships: data?.internships }));
      })
      .catch(() =>
        toast.error(`Unable to delete internship`, {
          position: "top-left",
        })
      )
      .finally(() => dispatch({ type: AppConfigEvent.STOP_LOADING }));
    closeAction();
  };
  return (
    <ModalBackground>
      <Modal>
        <ModalHeader>
          Internship{" "}
          <img
            src={"/assets/img/ghimages/close-icon.svg"}
            alt="X"
            onClick={closeAction}
          />
        </ModalHeader>
        <ModalBody>
          <RequiredText>* Indicates required</RequiredText>
          <FieldGroupContainer>
            Company name *
            <InputFiled
              value={selectedInternshipsData.companyName}
              onChange={({ target: { value } }) =>
                updateFromData({
                  ...selectedInternshipsData,
                  companyName: value,
                })
              }
              type="text"
            />
            {showErrors && formError.companyName && (
              <Error>{formError.companyName}</Error>
            )}
          </FieldGroupContainer>
          <FieldGroupContainer>
            Designation *
            <InputFiled
              value={selectedInternshipsData.designation}
              onChange={({ target: { value } }) =>
                updateFromData({
                  ...selectedInternshipsData,
                  designation: value,
                })
              }
              type="text"
            />
            {showErrors && formError.designation && (
              <Error>{formError.designation}</Error>
            )}
          </FieldGroupContainer>
          <FieldGroupContainer>
            Internship duration *
            <InputFiled
              value={selectedInternshipsData.internshipDuration}
              onChange={({ target: { value } }) =>
                updateFromData({
                  ...selectedInternshipsData,
                  internshipDuration: value,
                })
              }
              type="text"
            />
            {showErrors && formError.internshipDuration && (
              <Error>{formError.internshipDuration}</Error>
            )}
          </FieldGroupContainer>
          <DateGroupContainer>
            <FieldGroupContainer>
              Start Date *
              <DatePicker
                selected={
                  selectedInternshipsData.startDate
                    ? new Date(selectedInternshipsData.startDate)
                    : null
                }
                maxDate={new Date()}
                onChange={(date) => {
                  if (date)
                    updateFromData({
                      ...selectedInternshipsData,
                      startDate: Date.parse(`${date}`),
                    });
                }}
                customInput={<InputFiled type="text" />}
                showYearDropdown
                showMonthDropdown
              />
              {showErrors && formError.startDate && (
                <Error>{formError.startDate}</Error>
              )}
            </FieldGroupContainer>
            <FieldGroupContainer>
              End Date *
              <DatePicker
                selected={
                  selectedInternshipsData.endDate
                    ? new Date(selectedInternshipsData.endDate)
                    : null
                }
                onChange={(date) => {
                  if (date)
                    updateFromData({
                      ...selectedInternshipsData,
                      endDate: Date.parse(`${date}`),
                    });
                }}
                customInput={<InputFiled type="text" />}
                showYearDropdown
                showMonthDropdown
              />
              {showErrors && formError.endDate && (
                <Error>{formError.endDate}</Error>
              )}
            </FieldGroupContainer>
          </DateGroupContainer>
        </ModalBody>
        <ModalFooter>
          {forEdit ? (
            <ButtonGroup forEdit={true}>
              <RemoveAction onClick={deleteInternship}>
                Delete internship
              </RemoveAction>
              <ActionButton onClick={updateInternship}>Update</ActionButton>
            </ButtonGroup>
          ) : (
            <ButtonGroup forEdit={false}>
              <ActionButton onClick={addInternship}>Add</ActionButton>
            </ButtonGroup>
          )}
        </ModalFooter>
      </Modal>
    </ModalBackground>
  );
};

export default memo(AddEditInternshipModal);
