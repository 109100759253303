import { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  UserProfileEvent,
  AppConfigEvent,
} from "../../../redux/types/redux-event";
import {
  updateUserProfile,
  addProfilePic,
} from "../../../services/user-service";
import styled from "styled-components";

import {
  ModalBackground,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "./common/modal";
import { ButtonGroup, ActionButton, RemoveAction, Error } from "./common";
import Dropzone from "react-dropzone";
import { toBase64 } from "../../../helpers";
import {
  REMOVE_PROFILE_PIC,
  UPDATE_USER_PROFILE,
} from "../../../redux/features/profile-slice";

const Container = styled.div`
  width: 100%;
  max-width: 255px;
  margin: 28px auto;
  div:nth-child(2) {
    margin-top: 4px;
    max-width: 232px;
    text-align: center;
  }
`;

const ProfileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 5px;
  div:nth-child(1) {
    width: 230px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  img {
    cursor: pointer;
  }
`;

const InfoText = styled.div`
  align-items: center;
`;

const UploadButton = styled(ActionButton)`
  font-size: 18px;
  background: linear-gradient(270deg, #0c6d3b 0%, #026639 100%);
`;

const DragDropContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 232px;
  border: 1px dashed #c4c4c4;
  border-radius: 5px;
  gap: 8px;
  div:nth-child(1) {
    font-family: Poppins-Bold;
    font-size: 24px;
    color: rgba(61, 41, 36, 0.8);
  }
  div:nth-child(2) {
    font-family: Poppins-Regular;
    font-size: 18px;
    color: rgba(61, 41, 36, 0.8);
  }
  img {
    width: 220px;
    height: 220px;
    border-radius: 5px;
  }
`;

const AddEditProfilePicModal = ({ closeAction, forEdit }) => {
  const dispatch = useDispatch();

  const [profilePic, setProfilePic] = useState({
    uploadedFile: undefined,
    fileUrl: "",
  });
  const [showErrors, setShowErrors] = useState(false);
  const setupProfilePic = async (file) => {
    if (file?.length && file[0]?.size > 4_00_000) {
      setShowErrors(true);
      return;
    }
    const base64 = await toBase64(file[0]).catch(() =>
      toast.error(`Unable to upload image`, {
        position: "top-left",
      })
    );
    if (base64) {
      setProfilePic({ uploadedFile: file[0], fileUrl: base64 });
    }
    setShowErrors(false);
  };

  const uploadImage = () => {
    if (!profilePic.uploadedFile || showErrors) {
      return;
    }
    dispatch({ type: AppConfigEvent.START_LOADING });
    addProfilePic(profilePic.uploadedFile)
      .then((data) => {
        toast.success(`Profile picture updated successfully`, {
          position: "top-left",
        });
        // dispatch({
        //   type: UserProfileEvent.UPDATE_USER_PROFILE,
        //   data: { profileImgUrl: data?.profileImgUrl || "" },
        // });
        dispatch(UPDATE_USER_PROFILE({ profileImgUrl: data?.profileImgUrl }));
        window.location.reload();
      })
      .catch(() =>
        toast.error(`Unable to update your profile picture`, {
          position: "top-left",
        })
      )
      .finally(() => dispatch({ type: AppConfigEvent.STOP_LOADING }));
    closeAction();
  };

  const deleteImage = () => {
    dispatch({ type: AppConfigEvent.START_LOADING });
    updateUserProfile({ profileImgUrl: "" })
      .then(() => {
        toast.success(`Profile picture deleted successfully`, {
          position: "top-left",
        });
        //dispatch({ type: UserProfileEvent.REMOVE_PROFILE_PIC });
        dispatch(REMOVE_PROFILE_PIC);
      })
      .catch(() =>
        toast.error(`Unable to delete your profile picture`, {
          position: "top-left",
        })
      )
      .finally(() => dispatch({ type: AppConfigEvent.STOP_LOADING }));
    closeAction();
  };

  return (
    <ModalBackground>
      <Modal>
        <ModalHeader>
          Profile picture{" "}
          <img
            src={"/assets/img/ghimages/close-icon.svg"}
            alt="X"
            onClick={closeAction}
          />
        </ModalHeader>
        <ModalBody>
          <Container>
            <ProfileContainer>
              <Dropzone
                accept="image/*"
                onDrop={(file) => setupProfilePic(file)}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <DragDropContainer>
                      {profilePic.fileUrl ? (
                        <img src={profilePic.fileUrl} alt="" />
                      ) : (
                        <>
                          <InfoText>Drag & Drop</InfoText>
                          <InfoText>Or</InfoText>
                          <UploadButton>Upload</UploadButton>
                        </>
                      )}
                    </DragDropContainer>
                  </div>
                )}
              </Dropzone>
              {profilePic.fileUrl && (
                <img
                  src={"/assets/img/ghimages/close-icon.svg"}
                  alt="X"
                  onClick={() =>
                    setProfilePic({ uploadedFile: undefined, fileUrl: "" })
                  }
                />
              )}
            </ProfileContainer>
            {showErrors && <Error>Image size should not exceed 400KB.</Error>}
          </Container>
        </ModalBody>
        <ModalFooter>
          <p style={{ color: "red" }}>
            Note : Image size should not exceed 400KB.
          </p>
          <ButtonGroup forEdit={forEdit}>
            {forEdit && (
              <RemoveAction onClick={deleteImage}>
                Delete profile picture
              </RemoveAction>
            )}
            <ActionButton onClick={uploadImage}>
              {forEdit ? "Update" : "Add"}
            </ActionButton>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </ModalBackground>
  );
};
export default memo(AddEditProfilePicModal);
