import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { HelmetProvider } from "react-helmet-async";

import AppNavigation from "./navigation/app-navigation";
import { store } from "./redux/store";

import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <>
      <Provider store={store}>
        <HelmetProvider>
          <AppNavigation />
          <ToastContainer />
        </HelmetProvider>
      </Provider>
    </>
  );
}

export default App;
