const setDataInSessionStorage = (data: any, key: string) => {
  sessionStorage[key] = JSON.stringify(data)
}
const removeDataFromSessionStorage = ( key: string) => {
  sessionStorage.removeItem(key)
}

const getDataFromSessionStorage = (key: string) => {
  try {
    return sessionStorage[key] ? JSON.parse(sessionStorage[key]) : false
  } catch (err) {
    return err
  }
}
export { setDataInSessionStorage, getDataFromSessionStorage ,removeDataFromSessionStorage}
