let HOST = "localhost:8080";
let PROTOCOL = "http://";
let IMAGE_HOST = "qa.mygreenhorn.com";
const LMS_REDIRECT_URL = "https://academy.mygreenhorn.com/courses?jwt=";
const LMS_SPEED_MENTORING_URL =
  "https://academy.mygreenhorn.com/courses/SpeedTracking-32544/?jwt=";
const LMS_GROUP_DISCUSSIONS_URL =
  "https://academy.mygreenhorn.com/courses/GroupDiscussions-32545/?jwt=";
const LMS_HR_PERSONAL_INTERVIEW =
  "https://academy.mygreenhorn.com/courses/Booka11LiveMockInterview-32539/?jwt=";
const LMS_ONE_ON_ONE_URL =
  "https://academy.mygreenhorn.com/courses/BookaLive11MockTechInterviewSession-32675/?jwt=";
const LMS_CONFIDENCE_BUILDER =
  "https://academy.mygreenhorn.com/courses/ConfidenceBuilder-32744/?jwt=";

const COGNICUE_CREATE_STUDENT_INTERVIEW =
  "https://interview.mygreenhorn.com/7f41cbb2-903e-428e-b1b9-14e41653868a/?jwt=";

// const currentHost =
//   window && window.location && window.location.host
//     ? window.location.host
//     : "localhost:8080";

// HOST = "api.mygreenhorn.com";
// PROTOCOL = "https://";
// IMAGE_HOST = "mygreenhorn.com";

// const currentHost =
//   window && window.location && window.location.host
//     ? window.location.host
//     : "localhost:8080";

// HOST = currentHost.includes("localhost")
//   ? "localhost:8080"
//   : "api.mygreenhorn.com";
// PROTOCOL = "https://";
// IMAGE_HOST = "mygreenhorn.com";
const currentHost =
  window && window.location && window.location.host
    ? window.location.host
    : "localhost:8080";

// if (
//   currentHost === "qa.mygreenhorn.com" ||
//   currentHost === "www.qa.mygreenhorn.com"
// ) {
//   HOST = "qa-api.mygreenhorn.com";
//   PROTOCOL = "https://";
// } else if (
//   currentHost === "mygreenhorn.com" ||
//   currentHost === "www.mygreenhorn.com"
// ) {
//   HOST = "api.mygreenhorn.com";
//   PROTOCOL = "https://";
//   IMAGE_HOST = "mygreenhorn.com";
// }

// if (
//   currentHost === "mygreenhorn.com" ||
//   currentHost === "www.mygreenhorn.com"
// ) {
//   HOST = "api.mygreenhorn.com";
//   PROTOCOL = "https://";
//   IMAGE_HOST = "mygreenhorn.com";
// } else {
//   HOST = "api.mygreenhorn.com";
//   PROTOCOL = "https://";
//   IMAGE_HOST = "mygreenhorn.com";
// }

//currently used
if (currentHost.includes("localhost")) {
  HOST = "localhost:8080";
  PROTOCOL = "http://";
  IMAGE_HOST = "mygreenhorn.com";
} else {
  HOST = "api.mygreenhorn.com";
  PROTOCOL = "https://";
  IMAGE_HOST = "mygreenhorn.com";
}

const config = {
  HOST: HOST,
  PROTOCOL: PROTOCOL,
  IMAGE_HOST: IMAGE_HOST,
  LMS_REDIRECT_URL: LMS_REDIRECT_URL,
  LMS_SPEED_MENTORING_URL: LMS_SPEED_MENTORING_URL,
  LMS_GROUP_DISCUSSIONS_URL: LMS_GROUP_DISCUSSIONS_URL,
  LMS_HR_PERSONAL_INTERVIEW: LMS_HR_PERSONAL_INTERVIEW,
  LMS_ONE_ON_ONE_URL: LMS_ONE_ON_ONE_URL,
  LMS_CONFIDENCE_BUILDER: LMS_CONFIDENCE_BUILDER,
  COGNICUE_CREATE_STUDENT_INTERVIEW: COGNICUE_CREATE_STUDENT_INTERVIEW,
};

export default config;
