import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import Modal from "react-modal";

import "./jobs.css";

// import {Card,Col,Row,Badge,Button,Container} from 'react-bootstrap'
import { getJoblisting } from "../../services/jobs-service";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

//Modal.setAppElement('#yourAppElement');
const JobSlice = () => {
  const _user = localStorage.getItem("user");

  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [jobList, setjobList] = useState([]);
  const [currentAppliedJob, setcurrentAppliedJob] = useState();
  const [selectedCategory, setSetCategory] = useState(0);
  const [filteredjoblist, setfilteredjoblist] = useState([]);
  useEffect(() => {
    const resp = getJoblisting()
      .then((res) => {
        console.log(res);
        let reverseres = res.reverse().slice(0, 6);
        setjobList(reverseres);
        console.log(jobList);
        setfilteredjoblist(reverseres);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(jobList);
  }, []);
  const [isAddProfilePicModalOpen, setIsAddProfilePicModalOpen] =
    useState(false);

  const jobCategoryFilterHandler = (e) => {
    setSetCategory(e.target.value);
    let joblistarr = [];
    joblistarr = jobList;
    const result = joblistarr.filter((item) =>
      item.job_function.includes(e.target.value)
    );
    console.log("result", result);
    setfilteredjoblist(result);
  };

  const clearFilter = () => {
    setSetCategory(0);
    setfilteredjoblist(jobList);
  };
  function changeBackground(e) {
    e.target.style.background = "#dc3545";
    e.target.style.color = "white";
  }

  function changeBackgroundwhite(e) {
    e.target.style.background = "white";
    e.target.style.color = "black";
  }

  function getDateXDaysAgo(numOfDays, date = new Date()) {
    const daysAgo = new Date(date.getTime());

    daysAgo.setDate(date.getDate() - numOfDays);

    return daysAgo;
  }

  return (
    <div className="flexcontainer">
      <div className="row">
        {filteredjoblist.map((item) => {
          return (
            <>
              {item.isjobapproved !== "false" ? (
                <div className="col-md-6">
                  <div class="twm-jobs-list-style1 mb-5">
                    <div>
                      <div className="flexcontainer">
                        <b className="boldtitle">{item.job_title}</b>
                        &nbsp;&nbsp;
                        {item.isjobnew == "true" ? (
                          <div class="badgelabelitem">New</div>
                        ) : null}
                        &nbsp;&nbsp;
                        {item.isjobactive == "true" ? (
                          <div className="labelitemdatetitle">
                            {item.start_date}
                          </div>
                        ) : null}
                        {item.isjobactive == "false" ? (
                          <div className="disablelabelitem">
                            Position Closed
                          </div>
                        ) : null}
                      </div>
                      <p class="companynamestyle">{item.listed_by}</p>
                      <div className="labelContainer">
                        {item.number_of_opening ? (
                          <div className="transparentlabelitem">
                            <i class="fa-solid fa-users-line"></i>&nbsp;{" "}
                            {item.number_of_opening}
                            &nbsp; Openings
                          </div>
                        ) : null}
                        {item.job_function ? (
                          <div className="transparentlabelitem">
                            <i class="fa-solid fa-briefcase"></i>&nbsp;
                            {item.job_function}
                          </div>
                        ) : null}
                        {item.type ? (
                          <div className="transparentlabelitem">
                            <i class="fa-solid fa-business-time"></i>&nbsp;
                            {item.type}
                          </div>
                        ) : null}
                        {item.location ? (
                          <div className="transparentlabelitem">
                            <i class="fa-solid fa-location-dot"></i>&nbsp;
                            {item.location}
                          </div>
                        ) : null}

                        {item.qualification ? (
                          <div className="transparentlabelitem">
                            <i class="fa-solid fa-graduation-cap"></i>&nbsp;
                            {item.qualification}
                          </div>
                        ) : null}
                        {item.passoutyear ? (
                          <div className="transparentlabelitem">
                            <i class="fa-solid fa-business-time"></i>&nbsp;{" "}
                            {item.passoutyear}
                          </div>
                        ) : null}
                      </div>

                      <div className="row">
                        <div className="flexcontainer">
                          {item.isjobactive == "true" ? (
                            <Link
                              to="/single-job-description"
                              state={{ data: item }}
                              className="link"
                            >
                              <div
                                style={{ padding: "7px" }}
                                class="twm-jobs-browse site-text-primary"
                              >
                                Apply Now
                              </div>
                            </Link>
                          ) : null}
                          {item.isjobactive == "false" ? (
                            <button className="disabledstyle">Apply</button>
                          ) : null}
                          &nbsp;&nbsp;{" "}
                          <i
                            style={{ paddingTop: "10px", color: "black" }}
                            class="fa-solid fa-indian-rupee-sign"
                          ></i>
                          &nbsp;
                          <div style={{ paddingTop: "5px", color: "black" }}>
                            {item.salary}&nbsp;Lakhs
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default JobSlice;
