import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getMentorlisting } from "../../services/mentor-service";
import { Card, Form, Table, Row, Col, Badge, Container } from "react-bootstrap";
import { GetMentorStudentCollectionbyId } from "../../services/mentor-service";
import { getDataFromLocalStorage } from "../../browser-storage";
import { toast } from "react-toastify";
import "./studentcollection.css";
import { UpdateStudentDynamicPermissions } from "../../services/dynamictabs-service";
//import "./mentorlanding.css";
const StudentCollection = () => {
  const [mentorlist, setMetorList] = useState([]);
  const [studentcollection, setStudentCollection] = useState([]);
  let ArrayFormat = [];
  useEffect(() => {
    GetMentorProfileById();
  }, []);

  let GetMentorProfileById = async () => {
    const MentorDetials = getDataFromLocalStorage("user-details");
    console.log(MentorDetials);

    const getmentorusername = MentorDetials.userName;

    const SubmitResponse = await GetMentorStudentCollectionbyId(
      getmentorusername
    )
      .then((resp) => {
        console.log("resp", resp);
        for (let userdetials of resp) {
          let userMasterData = userdetials.user_score_master;
          let aptitudescore = userdetials.aptitudescore["rightAns"];
          let softskillscore = userdetials.softskillscore["score"];
          let userresumeUrl = "";
          if (userdetials.userresumeUrl) {
            userresumeUrl =
              "https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/" +
              userdetials.userresumeUrl;
          }

          ArrayFormat.push({
            uid: userdetials.uid,
            username: userdetials.username,
            userfullname: userdetials.userfullname,
            mobile: userdetials.mobile,
            collegename: userdetials.collegename,
            degreecode: userdetials.degreecode,
            softskillscore: softskillscore,
            aptitudescore: aptitudescore,
            mentorscore: userdetials.ScoreGivenByMentor,
            permissions: userdetials.DynamicPermissions,
            aiinterviewscore: userdetials.aiinterviewscore,
            mentorremark: userdetials.RemarkGivenByMentor,
            userresumeUrl: userresumeUrl,
          });
        }

        console.log("ArrayFormat", ArrayFormat);
        //setAppliedbyList(ArrayFormat);
        setStudentCollection(ArrayFormat);
      })
      .catch((err) => {
        console.log(err);

        toast.error(`Error ${err.response.data} `, {
          position: "top-left",
        });
      });
  };
  const [email, setEmail] = useState("");
  const [dynamicPermissions, setDynamicPermissions] = useState([]);
  let DynamicTabshandleChange = (i, e) => {
    let value = [...dynamicPermissions];
    value[i][e.target.name] = e.target.value;
    dynamicPermissions[i][e.target.name] = value[i][e.target.name];
    setDynamicPermissions(dynamicPermissions);
    setDynamicPermissions([...dynamicPermissions]);
    console.log(dynamicPermissions);
  };

  let addDynamicTabsFields = () => {
    setDynamicPermissions([
      ...dynamicPermissions,
      {
        value: "",
      },
    ]);
  };

  let removeDynamicTabsFields = (i) => {
    let value = [...dynamicPermissions];
    value.splice(i, 1);
    setDynamicPermissions(value);
  };
  const [payload, setPayload] = useState({});

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleDynamicPermissionsChange = (e) => {
    setDynamicPermissions(e.target.value.split(","));
  };

  const generatePayload = async () => {
    const newPayload = {
      email: email,
      DynamicPermissions: dynamicPermissions,
    };
    setPayload(newPayload);
    const SubmitResponse = await UpdateStudentDynamicPermissions(newPayload)
      .then((resp) => {
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
        GetMentorProfileById();
      })
      .catch((err) =>
        toast.error(`Error ${err} `, {
          position: "top-left",
        })
      );
  };
  const setDynamicAccessValues = (item) => {
    console.log("item access", item);
    setEmail(item.username);
    setDynamicPermissions(item.permissions);
  };
  return (
    <Wrapper>
      <SEO pageTitle={"Mentors"} />
      <>
        <section
          className="page__title-area page__title-height page__title-overlay d-flex align-items-center"
          style={{
            background: `url(/assets/img/ghimages/try/2.jpg)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-wrapper mt-110">
                  <h3 className="page__title">Your Students</h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="teacher__area pt-50 pb-50">
          <div className="container">
            <div className="row">
              <div className="col-xxl-6 offset-xxl-3">
                <div className="section__title-wrapper text-center mb-60">
                  <h2 className="section__title">
                    {" "}
                    <span className="yellow-bg">
                      {" "}
                      Your Students
                      <img
                        src="/assets/img/shape/yellow-bg-2.png"
                        alt=""
                      />{" "}
                    </span>{" "}
                    <br />
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          class="table-responsive"
          style={{ width: "100%", overflowX: "auto" }}
        >
          <Table
            responsive
            striped
            bordered
            hover
            style={{ whiteSpace: "nowrap" }}
          >
            <thead>
              <tr>
                <th>Student Email</th>

                <th>Student Name</th>
                <th>Applied Date</th>
                <th>Mobile No.</th>
                <th>College Name</th>
                <th>Degree</th>
                <th>Soft Skill score</th>
                <th>Aptitude score</th>
                <th>AI interview Score</th>
                <th>Mentor Score</th>
                <th>Dynamic Permissions</th>
                <th>Resume Link</th>
              </tr>
            </thead>
            <tbody>
              {studentcollection.map((item) => {
                return (
                  <tr>
                    <td>{item.username}</td>
                    <td>{item.userfullname}</td>
                    <td>{item.appliedbydate}</td>
                    <td>{item.mobile}</td>
                    <td>{item.collegename}</td>
                    <td>{item.degreecode}</td>
                    <td>{item.softskillscore}</td>
                    <td>{item.appliedbyaptitudescore}</td>
                    <td>{item.appliedbyaiscore}</td>
                    <td>{item.mentorscore}</td>
                    <td>
                      {item.permissions.map((item) => {
                        return (
                          <>
                            <div>{item},</div>
                          </>
                        );
                      })}
                      <button
                        className="e-btn"
                        onClick={(e) => {
                          setDynamicAccessValues(item); // Assuming item is defined somewhere in the surrounding scope
                        }}
                      >
                        Give Access
                      </button>
                      {/* </div> */}
                    </td>
                    <td>
                      <Link
                        to="/mentor-resume-builder-tool"
                        state={{ data: item.username }}
                        className="e-btn"
                      >
                        Access Resume
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Container>
            <Card className="mt-50 mb-20">
              <Card.Title style={{ padding: 10 }}>
                Give Student Access
              </Card.Title>
              <Card.Body>
                <div className="row">
                  <div className="col-md-4">
                    <label>Email:</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      value={email}
                      className="form-control"
                      onChange={handleEmailChange}
                    />
                  </div>
                </div>
                <div className="row mt-10 mb-10">
                  <div className="col-md-4">
                    <label>Dynamic Permissions (comma-separated):</label>
                  </div>
                  <div className="col-md-8">
                    <textarea
                      type="text"
                      rows={5}
                      value={dynamicPermissions.join(",")}
                      className="form-control"
                      onChange={handleDynamicPermissionsChange}
                    />
                    {/* {dynamicPermissions.map((element, index) => (
                      <div className="form-inline" key={index}>
                        <input
                          placeholder="Permissions"
                          className="form-control"
                          type="text"
                          name="dynamicTabTitle"
                          value={element.dynamicTabTitle}
                          onChange={(e) => DynamicTabshandleChange(index, e)}
                        />

                        {index ? (
                          <button
                            type="button"
                            className="btn btn-primary button remove"
                            onClick={() => removeDynamicTabsFields(index)}
                          >
                            Remove
                          </button>
                        ) : null}
                        <hr />
                      </div>
                    ))} */}
                    {/* <div className="button-section">
                      <button
                        className="e-btn"
                        type="button"
                        onClick={() => addDynamicTabsFields()}
                      >
                        Add
                      </button>
                    </div> */}
                  </div>
                </div>
                <button className="e-btn" onClick={generatePayload}>
                  Submit
                </button>
                {/* <pre>{JSON.stringify(payload, null, 2)}</pre> */}
              </Card.Body>
            </Card>
          </Container>
        </div>
      </>
    </Wrapper>
  );
};

export default StudentCollection;
