import styled from 'styled-components'

const OtherDetailTab = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100px;
  padding: 19px 28px;
  gap: 36px;
  color: rgba(61, 41, 36, 0.8) !important;
  box-shadow: 10px 30px 40px rgba(221, 219, 214, 0.46);
  border-radius: 3px;
  background: #ffffff;
`

const OtherDetailTabTabHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Poppins-Regular;
  font-weight: 600;
  font-size: 20px;
  img {
    cursor: pointer;
  }
`

const OtherDetailTabContent = styled.div<{ idx?: number }>`
  display: flex;
  flex-direction: row;
  gap: 15px;
  img {
    width: 78px;
    height: 78px;
  }
  div {
    display: flex;
    flex-direction: column;
    flex: 1;
    div:nth-child(1) {
      font-family: Poppins-Bold;
      font-size: 20px;
    }
    div:nth-child(2) {
      font-family: Poppins-Regular;
      font-size: 16px;
    }
    div:nth-child(3) {
      font-family: Poppins-Regular;
      font-size: 16px;
    }
  }
  span {
    img {
      height: 21px;
      width: 21px;
      cursor: pointer;
    }
    opacity: ${({ idx }) => (idx === 0 ? 1 : 0)};
  }
  &:hover {
    span {
      opacity: 1;
    }
  }
`

interface DetailTabProps {
  title: string
  actionIcon: string
  actionMethod: VoidFunction
  children: React.ReactNode
}

const DetailTab = ({ title, actionIcon = '', actionMethod, children }: DetailTabProps) => (
  <OtherDetailTab>
    <OtherDetailTabTabHeader>
      {title}
      <img src={actionIcon} onClick={actionMethod} alt="" />
    </OtherDetailTabTabHeader>
    {children}
  </OtherDetailTab>
)

export { OtherDetailTab, OtherDetailTabTabHeader, OtherDetailTabContent, DetailTab }
