import { Link } from "react-router-dom";
import { getDataFromLocalStorage } from "../../browser-storage";

const WhitePricing = () => {
  const pricing_data = getDataFromLocalStorage("whiteLabelPricing");
  console.log("pricing_data", pricing_data);
  return (
    <>
      <section className="price__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-3 offset-xxl-4">
              <div
                style={{ paddingTop: 30 }}
                className="section__title-wrapper mb-60 text-center"
              >
                <h2 className="section__title">
                  Our{" "}
                  <span className="yellow-bg yellow-bg-big">
                    Package
                    <img src="/assets/img/shape/yellow-bg.png" alt="" />
                  </span>
                </h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="price__tab-content">
                <div className="tab-content" id="nav-tabContent">
                  <div className="row">
                    {pricing_data.map((price, index) => (
                      <div
                        key={index}
                        className="col-xxl-3 col-xl-3 col-lg-3 col-md-3"
                      >
                        <div
                          className="price__item grey-bg mb-30 p-relative"
                          style={{
                            padding: "20px",
                          }}
                        >
                          <div className="price__head">
                            <h4 style={{ fontSize: "20px" }}>
                              {price.PricingTitle}
                            </h4>
                            <p style={{ fontSize: "18px" }}>
                              {" "}
                              {price.PricingSubTitle}
                            </p>
                          </div>
                          <div className="price__features mb-20 mt-20">
                            <ul>
                              {price.PricingFeatures.map((feature, index) => (
                                <li key={index}>
                                  {!feature == "" ? (
                                    <>
                                      <i className="far fa-check"></i>
                                      <div>{feature.value}</div>
                                    </>
                                  ) : null}
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Link
                              to="/main-payment-page"
                              state={{ data: price }}
                              style={{
                                position: "absolute",
                                bottom: 10,
                                //left: 24,
                              }}
                              className={`e-btn e-btn-border mt-5`}
                            >
                              Get Started
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhitePricing;
