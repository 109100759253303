import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import CampusEnquiryForm from "../enquiry-forms/campus-enquiry-form";

const PlacementOfficerDashboard = () => {
  return (
    <Wrapper>
      <SEO pageTitle={"Placement Officer Dashboard"} />
      <CampusEnquiryForm />
    </Wrapper>
  );
};

export default PlacementOfficerDashboard;
