import React, { useEffect, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import { Link } from "react-router-dom";
import config from "../../services/config";
//import "./pdfViewer.css";

//this is required to load the pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PdfViewer = ({ pdfUrl }) => {
  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [style, setStyle] = useState({ display: "block" });
  const [currentPDF, setCurrentPDF] = useState(null);

  useEffect(() => {
    console.log("pdfUrl", pdfUrl);
    const currentUrl = `${config.PROTOCOL}${config.IMAGE_HOST}/` + pdfUrl;
    console.log("currentUrl", currentUrl);
    setCurrentPDF(currentUrl);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 2.0;

  const zoomOutClass = isMinZoom ? "disabled" : "clickable";
  const zoomInClass = isMaxZoom ? "disabled" : "clickable";

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };
  return (
    <div>
      <div className="pdf-container">
        <div style={style} className="pdf-controls">
          <div class="d-flex flex-row bd-highlight mb-3">
            <div class="p-2 bd-highlight">
              {" "}
              <button
                className="e-btn"
                onClick={handlePrevPage}
                disabled={pageNumber === 1}
              >
                &lt;
              </button>
              <span className="e-btn" style={{ marginLeft: 1, marginRight: 1 }}>
                {pageNumber} of {numPages}
              </span>
              <button
                className="e-btn"
                onClick={handleNextPage}
                disabled={pageNumber === numPages}
              >
                &gt;
              </button>
            </div>
            <div class="p-2 bd-highlight">
              <div>
                <div className="e-btn" onClick={zoomOut}>
                  <i className={`fas fa-search-minus mx-3 ${zoomOutClass}`} />
                </div>
                <div
                  style={{ marginLeft: 1, marginRight: 1 }}
                  className="e-btn"
                >
                  <span>{(scale * 100).toFixed()}%</span>
                </div>
                <div className="e-btn" onClick={zoomIn}>
                  <i className={`fas fa-search-plus mx-3 ${zoomInClass}`} />
                </div>
              </div>
            </div>
            <div class="p-2 bd-highlight">
              <Link
                style={{ marginLeft: 1, marginRight: 1 }}
                className="e-btn"
                to={`/pdf-fullscreen-view`}
              >
                FullScreen
              </Link>
            </div>
          </div>
        </div>
        <Document
          file={currentPDF}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
        >
          <Page
            size="A4"
            style={{ backgroundColor: "tomato" }}
            pageNumber={pageNumber}
            fullScreen={true}
            width={"600"}
            scale={scale}
          />
        </Document>
      </div>
    </div>
  );
};

export default PdfViewer;
