import React from "react";

const ResumeBuilderDesignChild = ({ basicDetails, workExperience }) => {
  return (
    <div>
      {/* Display Basic Details */}
      <h2>Basic Details:</h2>
      <p>First Name: {basicDetails.firstname}</p>
      <p>First Name: {basicDetails.middlename}</p>
      <p>First Name: {basicDetails.middlename}</p>
      <p>First Name: {basicDetails.middlename}</p>

      {/* Display more details as needed */}

      {/* Display Work Experience */}
      <h2>Work Experience:</h2>
      {workExperience.map((experience, index) => (
        <div key={index}>
          <p>Company Name: {experience.companyName}</p>
          {/* Display more details as needed */}
        </div>
      ))}
    </div>
  );
};

export default ResumeBuilderDesignChild;
