const student_menu_data = [
  {
    id: 1,
    title: "Home",
    link: "/",
  },
  {
    id: 0,
    title: "Dashboard",
    link: "/student-dashboard",
  },
];

export default student_menu_data;
