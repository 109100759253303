import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import StudentInteractiveDashboard from "./student-interactive-dashboard";
import Profile from "./profile";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getUserProfile } from "../../services/user-service";
import {
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
} from "../../redux/features/profile-slice";
const StudentDashboard = () => {
  const dispatch = useDispatch();

  let getProfilefunction = async () => {
    const getprofileresult = await getUserProfile()
      .then((data) => {
        dispatch(GET_USER_PROFILE(data));
        dispatch(UPDATE_USER_PROFILE(data));
      })
      .catch(() =>
        //edit by yash
        console.log("Unable to fetch your profile")
      );
    console.log("getprofileresult", getprofileresult);
  };

  useEffect(() => {
    getProfilefunction();
  }, []);
  return (
    <Wrapper>
      <SEO pageTitle={"Student Dashboard"} />
      <StudentInteractiveDashboard />
      <Profile />
    </Wrapper>
  );
};

export default StudentDashboard;
