import React, { useEffect, useState, useRef, useMemo } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";

import "./admin.css";

import { getJoblisting, DeleteJobListing } from "../../services/jobs-service";

import { toast } from "react-toastify";

import Wrapper from "../../layout/wrapper";
const ref = React.createRef();

const AdminDeleteJobs = () => {
  const [jobList, setjobList] = useState([]);

  useEffect(() => {
    getALLJobListing();
  }, []);

  const getALLJobListing = () => {
    const resp = getJoblisting()
      .then((res) => {
        //let reverseres = res.reverse();
        setjobList(res);
        console.log(jobList);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(jobList);
  };

  let DeleteJobHandler = (item) => {
    console.log(item);
    const job_code = item.job_code;
    DeleteJobListing(job_code)
      .then((resp) => {
        console.log(resp);
        toast.success(`Deleted Successfully`, {
          position: "top-left",
        });
      })
      .catch((err) => {
        toast.error(`Error:${err}`, {
          position: "top-left",
        });
      });
  };

  return (
    <>
      <Wrapper>
        <Container>
          <Row style={{ marginTop: "50px" }}>
            <Card className="MainCard">
              <h1>All Job Listing</h1>
              {jobList.map((item) => {
                return (
                  <Card className="carddesign">
                    <Container className="jobcontainer">
                      <Row>
                        <Col>
                          <p className="jobtitle">
                            <b className="boldtitle">{item.job_title}</b> (
                            {item.number_of_opening} Openings){" "}
                            <Badge bg="warning" text="dark">
                              New
                            </Badge>
                          </p>
                        </Col>
                        <Col sm={3}>
                          {" "}
                          <p className="companytitle">
                            <img
                              width="50px"
                              src="/images/blankcompany.png"
                              style={{ borderRadius: "50%" }}
                            />{" "}
                            &nbsp;&nbsp;{item.listed_by}
                          </p>
                        </Col>
                        {/* <Col>
                            <p>
                              <i class="fa-solid fa-business-time"></i>&nbsp;
                              {item.job_function}
                            </p>
                          </Col> */}
                        {/* <Col>
                            <p>
                              <i class="fa-solid fa-location-dot"></i>&nbsp;
                              {item.location}
                            </p>
                          </Col> */}
                        {/* <Col>
                            <p>
                              <i class="fa-solid fa-briefcase"></i>&nbsp; Full
                              Time
                            </p>
                          </Col> */}
                        <Col sm={2}>
                          <div>
                            Launch Date:
                            <Badge bg="secondary">{item.start_date}</Badge>
                          </div>
                        </Col>

                        <Col sm={1}>
                          <button
                            className="e-btn"
                            onClick={() => DeleteJobHandler(item)}
                          >
                            Delete
                          </button>
                        </Col>
                      </Row>
                      <Row></Row>

                      <Row></Row>
                    </Container>
                  </Card>
                );
              })}
            </Card>
          </Row>
        </Container>
      </Wrapper>
    </>
  );
};
export default AdminDeleteJobs;
