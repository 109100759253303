import React, { useRef, useEffect, useState } from "react";
import "../resumebuilder/resumebuilder.css";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Badge from "react-bootstrap/Badge";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import config from "../../services/config";
import {
  setDataInLocalStorage,
  getDataFromLocalStorage,
} from "../../browser-storage";
import { toast } from "react-toastify";
import Accordion from "react-bootstrap/Accordion";
import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
// tslint:disable-next-line:no-unused-vars
const ref = React.createRef();

const ResumeBuilderTool = () => {
  const componentRef = useRef();

  let handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => getSavedData(), []);

  let getSavedData = () => {
    const userDetials = getDataFromLocalStorage("user");
    const email = userDetials.email;
    const Createdby = { CreatedBy: email };
    axios
      .post(`${config.PROTOCOL}${config.HOST}/resume/get-resume`, Createdby)
      .then(function (response) {
        console.log(response.data);
        const resumeResp = response.data;
        let getWorkExprienceValues = "";
        if (resumeResp) {
          setDataInLocalStorage("savedResumeData", resumeResp);

          const getsavedResumeData = getDataFromLocalStorage("savedResumeData");
          const getBasicDetails = getsavedResumeData.BasicDetails;
          const getEducationformValues = getsavedResumeData.EducationformValues;
          const getInternshipformValues =
            getsavedResumeData.InternshipformValues;
          if (getsavedResumeData.WorkExprienceformValues) {
            getWorkExprienceValues = getsavedResumeData.WorkExprienceformValues;
          }

          const getCertificationformValues =
            getsavedResumeData.CertificationformValues;
          const getAboutme = getsavedResumeData.Aboutme;
          const getTechnicalskills = getsavedResumeData.Technicalskills;
          const getInterpersonalskills = getsavedResumeData.Interpersonalskills;
          const getHobbies = getsavedResumeData.Hobbies;
          setBasicDetails(getBasicDetails);
          setEducationFormValues(getEducationformValues);
          setInternshipFormValues(getInternshipformValues);
          setCertificationFormValues(getCertificationformValues);
          if (getsavedResumeData.WorkExprienceformValues) {
            setWorkExprienceFormValues(getWorkExprienceValues);
          }
          setAboutme(getAboutme);
          setTechnicalskills(getTechnicalskills);
          setInterpersonalskills(getInterpersonalskills);
          setHobbies(getHobbies);
          console.log(getsavedResumeData);
        } else {
          toast.error(`You don't have a saved Resume yet`, {
            position: "top-left",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  let savepdf = () => {
    const userDetials = localStorage.getItem("user");
    console.log("userDetials", userDetials);
    const userDetialsparsed = JSON.parse(userDetials);
    const email = userDetialsparsed.email;
    console.log("email", email);
    const CreatedBy = { CreatedBy: email };
    console.log("createdby", CreatedBy);
    let resumedata = {
      BasicDetails,
      EducationformValues,
      InternshipformValues,
      CertificationformValues,
      WorkExprienceformValues,
      Aboutme,
      Technicalskills,
      Interpersonalskills,
      Hobbies,
      CreatedBy,
    };
    console.log(resumedata);

    setDataInLocalStorage("savedResumeData", {
      BasicDetails: BasicDetails,
      EducationformValues: EducationformValues,
      InternshipformValues: InternshipformValues,
      CertificationformValues: CertificationformValues,
      WorkExprienceformValues: WorkExprienceformValues,
      Aboutme: Aboutme,
      Technicalskills: Technicalskills,
      Interpersonalskills: Interpersonalskills,
      Hobbies: Hobbies,
    });

    axios
      .put(`${config.PROTOCOL}${config.HOST}/resume/resume-builder`, resumedata)
      .then(function (response) {
        console.log(response);
        toast.success(`Data Successfully Saved`, {
          position: "top-left",
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [BasicDetails, setBasicDetails] = useState({
    firstname: "",
    middlename: "",
    lastname: "",
    degree: "",
    phonenumber: "",
    email: "",
    linkedin: "",
  });
  const [EducationformValues, setEducationFormValues] = useState([
    {
      startYear: "",
      startMonth: "",
      endYear: "",
      endMonth: "",
      courseName: "",
      degreeName: "",
      collegeName: "",
      score: "",
    },
  ]);
  const [InternshipformValues, setInternshipFormValues] = useState([
    {
      startYear: "",
      startMonth: "",
      endYear: "",
      endMonth: "",
      companyName: "",
      designationName: "",
      duration: "",
      description: "",
    },
  ]);
  const [WorkExprienceformValues, setWorkExprienceFormValues] = useState([
    {
      startYear: "",
      startMonth: "",
      endYear: "",
      endMonth: "",
      companyName: "",
      designationName: "",
      duration: "",
      description: "",
    },
  ]);
  const [CertificationformValues, setCertificationFormValues] = useState([
    {
      startYear: "",
      startMonth: "",
      endYear: "",
      endMonth: "",
      certificateName: "",
      certificateSourceName: "",
      certificateLink: "",
    },
  ]);
  const [Aboutme, setAboutme] = useState([{ Aboutme: "" }]);
  const [Technicalskills, setTechnicalskills] = useState([
    { skillname: "", techskillrating: "" },
  ]);
  const [Interpersonalskills, setInterpersonalskills] = useState([
    { skillname: "", skillrating: "" },
  ]);
  const [Hobbies, setHobbies] = useState([{ hobbies: "" }]);
  const [formValues, setFormValues] = useState([{ name: "", email: "" }]);

  let BasicDetailsonChange = (evt) => {
    const value = evt.target.value;
    setBasicDetails({
      ...BasicDetails,
      [evt.target.name]: value,
    });
    console.log(BasicDetails);
  };

  let AboutmeonChange = (e) => {
    //let newaboutme = [...Aboutme];
    //newaboutme[e.target.Aboutme] = e.target.value;
    setAboutme({ Aboutme: e.target.value });
    console.log(Aboutme);
  };

  //Education
  let EducationhandleChange = (i, e) => {
    let newEducationFormValues = [...EducationformValues];
    newEducationFormValues[i][e.target.name] = e.target.value;
    EducationformValues[i][e.target.name] =
      newEducationFormValues[i][e.target.name];
    setEducationFormValues(EducationformValues);
    setEducationFormValues([...EducationformValues]);
    console.log(EducationformValues);
  };

  let addEducationFormFields = () => {
    setEducationFormValues([
      ...EducationformValues,
      {
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: "",
        courseName: "",
        degreeName: "",
        collegeName: "",
        score: "",
      },
    ]);
  };

  let removeEducationFormFields = (i) => {
    let newEducationFormValues = [...EducationformValues];
    newEducationFormValues.splice(i, 1);
    setEducationFormValues(newEducationFormValues);
  };

  let EducationhandleSubmit = (event) => {
    event.preventDefault();
    alert(JSON.stringify(EducationformValues));
  };

  //Internship
  let InternshiphandleChange = (i, e) => {
    let newInternshipFormValues = [...InternshipformValues];
    newInternshipFormValues[i][e.target.name] = e.target.value;
    InternshipformValues[i][e.target.name] =
      newInternshipFormValues[i][e.target.name];
    setInternshipFormValues(InternshipformValues);
    setInternshipFormValues([...InternshipformValues]);
    console.log(InternshipformValues);
  };

  let InternshipaddFormFields = () => {
    setInternshipFormValues([
      ...InternshipformValues,
      {
        startYear: "",
        startMonth: "",
        endYear: "",
        endMonth: "",
        companyName: "",
        designationName: "",
        duration: "",
        description: "",
      },
    ]);
  };

  let InternshipremoveFormFields = (i) => {
    let newInternshipFormValues = [...InternshipformValues];
    newInternshipFormValues.splice(i, 1);
    setInternshipFormValues(newInternshipFormValues);
  };

  //Work Exprience

  let WorkExpriencehandleChange = (i, e) => {
    let newWorkExprienceformValues = [...WorkExprienceformValues];
    newWorkExprienceformValues[i][e.target.name] = e.target.value;
    WorkExprienceformValues[i][e.target.name] =
      newWorkExprienceformValues[i][e.target.name];
    setWorkExprienceFormValues(WorkExprienceformValues);
    setWorkExprienceFormValues([...WorkExprienceformValues]);
    console.log(WorkExprienceformValues);
  };

  let WorkExprienceaddFormFields = () => {
    setWorkExprienceFormValues([
      ...WorkExprienceformValues,
      {
        startYear: "",
        startMonth: "",
        endYear: "",
        endMonth: "",
        companyName: "",
        designationName: "",
        duration: "",
        description: "",
      },
    ]);
  };

  let WorkExprienceremoveFormFields = (i) => {
    let newWorkExprienceformValues = [...WorkExprienceformValues];
    newWorkExprienceformValues.splice(i, 1);
    setWorkExprienceFormValues(newWorkExprienceformValues);
  };

  //Certification
  let CertificationhandleChange = (i, e) => {
    let newCertificationFormValues = [...CertificationformValues];
    newCertificationFormValues[i][e.target.name] = e.target.value;
    CertificationformValues[i][e.target.name] =
      newCertificationFormValues[i][e.target.name];
    setCertificationFormValues(CertificationformValues);
    setCertificationFormValues([...CertificationformValues]);
    console.log(CertificationformValues);
  };

  let CertificationaddFormFields = () => {
    setCertificationFormValues([
      ...CertificationformValues,
      {
        startYear: "",
        startMonth: "",
        endYear: "",
        endMonth: "",
        certificateName: "",
        certificateSourceName: "",
        certificateLink: "",
      },
    ]);
  };

  let CertificationremoveFormFields = (i) => {
    let newCertificationFormValues = [...CertificationformValues];
    newCertificationFormValues.splice(i, 1);
    setCertificationFormValues(newCertificationFormValues);
  };

  let TechnicalskillshandleChange = (i, e) => {
    let newTechnicalskills = [...Technicalskills];
    newTechnicalskills[i][e.target.name] = e.target.value;
    Technicalskills[i][e.target.name] = newTechnicalskills[i][e.target.name];
    setTechnicalskills(Technicalskills);
    setTechnicalskills([...Technicalskills]);
    console.log(Technicalskills);
  };

  let addTechnicalskillsFormFields = () => {
    setTechnicalskills([
      ...Technicalskills,
      { skillname: "", techskillrating: "" },
    ]);
  };

  let removeTechnicalskillsFormFields = (i) => {
    let newFormValues = [...Technicalskills];
    newFormValues.splice(i, 1);
    setTechnicalskills(newFormValues);
  };

  let InterpersonalskillshandleChange = (i, e) => {
    let newInterpersonal = [...Interpersonalskills];
    newInterpersonal[i][e.target.name] = e.target.value;
    Interpersonalskills[i][e.target.name] = newInterpersonal[i][e.target.name];
    setInterpersonalskills(Interpersonalskills);
    setInterpersonalskills([...Interpersonalskills]);
    console.log(Interpersonalskills);
  };

  let addInterpersonalskillsFormFields = () => {
    setInterpersonalskills([
      ...Interpersonalskills,
      { skillname: "", skillrating: "" },
    ]);
  };

  let removeInterpersonalskillsFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  let HobbieshandleChange = (i, e) => {
    let newHobbies = [...Hobbies];
    newHobbies[i][e.target.name] = e.target.value;
    Hobbies[i][e.target.name] = newHobbies[i][e.target.name];
    setHobbies(Hobbies);
    setHobbies([...Hobbies]);
    console.log(Hobbies);
  };

  let addHobbiesFormFields = () => {
    setHobbies([...Hobbies, { hobbies: "" }]);
  };

  let removeHobbiesFormFields = (i) => {
    let newFormValues = [...Hobbies];
    newFormValues.splice(i, 1);
    setHobbies(newFormValues);
  };

  const [descriptionfontsize, setdescriptionfontsize] = useState(16);
  const [titlefontsize, settitlefontsize] = useState(18);

  return (
    <Wrapper>
      <SEO pageTitle={"Resume Builder Tool"} />
      <Row>
        <Accordion
          style={{ marginTop: "20px" }}
          defaultActiveKey={["0"]}
          alwaysOpen
        >
          <Accordion.Item eventKey="0">
            <Accordion.Header>Advanced Customizations</Accordion.Header>
            <Accordion.Body>
              <Card>
                <div>
                  <div style={{ padding: 10 }}>
                    Change Title Font Size &nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={() => settitlefontsize(titlefontsize - 1)}
                    >
                      -
                    </button>
                    &nbsp; [{titlefontsize}] &nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={() => settitlefontsize(titlefontsize + 1)}
                    >
                      +
                    </button>
                  </div>
                  <div style={{ padding: 10 }}>
                    Change Description Font Size &nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        setdescriptionfontsize(descriptionfontsize - 1)
                      }
                    >
                      -
                    </button>
                    &nbsp; [{descriptionfontsize}] &nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        setdescriptionfontsize(descriptionfontsize + 1)
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Col className="divscroll" sm={4}>
          <div style={{ margin: 10 }}>
            <Badge style={{ width: "100%" }} bg="secondary">
              Form
            </Badge>
            <Card className="carddesign">
              <Card.Body>
                <Card.Title>Introduction</Card.Title>
                <Card.Text>
                  <div className="MobTextStyle">
                    <input
                      placeholder="First Name"
                      className="form-control marginTop5"
                      type="text"
                      name="firstname"
                      value={BasicDetails.firstname}
                      onChange={BasicDetailsonChange}
                    />
                    <input
                      placeholder="Middle Name"
                      className="form-control marginTop5"
                      type="text"
                      name="middlename"
                      value={BasicDetails.middlename}
                      onChange={BasicDetailsonChange}
                    />
                    <input
                      placeholder="Last Name"
                      className="form-control marginTop5"
                      type="text"
                      name="lastname"
                      value={BasicDetails.lastname}
                      onChange={BasicDetailsonChange}
                    />
                    <input
                      placeholder="Degree"
                      className="form-control marginTop5"
                      type="text"
                      name="degree"
                      value={BasicDetails.degree}
                      onChange={BasicDetailsonChange}
                    />
                    <input
                      placeholder="Phone"
                      className="form-control marginTop5"
                      type="text"
                      name="phonenumber"
                      value={BasicDetails.phonenumber}
                      onChange={BasicDetailsonChange}
                    />
                    <input
                      placeholder="Email"
                      className="form-control marginTop5"
                      type="text"
                      name="email"
                      value={BasicDetails.email}
                      onChange={BasicDetailsonChange}
                    />
                    <input
                      placeholder="Linkedin"
                      className="form-control marginTop5"
                      type="text"
                      name="linkedin"
                      value={BasicDetails.linkedin}
                      onChange={BasicDetailsonChange}
                    />
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="carddesign marginTop5">
              <Card.Body>
                <Card.Title>Education</Card.Title>
                <Card.Text>
                  {EducationformValues.map((element, index) => (
                    <div className="form-inline" key={index}>
                      <Row className="marginTop5">
                        <Col>
                          <select
                            name="startYear"
                            className="form-control"
                            value={EducationformValues.startYear}
                            onChange={(e) => EducationhandleChange(index, e)}
                            placeholder="StartYear"
                          >
                            <option
                              name=""
                              value=""
                              style={{ display: "none" }}
                            >
                              Start Year
                            </option>
                            <option name="2025" value="2025">
                              2025
                            </option>
                            <option name="2024" value="2024">
                              2024
                            </option>
                            <option name="2023" value="2023">
                              2023
                            </option>
                            <option name="2022" value="2022">
                              2022
                            </option>
                            <option name="2021" value="2021">
                              2021
                            </option>
                            <option name="2020" value="2020">
                              2020
                            </option>
                            <option name="2019" value="2019">
                              2019
                            </option>
                            <option name="2018" value="2018">
                              2018
                            </option>
                            <option name="2017" value="2017">
                              2017
                            </option>
                            <option name="2016" value="2016">
                              2016
                            </option>
                            <option name="2015" value="2015">
                              2015
                            </option>
                            <option name="2014" value="2014">
                              2014
                            </option>
                            <option name="2013" value="2013">
                              2013
                            </option>
                            <option name="2012" value="2012">
                              2012
                            </option>
                            <option name="2011" value="2011">
                              2011
                            </option>
                            <option name="2010" value="2010">
                              2010
                            </option>
                          </select>
                        </Col>
                        <Col>
                          <select
                            name="endYear"
                            className="form-control"
                            value={element.endYear}
                            onChange={(e) => EducationhandleChange(index, e)}
                            placeholder="EndYear"
                          >
                            <option
                              name=""
                              value=""
                              style={{ display: "none" }}
                            >
                              End Year
                            </option>
                            <option name="2025" value="2025">
                              2025
                            </option>
                            <option name="2024" value="2024">
                              2024
                            </option>
                            <option name="2023" value="2023">
                              2023
                            </option>
                            <option name="2022" value="2022">
                              2022
                            </option>
                            <option name="2021" value="2021">
                              2021
                            </option>
                            <option name="2020" value="2020">
                              2020
                            </option>
                            <option name="2019" value="2019">
                              2019
                            </option>
                            <option name="2018" value="2018">
                              2018
                            </option>
                            <option name="2017" value="2017">
                              2017
                            </option>
                            <option name="2016" value="2016">
                              2016
                            </option>
                            <option name="2015" value="2015">
                              2015
                            </option>
                            <option name="2014" value="2014">
                              2014
                            </option>
                            <option name="2013" value="2013">
                              2013
                            </option>
                            <option name="2012" value="2012">
                              2012
                            </option>
                            <option name="2011" value="2011">
                              2011
                            </option>
                            <option name="2010" value="2010">
                              2010
                            </option>
                          </select>
                        </Col>
                      </Row>
                      <Row className="marginTop5">
                        <Col>
                          <select
                            name="startMonth"
                            className="form-control"
                            value={EducationformValues.startMonth}
                            onChange={(e) => EducationhandleChange(index, e)}
                            placeholder="StartMonth"
                          >
                            <option
                              name=""
                              value=""
                              style={{ display: "none" }}
                            >
                              Start Month
                            </option>
                            <option name="January" value="Jan">
                              January
                            </option>
                            <option name="February" value="Feb">
                              February
                            </option>
                            <option name="March" value="Mar">
                              March
                            </option>
                            <option name="April" value="Apr">
                              April
                            </option>
                            <option name="May" value="May">
                              May
                            </option>
                            <option name="June" value="Jun">
                              June
                            </option>
                            <option name="July" value="Jul">
                              July
                            </option>
                            <option name="August" value="Aug">
                              August
                            </option>
                            <option name="September" value="Sep">
                              September
                            </option>
                            <option name="October" value="Oct">
                              October
                            </option>
                            <option name="November" value="Nov">
                              November
                            </option>
                            <option name="December" value="Dec">
                              December
                            </option>
                          </select>
                        </Col>
                        <Col>
                          <select
                            name="endMonth"
                            className="form-control"
                            value={element.endMonth}
                            onChange={(e) => EducationhandleChange(index, e)}
                            placeholder="EndMonth"
                          >
                            <option
                              name=""
                              value=""
                              style={{ display: "none" }}
                            >
                              End Month
                            </option>
                            <option name="January" value="Jan">
                              January
                            </option>
                            <option name="February" value="Feb">
                              February
                            </option>
                            <option name="March" value="Mar">
                              March
                            </option>
                            <option name="April" value="Apr">
                              April
                            </option>
                            <option name="May" value="May">
                              May
                            </option>
                            <option name="June" value="Jun">
                              June
                            </option>
                            <option name="July" value="Jul">
                              July
                            </option>
                            <option name="August" value="Aug">
                              August
                            </option>
                            <option name="September" value="Sep">
                              September
                            </option>
                            <option name="October" value="Oct">
                              October
                            </option>
                            <option name="November" value="Nov">
                              November
                            </option>
                            <option name="December" value="Dec">
                              December
                            </option>
                          </select>
                        </Col>
                      </Row>
                      <input
                        placeholder="School / College Name"
                        className="form-control"
                        type="text"
                        name="collegeName"
                        value={element.collegeName || ""}
                        onChange={(e) => EducationhandleChange(index, e)}
                      />
                      <input
                        placeholder="Class / Degree Name"
                        className="form-control"
                        type="text"
                        name="degreeName"
                        value={element.degreeName || ""}
                        onChange={(e) => EducationhandleChange(index, e)}
                      />
                      <input
                        placeholder="Board / Course Name"
                        className="form-control"
                        type="text"
                        name="courseName"
                        value={element.courseName || ""}
                        onChange={(e) => EducationhandleChange(index, e)}
                      />
                      <input
                        placeholder="Score"
                        className="form-control"
                        type="text"
                        name="score"
                        value={element.score || ""}
                        onChange={(e) => EducationhandleChange(index, e)}
                      />
                      {index ? (
                        <button
                          type="button"
                          className="btn btn-primary button remove"
                          onClick={() => removeEducationFormFields(index)}
                        >
                          Remove
                        </button>
                      ) : null}
                    </div>
                  ))}
                  <div className="button-section">
                    <button
                      className="btn btn-primary button add"
                      type="button"
                      onClick={() => addEducationFormFields()}
                    >
                      Add
                    </button>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="carddesign marginTop5">
              <Card.Body>
                <Card.Title>Internship & Projects</Card.Title>
                <Card.Text>
                  {InternshipformValues.map((element, index) => (
                    <div className="form-inline" key={index}>
                      <Row className="marginTop5">
                        <Col>
                          <select
                            name="startYear"
                            className="form-control"
                            value={element.startYear}
                            onChange={(e) => InternshiphandleChange(index, e)}
                            placeholder="StartYear"
                          >
                            <option
                              name=""
                              value=""
                              style={{ display: "none" }}
                            >
                              Start Year
                            </option>
                            <option name="2025" value="2025">
                              2025
                            </option>
                            <option name="2024" value="2024">
                              2024
                            </option>
                            <option name="2023" value="2023">
                              2023
                            </option>
                            <option name="2022" value="2022">
                              2022
                            </option>
                            <option name="2021" value="2021">
                              2021
                            </option>
                            <option name="2020" value="2020">
                              2020
                            </option>
                            <option name="2019" value="2019">
                              2019
                            </option>
                            <option name="2018" value="2018">
                              2018
                            </option>
                            <option name="2017" value="2017">
                              2017
                            </option>
                            <option name="2016" value="2016">
                              2016
                            </option>
                            <option name="2015" value="2015">
                              2015
                            </option>
                            <option name="2014" value="2014">
                              2014
                            </option>
                            <option name="2013" value="2013">
                              2013
                            </option>
                            <option name="2012" value="2012">
                              2012
                            </option>
                            <option name="2011" value="2011">
                              2011
                            </option>
                            <option name="2010" value="2010">
                              2010
                            </option>
                          </select>
                        </Col>
                        <Col>
                          <select
                            name="endYear"
                            className="form-control"
                            value={element.endYear}
                            onChange={(e) => InternshiphandleChange(index, e)}
                            placeholder="EndYear"
                          >
                            <option
                              name=""
                              value=""
                              style={{ display: "none" }}
                            >
                              End Year
                            </option>
                            <option name="2025" value="2025">
                              2025
                            </option>
                            <option name="2024" value="2024">
                              2024
                            </option>
                            <option name="2023" value="2023">
                              2023
                            </option>
                            <option name="2022" value="2022">
                              2022
                            </option>
                            <option name="2021" value="2021">
                              2021
                            </option>
                            <option name="2020" value="2020">
                              2020
                            </option>
                            <option name="2019" value="2019">
                              2019
                            </option>
                            <option name="2018" value="2018">
                              2018
                            </option>
                            <option name="2017" value="2017">
                              2017
                            </option>
                            <option name="2016" value="2016">
                              2016
                            </option>
                            <option name="2015" value="2015">
                              2015
                            </option>
                            <option name="2014" value="2014">
                              2014
                            </option>
                            <option name="2013" value="2013">
                              2013
                            </option>
                            <option name="2012" value="2012">
                              2012
                            </option>
                            <option name="2011" value="2011">
                              2011
                            </option>
                            <option name="2010" value="2010">
                              2010
                            </option>
                          </select>
                        </Col>
                      </Row>
                      <Row className="marginTop5">
                        <Col>
                          <select
                            name="startMonth"
                            className="form-control"
                            value={element.startMonth}
                            onChange={(e) => InternshiphandleChange(index, e)}
                            placeholder="StartMonth"
                          >
                            <option
                              name=""
                              value=""
                              style={{ display: "none" }}
                            >
                              Start Month
                            </option>
                            <option name="January" value="Jan">
                              January
                            </option>
                            <option name="February" value="Feb">
                              February
                            </option>
                            <option name="March" value="Mar">
                              March
                            </option>
                            <option name="April" value="Apr">
                              April
                            </option>
                            <option name="May" value="May">
                              May
                            </option>
                            <option name="June" value="Jun">
                              June
                            </option>
                            <option name="July" value="Jul">
                              July
                            </option>
                            <option name="August" value="Aug">
                              August
                            </option>
                            <option name="September" value="Sep">
                              September
                            </option>
                            <option name="October" value="Oct">
                              October
                            </option>
                            <option name="November" value="Nov">
                              November
                            </option>
                            <option name="December" value="Dec">
                              December
                            </option>
                          </select>
                        </Col>
                        <Col>
                          <select
                            name="endMonth"
                            className="form-control"
                            value={element.endMonth}
                            onChange={(e) => InternshiphandleChange(index, e)}
                            placeholder="EndMonth"
                          >
                            <option
                              name=""
                              value=""
                              style={{ display: "none" }}
                            >
                              End Month
                            </option>
                            <option name="January" value="Jan">
                              January
                            </option>
                            <option name="February" value="Feb">
                              February
                            </option>
                            <option name="March" value="Mar">
                              March
                            </option>
                            <option name="April" value="Apr">
                              April
                            </option>
                            <option name="May" value="May">
                              May
                            </option>
                            <option name="June" value="Jun">
                              June
                            </option>
                            <option name="July" value="Jul">
                              July
                            </option>
                            <option name="August" value="Aug">
                              August
                            </option>
                            <option name="September" value="Sep">
                              September
                            </option>
                            <option name="October" value="Oct">
                              October
                            </option>
                            <option name="November" value="Nov">
                              November
                            </option>
                            <option name="December" value="Dec">
                              December
                            </option>
                          </select>
                        </Col>
                      </Row>
                      <input
                        placeholder="Company Name"
                        className="form-control"
                        type="text"
                        name="companyName"
                        value={element.companyName}
                        onChange={(e) => InternshiphandleChange(index, e)}
                      />
                      <input
                        placeholder="Designation Name"
                        className="form-control"
                        type="text"
                        name="designationName"
                        value={element.designationName}
                        onChange={(e) => InternshiphandleChange(index, e)}
                      />
                      {/* <input
                    placeholder="Duration"
                    className="form-control"
                    type="text"
                    name="duration"
                    value={element.duration}
                    onChange={(e) => InternshiphandleChange(index, e)}
                  /> */}
                      <textarea
                        placeholder="Add Intership Description"
                        className="form-control"
                        id="description"
                        name="description"
                        rows="10"
                        cols="35"
                        value={element.description}
                        onChange={(e) => InternshiphandleChange(index, e)}
                      ></textarea>

                      {index ? (
                        <button
                          type="button"
                          className="btn btn-primary button remove"
                          onClick={() => InternshipremoveFormFields(index)}
                        >
                          Remove
                        </button>
                      ) : null}
                    </div>
                  ))}
                  <div className="button-section">
                    <button
                      className="btn btn-primary button add"
                      type="button"
                      onClick={() => InternshipaddFormFields()}
                    >
                      Add
                    </button>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="carddesign marginTop5">
              <Card.Body>
                <Card.Title>Work Exprience (Optional)</Card.Title>
                <Card.Text>
                  {WorkExprienceformValues.map((element, index) => (
                    <div className="form-inline" key={index}>
                      <Row className="marginTop5">
                        <Col>
                          <select
                            name="startYear"
                            className="form-control"
                            value={element.startYear}
                            onChange={(e) =>
                              WorkExpriencehandleChange(index, e)
                            }
                            placeholder="StartYear"
                          >
                            <option
                              name=""
                              value=""
                              style={{ display: "none" }}
                            >
                              Start Year
                            </option>
                            <option name="2025" value="2025">
                              2025
                            </option>
                            <option name="2024" value="2024">
                              2024
                            </option>
                            <option name="2023" value="2023">
                              2023
                            </option>
                            <option name="2022" value="2022">
                              2022
                            </option>
                            <option name="2021" value="2021">
                              2021
                            </option>
                            <option name="2020" value="2020">
                              2020
                            </option>
                            <option name="2019" value="2019">
                              2019
                            </option>
                            <option name="2018" value="2018">
                              2018
                            </option>
                            <option name="2017" value="2017">
                              2017
                            </option>
                            <option name="2016" value="2016">
                              2016
                            </option>
                            <option name="2015" value="2015">
                              2015
                            </option>
                            <option name="2014" value="2014">
                              2014
                            </option>
                            <option name="2013" value="2013">
                              2013
                            </option>
                            <option name="2012" value="2012">
                              2012
                            </option>
                            <option name="2011" value="2011">
                              2011
                            </option>
                            <option name="2010" value="2010">
                              2010
                            </option>
                          </select>
                        </Col>
                        <Col>
                          <select
                            name="endYear"
                            className="form-control"
                            value={element.endYear}
                            onChange={(e) =>
                              WorkExpriencehandleChange(index, e)
                            }
                            placeholder="EndYear"
                          >
                            <option
                              name=""
                              value=""
                              style={{ display: "none" }}
                            >
                              End Year
                            </option>
                            <option name="2025" value="2025">
                              2025
                            </option>
                            <option name="2024" value="2024">
                              2024
                            </option>
                            <option name="2023" value="2023">
                              2023
                            </option>
                            <option name="2022" value="2022">
                              2022
                            </option>
                            <option name="2021" value="2021">
                              2021
                            </option>
                            <option name="2020" value="2020">
                              2020
                            </option>
                            <option name="2019" value="2019">
                              2019
                            </option>
                            <option name="2018" value="2018">
                              2018
                            </option>
                            <option name="2017" value="2017">
                              2017
                            </option>
                            <option name="2016" value="2016">
                              2016
                            </option>
                            <option name="2015" value="2015">
                              2015
                            </option>
                            <option name="2014" value="2014">
                              2014
                            </option>
                            <option name="2013" value="2013">
                              2013
                            </option>
                            <option name="2012" value="2012">
                              2012
                            </option>
                            <option name="2011" value="2011">
                              2011
                            </option>
                            <option name="2010" value="2010">
                              2010
                            </option>
                          </select>
                        </Col>
                      </Row>
                      <Row className="marginTop5">
                        <Col>
                          <select
                            name="startMonth"
                            className="form-control"
                            value={element.startMonth}
                            onChange={(e) =>
                              WorkExpriencehandleChange(index, e)
                            }
                            placeholder="StartMonth"
                          >
                            <option
                              name=""
                              value=""
                              style={{ display: "none" }}
                            >
                              Start Month
                            </option>
                            <option name="January" value="Jan">
                              January
                            </option>
                            <option name="February" value="Feb">
                              February
                            </option>
                            <option name="March" value="Mar">
                              March
                            </option>
                            <option name="April" value="Apr">
                              April
                            </option>
                            <option name="May" value="May">
                              May
                            </option>
                            <option name="June" value="Jun">
                              June
                            </option>
                            <option name="July" value="Jul">
                              July
                            </option>
                            <option name="August" value="Aug">
                              August
                            </option>
                            <option name="September" value="Sep">
                              September
                            </option>
                            <option name="October" value="Oct">
                              October
                            </option>
                            <option name="November" value="Nov">
                              November
                            </option>
                            <option name="December" value="Dec">
                              December
                            </option>
                          </select>
                        </Col>
                        <Col>
                          <select
                            name="endMonth"
                            className="form-control"
                            value={element.endMonth}
                            onChange={(e) =>
                              WorkExpriencehandleChange(index, e)
                            }
                            placeholder="EndMonth"
                          >
                            <option
                              name=""
                              value=""
                              style={{ display: "none" }}
                            >
                              End Month
                            </option>
                            <option name="January" value="Jan">
                              January
                            </option>
                            <option name="February" value="Feb">
                              February
                            </option>
                            <option name="March" value="Mar">
                              March
                            </option>
                            <option name="April" value="Apr">
                              April
                            </option>
                            <option name="May" value="May">
                              May
                            </option>
                            <option name="June" value="Jun">
                              June
                            </option>
                            <option name="July" value="Jul">
                              July
                            </option>
                            <option name="August" value="Aug">
                              August
                            </option>
                            <option name="September" value="Sep">
                              September
                            </option>
                            <option name="October" value="Oct">
                              October
                            </option>
                            <option name="November" value="Nov">
                              November
                            </option>
                            <option name="December" value="Dec">
                              December
                            </option>
                          </select>
                        </Col>
                      </Row>
                      <input
                        placeholder="Company Name"
                        className="form-control"
                        type="text"
                        name="companyName"
                        value={element.companyName}
                        onChange={(e) => WorkExpriencehandleChange(index, e)}
                      />
                      <input
                        placeholder="Designation Name"
                        className="form-control"
                        type="text"
                        name="designationName"
                        value={element.designationName}
                        onChange={(e) => WorkExpriencehandleChange(index, e)}
                      />
                      {/* <input
                    placeholder="Duration"
                    className="form-control"
                    type="text"
                    name="duration"
                    value={element.duration}
                    onChange={(e) => WorkExpriencehandleChange(index, e)}
                  /> */}
                      <textarea
                        placeholder="Add Work Description"
                        className="form-control"
                        id="description"
                        name="description"
                        rows="10"
                        cols="35"
                        value={element.description}
                        onChange={(e) => WorkExpriencehandleChange(index, e)}
                      ></textarea>

                      {index ? (
                        <button
                          type="button"
                          className="btn btn-primary button remove"
                          onClick={() => WorkExprienceremoveFormFields(index)}
                        >
                          Remove
                        </button>
                      ) : null}
                    </div>
                  ))}
                  <div className="button-section">
                    <button
                      className="btn btn-primary button add"
                      type="button"
                      onClick={() => WorkExprienceaddFormFields()}
                    >
                      Add
                    </button>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="carddesign marginTop5">
              <Card.Body>
                <Card.Title>Certification</Card.Title>
                <Card.Text>
                  {CertificationformValues.map((element, index) => (
                    <div className="form-inline" key={index}>
                      {/* <Row className="marginTop5">
                    <Col>
                      <select
                        name="startYear"
                        className="form-control"
                        value={element.startYear}
                        onChange={(e) => CertificationhandleChange(index, e)}
                        placeholder="StartYear"
                      >
                        <option name="" value="" style={{ display: 'none' }}>
                          Start Year
                        </option>
                        <option name="2025" value="2025">
                          2025
                        </option>
                        <option name="2024" value="2024">
                          2024
                        </option>
                        <option name="2023" value="2023">
                          2023
                        </option>
                        <option name="2022" value="2022">
                          2022
                        </option>
                        <option name="2021" value="2021">
                          2021
                        </option>
                        <option name="2020" value="2020">
                          2020
                        </option>
                        <option name="2019" value="2019">
                          2019
                        </option>
                        <option name="2018" value="2018">
                          2018
                        </option>
                        <option name="2017" value="2017">
                          2017
                        </option>
                        <option name="2016" value="2016">
                          2016
                        </option>
                        <option name="2015" value="2015">
                          2015
                        </option>
                        <option name="2014" value="2014">
                          2014
                        </option>
                        <option name="2013" value="2013">
                          2013
                        </option>
                        <option name="2012" value="2012">
                          2012
                        </option>
                        <option name="2011" value="2011">
                          2011
                        </option>
                        <option name="2010" value="2010">
                          2010
                        </option>
                      </select>
                    </Col>
                    <Col>
                      <select
                        name="endYear"
                        className="form-control"
                        value={element.endYear}
                        onChange={(e) => CertificationhandleChange(index, e)}
                        placeholder="EndYear"
                      >
                        <option name="" value="" style={{ display: 'none' }}>
                          End Year
                        </option>
                        <option name="2025" value="2025">
                          2025
                        </option>
                        <option name="2024" value="2024">
                          2024
                        </option>
                        <option name="2023" value="2023">
                          2023
                        </option>
                        <option name="2022" value="2022">
                          2022
                        </option>
                        <option name="2021" value="2021">
                          2021
                        </option>
                        <option name="2020" value="2020">
                          2020
                        </option>
                        <option name="2019" value="2019">
                          2019
                        </option>
                        <option name="2018" value="2018">
                          2018
                        </option>
                        <option name="2017" value="2017">
                          2017
                        </option>
                        <option name="2016" value="2016">
                          2016
                        </option>
                        <option name="2015" value="2015">
                          2015
                        </option>
                        <option name="2014" value="2014">
                          2014
                        </option>
                        <option name="2013" value="2013">
                          2013
                        </option>
                        <option name="2012" value="2012">
                          2012
                        </option>
                        <option name="2011" value="2011">
                          2011
                        </option>
                        <option name="2010" value="2010">
                          2010
                        </option>
                      </select>
                    </Col>
                  </Row> */}
                      {/* <Row className="marginTop5">
                    <Col>
                      <select
                        name="startMonth"
                        className="form-control"
                        value={element.startMonth}
                        onChange={(e) => CertificationhandleChange(index, e)}
                        placeholder="StartMonth"
                      >
                        <option name="" value="" style={{ display: 'none' }}>
                          Start Month
                        </option>
                        <option name="January" value="Jan">
                          January
                        </option>
                        <option name="February" value="Feb">
                          February
                        </option>
                        <option name="March" value="Mar">
                          March
                        </option>
                        <option name="April" value="Apr">
                          April
                        </option>
                        <option name="May" value="May">
                          May
                        </option>
                        <option name="June" value="Jun">
                          June
                        </option>
                        <option name="July" value="Jul">
                          July
                        </option>
                        <option name="August" value="Aug">
                          August
                        </option>
                        <option name="September" value="Sep">
                          September
                        </option>
                        <option name="October" value="Oct">
                          October
                        </option>
                        <option name="November" value="Nov">
                          November
                        </option>
                        <option name="December" value="Dec">
                          December
                        </option>
                      </select>
                    </Col>
                    <Col>
                      <select
                        name="endMonth"
                        className="form-control"
                        value={element.endMonth}
                        onChange={(e) => CertificationhandleChange(index, e)}
                        placeholder="EndMonth"
                      >
                        <option name="" value="" style={{ display: 'none' }}>
                          End Month
                        </option>
                        <option name="January" value="Jan">
                          January
                        </option>
                        <option name="February" value="Feb">
                          February
                        </option>
                        <option name="March" value="Mar">
                          March
                        </option>
                        <option name="April" value="Apr">
                          April
                        </option>
                        <option name="May" value="May">
                          May
                        </option>
                        <option name="June" value="Jun">
                          June
                        </option>
                        <option name="July" value="Jul">
                          July
                        </option>
                        <option name="August" value="Aug">
                          August
                        </option>
                        <option name="September" value="Sep">
                          September
                        </option>
                        <option name="October" value="Oct">
                          October
                        </option>
                        <option name="November" value="Nov">
                          November
                        </option>
                        <option name="December" value="Dec">
                          December
                        </option>
                      </select>
                    </Col>
                  </Row> */}
                      <input
                        placeholder="Certificate Name"
                        className="form-control"
                        type="text"
                        name="certificateName"
                        value={element.certificateName}
                        onChange={(e) => CertificationhandleChange(index, e)}
                      />
                      {/* <input
                    placeholder="Certificate Source Name"
                    className="form-control"
                    type="text"
                    name="certificateSourceName"
                    value={element.certificateSourceName}
                    onChange={(e) => CertificationhandleChange(index, e)}
                  /> */}
                      <input
                        placeholder="Certificate Link"
                        className="form-control"
                        type="text"
                        name="certificateLink"
                        value={element.certificateLink}
                        onChange={(e) => CertificationhandleChange(index, e)}
                      />
                      {index ? (
                        <button
                          type="button"
                          className="btn btn-primary button remove"
                          onClick={() => CertificationremoveFormFields(index)}
                        >
                          Remove
                        </button>
                      ) : null}
                    </div>
                  ))}
                  <div className="button-section">
                    <button
                      className="btn btn-primary button add"
                      type="button"
                      onClick={() => CertificationaddFormFields()}
                    >
                      Add
                    </button>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="carddesign marginTop5">
              <Card.Body>
                <Card.Title>About Me</Card.Title>
                <Card.Text>
                  <textarea
                    id="Aboutme"
                    rows="10"
                    cols="35"
                    value={Aboutme.Aboutme}
                    onChange={(e) => AboutmeonChange(e)}
                  ></textarea>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="carddesign marginTop5">
              <Card.Body>
                <Card.Title>Technical Skills</Card.Title>
                <Card.Text>
                  {Technicalskills.map((element, index) => (
                    <div className="form-inline" key={index}>
                      <Row className="marginTop5">
                        <Col>
                          <input
                            placeholder="Technical skill Name"
                            className="form-control"
                            type="text"
                            name="skillname"
                            value={element.skillname}
                            onChange={(e) =>
                              TechnicalskillshandleChange(index, e)
                            }
                          />
                        </Col>
                        <Col>
                          <select
                            name="techskillrating"
                            className="form-control"
                            value={element.techskillrating}
                            onChange={(e) =>
                              TechnicalskillshandleChange(index, e)
                            }
                            placeholder="Tech Skill"
                          >
                            <option
                              name=""
                              value=""
                              style={{ display: "none" }}
                            >
                              Rate Yourself
                            </option>
                            <option name="1" value="1">
                              1
                            </option>
                            <option name="2" value="2">
                              2
                            </option>
                            <option name="3" value="3">
                              3
                            </option>
                            <option name="4" value="4">
                              4
                            </option>
                            <option name="5" value="5">
                              5
                            </option>
                          </select>
                        </Col>
                      </Row>
                      {index ? (
                        <button
                          type="button"
                          className="btn btn-primary button remove"
                          onClick={() => removeTechnicalskillsFormFields(index)}
                        >
                          Remove
                        </button>
                      ) : null}
                    </div>
                  ))}
                  <div className="button-section">
                    <button
                      className="btn btn-primary button add"
                      type="button"
                      onClick={() => addTechnicalskillsFormFields()}
                    >
                      Add
                    </button>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="carddesign marginTop5">
              <Card.Body>
                <Card.Title>Interpersonal Skills</Card.Title>
                <Card.Text>
                  {Interpersonalskills.map((element, index) => (
                    <div className="form-inline" key={index}>
                      <Row className="marginTop5">
                        <Col>
                          <input
                            placeholder="Interpersonal skill Name : Ex Teamwork"
                            className="form-control"
                            type="text"
                            name="skillname"
                            value={element.skillname}
                            onChange={(e) =>
                              InterpersonalskillshandleChange(index, e)
                            }
                          />
                        </Col>
                        <Col>
                          <select
                            name="skillrating"
                            className="form-control"
                            value={element.skillrating}
                            onChange={(e) =>
                              InterpersonalskillshandleChange(index, e)
                            }
                            placeholder="Interpersonal Skill"
                          >
                            <option
                              name=""
                              value=""
                              style={{ display: "none" }}
                            >
                              Rate Yourself
                            </option>
                            <option name="1" value="1">
                              1
                            </option>
                            <option name="2" value="2">
                              2
                            </option>
                            <option name="3" value="3">
                              3
                            </option>
                            <option name="4" value="4">
                              4
                            </option>
                            <option name="5" value="5">
                              5
                            </option>
                          </select>
                        </Col>
                      </Row>
                      {index ? (
                        <button
                          type="button"
                          className="btn btn-primary button remove"
                          onClick={() =>
                            removeInterpersonalskillsFormFields(index)
                          }
                        >
                          Remove
                        </button>
                      ) : null}
                    </div>
                  ))}
                  <div className="button-section">
                    <button
                      className="btn btn-primary button add"
                      type="button"
                      onClick={() => addInterpersonalskillsFormFields()}
                    >
                      Add
                    </button>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="carddesign marginTop5 marginBottom20">
              <Card.Body>
                <Card.Title>Intrests</Card.Title>
                <Card.Text>
                  {Hobbies.map((element, index) => (
                    <div className="form-inline" key={index}>
                      <Row className="marginTop5">
                        <input
                          placeholder="Add Hobbies & Intrests"
                          className="form-control"
                          type="text"
                          name="hobbies"
                          value={element.hobbies}
                          onChange={(e) => HobbieshandleChange(index, e)}
                        />
                      </Row>
                      {index ? (
                        <button
                          type="button"
                          className="btn btn-primary button remove"
                          onClick={() => removeHobbiesFormFields(index)}
                        >
                          Remove
                        </button>
                      ) : null}
                    </div>
                  ))}
                  <div className="button-section">
                    <button
                      className="btn btn-primary button add"
                      type="button"
                      onClick={() => addHobbiesFormFields()}
                    >
                      Add
                    </button>
                    <button
                      className="btn btn-primary button submit"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Col>

        <Col sm={8}>
          <div style={{ margin: 10 }}>
            <Container>
              <Row>
                <Col>
                  <button
                    style={{
                      height: "25px",
                      width: "100%",
                      marginTop: "2px",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }}
                    className="buttondesign"
                    onClick={getSavedData}
                  >
                    Get Saved Data <i class="fas fa-file-pdf"></i>
                  </button>
                </Col>
                <Col>
                  <button
                    style={{
                      height: "25px",
                      width: "100%",
                      marginTop: "2px",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }}
                    className="buttondesign"
                    onClick={savepdf}
                  >
                    Save PDF <i class="fa fa-save"></i>
                  </button>
                </Col>
                <Col>
                  <button
                    style={{
                      height: "25px",
                      width: "100%",
                      marginTop: "2px",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }}
                    className="buttondesign"
                    onClick={handlePrint}
                  >
                    Download PDF <i class="fas fa-download"></i>
                  </button>
                </Col>
              </Row>
            </Container>

            <div className="App" id="App">
              {/* <button onClick={handlePrint} className="print__button">  Print </button> */}

              <div ref={componentRef}>
                <div
                  id="toprint"
                  style={{
                    marginTop: "0px",
                    marginBottom: "0px",
                    marginLeft: "15px",
                    marginRight: "15px",
                    border: "0px",
                  }}
                  className="toprint"
                >
                  <div
                    className="row"
                    style={{ borderBottom: "2px solid #e0e0e0" }}
                  >
                    <div className="col-md-4">
                      <h1
                        style={{
                          margin: "0px!important",
                          lineHeight: "1.2",
                          color: "#04355f",
                        }}
                      >
                        {BasicDetails.firstname}
                      </h1>
                      <h1
                        style={{
                          margin: "0px!important",
                          lineHeight: "1.2",
                          color: "#04355f",
                        }}
                      >
                        {BasicDetails.middlename}
                      </h1>
                      <h1
                        style={{
                          margin: "0px!important",
                          lineHeight: "1.2",
                          color: "#04355f",
                        }}
                      >
                        {BasicDetails.lastname}
                      </h1>
                      {/* <p>{BasicDetails.degree}</p> */}
                    </div>
                    <div className="col-md-8">
                      <p
                        style={{
                          textAlign: "right",
                          marginBottom: "0px",
                          display: "block",
                          width: "100%!important",
                          fontSize: `${descriptionfontsize}px`,
                        }}
                      >
                        {BasicDetails.phonenumber}&nbsp;&nbsp;&nbsp;&nbsp;
                        {!BasicDetails.phonenumber ? (
                          BasicDetails.phonenumber
                        ) : (
                          <img
                            width="16px"
                            src="https://mygreenhorn.com/images/Phone.png"
                            alt="phone icon"
                          />
                        )}
                      </p>
                      <p
                        style={{
                          textAlign: "right",
                          marginBottom: "0px",
                          display: "block",
                          width: "100%!important",
                          fontSize: `${descriptionfontsize}px`,
                        }}
                      >
                        {BasicDetails.email}&nbsp;&nbsp;&nbsp;&nbsp;
                        {!BasicDetails.email ? (
                          BasicDetails.email
                        ) : (
                          <img
                            width="16px"
                            src="https://mygreenhorn.com/images/Message.png"
                            alt="message icon"
                          />
                        )}
                      </p>
                      <p
                        style={{
                          textAlign: "right",
                          marginBottom: "0px",
                          display: "block",
                          width: "100%!important",
                          fontSize: `${descriptionfontsize}px`,
                        }}
                      >
                        {BasicDetails.linkedin}&nbsp;&nbsp;&nbsp;&nbsp;
                        {!BasicDetails.linkedin ? (
                          BasicDetails.linkedin
                        ) : (
                          <img
                            width="16px"
                            src="https://mygreenhorn.com/images/Linkedin.png"
                            alt="linked icon"
                          />
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div
                      style={{
                        borderRight: "3px solid #e0e0e0",
                        height: "auto",
                        paddingRight: 0,
                      }}
                      className="col-md-4"
                    >
                      <div style={{ marginBottom: 20 }}>
                        <p
                          style={{
                            marginTop: "10px",
                            color: "#04355f",
                            fontWeight: "600",
                            height: 30,
                            fontSize: `${titlefontsize}px`,
                          }}
                        >
                          Technical Skills
                          <span
                            style={{
                              float: "right",
                              marginBottom: "0px",
                              marginTop: 5,
                              marginRight: 5,
                            }}
                          >
                            <img
                              width="20px"
                              src="https://mygreenhorn.com/images/Television.png"
                              alt="Technical skills icon"
                            />
                          </span>
                        </p>
                        <div style={{ marginBottom: 10 }}>
                          {Technicalskills.map((item) => {
                            console.log(item);
                            return (
                              <div className="marginBottom20">
                                <div style={{ height: "20px" }} className="row">
                                  <div
                                    style={{
                                      fontSize: `${descriptionfontsize}px`,
                                    }}
                                  >
                                    {item.skillname}
                                  </div>
                                  {item.skillrating !== "" &&
                                  item.skillname !== "" ? (
                                    <div>
                                      <progress
                                        value={item.techskillrating}
                                        max="5"
                                      ></progress>
                                      &nbsp;&nbsp;&nbsp;
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div
                        style={{
                          borderBottom: "2px solid #e0e0e0",
                          borderTop: "2px solid #e0e0e0",
                          marginTop: 10,
                        }}
                      >
                        <p
                          style={{
                            marginTop: "10px",
                            color: "#04355f",
                            fontWeight: "600",
                            height: 30,
                            fontSize: `${titlefontsize}px`,
                          }}
                        >
                          Interpersonal Skills{" "}
                          <span
                            style={{
                              float: "right",
                              marginBottom: "0px",
                              marginTop: 5,
                              marginRight: 5,
                            }}
                          >
                            <img
                              width="20px"
                              src="https://mygreenhorn.com/images/Universal.png"
                              alt="Interpersonal skills icon"
                            />
                          </span>
                        </p>
                        {Interpersonalskills.map((item) => {
                          console.log(item);
                          return (
                            <div className="marginBottom20">
                              <div style={{ height: "20px" }} className="row">
                                <div
                                  style={{
                                    fontSize: `${descriptionfontsize}px`,
                                  }}
                                >
                                  {item.skillname}
                                </div>
                                <div>
                                  {item.skillrating !== "" &&
                                  item.skillname !== "" ? (
                                    <progress
                                      value={item.skillrating}
                                      max="5"
                                    ></progress>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div>
                        <p
                          style={{
                            marginTop: "10px",
                            color: "#04355f",
                            fontWeight: "600",
                            height: 30,
                            fontSize: `${titlefontsize}px`,
                          }}
                        >
                          Certification{" "}
                          <span
                            style={{
                              float: "right",
                              marginBottom: "0px",
                              marginTop: 5,
                              marginRight: 5,
                            }}
                          >
                            <img
                              width="20px"
                              src="https://mygreenhorn.com/images/Certificate.png"
                            />
                          </span>
                        </p>
                        {CertificationformValues.map((item) => {
                          return (
                            <div className="">
                              <div style={{ marginBottom: "0px" }}>
                                <div className="row">
                                  <p
                                    style={{
                                      marginBottom: "0px!important",
                                      marginTop: "0px",
                                      fontSize: `${descriptionfontsize}px`,
                                      marginRight: 2,
                                    }}
                                  >
                                    {item.certificateName}{" "}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: `${descriptionfontsize}px`,
                                    }}
                                  >
                                    {item.certificateName ? (
                                      <a
                                        style={{ color: "blue" }}
                                        href={item.certificateLink}
                                      >
                                        View Certificate
                                      </a>
                                    ) : null}
                                  </p>
                                  {/* <p> {item.startMonth}{item.startYear}
                              {item.startYear !== '' ? '-' : null}
                              {item.endMonth}
                              {item.endYear}
                              </p> */}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div>
                        <p
                          style={{
                            borderTop: "2px solid #e0e0e0",
                            marginTop: "10px",
                            color: "#04355f",
                            fontWeight: "600",
                            fontSize: `${titlefontsize}px`,
                          }}
                        >
                          Interests
                          <span
                            style={{
                              float: "right",
                              marginBottom: "0px",
                              padding: 5,
                            }}
                          >
                            <img
                              width="20px"
                              src="https://mygreenhorn.com/images/Star.png"
                            />
                          </span>
                        </p>
                        {Hobbies.map((item) => {
                          console.log(item);
                          return (
                            <div style={{ marginBottom: "10px" }}>
                              <p
                                style={{
                                  color: "#04355f",
                                  marginBottom: "5px!important",
                                  fontSize: `${descriptionfontsize}px`,
                                }}
                              >
                                {item.hobbies}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="col-md-8">
                      <div
                        style={{
                          borderBottom: "2px solid #e0e0e0",
                          marginBottom: "10px",
                        }}
                      >
                        <p
                          style={{
                            marginTop: "10px",
                            fontSize: `${titlefontsize}px`,
                          }}
                          className="resumesubtitle"
                        >
                          About me{" "}
                          <span className="introicons">
                            <img
                              width="20px"
                              src="https://mygreenhorn.com/images/User.png"
                            />
                          </span>
                        </p>
                        <p
                          style={{ fontSize: `${descriptionfontsize}px` }}
                          className="Aboutme"
                        >
                          {Aboutme.Aboutme}
                        </p>
                      </div>
                      {WorkExprienceformValues[0].companyName !== "" ||
                      WorkExprienceformValues[0].startYear !== "" ? (
                        <div style={{ borderBottom: "2px solid #e0e0e0" }}>
                          <p
                            style={{
                              marginTop: "10px",
                              color: "#04355f",
                              fontWeight: "600",
                              fontSize: `${titlefontsize}px`,
                            }}
                          >
                            Work Exprience
                            <span
                              style={{ float: "right", marginBottom: "0px" }}
                            >
                              <img
                                width="20px"
                                src="https://mygreenhorn.com/images/Message.png"
                              />
                            </span>
                          </p>
                          {WorkExprienceformValues
                            ? WorkExprienceformValues.map((item) => {
                                console.log(item);
                                return (
                                  <div style={{ marginBottom: "10px" }}>
                                    <div className="row">
                                      <p
                                        style={{
                                          color: "#04355f",
                                          marginBottom: "0px!important",
                                          marginTop: "0px",
                                          fontSize: `${descriptionfontsize}px`,
                                          fontWeight: 600,
                                        }}
                                      >
                                        {item.designationName}
                                        {item.designationName !== ""
                                          ? ","
                                          : null}
                                        &nbsp;
                                        {item.companyName}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: `${descriptionfontsize}px`,
                                        }}
                                      >
                                        {item.startMonth} {item.startYear}{" "}
                                        {item.startYear !== "" ? "-" : null}{" "}
                                        {item.endMonth} {item.endYear}
                                      </p>

                                      {/* <p style={{ marginBottom: '0px', marginTop: '0px', fontSize: '15px' }}>{item.duration}</p> */}
                                      <p
                                        style={{
                                          marginBottom: "0px",
                                          marginTop: "0px",
                                          fontSize: `${descriptionfontsize}px`,
                                        }}
                                      >
                                        {item.description}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      ) : null}
                      <div style={{ borderBottom: "2px solid #e0e0e0" }}>
                        <p
                          style={{
                            marginTop: "10px",
                            color: "#04355f",
                            fontWeight: "600",
                            fontSize: `${titlefontsize}px`,
                          }}
                        >
                          Internship and Projects{" "}
                          <span style={{ float: "right", marginBottom: "0px" }}>
                            <img
                              width="20px"
                              src="https://mygreenhorn.com/images/Message.png"
                            />
                          </span>
                        </p>
                        {InternshipformValues.map((item) => {
                          console.log(item);
                          return (
                            <div style={{ marginBottom: "10px" }}>
                              <div className="row">
                                <p
                                  style={{
                                    color: "#04355f",
                                    marginBottom: "0px!important",
                                    marginTop: "0px",
                                    fontSize: `${descriptionfontsize}px`,
                                    fontWeight: 600,
                                  }}
                                >
                                  {item.designationName}
                                  {item.designationName ? <>,&nbsp;</> : null}
                                  {item.companyName}
                                </p>
                                <p
                                  style={{
                                    fontSize: `${descriptionfontsize}px`,
                                  }}
                                >
                                  {item.startMonth} {item.startYear}{" "}
                                  {item.startYear !== "" ? "-" : null}{" "}
                                  {item.endMonth} {item.endYear}
                                </p>

                                {/* <p style={{ marginBottom: '0px', marginTop: '0px', fontSize: '15px' }}>{item.duration}</p> */}
                                <p
                                  style={{
                                    marginBottom: "0px",
                                    marginTop: "0px",
                                    fontSize: `${descriptionfontsize}px`,
                                  }}
                                >
                                  {item.description}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div>
                        <p
                          style={{
                            marginTop: "10px",
                            color: "#04355f",
                            fontWeight: "600",
                            fontSize: `${titlefontsize}px`,
                          }}
                        >
                          Education{" "}
                          <span style={{ float: "right", marginBottom: "0px" }}>
                            <img
                              width="20px"
                              src="https://mygreenhorn.com/images/Graduationcap.png"
                            />
                          </span>
                        </p>
                        {EducationformValues.map((item) => {
                          return (
                            <div
                              className="educationrow"
                              style={{ marginBottom: "0px" }}
                            >
                              <div className="row">
                                <p
                                  style={{
                                    color: "#04355f",
                                    marginBottom: "0px!important",
                                    marginTop: "0px",
                                    fontSize: `${descriptionfontsize}px`,
                                    fontWeight: 600,
                                  }}
                                >
                                  {item.degreeName}
                                  {item.degreeName ? <>,&nbsp;</> : null}
                                  {item.collegeName}
                                </p>
                                <p
                                  style={{
                                    fontSize: `${descriptionfontsize}px`,
                                  }}
                                >
                                  {item.startMonth} &nbsp;{item.startYear}{" "}
                                  {item.startYear !== "" ? "-" : null}{" "}
                                  {item.endMonth}&nbsp; {item.endYear}
                                </p>
                                {/* <p style={{ marginBottom: '0px!important', marginTop: '0px', fontSize: '15px' }}>{item.courseName}</p> */}

                                <p
                                  style={{
                                    marginBottom: "0px!important",
                                    marginTop: "0px",
                                    fontSize: `${descriptionfontsize}px`,
                                  }}
                                >
                                  {item.score !== "" ? "Score:" : null}
                                  {item.score}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default ResumeBuilderTool;
