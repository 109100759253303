import React from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./homepaymentpage.css";
import YouTube from "react-youtube";
import styled from "styled-components";
import { Link } from "react-router-dom";
const Video = styled.iframe`
  display: flex;
  align-items: center;
  border: none;
  max-width: 490px;
  width: 100%;
  height: 100%;
  max-height: 276px;
  min-height: 276px;
  box-shadow: 9px 14px 15px rgba(0, 0, 0, 0.25);
`;
const HowItWorks = () => {
  const opts = {
    width: "444",
    height: "250",

    playerVars: {
      autoplay: 0,
    },
  };

  let _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  return (
    <>
      <Container>
        <Row>
          <Col>
            <Card>
              <YouTube
                className="cusmobilevideo"
                videoId="VbtspjcyjsI"
                opts={opts}
                onReady={_onReady}
              />
              <Card.Body>
                <Card.Text>
                  <p style={{ color: "#2b4eff" }}>
                    We use our smart assessments and AI tool to understand your
                    profile
                  </p>
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Img
                width={444}
                variant="top"
                src="/assets/img/ghimages/path.png"
                className="mt-40"
              />
              <Card.Body>
                <Card.Text>
                  <p style={{ color: "#2b4eff" }}>
                    We identify your skill gaps and develop a custom plan for
                    you to accomplish your career objectives
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Img
                variant="top"
                height="260px"
                src="/assets/img/hero/6.png"
              />
              <Card.Body>
                <Card.Text>
                  <p style={{ color: "#2b4eff" }}>
                    We conduct live programs to coach you on essential skills,
                    assess you through interactive exercises, and make you ready
                    for your next career milestone
                  </p>
                </Card.Text>
                <Link to="/mentors" className="e-btn e-btn-2">
                  Our Mentors
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HowItWorks;
