import { useState, memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { toast } from "react-toastify";
import {
  UserProfileEvent,
  AppConfigEvent,
} from "../../../redux/types/redux-event";
import { updateUserProfile } from "../../../services/user-service";

import {
  ModalBackground,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "./common/modal";
import {
  RequiredText,
  FieldGroupContainer,
  DateGroupContainer,
  InputFiled,
  Error,
  ButtonGroup,
  ActionButton,
} from "./common";
import {
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
} from "../../../redux/features/profile-slice";
// interface IntroForm {
//   firstName: string;
//   lastName?: string;
//   profession: string;
//   location: string;
// }

// interface Props {
//   closeAction: VoidFunction;
// }

const EditIntroModal = ({ closeAction }) => {
  const dispatch = useDispatch();
  const firstName = useSelector((state) => state.profile.firstName);
  const lastName = useSelector((state) => state.profile.lastName);
  const profession = useSelector((state) => state.profile.profession);
  const location = useSelector((state) => state.profile.location);
  const profileImgUrl = useSelector((state) => state.profile.profileImgUrl);
  const coverImgUrl = useSelector((state) => state.profile.coverImgUrl);

  const [introData, setIntroData] = useState({
    firstName,
    lastName,
    profession,
    location,
  });

  const [formError, setFormError] = useState({
    firstName: "",
    lastName: "",
    profession: "",
    location: "",
  });
  const [showErrors, setShowErrors] = useState(false);
  const validateData = () => {
    const formErrorData = { ...formError };
    formErrorData.firstName = introData.firstName
      ? ""
      : "Please enter your first name.";
    formErrorData.profession = introData.profession
      ? ""
      : "Please enter your profession.";
    formErrorData.location = introData.location
      ? ""
      : "Please enter your location.";
    setFormError(formErrorData);
    return !(
      formErrorData.firstName ||
      formErrorData.profession ||
      formErrorData.location
    );
  };

  const updateFromData = (fromData) => {
    setIntroData(fromData);
    validateData();
    if (showErrors) {
      setShowErrors(false);
    }
  };

  const saveIntroData = () => {
    setShowErrors(true);
    if (!validateData()) {
      return;
    }
    dispatch({ type: AppConfigEvent.START_LOADING });
    updateUserProfile({ ...introData })
      .then(({ firstName, lastName, profession, location }) => {
        toast.success(`Information updated successfully `, {
          position: "top-left",
        });
        // dispatch({
        //   type: UserProfileEvent.UPDATE_USER_PROFILE,
        //   data: { firstName, lastName, profession, location },
        // });
        dispatch(
          UPDATE_USER_PROFILE({ firstName, lastName, profession, location })
        );
      })
      .catch(() =>
        toast.error(`Unable to update your information `, {
          position: "top-left",
        })
      )
      .finally(() => dispatch({ type: AppConfigEvent.STOP_LOADING }));
    closeAction();
  };
  return (
    <ModalBackground>
      <Modal>
        <ModalHeader>
          Intro{" "}
          <img
            src={"/assets/img/ghimages/close-icon.svg"}
            alt="X"
            onClick={closeAction}
          />
        </ModalHeader>
        <ModalBody>
          <RequiredText>* Indicates required</RequiredText>
          <DateGroupContainer>
            <FieldGroupContainer>
              First Name *
              <InputFiled
                value={introData.firstName}
                onChange={({ target: { value } }) =>
                  updateFromData({ ...introData, firstName: value })
                }
                type="text"
              />
              {showErrors && formError.firstName && (
                <Error>{formError.firstName}</Error>
              )}
            </FieldGroupContainer>
            <FieldGroupContainer>
              Last Name *
              <InputFiled
                value={introData.lastName}
                onChange={({ target: { value } }) =>
                  updateFromData({ ...introData, lastName: value })
                }
                type="text"
              />
              {showErrors && formError.lastName && (
                <Error>{formError.lastName}</Error>
              )}
            </FieldGroupContainer>
          </DateGroupContainer>
          <FieldGroupContainer>
            Profession *
            <InputFiled
              value={introData.profession}
              onChange={({ target: { value } }) =>
                updateFromData({ ...introData, profession: value })
              }
              type="text"
            />
            {showErrors && formError.profession && (
              <Error>{formError.profession}</Error>
            )}
          </FieldGroupContainer>
          <FieldGroupContainer>
            Location *
            <InputFiled
              value={introData.location}
              onChange={({ target: { value } }) =>
                updateFromData({ ...introData, location: value })
              }
              type="text"
            />
            {showErrors && formError.location && (
              <Error>{formError.location}</Error>
            )}
          </FieldGroupContainer>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup forEdit={false}>
            <ActionButton onClick={saveIntroData}>Update</ActionButton>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </ModalBackground>
  );
};

export default memo(EditIntroModal);
