const about_info = {
  reviews: "8,200+",
  about_name: "Depikka Siingh",
  about_img: "/assets/img/ghimages/1.jpg",
  about_banner: "/assets/img/about/about-banner.jpg",
  students_imgs: [
    "/assets/img/about/student-4.jpg",
    "/assets/img/about/student-3.jpg",
    "/assets/img/about/student-2.jpg",
    "/assets/img/about/student-1.jpg",
  ],
  students: "",
  title: <>{/* Achieve your <br /> */}</>,
  shape_text: "",
  title_2: "About me",
  desc: "",
  sub_desc: "",
  about_list: [
    "Coach Kenna is here to guide you on an empowering journey of personal and professional development that will transform your college experience and prepare you for success in the corporate world with her signature program – CollegeNCareer Catalyst",
    "We have experienced the challenges and the journey and we know how to make it easy for you.",
    "Our ultimate goal is to witness our students not only achieving academic excellence but also becoming confident, capable, and compassionate individuals who leave a positive mark on the world.",
  ],
};

const WhiteAboutArea = ({ padd = "pt-90 pb-90" }) => {
  return (
    <>
      <section style={{ paddingBottom: 50 }} className={`about__area ${padd}`}>
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-6 col-xl-6 col-lg-6">
              <img
                className="pt-60"
                style={{ borderRadius: "50%" }}
                src={about_info.about_img}
              />
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6">
              <div className="about__content pl-70 pr-60 pt-25">
                <div className="section__title-wrapper mb-25">
                  <h2 style={{ fontSize: 36 }} className="section__title">
                    {about_info.title}
                    <span className="yellow-bg-big">
                      {about_info.shape_text}{" "}
                      <img src="/assets/img/shape/yellow-bg-2.png" alt="" />
                    </span>{" "}
                    {about_info.title_2}{" "}
                  </h2>
                  {/* <p style={{ textAlign: "left" }}>{about_info.desc}</p> */}
                  <p style={{ textAlign: "left" }}>
                    {about_info.about_list.map((item) => {
                      return (
                        <>
                          <p
                            className="pt-10"
                            style={{ fontSize: 18, fontWeight: 400 }}
                          >
                            {item}
                          </p>
                        </>
                      );
                    })}
                  </p>
                  <p style={{ textAlign: "left" }} className="pt-20">
                    {about_info.sub_desc}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-6">
              <p className="pt-50" style={{ textAlign: "left" }}>
                Coach Kenna has an experience of over 15+ years with corporates
                globally. Her success journey started when she got the
                opportunity to learn from her best mentor, so she understands
                how crucial the role of the right mentor is. She is a Certified
                expert-level corporate trainer, CBT practitioner, Premiership in
                Business Management fromICTRD, Copywriter, and Numerologist as
                well. Achieved multiple awards for her training and services,
                she believes in holistic learning & development as the root of
                real success
              </p>
            </div>
            <div className="col-md-6">
              {" "}
              <img
                className="pt-40"
                width={350}
                height={350}
                src="/assets/img/WhiteLabled/about/1.jpeg"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhiteAboutArea;
