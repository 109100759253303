import Cookies from 'universal-cookie'

const cookies = new Cookies()

const setDataInCookies = (key: string, data: any) => {
  cookies.set(key, JSON.stringify(data), { path: '/' })
}

const getDataFromCookies = (key: string) => {
  return cookies.get(key)
}

const removeDataFromCookies = (key: string) => {
  cookies.remove(key, { path: '/' })
}

export { setDataInCookies, getDataFromCookies, removeDataFromCookies }
