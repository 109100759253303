import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { getDataFromLocalStorage } from "../../browser-storage";
import { toast } from "react-toastify";
import {
  AddMentorProfile,
  GetMentorProfile,
  UpdateMentorProfileById,
} from "../../services/mentor-service";
import MentorSliderMenu from "./mentorslidermenu";
import { Col, Row } from "react-bootstrap";
import Dropzone from "react-dropzone";
import Modal from "react-modal";
import { toBase64 } from "../../helpers/index";
import styled from "styled-components";
import { addMentorProfilePic } from "../../services/user-service";
import { setDataInLocalStorage } from "../../browser-storage";
import config from "../../services/config";
import JoditEditor from "jodit-react";
import * as DOMPurify from "dompurify";
const DragDropContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 232px;
  border: 1px dashed #c4c4c4;
  border-radius: 5px;
  gap: 8px;
  div:nth-child(1) {
    font-family: Poppins-Bold;
    font-size: 24px;
    color: rgba(61, 41, 36, 0.8);
  }
  div:nth-child(2) {
    font-family: Poppins-Regular;
    font-size: 18px;
    color: rgba(61, 41, 36, 0.8);
  }
  img {
    width: 220px;
    height: 220px;
    border-radius: 5px;
  }
`;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "550px",
  },
};

const MentorProfile = () => {
  const editor = useRef(null);
  const [mentorprofiledetials, setMentorProfileDetials] = useState({
    userId: "",
    userEmailId: "",
    firstName: "",
    lastName: "",
    fullName: "",
    mobile: "",
    education: "",
    profession: "",
    location: "",
    profileImgUrl: "",
    dob: "",
    aboutme: "",
    linkedinlink: "",
    mentor_intro_video: "",
    sector: "",
    course_created: [],
  });

  // Model Requirements
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }
  useEffect(() => {
    const userDetials = getDataFromLocalStorage("user-details");
    console.log(userDetials);

    const currentemail = userDetials.userName;
    GetMentorProfileById(currentemail);
    // const currentmobile = MentorDetials.mobile;
    // console.log(currentemail, currentmobile);

    // setMentorProfileDetials({
    //   ...mentorprofiledetials,
    //   userEmailId: currentemail,
    //   mobile: currentmobile,
    // });
    // console.log(mentorprofiledetials);
    // console.log("userEmailId", mentorprofiledetials.userEmailId);
    setMentorProfileDetials({ userEmailId: currentemail });
  }, []);
  const [editMode, setEditMode] = useState(false);
  let GetMentorProfileById = async (currentemail) => {
    const SubmitResponse = await GetMentorProfile(currentemail)
      .then((resp) => {
        console.log(resp);
        let checkIsProfileCreated = resp;
        if (checkIsProfileCreated.length == 0) {
          console.log("Here");
          setEditMode(false);
        } else {
          let mentorresp = checkIsProfileCreated[0];
          console.log("mentorresp", mentorresp);
          setMentorProfileDetials(mentorresp);
          setEditMode(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setEditMode(true);
        toast.error(`Error ${err.response.data} `, {
          position: "top-left",
        });
      });
  };
  const [profilePic, setProfilePic] = useState({
    uploadedFile: undefined,
    fileUrl: "",
  });
  const [showErrors, setShowErrors] = useState(false);
  const setupProfilePic = async (file) => {
    console.log("file", file);
    // if (file?.length && file[0]?.size > 4_00_000) {
    //   console.log("Inside this", file);
    //   setShowErrors(true);
    //   return;
    // }
    console.log(file[0]);
    const base64 = await toBase64(file[0]).catch(() =>
      toast.error(`Unable to Upload Image`, {
        position: "top-left",
      })
    );
    console.log("base64", base64);
    if (base64) {
      setProfilePic({ uploadedFile: file[0], fileUrl: base64 });
    }
    console.log("profilePic", profilePic.fileUrl);
    setShowErrors(false);
  };

  const uploadImage = () => {
    if (!profilePic.uploadedFile || showErrors) {
      return;
    }

    addMentorProfilePic(profilePic.uploadedFile)
      .then((data) => {
        console.log(data);
        toast.success(`Profile picture updated successfully`, {
          position: "top-left",
        });
        //window.location.reload();

        closeModal();
        GetMentorProfileById(mentorprofiledetials.userEmailId);
      })
      .catch(() =>
        toast.error(`Unable to update your profile picture`, {
          position: "top-left",
        })
      );
  };

  let ProfileDetailsonChange = (evt) => {
    const value = evt.target.value;
    setMentorProfileDetials({
      ...mentorprofiledetials,
      [evt.target.name]: value,
    });
    console.log(mentorprofiledetials);
  };

  let SubmitMentorProfile = async () => {
    setMentorProfileDetials({
      ...mentorprofiledetials,
      profileImgUrl:
        "https://s3.ap-south-1.amazonaws.com/qa.mygreenhorn.com/assets/img/ghimages/blankprofile.png",
    });
    console.log(mentorprofiledetials);
    const SubmitResponse = await AddMentorProfile(mentorprofiledetials)
      .then((resp) => {
        console.log(resp);
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
        //window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Error ${err.response.data} `, {
          position: "top-left",
        });
      });
  };
  let switchtoaddmode = () => {
    setEditMode(false);
  };

  let SubmitUpdateMentorProfile = async () => {
    const SubmitResponse = await UpdateMentorProfileById(mentorprofiledetials)
      .then((resp) => {
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
      })
      .catch((err) =>
        toast.error(`Error ${err} `, {
          position: "top-left",
        })
      );
  };
  let richtext = (e) => {
    let clean = DOMPurify.sanitize(e);
    setMentorProfileDetials({ ...mentorprofiledetials, aboutme: clean });
    console.log("aboutme", mentorprofiledetials.aboutme);
  };
  return (
    <Wrapper>
      <SEO pageTitle={"Mentor Profile"} />
      <MentorSliderMenu />
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        contentClassName="myModel"
      >
        <Row>
          <Col sm={11}>
            <h3>Upload Profile Image</h3>
          </Col>
          <Col sm={1}>
            {" "}
            <button
              style={{ color: "red", background: "#ffffff" }}
              onClick={closeModal}
            >
              <h3 style={{ color: "red", background: "#ffffff" }}>X</h3>
            </button>
          </Col>
        </Row>

        <Dropzone accept="" onDrop={(file) => setupProfilePic(file)}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <DragDropContainer>
                {profilePic.fileUrl ? (
                  <img src="/assets/img/ghimages/uploadimg.png" alt="" />
                ) : (
                  <>
                    <p>Drag & Drop</p>
                    <p>Or</p>
                    <button className="e-btn">Upload</button>
                  </>
                )}
              </DragDropContainer>
            </div>
          )}
        </Dropzone>
        <Row>
          <Col sm={9}>
            <p style={{ color: "red" }}>Note : Maximum Upload Size is 400 KB</p>
          </Col>
          <Col className="text-center" sm={3}>
            <button
              style={{ marginTop: 20 }}
              className="e-btn"
              onClick={uploadImage}
            >
              Submit
            </button>
          </Col>
        </Row>

        {/* {profilePic.fileUrl && (
          <img
            src="/assets/img/ghimages/close.svg"
            alt="X"
            onClick={() =>
              setProfilePic({
                uploadedFile: undefined,
                fileUrl: "",
              })
            }
          />
        )} */}
      </Modal>
      <Row style={{ marginTop: 20 }}>
        <Col sm={10}></Col>
        <Col sm={1}>
          {" "}
          {/* <button
            className="e-btn"
            type="button"
            onClick={() => EditProfileHandler()}
          >
            Edit Profile
          </button> */}
        </Col>
        {/* <Col sm={2}>
          <button className="e-btn" onClick={switchtoaddmode}>
            Switch To Add Mode
          </button>
        </Col> */}
      </Row>
      <div className="MobTextStyle">
        <div className="button-section"></div>

        <div style={{ marginTop: 20 }} className="row">
          <div className="col-md-3">
            <div className="">
              <div style={{ margin: 20 }}>
                {mentorprofiledetials.profileImgUrl ? (
                  <img
                    src={`${config.PROTOCOL}${config.IMAGE_HOST}/${mentorprofiledetials.profileImgUrl}`}
                    //rc={`https://s3.ap-south-1.amazonaws.com/qa.mygreenhorn.com/data/profile/yashsharma1622%40gmail.com/profile.png`}
                    alt=""
                  />
                ) : (
                  <img src="/assets/img/ghimages/blankprofile.png" alt="" />
                )}
                <div className="text-center pt-10">
                  <button className="e-btn" onClick={openModal}>
                    Set Profile Picture
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="col-md-11">
              <div className="fieldcontainer">
                <p>Email Id</p>
                <input
                  placeholder="User Email Id"
                  className="form-control marginTop5"
                  type="text"
                  name="fullName"
                  value={mentorprofiledetials.userEmailId}
                  onChange={ProfileDetailsonChange}
                  disabled
                />
              </div>
              <div className="fieldcontainer">
                <p>Full Name</p>
                <input
                  placeholder="Full Name"
                  className="form-control marginTop5"
                  type="text"
                  name="fullName"
                  value={mentorprofiledetials.fullName}
                  onChange={ProfileDetailsonChange}
                />
              </div>
              <div className="fieldcontainer">
                <p>Mobile</p>
                <input
                  placeholder="Mobile"
                  className="form-control marginTop5"
                  type="number"
                  name="mobile"
                  value={mentorprofiledetials.mobile}
                  onChange={ProfileDetailsonChange}
                />
              </div>

              <div className="fieldcontainer">
                <p>About me</p>
                {/* <input
                  placeholder="About Me"
                  className="form-control marginTop5"
                  type="text"
                  name="aboutme"
                  value={mentorprofiledetials.aboutme}
                  onChange={ProfileDetailsonChange}
                /> */}
                <JoditEditor
                  ref={editor}
                  value={mentorprofiledetials.aboutme}
                  config={config}
                  tabIndex={1} // tabIndex of textarea
                  //onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                  onChange={(newContent) => richtext(newContent)}
                />
              </div>
              <div className="fieldcontainer">
                <p>LinkedIn Profile Link</p>
                <input
                  placeholder="LinkedIn Profile Link"
                  className="form-control marginTop5"
                  type="text"
                  name="linkedinlink"
                  value={mentorprofiledetials.linkedinlink}
                  onChange={ProfileDetailsonChange}
                />
              </div>
              <div className="fieldcontainer">
                <p>Mentor Intro Video</p>
                <input
                  placeholder="Youtube Video Code"
                  className="form-control marginTop5"
                  type="text"
                  name="mentor_intro_video"
                  value={mentorprofiledetials.mentor_intro_video}
                  onChange={ProfileDetailsonChange}
                />
              </div>
              <div className="fieldcontainer">
                <p>Specialization</p>
                <select
                  name="profession"
                  className="form-control"
                  value={mentorprofiledetials.profession}
                  onChange={ProfileDetailsonChange}
                  placeholder=""
                >
                  <option name="" value="" style={{ display: "none" }}>
                    Choose Category
                  </option>
                  <option name="Soft Skills Mentor" value="Soft Skills Mentor">
                    Soft Skills Mentor
                  </option>
                  <option name="Career Coach" value="Career Coach">
                    Career Coach
                  </option>

                  <option name="Career Counselor" value="Career Counselor">
                    Career Counselor
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row text-center">
        <div className="button-section">
          {editMode == false ? (
            <>
              <button
                className="btn btn-primary button add"
                type="button"
                onClick={SubmitMentorProfile}
              >
                Submit
              </button>
            </>
          ) : (
            <button
              className="btn btn-success marginTop5"
              onClick={SubmitUpdateMentorProfile}
            >
              Submit
            </button>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default MentorProfile;
