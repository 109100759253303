import axios from "axios";
import config from "./config";
import { getDataFromLocalStorage } from "../browser-storage";
import { LocalStorageConstants } from "./localstorageconstants";

const getToken = () => getDataFromLocalStorage(LocalStorageConstants.TOKEN);

const getJoblisting = (): Promise<any> => {
  const headers = {
    //Authorization: `Bearer ${getToken()}`,
    Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ5YXNoc2hhcm1hMTYyMkBnbWFpbC5jb20iLCJpYXQiOjE2NTU5NjM1MDN9.WdUsRWPrRg4-vEuSS1nB93zuKYj2Awu6mlzdXb7UaE4`,
  };
  return axios
    .get(`${config.PROTOCOL}${config.HOST}/job-listing/get-job-listing`, {
      headers,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const applyJobListing = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/job-listing/apply-job-listing`,
      { payload: payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const GetJobsbyRecuiterId = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/job-listing/get-jobs-listing-by-recuiter`,
      { payload: payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const GetStudentDatafromMaster = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/job-listing/get-student-master-by-id`,
      { payload: payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const SendMail = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/user/send-mail`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const addJobListing = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/job-listing/add-job-listing`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const EditJobListing = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .put(
      `${config.PROTOCOL}${config.HOST}/job-listing/edit-job-listing`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const DeleteJobListing = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/job-listing/delete-job-listing`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const AppliedbyJobListing = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/job-listing/appliedby-job-listing`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const ViewAllAppliedListing = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/job-listing/view-all-applied-listing`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const viewAllUsers = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/job-listing/view-all-users`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const viewAllPremiumUsers = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/job-listing/view-all-premium-users`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

export {
  GetJobsbyRecuiterId,
  GetStudentDatafromMaster,
  getJoblisting,
  addJobListing,
  applyJobListing,
  EditJobListing,
  DeleteJobListing,
  AppliedbyJobListing,
  ViewAllAppliedListing,
  viewAllUsers,
  viewAllPremiumUsers,
  SendMail,
};
