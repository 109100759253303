import { useEffect, useState } from "react";
import styled from "styled-components";
import TestHeader from "../test-header/TestHeader";
import SkillPage from "../skill";
import FinishPage from "../finish";
import { AssessmentType } from "../../../../redux/types/generic-constant";
import { getQueryParams } from "../../../../helpers";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
`;
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 50px 70px;
`;
const PageHeader = styled.label`
  display: flex;
  width: 100%;
  font-size: 22px;
  color: #3e3d43;
  margin-bottom: 70px;
`;
const Points = styled.span`
  display: flex;
  width: 100%;
  max-width: 1160px;
`;
const PointsBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;
const Span = styled.span`
  display: flex;
  width: 100%;
  max-width: 1160px;
  padding-bottom: 30px;
`;
const BeginBtn = styled.button`
  display: flex;
  max-width: 176px;
  justify-content: center;
  padding: 20px;
  align-items: center;
  border-radius: 60px;
  background: #016738;
  color: #ffffff;
  border: 1px;
  opacity: ${(props) => (props.assessmentId ? "40%" : "100%")};
  font-weight: 900;
  margin-top: 25px;
  cursor: ${(props) => (props.assessmentId ? "not-allowed" : "pointer")};
`;
// interface StyledProps{
//     assessmentId:boolean
// }
const SoftSkillBeginPage = () => {
  const [pageNumb, setPage] = useState(0);
  const [testId, setTestId] = useState("");
  const [assessmentType, setType] = useState(AssessmentType.SOFT_SKILL);
  const setData = (testId, type, pageNumber) => {
    setTestId(testId);
    setType(type);
    setPage(pageNumber);
  };
  const [assessmentId, setId] = useState(getQueryParams("id") || "");

  return (
    <Container>
      {/* complete later */}
      {/* <Header
        headerConfig={{
          ghLogo: true,
          searchBar: false,
          home: false,
          msg: false,
          notification: false,
          avatarControl: true,
          timeTicker: false,
        }}
      /> */}
      {pageNumb === 0 && (
        <PageContainer>
          <PageHeader> Soft Skill Assessment</PageHeader>
          <Span>
            The soft skill assessment will help you understand where you stand
            on 4 key dimensions that are essential for career:
          </Span>
          <PointsBlock>
            <Points> - Can you manage your strengths and weaknesses?</Points>
            <Points> - Can you communicate effectively? </Points>
            <Points> - Can you collaborate with people? </Points>
            <Points> - Can you handle stress? </Points>
          </PointsBlock>
          <Span>
            {" "}
            &bull; There are no right or wrong answers. Your honest assessment
            of yourself will help you identify your improvement areas
          </Span>
          <Span>
            {" "}
            &bull; This section is not timed. Typically it takes 5 minutes to
            finish this section{" "}
          </Span>
          <Span>
            {" "}
            &bull; Once you submit your responses, we will provide you a
            detailed scorecard with feedback
          </Span>

          <BeginBtn
            assessmentId={!assessmentId}
            disabled={!assessmentId}
            onClick={() => setPage(1)}
          >
            Begin
          </BeginBtn>
        </PageContainer>
      )}
      {pageNumb === 1 && (
        <SkillPage
          assessmentId={assessmentId}
          assessmentType={AssessmentType.SOFT_SKILL}
          setPage={setData}
        />
      )}
      {pageNumb === 2 && (
        <FinishPage testId={testId} assessmentType={assessmentType} />
      )}
    </Container>
  );
};

export default SoftSkillBeginPage;
