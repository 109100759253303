import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getMentorlisting } from "../../services/mentor-service";
const MentorListing = () => {
  const [mentorlist, setMetorList] = useState([]);
  useEffect(() => {
    getMentorListingfunc();
  }, []);
  let MentorActiveProfiles = [];
  let getMentorListingfunc = async () => {
    const SubmitResponse = await getMentorlisting()
      .then((resp) => {
        console.log(resp);
        for (let mentor of resp) {
          //console.log("mentor", mentor.profileImgUrl);
          if (mentor.isMentorProfileActive === "true") {
            MentorActiveProfiles.push(mentor);
          }
        }
        setMetorList(MentorActiveProfiles);
      })
      .catch((err) => console.log(err));
  };
  return (
    <Wrapper>
      <SEO pageTitle={"Mentors"} />
      <>
        <section
          className="page__title-area page__title-height page__title-overlay d-flex align-items-center"
          style={{
            background: `url(/assets/img/ghimages/try/2.jpg)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-wrapper mt-110">
                  <h3 className="page__title">Mentors</h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="teacher__area pt-50 pb-50">
          <div className="container">
            <div className="row">
              <div className="col-xxl-6 offset-xxl-3">
                <div className="section__title-wrapper text-center mb-60">
                  <h2 className="section__title">
                    Our{" "}
                    <span className="yellow-bg">
                      {" "}
                      Expert Mentors
                      <img
                        src="/assets/img/shape/yellow-bg-2.png"
                        alt=""
                      />{" "}
                    </span>{" "}
                    <br />
                  </h2>
                  {/* <p>
                    You don't have to struggle alone, you've got our assistance
                    and help.
                  </p> */}
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                {mentorlist.map((teacher, i) => (
                  <div className="col-md-3">
                    <div className="teacher__item text-center grey-bg-5 transition-3 mb-30">
                      <div className="teacher__thumb w-img fix">
                        {teacher.profileImgUrl !== "" &&
                        teacher.profileImgUrl !== undefined ? (
                          <Link
                            to="/mentor-details"
                            state={{ data: teacher }}
                            className="link-btn"
                          >
                            <img
                              width="219px"
                              height="219px"
                              src={teacher.profileImgUrl}
                              alt="Mentor Image"
                            />
                          </Link>
                        ) : null}
                        {teacher.profileImgUrl == "" ||
                        teacher.profileImgUrl == undefined ? (
                          <>
                            {" "}
                            <Link
                              to="/mentor-details"
                              state={{ data: teacher }}
                              className="link-btn"
                            >
                              <img
                                width="219px"
                                height="219px"
                                src={"/assets/img/ghimages/blankprofile.png"}
                                alt="Blank Image"
                              />
                            </Link>
                          </>
                        ) : null}
                      </div>
                      <div className="teacher__content">
                        <h3 className="teacher__title">
                          <Link
                            to="/mentor-details"
                            state={{ data: teacher }}
                            className="link-btn"
                          >
                            {teacher.fullName}
                          </Link>
                        </h3>
                        <span> {teacher.title}</span>

                        <div className="teacher__social">
                          <ul>
                            {/* {teacher.social_links.map((link, index) => (
                            <li key={index}>
                              <a href="#">
                                <i className={link}></i>
                              </a>
                            </li>
                          ))} */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </>
    </Wrapper>
  );
};

export default MentorListing;
