import React from 'react';

const Mic = () => {
  return (
    <svg viewBox="0 0 512 512">
      <g>
        <g>
          <path className="st0" d="M435.7,0H126.5C89.6,0,59.6,30,59.6,66.9v378.3c0,36.9,30,66.9,66.9,66.9h309.2c9.2,0,16.7-7.5,16.7-16.7    c0-17.5,0-461.2,0-478.6C452.4,7.5,444.9,0,435.7,0z M338.9,396.2c0,13.7-15.7,21.5-26.6,13.4L244,358.9h-54.2    c-9.2,0-16.7-7.5-16.7-16.7v-72c0-9.2,7.5-16.7,16.7-16.7H244l68.3-50.7c11-8.2,26.6-0.3,26.6,13.4V396.2z M419,100.3H126.5    c-18.5,0-33.5-15-33.5-33.5c0-18.5,15-33.5,33.5-33.5H419V100.3z" />
        </g>
      </g>
      <g>
        <g>
          <rect x="206.5" y="286.8" className="st0" width="26.3" height="38.7" />
        </g>
      </g>
      <g>
        <g>
          <polygon className="st0" points="266.2,278.5 266.2,333.8 305.5,363 305.5,249.3   " />
        </g>
      </g>
    </svg>
  );
};

export default Mic;