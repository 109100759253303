import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";

import { getDataFromLocalStorage } from "../../browser-storage";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import * as DOMPurify from "dompurify";
import config from "../../services/config";
import MentorSliderMenu from "./mentorslidermenu";
import { v4 as uuid } from "uuid";
import { GetMentorProfile } from "../../services/mentor-service";
import {
  AddMentorDynamicTabs,
  getDynamicTabsDatabyMentorId,
  UpdateDynamicCourseListing,
  UpdateDynamicTabsListing,
  UpdateStudentDynamicPermissions,
} from "../../services/dynamictabs-service";
const MentorManageDynamicStudentPermissions = () => {
  const [dynamictabslisting, setDynamicTabListing] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [mentorname, setmentorname] = useState("");

  useEffect(() => {
    getDynamicTabsListingByMentorId();
  }, []);

  let getDynamicTabsListingByMentorId = async () => {
    const MentorDetials = getDataFromLocalStorage("user-details");
    console.log(MentorDetials);
    const getmentorname = MentorDetials.fullName;
    const getmentorusername = MentorDetials.userName;
    const profileImgUrl = MentorDetials.profileImgUrl;
    const uniquecoursecode = uuid();
    const currentemail = MentorDetials.userName;
    console.log("uniquecoursecode", uniquecoursecode);
    console.log("currentemail", currentemail);
    setDynamicTabsDetails({
      dynamicTabForMentorId: currentemail,
    });

    const SubmitResponse = await getDynamicTabsDatabyMentorId(currentemail)
      .then((resp) => {
        console.log(resp);
        const respreverse = resp.reverse();
        setDynamicTabListing(respreverse);
        //GetMentorProfileById(currentemail);
      })
      .catch((err) => console.log(err));
  };

  //   let GetMentorProfileById = async (currentemail) => {
  //     const SubmitResponse = await GetMentorProfile(currentemail)
  //       .then((resp) => {
  //         console.log("resp", resp);
  //         const currentResp = resp[0];
  //         const currentfullname = currentResp.fullName;
  //         const currentuserEmailId = currentResp.userEmailId;
  //         const uniquecoursecode = uuid();
  //         setCourseDetails({
  //           course_createdby_mentor: currentfullname,
  //           userEmailId: currentuserEmailId,
  //           course_code: uniquecoursecode,
  //         });
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setEditMode(true);
  //         toast.error(`Error ${err.response.data} `, {
  //           position: "top-left",
  //         });
  //       });
  //   };

  const [DynamicTabsDetails, setDynamicTabsDetails] = useState({
    dynamicTabForMentorId: "",
    dynamicTabsArray: [],
  });
  let onChange = (evt) => {
    const value = evt.target.value;
    setDynamicTabsDetails({
      ...DynamicTabsDetails,
      [evt.target.name]: value,
    });
    console.log(DynamicTabsDetails);
  };
  useEffect(() => {
    getDynamicTabsDatabyMentorId();
  }, []);

  let EditHandler = (item) => {
    console.log(item);
    console.log(item.dynamicTabForMentorId);
    setEditMode(true);
    setDynamicTabsDetails({
      dynamicTabForMentorId: item.dynamicTabForMentorId,
      dynamicTabsArray: item.dynamicTabsArray,
    });
    setDynamicTabs(item.dynamicTabsArray);
  };

  //   let DeleteHandler = (item) => {
  //     console.log(item);
  //     const dynamicTabForMentorId = item.course_code;
  //     DeleteCourseListing(course_code).then((resp) => {
  //       console.log(resp);
  //       getCourseListingByMentor();
  //     });
  //   };

  const [DynamicTabs, setDynamicTabs] = useState([
    {
      dynamicTabId: "",
      dynamicTabTitle: "",
      dynamicTabCondition: "",
      dynamicTabOrder: "",
      //   dynamicTabCourseId: "",
      //   dynamicTabLink: "",
      //   dynamicTabIcon: "",
      //   dynamicTabAlt: "",
    },
  ]);

  let DynamicTabshandleChange = (i, e) => {
    let value = [...DynamicTabs];
    value[i][e.target.name] = e.target.value;
    DynamicTabs[i][e.target.name] = value[i][e.target.name];
    setDynamicTabs(DynamicTabs);
    setDynamicTabs([...DynamicTabs]);
    console.log(DynamicTabs);
  };

  let addDynamicTabsFields = () => {
    setDynamicTabs([
      ...DynamicTabs,
      {
        dynamicTabId: "",
        dynamicTabTitle: "",
        dynamicTabCondition: "",
        dynamicTabOrder: "",
        //   dynamicTabCourseId: "",
        //   dynamicTabLink: "",
        //   dynamicTabIcon: "",
        //   dynamicTabAlt: "",
      },
    ]);
  };

  let removeDynamicTabsFields = (i) => {
    let value = [...DynamicTabs];
    value.splice(i, 1);
    setDynamicTabs(value);
  };

  let SubmitDynamicTabsDetials = async () => {
    console.log("DynamicTabs", DynamicTabs);
    DynamicTabsDetails.dynamicTabsArray = DynamicTabs;
    console.log(DynamicTabsDetails);

    const SubmitResponse = await AddMentorDynamicTabs(DynamicTabsDetails)
      .then((resp) => {
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
        window.location.reload();
      })
      .catch((err) =>
        toast.error(`Error ${err} `, {
          position: "top-left",
        })
      );
  };

  let SubmitEditDynamicTabsDetails = async () => {
    DynamicTabsDetails.dynamicTabsArray = DynamicTabs;
    console.log(DynamicTabsDetails);
    const SubmitResponse = await UpdateDynamicTabsListing(DynamicTabsDetails)
      .then((resp) => {
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
      })
      .catch((err) =>
        toast.error(`Error ${err} `, {
          position: "top-left",
        })
      );
  };

  const [email, setEmail] = useState("");
  const [dynamicPermissions, setDynamicPermissions] = useState([]);
  const [payload, setPayload] = useState({});

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleDynamicPermissionsChange = (e) => {
    setDynamicPermissions(e.target.value.split(","));
  };

  const generatePayload = async () => {
    const newPayload = {
      email: email,
      DynamicPermissions: dynamicPermissions,
    };
    setPayload(newPayload);
    const SubmitResponse = await UpdateStudentDynamicPermissions(newPayload)
      .then((resp) => {
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
      })
      .catch((err) =>
        toast.error(`Error ${err} `, {
          position: "top-left",
        })
      );
  };

  const switchtoaddmode = () => {};
  return (
    <Wrapper>
      <SEO pageTitle={"Manage Dynamic Tabs"} />
      <MentorSliderMenu />
      <Container>
        <div>
          <div>
            <label>Email:</label>
            <input type="text" value={email} onChange={handleEmailChange} />
          </div>
          <div>
            <label>Dynamic Permissions (comma-separated):</label>
            <input
              type="text"
              value={dynamicPermissions.join(",")}
              onChange={handleDynamicPermissionsChange}
            />
          </div>
          <button onClick={generatePayload}>Generate Payload</button>
          <pre>{JSON.stringify(payload, null, 2)}</pre>
        </div>
      </Container>

      <Container>
        <Row style={{ marginTop: "50px" }}>
          <Col className="divscroll">
            <Card className="MainCard">
              <div className="section__title-wrapper mb-45">
                <h2 className="section__title">
                  All{" "}
                  <span className="yellow-bg">
                    Tabs <img src="/assets/img/shape/yellow-bg-2.png" alt="" />
                  </span>
                  Listing
                </h2>
              </div>
              {dynamictabslisting.map((item) => {
                return (
                  <Card className="carddesign">
                    <Container className="jobcontainer">
                      <Row>
                        <p className="jobtitle">
                          <b className="boldtitle">
                            Dynamic Tabs for Mentor Id: &nbsp;&nbsp;
                            {item.dynamicTabForMentorId}
                          </b>
                        </p>
                      </Row>

                      <Row>
                        <Col>
                          <button
                            className="e-btn"
                            onClick={() => EditHandler(item)}
                          >
                            Edit
                          </button>
                        </Col>
                        {/* <Col>
                          <button
                            className="e-btn"
                            onClick={() => DeleteCourseHandler(item)}
                          >
                            Delete
                          </button>
                        </Col> */}
                      </Row>
                      <Row></Row>
                    </Container>
                  </Card>
                );
              })}
            </Card>
          </Col>
          <Col>
            <Card className="MainCard">
              {editMode == false ? (
                <>
                  <Col>
                    <div className="section__title-wrapper mb-45">
                      <h2 className="section__title">
                        <span className="yellow-bg">
                          Add{" "}
                          <img src="/assets/img/shape/yellow-bg-2.png" alt="" />
                        </span>
                        Course
                      </h2>
                    </div>
                  </Col>
                </>
              ) : (
                <>
                  <Container>
                    <Row>
                      <Col sm={8}>
                        <div className="section__title-wrapper mb-45">
                          <h2 className="section__title">
                            Edit{" "}
                            <span className="yellow-bg">
                              Course{" "}
                              <img
                                src="/assets/img/shape/yellow-bg-2.png"
                                alt=""
                              />
                            </span>
                            Listing
                          </h2>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <button className="e-btn" onClick={switchtoaddmode}>
                          Switch To Add Mode
                        </button>
                      </Col>
                    </Row>
                  </Container>
                </>
              )}

              <Container>
                <div className="MobTextStyle">
                  <input
                    placeholder="Mentor Email Id"
                    className="form-control marginTop5"
                    type="text"
                    name="dynamicTabForMentorId"
                    value={DynamicTabsDetails.dynamicTabForMentorId}
                    disabled
                  />
                </div>
                <Card className="carddesign marginTop5">
                  <Card.Body>
                    <Card.Title>Dynamic Tabs</Card.Title>
                    <Card.Text>
                      {DynamicTabs.map((element, index) => (
                        <div className="form-inline" key={index}>
                          <input
                            placeholder="Tab Title"
                            className="form-control"
                            type="text"
                            name="dynamicTabTitle"
                            value={element.dynamicTabTitle}
                            onChange={(e) => DynamicTabshandleChange(index, e)}
                          />
                          <input
                            placeholder="Unique Keyword for Course"
                            className="form-control"
                            type="text"
                            name="dynamicTabCondition"
                            value={element.dynamicTabCondition}
                            onChange={(e) => DynamicTabshandleChange(index, e)}
                          />
                          <input
                            placeholder="Tab Order"
                            className="form-control"
                            type="text"
                            name="dynamicTabOrder"
                            value={element.dynamicTabOrder}
                            onChange={(e) => DynamicTabshandleChange(index, e)}
                          />

                          {index ? (
                            <button
                              type="button"
                              className="btn btn-primary button remove"
                              onClick={() => removeDynamicTabsFields(index)}
                            >
                              Remove
                            </button>
                          ) : null}
                          <hr />
                        </div>
                      ))}
                      <div className="button-section">
                        <button
                          className="e-btn"
                          type="button"
                          onClick={() => addDynamicTabsFields()}
                        >
                          Add
                        </button>
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Container>
              {editMode == false ? (
                <>
                  <button className="e-btn" onClick={SubmitDynamicTabsDetials}>
                    Add Submit
                  </button>
                </>
              ) : (
                <button
                  className="e-btn marginTop5"
                  onClick={SubmitEditDynamicTabsDetails}
                >
                  Edit Submit
                </button>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default MentorManageDynamicStudentPermissions;
