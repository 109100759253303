import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import YouTube from "react-youtube";

import styled from "styled-components";
import Accordion from "react-bootstrap/Accordion";
import "./placementtools.css";

import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";

import { razorpayCreateOrder } from "../../services/payment-service";
import Alert from "react-bootstrap/Alert";
import { addserviceScheduler } from "../../services/scheduler-service";
import {
  setDataInLocalStorage,
  getDataFromLocalStorage,
} from "../../browser-storage";
import { toast } from "react-toastify";
import ReactPlayer from "react-player";
import screenful from "screenfull";
import Controls from "./Controls";
const Video = styled.iframe`
  display: flex;
  align-items: center;
  border: none;
  max-width: 490px;
  width: 100%;
  height: 100%;
  max-height: 276px;
  min-height: 276px;
  box-shadow: 9px 14px 15px rgba(0, 0, 0, 0.25);
`;

const ConfidenceBuilder = () => {
  const opts = {
    width: "500",
    height: "250",

    playerVars: {
      autoplay: 0,
    },
  };

  let _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const [isscheduledone, setscheduledone] = useState(false);
  const [isBookOpen, setBookOpen] = useState(false);
  const [isDoneScheduling, setDoneScheduling] = useState(false);
  const [isTimeSelected, setTimeSelected] = useState(false);

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => {
      if (e) {
        setscheduledone(true);
        setscheduledone(true);
      }
    },
  });

  const prefill = {
    name: "Test Name",
    email: "Email@gmail.com",
  };

  const pageSettings = {
    primaryColor: "417658",
    textColor: "251e35",
    backgroundColor: "f7f5f4",
    hideLandingPageDetails: true,
    hideEventTypeDetails: true,
  };

  function getCalendlyHeightToEnsureNoCrop() {
    if (!isTimeSelected) return { height: 600 };
    if (isDoneScheduling) return { height: 450 };
    return { height: 660 };
  }

  function handleDateAndTimeSelected() {
    setTimeSelected(true);
  }

  function handleEventScheduled() {
    setDoneScheduling(true);
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const payload = { amount: 79900 };

    const result = await razorpayCreateOrder(payload)
      .then((res) => {
        console.log(res);
        let response = res;
        const id = response.id;
        const options = {
          currency: "INR",
          name: "GreenHorn",
          order_id: id,
          description: "GreenHorn Transaction",
          handler: async function (response) {
            const data = {
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            };
            console.log(response);

            const userDetials = getDataFromLocalStorage("user-details");
            console.log(userDetials);
            const email = userDetials.email;
            const payload = {
              username: userDetials.userName,
              servicebooked: "Confidence Builder",
              bookingDate: new Date(),
              amount: "799",
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            };
            const schedulerResult = await addserviceScheduler(payload)
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });

            console.log(schedulerResult);

            if (response) {
              toast.success(
                `Payment Successfully Completed, Please check your mail for more detials `,
                {
                  position: "top-left",
                }
              );
            }
          },
          theme: {
            color: "#61dafb",
          },
        };

        const paymentObject = new window.Razorpay(options);

        paymentObject.open();
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(result);
  }

  function dummystatechange() {
    setscheduledone(true);
    setscheduledone(true);
  }

  let handleBookOpen = () => {
    setBookOpen(true);
    setBookOpen(true);
  };

  return (
    <Wrapper>
      <SEO pageTitle={"Confidence Builder"} />
      <>
        {/* <Row style={{ marginBottom: "20px" }}>
          <Card className="carddesign">
            <Card.Body>
              <Row className="text-center">
                <div style={{ border: "10px solid white" }} className="desktop">
                  <YouTube
                    className="cusmobilevideo"
                    videoId="mJKsESo4brQ"
                    opts={opts}
                    onReady={_onReady}
                  />
                </div>
                <div className="mobile">
                  <Video src={"https://www.youtube.com/embed/mJKsESo4brQ"} />
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Row> */}

        <Row style={{ marginLeft: 10, marginRight: 10 }}>
          <div className="desktop">
            <Accordion defaultActiveKey={["0"]} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Introduction to Confidence Builder
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col className="carddesign">
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Introduction-to-Confidence-Builder.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/hidden1.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                      />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Module 1: Brand You</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 1 – Know Yourself
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/01.-Know-Yourself_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/z1.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 2 – Your Strengths
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/02.-Strengths_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/z2.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 3 – Your Weaknesses
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/03.-Weaknesses_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/333333.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 4 – Goal Setting
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/04.-Goal-Setting_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/44444444.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 5 – Personal Branding
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/05-.Brand_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/5555555.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 6 – Professional Networking
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/06.-Professional-Networking_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/66666666.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                      />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Module 2: Communicate for Impact
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 1 – Verbal Communication
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-2-01-Verbal-Communication_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/c1.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 2 – Non-Verbal Communication
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-2-02-Non-verbal-communication_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/c2.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 3 – Public Speaking
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-2_03-Public-Speaking_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/c3.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                      />
                    </Col>
                    <Col></Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  Module 3: Master Group Discussions
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 1 – What do Companies assess?
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-3_Sibin_Final.1.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/g2.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 2 – Leading a Group Discussion
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-3_Sibin_Final.2.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/g1.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 3 – Broad categories of Group Discussion Topics
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-3_Sibin_Final.3.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/g3.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                      />
                    </Col>
                    <Col></Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Module 4: Nail that Personal Interview
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 1 – What does an interviewer assess?
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-4_0.1_Final-1.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/i1.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 2 – Preparation leading to your Interview
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-4_0.2_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/i2.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 3 – Resume
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-4_0.3_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/i3.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 4 – Frequently Asked Questions
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-4_0.4_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/i4.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 5 – Finding Job as a Fresher{" "}
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-4_0.5_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/i5.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 6 – On the interview Day
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-4_0.6_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/i6.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                      />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="mobile">
            <Accordion defaultActiveKey={["0"]} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Introduction to Confidence Builder
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col className="carddesign">
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Introduction-to-Confidence-Builder.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/hidden1.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                        width="380px"
                        height="210px"
                      />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Module 1: Brand You</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 1 – Know Yourself
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/01.-Know-Yourself_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/z1.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                        width="380px"
                        height="210px"
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 2 – Your Strengths
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/02.-Strengths_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/z2.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                        width="380px"
                        height="210px"
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 3 – Your Weaknesses
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/03.-Weaknesses_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/333333.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                        width="380px"
                        height="210px"
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 4 – Goal Setting
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/04.-Goal-Setting_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/44444444.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                        width="380px"
                        height="210px"
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 5 – Personal Branding
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/05-.Brand_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/5555555.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                        width="380px"
                        height="210px"
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 6 – Professional Networking
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/06.-Professional-Networking_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/66666666.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                        width="380px"
                        height="210px"
                      />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Module 2: Communicate for Impact
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 1 – Verbal Communication
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-2-01-Verbal-Communication_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/c1.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                        width="380px"
                        height="210px"
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 2 – Non-Verbal Communication
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-2-02-Non-verbal-communication_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/c2.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                        width="380px"
                        height="210px"
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 3 – Public Speaking
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-2_03-Public-Speaking_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/c3.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                        width="380px"
                        height="210px"
                      />
                    </Col>
                    <Col></Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  Module 3: Master Group Discussions
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 1 – What do Companies assess?
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-3_Sibin_Final.1.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/g2.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                        width="380px"
                        height="210px"
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 2 – Leading a Group Discussion
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-3_Sibin_Final.2.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/g1.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                        width="380px"
                        height="210px"
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 3 – Broad categories of Group Discussion Topics
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-3_Sibin_Final.3.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/g3.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                        width="380px"
                        height="210px"
                      />
                    </Col>
                    <Col></Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Module 4: Nail that Personal Interview
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 1 – What does an interviewer assess?
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-4_0.1_Final-1.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/i1.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                        width="380px"
                        height="210px"
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 2 – Preparation leading to your Interview
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-4_0.2_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/i2.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                        width="380px"
                        height="210px"
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 3 – Resume
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-4_0.3_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/i3.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                        width="380px"
                        height="210px"
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 4 – Frequently Asked Questions
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-4_0.4_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/i4.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                        width="380px"
                        height="210px"
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 5 – Finding Job as a Fresher{" "}
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-4_0.5_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/i5.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                        width="380px"
                        height="210px"
                      />
                    </Col>
                    <Col className="carddesign">
                      <div className="videogallerytitle">
                        Chapter 6 – On the interview Day
                      </div>
                      <ReactPlayer
                        url="http://achillobator.com/confidence_builder_videos/Module-4_0.6_Final.mp4"
                        controls
                        light="https://s3.ap-south-1.amazonaws.com/mygreenhorn.com/data/confidence_builder_banners/i6.png"
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing
                        width="380px"
                        height="210px"
                      />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Row>
      </>
    </Wrapper>
  );
};

export default ConfidenceBuilder;
