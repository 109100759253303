import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";

import admin_menu_data_2 from "../../data/admin-menu-data-2";
import { Link } from "react-router-dom";
const AdminDashboard = () => {
  return (
    <Wrapper>
      <SEO pageTitle={"Admin Dashboard"} />
      <div className="container">
        <div className="row">
          {admin_menu_data_2.map((item) => {
            return (
              <div className="col-md-3">
                <Link
                  style={{ margin: 10 }}
                  className="e-btn btn-lg"
                  to={item.link}
                >
                  {item.title}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </Wrapper>
  );
};

export default AdminDashboard;
