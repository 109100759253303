import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LOGO_URL } from "../../layout/headers/header";
import { menu_bar, selectMenuBar } from "../../redux/features/header-slice";
import { Search } from "../../svg";

const Sidebar = () => {
  const menuOpen = useSelector(selectMenuBar);
  const dispatch = useDispatch();

  return (
    <>
      <div className={menuOpen ? "sidebar__area open" : "sidebar__area"}>
        <div className="sidebar__wrapper">
          <div className="sidebar__close">
            <button
              className="sidebar__close-btn"
              id="sidebar__close-btn"
              onClick={() => dispatch(menu_bar(false))}
            >
              <span>
                <i className="far fa-times"></i>
              </span>
              <span>close</span>
            </button>
          </div>
          <div className="sidebar__content">
            <div className="logo mb-40">
              <Link to="/">
                <img src={LOGO_URL} alt="logo" />
              </Link>
            </div>
            <div className="mm-menu">
              <ul>
                <li onClick={() => dispatch(menu_bar(false))}>
                  <Link to="/">Home </Link>
                </li>

                <li onClick={() => dispatch(menu_bar(false))}>
                  <Link to="/student-login">Sign in</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- sidebar overlay end --> */}
      <div
        onClick={() => dispatch(menu_bar(false))}
        className={`body-overlay ${menuOpen ? "opened" : ""}`}
      ></div>
      {/* <!-- sidebar overlay end --> */}
    </>
  );
};

export default Sidebar;
