import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/common/breadcrumb/breadcrumb";
const PlacementTools = () => {
  return (
    <Wrapper>
      <SEO pageTitle={"Career Tools"} />
      {/* breadcrumb start */}
      <section
        className="page__title-area page__title-height page__title-overlay d-flex align-items-center"
        style={{
          background: `url(/assets/img/ghimages/try/5.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="page__title-wrapper mt-110">
                <h3 className="page__title">
                  Career
                  <br /> Tools
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* breadcrumb end */}
      <section className="teacher__area pt-55">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 offset-xxl-3">
              <div className="section__title-wrapper text-center mb-60">
                <h2 className="section__title">
                  All{" "}
                  <span className="yellow-bg">
                    {" "}
                    You Need
                    <img src="/assets/img/shape/yellow-bg-2.png" alt="" />{" "}
                  </span>{" "}
                  Career Tools
                  <br />
                </h2>
                {/* <p>
                  You don't have to struggle alone, you've got our assistance
                  and help.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="section-content">
        <div class="twm-jobs-grid-wrap">
          <div style={{ margin: 20 }} class="row text-center">
            <div class="col-lg-4 col-md-4">
              <Link
                to="/student-login"
                state={{ data: "/resume-builder" }}
                className="link"
              >
                <div class="twm-jobs-featured-style1 m-b30">
                  <div
                    style={{ width: "400px", height: "315px" }}
                    class="twm-media"
                  >
                    <img
                      src="/assets/img/ghimages/pt/resume-builder.png"
                      alt="#"
                    />
                  </div>

                  <div class="twm-mid-content">
                    <Link
                      to="/student-login"
                      state={{ data: "/resume-builder" }}
                      className="twm-job-title"
                    >
                      <h4>Resume Builder</h4>
                    </Link>
                  </div>
                </div>
              </Link>
            </div>

            <div class="col-lg-4 col-md-4">
              <Link
                to="/student-login"
                state={{ data: "/assessment" }}
                className="link"
              >
                <div class="twm-jobs-featured-style1 m-b30">
                  <div
                    style={{ width: "400px", height: "315px" }}
                    class="twm-media"
                  >
                    <img src="/assets/img/ghimages/pt/assessment.png" alt="#" />
                  </div>

                  <div class="twm-mid-content">
                    <Link
                      to="/student-login"
                      state={{ data: "/assessment" }}
                      className="twm-job-title"
                    >
                      <h4>Aptitude Assessments</h4>
                    </Link>
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-lg-4 col-md-4">
              <Link
                to="/student-login"
                state={{ data: "/ai-interview" }}
                className="link"
              >
                <div class="twm-jobs-featured-style1 m-b30">
                  <div
                    style={{ width: "400px", height: "315px" }}
                    class="twm-media"
                  >
                    <img
                      width="400px"
                      height="315px"
                      src="/assets/img/ghimages/pt/ai.png"
                      alt="#"
                    />
                  </div>

                  <div class="twm-mid-content">
                    <Link
                      to="/student-login"
                      state={{ data: "/ai-interview" }}
                      className="twm-job-title"
                    >
                      <h4>AI Interview</h4>
                    </Link>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div style={{ margin: 20 }} class="row text-center">
            <div class="col-lg-4 col-md-4">
              <Link
                to="/student-login"
                state={{ data: "/confidence-builder" }}
                className="link"
              >
                <div class="twm-jobs-featured-style1 m-b30">
                  <div
                    style={{ width: "400px", height: "315px" }}
                    class="twm-media"
                  >
                    <img
                      src="/assets/img/ghimages/pt/confidence-builder.png"
                      alt="#"
                    />
                  </div>

                  <div class="twm-mid-content">
                    <Link
                      to="/student-login"
                      state={{ data: "/confidence-builder" }}
                      className="twm-job-title"
                    >
                      <h4>Confidence Builder</h4>
                    </Link>
                  </div>
                </div>
              </Link>
            </div>

            <div class="col-lg-4 col-md-4">
              <Link
                to="/student-login"
                state={{ data: "/interview-tips" }}
                className="link"
              >
                <div class="twm-jobs-featured-style1 m-b30">
                  <div
                    style={{ width: "400px", height: "315px" }}
                    class="twm-media"
                  >
                    <img src="/assets/img/ghimages/pt/int_new.png" alt="#" />
                  </div>

                  <div class="twm-mid-content">
                    <Link
                      to="/student-login"
                      state={{ data: "/interview-tips" }}
                      className="twm-job-title"
                    >
                      <h4>Interview Tips</h4>
                    </Link>
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-lg-4 col-md-4">
              <Link
                to="/student-login"
                state={{ data: "/success-stories" }}
                className="link"
              >
                <div class="twm-jobs-featured-style1 m-b30">
                  <div
                    style={{ width: "400px", height: "315px" }}
                    class="twm-media"
                  >
                    <img
                      src="/assets/img/ghimages/pt/ai-interview.png"
                      alt="#"
                    />
                  </div>

                  <div class="twm-mid-content">
                    <Link
                      to="/student-login"
                      state={{ data: "/success-stories" }}
                      className="twm-job-title"
                    >
                      <h4>GreenHorn Score</h4>
                    </Link>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* JOB POST END */}
      </div>
    </Wrapper>
  );
};

export default PlacementTools;
