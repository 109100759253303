import styled from "styled-components";

const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 2px solid #0d6d3b;
  border-radius: 50%;
  cursor: pointer;
`;

const RadioDot = styled.div`
  width: 12px;
  height: 12px;
  background: #0d6d3b;
  border-radius: 50%;
`;

const Radio = ({ isChecked, ...otherHtmlProps }: any) => (
  <RadioContainer {...otherHtmlProps}>
    {isChecked && <RadioDot />}
  </RadioContainer>
);

export default Radio;
