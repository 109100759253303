import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import {
  EduCap,
  Mic,
  Promotion,
  Chat,
  OnlineCourse,
  Research,
  Support,
  Books,
  Followers,
  Learners,
  Students,
} from "../../svg";
import { hero_two } from "../../data";
import Counter from "../../components/common/count-up";
import { Link } from "react-router-dom";
import "./mentorlanding.css";

const service_data = [
  {
    bg: "blue-bg-4",
    icon: <OnlineCourse />,
    title: (
      <>
        Unlimited
        <br /> Earning
      </>
    ),
    subtitle: "Arse over tit morish wind up gormless butty.!",
  },
  {
    bg: "pink-bg",
    icon: <Support />,
    title: (
      <>
        Flexible Work
        <br /> Hours
      </>
    ),
    subtitle: "Arse over tit morish wind up gormless butty.!",
  },
  {
    bg: "purple-bg",
    icon: <Chat />,
    title: (
      <>
        One Stop
        <br /> Platform
      </>
    ),
    subtitle: "Arse over tit morish wind up gormless butty.!",
  },
  {
    bg: "green-bg",
    icon: <Research />,
    title: (
      <>
        Build Your
        <br /> Curriculum
      </>
    ),
    subtitle: "Arse over tit morish wind up gormless butty.!",
  },
];

const about_info = {
  reviews: "8,200+",
  about_img: "/assets/img/shape/about.jpg",
  about_banner: "/assets/img/shape/about-banner.jpg",
  students_imgs: [
    "/assets/img/shape/student-4.jpg",
    "/assets/img/shape/student-3.jpg",
    "/assets/img/shape/student-2.jpg",
    "/assets/img/shape/student-1.jpg",
  ],
  students: "4,000+",
  title: (
    <>
      Who is an ideal <br />
    </>
  ),
  shape_text: "mentor?",
  title_2: "",
  desc: "Although there are no set criteria that define a mentor, here are a few things that we consider",
  about_list: [
    "You have extensive experience in coaching individuals and groups on career and life skills",
    "You are an excellent communicator and a great facilitator",
    "You have great networking skills and can successfully generate your own business as a freelancer",
    "You are well accustomed with both online and classroom coaching",
  ],
};

const what_content = {
  title: "What is",
  shape_text: "Skilline?",
  desc: "Sloshed faff about me old mucker blatant bubble and squeak hanky panky some dodgy chav bevvy arse chimney pot I, ruddy plastered buggered smashing blow off I'm telling up the kyver he legged it bleeder jolly good, ",
};

const what_data = [
  {
    col: "offset-xl-1 offset-xl-1",
    thumb: "/assets/img/what/what-1.jpg",
    title: (
      <>
        Mostly <br /> Online Learning
      </>
    ),
    btn_text: "Start a class today",
  },
  {
    thumb: "/assets/img/what/what-2.jpg",
    title: (
      <>
        Become <br /> an Instructor
      </>
    ),
    btn_text: "Start a class today",
  },
];

const w_c_content = {
  sm_title: "Why Choses Me",
  title_b_s: "Tools for",
  shape_text: "Teachers",
  title_a_s: "and Learners",
  desc: "Oxford chimney pot Eaton faff about blower blatant brilliant, bubble and squeak he legged it Charles bonnet arse at public school bamboozled.",
  img: "/assets/img/why/why.png",
};

const counter_data = [
  {
    col: "col-xxl-2 offset-xxl-1 col-xl-2 offset-xl-1 col-lg-3 col-md-3 offset-md-0 col-sm-5 offset-sm-2",
    counter: 14263,
    icon: <Students />,
    icon_name: "user",
    text: "",
    title: "Students Enrolled",
  },
  {
    col: "col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-5",
    counter: 102,
    icon: <Books />,
    icon_name: "book",
    pl: "counter__pl-80",
    text: "",
    title: "Total Courses",
  },
  {
    col: "col-xxl-2 offset-xxl-0 col-xl-3 offset-xl-0 col-lg-3 offset-lg-0 col-md-3 offset-md-0 col-sm-5 offset-sm-2",
    counter: 983,
    icon: <Learners />,
    icon_name: "graduate",
    pl: "counter__pl-34",
    text: "",
    title: "Online Learners",
  },
  {
    col: "col-xxl-2 offset-xxl-1 col-xl-3 col-lg-3 col-md-3 col-sm-5",
    counter: 8,
    icon: <Followers />,
    icon_name: "globe",
    text: "K",
    title: "Foreign Followers",
  },
];

const counter_content = {
  title: "We are",
  shape_text: "Proud",
  subtitle: "",
};

const MentorsLanding = () => {
  return (
    <Wrapper>
      <SEO pageTitle={"Become Mentor"} />
      <section className="hero__area hero__height hero__height-2 d-flex align-items-center blue-bg-3 p-relative fix">
        <div className="hero__shape">
          <img
            className="hero-1-circle"
            src="/assets/img/shape/hero/hero-1-circle.png"
            alt=""
          />
          <img
            className="hero-1-circle-2"
            src="/assets/img/shape/hero/hero-1-circle-2.png"
            alt=""
          />
          <img
            className="hero-1-dot-2"
            src="/assets/img/shape/hero/hero-1-dot-2.png"
            alt=""
          />
        </div>
        <div className="container">
          <div className="hero__content-wrapper mt-90">
            <div className="row align-items-center">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <div className="hero__content hero__content-2 p-relative z-index-1">
                  <h3
                    style={{ fontSize: 45 }}
                    className="hero__title hero__title-2"
                  >
                    Apply your training frameworks, assessments, and live
                    exercises to
                    <br />
                    <span className="yellow-shape">
                      transform careers
                      <img
                        src="/assets/img/shape/yellow-bg.png"
                        alt="yellow-shape"
                      />
                    </span>{" "}
                  </h3>
                  <h4></h4>

                  <Link
                    // to="/mentor-signup"
                    to="/mentor-enquiry-form"
                    className="e-btn e-btn-3 mt-30 mr-30"
                  >
                    Enquire Now
                  </Link>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <div className="hero__thumb-wrapper mb--120">
                  <div className="hero__thumb-2 scene">
                    <img
                      style={{ minHeight: 600 }}
                      className="hero-big"
                      src={hero_two.img}
                      alt=""
                    />
                    <img
                      className="hero-shape-purple"
                      src="/assets/img/hero/hero-2/hero-shape-purple.png"
                      alt=""
                    />
                    <div className="desktop">
                      <div
                        className="hero__promotion d-flex white-bg layer"
                        data-depth="0.1"
                      >
                        <div className="hero__promotion-icon inspiration mr-10">
                          <span>
                            <Promotion />
                          </span>
                        </div>
                        <div className="hero__promotion-text">
                          <h5>{hero_two.hero_thumb_title}</h5>
                          <p>{hero_two.hero_thumb_text}</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="hero__promotion education d-none d-lg-flex white-bg layer"
                      data-depth="0.2"
                    >
                      <div className="hero__promotion-icon mr-10">
                        <span className="cap">
                          <EduCap />
                        </span>
                      </div>
                      <div className="hero__promotion-text">
                        <h5>{hero_two.hero_thumb_text_2}</h5>
                      </div>
                    </div>
                    <div
                      className="hero__class d-none d-lg-flex layer"
                      data-depth="0.3"
                    >
                      <div className="hero__class-thumb mr-15">
                        <img
                          style={{ padding: 2 }}
                          src="/assets/img/logo/GH1.png"
                          alt=""
                        />
                      </div>
                      <div className="hero__class-text">
                        <h5>{hero_two.hero_thumb_title_2}</h5>
                        <p>{hero_two.hero_thumb_text_3}</p>
                        {/* <Link to="/mentor-enquiry-form">Enquire Now</Link> */}
                      </div>
                    </div>
                    <div className="hero__mic">
                      <span>
                        <Mic />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services__area pt-115 pb-40">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3">
              <div className="section__title-wrapper section-padding mb-60 text-center">
                <h2 className="section__title">
                  Why{" "}
                  <span className="yellow-bg">
                    Us <img src="/assets/img/shape/yellow-bg-2.png" alt="" />
                  </span>{" "}
                  ?
                </h2>
                <p>
                  Our platform comes loaded with everything that you need for
                  coaching. As a mentor you avail all our premium content, tools
                  and assessments for FREE to mentor your candidates. You can
                  also choose to customize your curriculum. Our countless
                  benefits include
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {service_data.map((item, index) => (
              <div
                key={index}
                className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6"
              >
                <div className={`services__item ${item.bg} mb-30`}>
                  <div className="services__icon">{item.icon}</div>
                  <div className="services__content">
                    <h3 className="services__title">{item.title}</h3>
                    {/* <p>{item.subtitle}</p> */}
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* <section className="what__area pt-115">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
              <div className="section__title-wrapper mb-60 text-center">
                <h2 className="section__title">{what_content.title} <span className="yellow-bg-big">
                  {what_content.shape_text} <img src="/assets/img/shape/yellow-bg-2.png" alt="" /></span>
                </h2>
                <p>{what_content.desc}</p>
              </div>
            </div>
          </div>
          <div className="row">
            {what_data.map((item, index) => (
              <div key={index} className={`col-xxl-5 col-xl-5 col-lg-6 ${item.col ? item.col : ''}`}>
                <div className="what__item transition-3 mb-30 p-relative fix">
                  <div className="what__thumb w-img">
                    <img src={item.thumb} alt="" />
                  </div>
                  <div className="what__content p-absolute text-center">
                    <h3 className="what__title white-color">{item.title}</h3>
                    <Link to="/contact" className="e-btn e-btn-border-2">{item.btn_text}</Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      <section className="why__area pt-125">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">
              <div className="why__thumb">
                <img src="/assets/img/hero/22.png" alt="" />
                <img
                  className="why-green"
                  src="/assets/img/why/why-shape-green.png"
                  alt=""
                />
                <img
                  className="why-pink"
                  src="/assets/img/why/why-shape-pink.png"
                  alt=""
                />
                <img
                  className="why-dot"
                  src="/assets/img/why/why-shape-dot.png"
                  alt=""
                />
                <img
                  className="why-line"
                  src="/assets/img/why/why-shape-line.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-xxl-5 offset-xxl-1 col-xl-5 offset-xl-1 col-lg-6 col-md-8">
              <div className="why__content pr-50 mt-40">
                <div className="section__title-wrapper mb-30">
                  <h2 className="section__title">
                    How do we{" "}
                    <span className="yellow-bg yellow-bg-big">
                      reward{" "}
                      <img src="/assets/img/shape/yellow-bg.png" alt="" />
                    </span>
                    our mentors?
                  </h2>
                  <p>
                    We believe in rewarding our mentors in a way that no other
                    platform does. That’s why we don’t limit your earning, we
                    directly go for revenue sharing
                  </p>
                </div>
                <div className="why__btn">
                  <Link
                    // to="/mentor-signup"
                    to="/mentor-enquiry-form"
                    className="e-btn e-btn-3 mr-30"
                  >
                    Join for Free
                  </Link>
                  {/* <Link to="/mentor-enquiry-form" className="link-btn">
                    Learn More
                    <i className="far fa-arrow-right"></i>
                    <i className="far fa-arrow-right"></i>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="why__area pt-125">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xxl-5 offset-xxl-1 col-xl-5 offset-xl-1 col-lg-6 col-md-8">
              <div className="why__content pr-50 mt-40">
                <div className="section__title-wrapper mb-30">
                  <h2 className="section__title">
                    {" "}
                    How do{" "}
                    <span className="yellow-bg yellow-bg-big">
                      Mentors design{" "}
                      <img src="/assets/img/shape/yellow-bg.png" alt="" />
                    </span>{" "}
                    their program?
                  </h2>
                  <p>
                    Our mentors have the flexibility to design their own
                    programs. They can choose modules from our platform and also
                    add their own. We want our mentors to provide learners
                    various options to choose from, based on their unique needs.
                    Programs typically vary from 8 to 24 hours of live
                    interaction broken down into multiple sessions.{" "}
                  </p>
                </div>
                <div className="why__btn">
                  {/* <Link to="/mentor-signup" className="e-btn e-btn-3 mr-30">
                    Join for Free
                  </Link> */}
                  <Link to="/mentor-enquiry-form" className="link-btn">
                    Enquire Now
                    <i className="far fa-arrow-right"></i>
                    <i className="far fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">
              <div className="why__thumb">
                <img src="/assets/img/hero/14.png" alt="" />
                <img
                  className="why-green"
                  src="/assets/img/why/why-shape-green.png"
                  alt=""
                />
                <img
                  className="why-pink"
                  src="/assets/img/why/why-shape-pink.png"
                  alt=""
                />
                <img
                  className="why-dot"
                  src="/assets/img/why/why-shape-dot.png"
                  alt=""
                />
                <img
                  className="why-line"
                  src="/assets/img/why/why-shape-line.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`about__area pt-90 pb-150`}>
        <div className="container">
          <div className="row">
            <div className="col-xxl-5 offset-xxl-1 col-xl-6 col-lg-6">
              <div className="why__thumb">
                <img src="/assets/img/hero/9.png" alt="" />
                <img
                  className="why-green"
                  src="/assets/img/why/why-shape-green.png"
                  alt=""
                />
                <img
                  className="why-pink"
                  src="/assets/img/why/why-shape-pink.png"
                  alt=""
                />
                <img
                  className="why-dot"
                  src="/assets/img/why/why-shape-dot.png"
                  alt=""
                />
                <img
                  className="why-line"
                  src="/assets/img/why/why-shape-line.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6">
              <div className="about__content pl-70 pr-60 pt-25">
                <div className="section__title-wrapper mb-25">
                  <h2 className="section__title">
                    {about_info.title}
                    <span className="yellow-bg-big">
                      {about_info.shape_text}{" "}
                      <img src="/assets/img/shape/yellow-bg-2.png" alt="" />
                    </span>{" "}
                    {about_info.title_2}{" "}
                  </h2>
                  <p>{about_info.desc}</p>
                </div>
                <div className="about__list mb-35">
                  <ul>
                    {about_info.about_list.map((list, index) => (
                      <li key={index} className="d-flex align-items-center">
                        <i className="icon_check"></i> {list}
                      </li>
                    ))}
                  </ul>
                </div>
                <Link
                  //  to="/mentor-signup"
                  to="/mentor-enquiry-form"
                  className="e-btn e-btn-border"
                >
                  apply now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default MentorsLanding;
