import axios from "axios";
import config from "./config";
import { getDataFromLocalStorage } from "../browser-storage";
import { LocalStorageConstants } from "./localstorageconstants";

const getToken = () => getDataFromLocalStorage(LocalStorageConstants.TOKEN);

const getConfig = async () => {
  try {
    const response = await fetch("/config.json"); // Adjust the path based on your project structure
    const config = await response.json();
    return config;
  } catch (error) {
    console.error("Error fetching configuration:", error);
    throw error;
  }
};

const getWhiteLabelConfig = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/whitelabel/get-whitelabel-details-by-hostname`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const addWhiteLabelConfig = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/whitelabel/add-whitelabel-master-config`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const GetAllWhiteLabelListing = (): Promise<any> => {
  return axios
    .get(
      `${config.PROTOCOL}${config.HOST}/whitelabel/get-all-whitelabel-listing`
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const UpdateWhiteLabelConfig = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .put(
      `${config.PROTOCOL}${config.HOST}/whitelabel/update-whitelabel-details-by-hostname`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
export {
  getWhiteLabelConfig,
  addWhiteLabelConfig,
  GetAllWhiteLabelListing,
  UpdateWhiteLabelConfig,
};
