import React, { useEffect, useState } from "react";
import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import YouTube from "react-youtube";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import "./learning.css";
const Video = styled.iframe`
  display: flex;
  align-items: center;
  border: none;
  max-width: 490px;
  width: 100%;
  height: 100%;
  max-height: 276px;
  min-height: 276px;
  box-shadow: 9px 14px 15px rgba(0, 0, 0, 0.25);
`;

const VideoBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const ViewSingleCourse = () => {
  const location = useLocation();
  const [currentvidetoplay, setCurrentvideotoplay] = useState({
    title: "",
    videoCode: "",
  });

  const [courseDetails, setCourseDetails] = useState({
    courseTitle: "",
    courseDescription: "",
    courseCreatedBy: "",
    courseCategory: "",
    courseDate: "",
    coursePrice: "",
    courseLiked: "",
    courseShared: "",
  });
  const [youtubePlaylist, setyoutubePlaylist] = useState([]);
  useEffect(() => {
    console.log(location, " useLocation Hook");

    const SingleCourse = location.state?.data;
    console.log("SingleCourse", SingleCourse);
    const resyoutubePlaylist = SingleCourse.course_videos;

    setCourseDetails({
      courseTitle: SingleCourse.course_title,
      courseDescription: SingleCourse.course_description,
      courseCreatedBy: SingleCourse.course_createdby_mentor,
      courseCategory: SingleCourse.course_category,
      courseDate: SingleCourse.course_created_date,
      coursePrice: SingleCourse.course_price,
      courseLiked: SingleCourse.course_liked,
      courseShared: SingleCourse.course_shared,
    });
    console.log("resyoutubePlaylist", resyoutubePlaylist);
    for (let video of resyoutubePlaylist) {
      setCurrentvideotoplay({
        title: video.video_title,
        videoCode: video.video_embed_code,
      });
      break;
    }
    setyoutubePlaylist(resyoutubePlaylist);
  }, []);
  const opts = {
    width: 1024,
    height: 600,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  let _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  const setSeletedvideotoplay = (item) => {
    console.log("item", item);
    setCurrentvideotoplay({
      title: item.video_title,
      videoCode: item.video_embed_code,
    });
  };
  return (
    <Wrapper>
      <SEO pageTitle={"View Course Detials"} />
      <Card className="videoMainCard desktop">
        <Col sm={12}>
          <Row>
            <Col style={{ width: "69%" }} sm={9}>
              <div className="desktop">
                <YouTube
                  className="cusmobilevideo"
                  videoId={currentvidetoplay.videoCode}
                  opts={opts}
                  onReady={_onReady}
                />
              </div>
            </Col>
            <Col style={{ width: "31%" }} sm={3}>
              <div className="divscroll">
                {youtubePlaylist.map((item) => {
                  return (
                    <>
                      <Card
                        onClick={() => setSeletedvideotoplay(item)}
                        className="playlistcard"
                      >
                        <Row className="singleCard">
                          <Col sm={1}>
                            <img
                              width="25px"
                              src="/assets/img/common/redplaybtn.svg"
                              alt="Youtube Play"
                            />
                          </Col>
                          <Col>
                            <p className="singlevideoList">
                              {item.video_title}
                            </p>
                          </Col>
                        </Row>
                      </Card>
                    </>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Col>
        <div className="videoMainCard">
          <h2 className="aboutcoursetitle">About Course</h2>
          <p className="coursetitle">{courseDetails.courseTitle}</p>
          <p className="mentornametitle">{courseDetails.courseCreatedBy}</p>
          {/* area for label */}
          <Row>
            <div className="labelContainer">
              {courseDetails.courseCategory ? (
                <div className="coursetransparentlabelitem">
                  <i class="fa-solid fa-briefcase"></i>&nbsp;{" "}
                  {courseDetails.courseCategory}
                  &nbsp;
                </div>
              ) : null}
              {courseDetails.courseDate ? (
                <div className="coursetransparentlabelitem">
                  <i class="fa-solid fa-calendar-days"></i>&nbsp;
                  {courseDetails.courseDate}
                </div>
              ) : null}
              {courseDetails.coursePrice ? (
                <div className="coursetransparentlabelitem">
                  <i class="fa-solid fa-indian-rupee-sign"></i>&nbsp;
                  {courseDetails.coursePrice}
                </div>
              ) : null}
              {courseDetails.courseLiked ? (
                <div className="coursetransparentlabelitem">
                  <i class="fa-solid fa-location-dot"></i>&nbsp;
                  {courseDetails.courseLiked}
                </div>
              ) : null}

              {courseDetails.courseShared ? (
                <div className="coursetransparentlabelitem">
                  <i class="fa-solid fa-graduation-cap"></i>&nbsp;
                  {courseDetails.courseShared}
                </div>
              ) : null}
            </div>
          </Row>
          <p>{courseDetails.courseDescription}</p>
        </div>
      </Card>
      <Card className="mobile">
        <div>
          <VideoBlock className="">
            <Video
              src={`https://www.youtube.com/embed/${currentvidetoplay.videoCode}`}
            />
          </VideoBlock>
        </div>
        <div className="divscroll">
          {youtubePlaylist.map((item) => {
            return (
              <>
                <Card
                  onClick={() => setSeletedvideotoplay(item)}
                  className="playlistcard"
                >
                  <Row className="singleCard">
                    <Col sm={1}>
                      <img
                        width="25px"
                        src="/assets/img/common/redplaybtn.svg"
                        alt="Youtube Play"
                      />
                    </Col>
                    <Col>
                      <p className="singlevideoList">{item.video_title}</p>
                    </Col>
                  </Row>
                </Card>
              </>
            );
          })}
        </div>
        <div className="videoMainCard">
          <h2 className="aboutcoursetitle">About Course</h2>
          <p className="coursetitle">{courseDetails.courseTitle}</p>
          <p className="mentornametitle">{courseDetails.courseCreatedBy}</p>
          {/* area for label */}
          <Row>
            <div className="labelContainer">
              {courseDetails.courseCategory ? (
                <div className="coursetransparentlabelitem">
                  <i class="fa-solid fa-briefcase"></i>&nbsp;{" "}
                  {courseDetails.courseCategory}
                  &nbsp;
                </div>
              ) : null}
              {courseDetails.courseDate ? (
                <div className="coursetransparentlabelitem">
                  <i class="fa-solid fa-calendar-days"></i>&nbsp;
                  {courseDetails.courseDate}
                </div>
              ) : null}
              {courseDetails.coursePrice ? (
                <div className="coursetransparentlabelitem">
                  <i class="fa-solid fa-indian-rupee-sign"></i>&nbsp;
                  {courseDetails.coursePrice}
                </div>
              ) : null}
              {courseDetails.courseLiked ? (
                <div className="coursetransparentlabelitem">
                  <i class="fa-solid fa-location-dot"></i>&nbsp;
                  {courseDetails.courseLiked}
                </div>
              ) : null}

              {courseDetails.courseShared ? (
                <div className="coursetransparentlabelitem">
                  <i class="fa-solid fa-graduation-cap"></i>&nbsp;
                  {courseDetails.courseShared}
                </div>
              ) : null}
            </div>
          </Row>
          {/* <p>{courseDetails.courseDescription}</p> */}
          <div
            style={{ color: "black!important" }}
            dangerouslySetInnerHTML={{
              __html: courseDetails.courseDescription,
            }}
          />
        </div>
      </Card>
    </Wrapper>
  );
};

export default ViewSingleCourse;
