import Footer from "./footers/footer";
import Header from "./headers/header";
const Wrapper = ({ children }) => {
  return (
    <>
      {/* header start */}
      <Header header_shadow={true} />
      {/* header end */}

      {children}

      {/* footer area start */}
      <Footer pt="pt-90" />
      {/* footer area end */}
    </>
  );
};

export default Wrapper;
