import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import {
  getQuickInterviewDetailsbyId,
  obtainCognicueToken,
  quickInterviewToken,
} from "../../services/quick-interview";
import config from "../../services/config";
const AIInterviewPage = () => {
  const startAIinterview = () => {
    quickInterviewToken().then((token) => {
      //REPLEACED QUICK_INTERVIEW WITH COGNICUE_CREATE_STUDENT_INTERVIEW
      window.location.href = config.COGNICUE_CREATE_STUDENT_INTERVIEW + token;
    });
  };
  const accessAIInterviewReport = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 4a4dc12a68044c94e6bd351a707de2efb3301692"
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://services.cognicue.in/api/token/obtain/", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        const json_result = JSON.parse(result);
        console.log("json_result", json_result);
        const access_resp = json_result.access;
        console.log("access_resp", access_resp);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <Wrapper>
      <SEO pageTitle={"AI Interview"} />
      <div className="row pt-20 pb-20">
        <div className="col-md-3">
          <button className="e-btn" onClick={() => startAIinterview()}>
            Take Interview
          </button>
        </div>
      </div>
      <div className="row pt-20 pb-20">
        <div className="col-md-3">
          <button className="e-btn" onClick={() => accessAIInterviewReport()}>
            View Interview Report
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default AIInterviewPage;
