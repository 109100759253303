import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import ExportAppliedbyJobs from "./exportappliedbyjobs";

const ShowAppliedStudents = () => {
  return (
    <Wrapper>
      <SEO pageTitle={"Show Applied Students"} />
      <ExportAppliedbyJobs />
    </Wrapper>
  );
};

export default ShowAppliedStudents;
