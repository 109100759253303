import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const available_assessmentSlice = createSlice({
  name: "available_assessment",
  initialState,
  reducers: {
    GET_AVAILABLE_ASSESSMENT(state = [], action) {
      return [...action.payload];
    },
    GET_MODIFIED_AVAILABLE_ASSESSMENT(state = [], action) {
      return [...action.payload];
    },
  },
});

export const { GET_AVAILABLE_ASSESSMENT, GET_MODIFIED_AVAILABLE_ASSESSMENT } =
  available_assessmentSlice.actions;

export default available_assessmentSlice.reducer;
