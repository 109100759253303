import { useState } from "react";
import { useSelector } from "react-redux";
//import { RootState } from 'redux/store'
import { ModalType } from "../../../../../redux/types/generic-constant";
import styled from "styled-components";

import AddEditSkillModal from "./add-edit-skills-modal";
import "../../profile.css";
const SkillsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 19px 21px;
  gap: 36px;
  color: rgba(61, 41, 36, 0.8) !important;
  background: #ffffff;
  box-shadow: 10px 30px 40px rgba(221, 219, 214, 0.46);
  border-radius: 3px;
`;

const SkillsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Poppins-Regular;
  font-weight: 600;
  font-size: 20px;
  img {
    cursor: pointer;
  }
`;

const SkillTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  &:hover {
    img {
      opacity: 1 !important;
    }
  }
  img {
    height: 21px;
    cursor: pointer;
    opacity: ${({ idx }) => (idx === 0 ? 1 : 0)};
  }
`;

const SkillNameContainer = styled.div`
  font-family: Poppins-Regular;
  font-size: 12px;
  color: #635450;
  width: 100%;
  max-width: 88px;
`;

const SkillProgressBarContainer = styled.div`
  margin-top: 4px;
  width: 100%;
  height: 12px;
  max-width: 100px;
  background: #c4c4c4;
  border-radius: 2px;
`;

const SkillProgressBar = styled.div`
  width: ${({ progress }) => progress || 0}%;
  transition: width 1s;
  height: 12px;
  background: #17b978;
  border-radius: 2px;
`;

const ProgressBarForegroundWrapper = styled.div`
  margin-top: -12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 12px;
  max-width: 100px;
  border-radius: 2px;
  background: transparent;
  div:nth-child(1) {
    border-left-width: 3.5px;
    border-radius: 2px 0 0 2px;
  }
  div:last-child {
    border-right-width: 3.5px;
    border-radius: 0 2px 2px 0;
  }
`;

const ProgressForegroundBar = styled.div`
  width: 20%;
  border: 2px solid white;
  background: transparent;
`;

const SkillProgress = ({ skills = [], setOpenModalType, setSelectedIndex }) => (
  <>
    {skills.map(({ name, rating }, idx) => (
      <SkillTabContainer key={idx} idx={idx}>
        <SkillNameContainer>{name}</SkillNameContainer>
        <SkillProgressBarContainer>
          <SkillProgressBar progress={rating} />
          <ProgressBarForegroundWrapper>
            <ProgressForegroundBar />
            <ProgressForegroundBar />
            <ProgressForegroundBar />
            <ProgressForegroundBar />
            <ProgressForegroundBar />
          </ProgressBarForegroundWrapper>
        </SkillProgressBarContainer>
        <img
          src={"/assets/img/ghimages/edit-icon.svg"}
          alt="edit"
          onClick={() => {
            setOpenModalType(ModalType.EDIT);
            setSelectedIndex(idx);
          }}
        />
      </SkillTabContainer>
    ))}
  </>
);

const Skills = () => {
  const skills = useSelector((state) => state.profile.skills);
  const [openModalType, setOpenModalType] = useState(undefined);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  return (
    <>
      <SkillsContainer>
        <SkillsHeader>
          Skills
          <img
            src={"/assets/img/ghimages/add-icon.svg"}
            alt=""
            onClick={() => setOpenModalType(ModalType.ADD)}
          />
        </SkillsHeader>
        <SkillProgress
          skills={skills}
          setOpenModalType={setOpenModalType}
          setSelectedIndex={setSelectedIndex}
        />
      </SkillsContainer>
      {openModalType && (
        <AddEditSkillModal
          closeAction={() => {
            setOpenModalType(undefined);
            setSelectedIndex(-1);
          }}
          forEdit={openModalType === ModalType.EDIT}
          selectedIndex={selectedIndex}
        />
      )}
    </>
  );
};

export default Skills;
