export const initialBasicDetails = {
  firstname: "",
  middlename: "",
  lastname: "",
  degree: "",
  phonenumber: "",
  profileImgUrl: "blank",
  email: "",
  city: "",
  pincode: "",
  linkedin: "",
};

export const initialAboutMe = [{ Aboutme: "" }];

export const initialWorkExperience = [
  {
    startYear: "",
    startMonth: "",
    endYear: "",
    endMonth: "",
    companyName: "",
    location: "",
    designationName: "",
    duration: "",
    description: [],
  },
];

export const initialEducation = [
  {
    startYear: "",
    startMonth: "",
    endYear: "",
    endMonth: "",
    courseName: "",
    degreeName: "",
    collegeName: "",
    score: "",
  },
];

export const initialCertification = [
  {
    certificateName: "",
    certificateSourceName: "",
    certificateLink: "",
  },
];

export const initialSkills = [{ skillname: "" }];

export const initialAwards = [{ awardname: "", awardedby: "" }];

export const initialLanguages = [{ langugename: "", languagerating: "" }];
