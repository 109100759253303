import React, { useEffect, useState, useRef, useMemo } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";

import "../admin-portal/admin.css";
import {
  AppliedbyJobListing,
  GetJobsbyRecuiterId,
} from "../../services/jobs-service";
import {
  setDataInLocalStorage,
  getDataFromLocalStorage,
} from "../../browser-storage";
import { CSVLink, CSVDownload } from "react-csv";
import { useReactToPrint } from "react-to-print";
import Wrapper from "../../layout/wrapper";
import RecuiterSliderMenu from "./recuiterslidermenu";
const AppliedStudentsList = () => {
  const csvLink = useRef();
  const [jobList, setjobList] = useState([]);
  const [selectedjob, setSelectedJob] = useState({
    jobCode: "",
    jobTitle: "",
  });
  const [appliedbylist, setAppliedbyList] = useState([]);
  useEffect(() => {
    getALLJobListing();
  }, []);

  const getALLJobListing = async () => {
    const userDetials = getDataFromLocalStorage("user-details");
    console.log(userDetials);
    const currentrole = userDetials.role;
    const currentemail = userDetials.email;
    const resp = await GetJobsbyRecuiterId(currentemail)
      .then((res) => {
        let reverseres = res.reverse();
        setjobList(reverseres);
        console.log(jobList);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(jobList);
  };

  let AppliedbyJobHandler = (item) => {
    console.log(item);
    setSelectedJob({ jobCode: item.job_code, jobTitle: item.job_title });
    const job_code = item.job_code;
    const response = AppliedbyJobListing(job_code).then((resp) => {
      console.log("Response from appliedbyjob API", resp);
      const arrUniq = [
        ...new Map(
          resp.map((v) => [JSON.stringify([v.job_code, v.appliedby]), v])
        ).values(),
      ];
      console.log("Test arrUniq", arrUniq);
      setAppliedbyList(arrUniq);
    });

    console.log("respose", response);
  };

  const ExportDataICSV = async () => {
    csvLink.current.link.click();
  };
  const componentRef = useRef();
  let handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [currentselectedjob, setCurrentSelectedJob] = useState();
  const jobChangeHandler = async (e) => {
    console.log("e.target.value", e.target.value);
    let selectedJob = e.target.value;
    console.log("appliedbyAllUser", appliedbylist);
    setCurrentSelectedJob(selectedJob);
    let result = appliedbylist.filter(function (item) {
      console.log(item.job_code, selectedJob);
      return item.job_code == selectedJob;
    });
  };
  return (
    <>
      <Wrapper>
        <RecuiterSliderMenu />
        <div style={{ margin: 30 }}>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8">
            <div className="section__title-wrapper mb-45">
              <h2 className="section__title">
                Applied{" "}
                <span className="yellow-bg">
                  Students{" "}
                  <img src="/assets/img/shape/yellow-bg-2.png" alt="" />
                </span>
                List
              </h2>
            </div>
          </div>
          <Card style={{ marginBottom: 100 }} className="MainCard">
            {jobList.map((item, index) => {
              return (
                <Card className="carddesign">
                  <Container className="jobcontainer">
                    <Row>
                      <Col sm={1}>{index + 1}</Col>
                      <Col>
                        {" "}
                        <b className="boldtitle">{item.job_title}</b>
                      </Col>
                      <Col>
                        <button
                          style={{ height: 30, lineHeight: 0 }}
                          className="e-btn"
                          onClick={() => AppliedbyJobHandler(item)}
                        >
                          Applied By
                        </button>
                      </Col>
                    </Row>
                  </Container>
                </Card>
              );
            })}
          </Card>
          {/* <Card>
        <Form style={{ marginTop: 0, width: "100%" }}>
          <Form.Control
            as="select"
            onChange={(e) => jobChangeHandler(e)}
            defaultValue="Choose....."
          >
            {jobList.map((job, i) => (
              <option key={i} value={job.job_code}>
                {job.job_title} - {job.listed_by} - {job.location}
              </option>
            ))}
          </Form.Control>
        </Form>
      </Card> */}
          <Card>
            <CSVLink
              data={appliedbylist}
              filename="transactions.csv"
              className="hidden"
              ref={csvLink}
              target="_blank"
            />
            <Row>
              <Col sm={3}>
                <button className="e-btn" onClick={ExportDataICSV}>
                  Download Student List in CSV Format
                </button>
              </Col>
              <Col>
                <button className="e-btn" onClick={handlePrint}>
                  Download Student List in PDF Format{" "}
                  <i class="fa-solid fa-file-pdf"></i>
                </button>
              </Col>
            </Row>

            <Table ref={componentRef} striped bordered hover>
              <thead>
                <tr>
                  {/* <th>Job Code</th> */}
                  <th>Job Title</th>
                  <th>Applied by</th>
                  <th>Applied Date</th>
                  <th>Mobile No.</th>
                  <th>College Name</th>
                  <th>Degree</th>
                  <th>Soft Skill score</th>
                  <th>Aptitude score</th>
                  <th>AI interview Score</th>
                  <th>Resume Link</th>
                </tr>
              </thead>
              <tbody>
                {appliedbylist.map((item) => {
                  return (
                    <tr>
                      {/* <td>{item.job_code}</td> */}
                      <td>{item.job_title}</td>
                      <td>{item.appliedby}</td>
                      <td>{item.appliedbydate}</td>
                      <td>{item.mobile}</td>
                      <td>{item.collegename}</td>
                      <td>{item.degreecode}</td>
                      <td>{item.appliedbysoftscore}</td>
                      <td>{item.appliedbyaptitudescore}</td>
                      <td>{item.appliedbyaiscore}</td>
                      <td>
                        {item.userresumeUrl ? (
                          <a href={item.userresumeUrl}>Download</a>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
        </div>
      </Wrapper>
    </>
  );
};

export default AppliedStudentsList;
