import styled from "styled-components";
import GreenhornScore from "./sections/greenhorn-score";
import Education from "./sections/education";
import Internship from "./sections/internship";
import Certifications from "./sections/certifications";
import Languages from "./sections/languages";
import Skills from "./sections/skills";
import BootContainer from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "./profile.css";
import React, { useEffect, useState } from "react";
import { getrewardforUser } from "../../../services/rewards-service";
import { getDataFromLocalStorage } from "../../../browser-storage";
import {
  getAllUserScore,
  getUserProfile,
} from "../../../services/user-service";
import { useDispatch } from "react-redux";
import {
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
} from "../../../redux/features/profile-slice";
import YouTube from "react-youtube";

import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "../profile/sections/greenhorn-score/greenhorn-score.css";
import "react-datepicker/dist/react-datepicker.css";
const GhScorePie = styled(CircularProgressbarWithChildren)`
  width: 170px;
  height: 170px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const GhScoreTextContainer = styled.div`
  width: 58.17px;
  height: 58.17px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #ffffff;
  font-family: Poppins-Bold;
  font-size: 28px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
`;

const OtherDetailTabContainer = styled.div`
  width: 72%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const Video = styled.iframe`
  display: flex;
  align-items: center;
  border: none;
  max-width: 490px;
  width: 100%;
  height: 100%;
  max-height: 276px;
  min-height: 276px;
  box-shadow: 9px 14px 15px rgba(0, 0, 0, 0.25);
`;

const DetailInfo = () => {
  const dispatch = useDispatch();
  const [softskillscore, setsoftskillscore] = useState([]);
  const [aptitudescore, setaptitudescore] = useState([]);
  const [aptitudescorelist, setaptitudescorelist] = useState([]);
  const [finalaptiscore, setFinalAptiScore] = useState("");
  const [FinalScore, setFinalScore] = useState(0);
  useEffect(() => {
    const userDetials = getDataFromLocalStorage("user-details");
    console.log(userDetials);
    const email = userDetials.email;
    GetAllUserData();
    GetAllScoreFunction(email);
    GetStudentReward(email);
  }, []);

  const GetAllUserData = () => {
    const ProfileResponse = getUserProfile()
      .then((resp) => {
        console.log(resp);
        dispatch(GET_USER_PROFILE(resp));
        dispatch(UPDATE_USER_PROFILE(resp));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const findMax = (arr: any) => {
    var largest = arr[0];

    for (var i = 0; i < arr.length; i++) {
      if (largest < arr[i]) {
        largest = arr[i];
      }
    }
    console.log(largest);

    setFinalAptiScore(largest);
    return largest;
  };

  const GetAllScoreFunction = async (email: any) => {
    const getAllUserData = await getAllUserScore(email)
      .then((resp) => {
        console.log(resp);
        const scoregivenbymentor = resp.ScoreGivenByMentor;
        //const aiinterviewscore = resp.aiinterviewscore;
        const all_test_response = resp.test_response;

        console.log("all_test_response", all_test_response);
        const SoftArr: any = [];
        const AptiArr = [];
        const AptiScore = [];
        for (let test of all_test_response) {
          if (test.result.totalQues == 20) {
            console.log(test);
            SoftArr.push(test);
            //setsoftskillscore(test);
          } else {
            //setaptitudescorelist(test);
            AptiArr.push(test);
            AptiScore.push(test.result.rightAns);
          }
        }
        setsoftskillscore(SoftArr);
        console.log("softskillscore", softskillscore);
        console.log("SoftArr", SoftArr);
        console.log("aptitudescore", aptitudescore);
        console.log("AptiArr", AptiArr);
        console.log("AptiScore", AptiScore);

        let findMaxtemp = findMax(AptiScore);
        //console.log("findMaxtemp", findMaxtemp);
        console.log("scoregivenbymentor", scoregivenbymentor);
        //console.log("aiinterviewscore", aiinterviewscore);
        let finalAptiScoreCalc: any;
        console.log(findMaxtemp);
        if (findMaxtemp) {
          //afer sir said
          // finalAptiScoreCalc = Number(findMaxtemp) * 5 * 0.3;
          finalAptiScoreCalc = findMaxtemp;
        } else {
          finalAptiScoreCalc = 0;
        }
        let finalSoftScore: any;
        if (SoftArr[0]) {
          finalSoftScore = Number(SoftArr[0].result.score) * 0.2;
        } else {
          finalSoftScore = 0;
        }
        let finalMentorScore: any;
        if (scoregivenbymentor) {
          finalMentorScore = Number(scoregivenbymentor);
        } else {
          finalMentorScore = 0;
        }
        console.log("finalSoftScore", finalSoftScore);
        console.log("finalAptiScoreCalc", finalAptiScoreCalc);
        console.log("scoregivenbymentor", scoregivenbymentor);
        console.log("Number(scoregivenbymentor)", Number(scoregivenbymentor));
        const FinalScore =
          Number(scoregivenbymentor) +
          Number(finalSoftScore) +
          Number(finalAptiScoreCalc);
        console.log(FinalScore);
        setFinalScore(FinalScore);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(getAllUserData);
  };

  const GetStudentReward = (email: any) => {
    const getRewardBalance = getrewardforUser(email)
      .then((resp) => {
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(getRewardBalance);
  };

  const opts = {
    width: "100%",
    height: "165",

    playerVars: {
      autoplay: 0,
    },
  };

  let _onReady = (event: any) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  return (
    <BootContainer style={{ marginTop: "10px", marginBottom: "50px" }}>
      <Row>
        <Col sm={3}>
          <Card className="profileCard">
            {" "}
            {/* <GreenhornScore />{" "} */}
            <div className="GhScoreContainer">
              Greenhorn Score
              <GhScorePie
                // value={ghScore}
                value={FinalScore}
                styles={buildStyles({
                  rotation: 0,
                  strokeLinecap: "round",
                  pathTransitionDuration: 0.5,
                  pathColor: `#016738`,
                  trailColor: "#d6d6d6",
                })}
              >
                <GhScoreTextContainer>
                  {Number(FinalScore.toFixed(2))}
                </GhScoreTextContainer>
              </GhScorePie>
            </div>
            {/* <div style={{ marginTop: 10 }}>
              <div className="desktop">
                <YouTube
                  className="cusmobilevideo"
                  videoId="KdSSN5q3Vls"
                  opts={opts}
                  onReady={_onReady}
                />
              </div>
              <div className="mobile">
                <Video src={`https://www.youtube.com/embed/KdSSN5q3Vls`} />
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              <div className="desktop">
                <YouTube
                  className="cusmobilevideo"
                  videoId="VbtspjcyjsI"
                  opts={opts}
                  onReady={_onReady}
                />
              </div>
              <div className="mobile">
                <Video src={`https://www.youtube.com/embed/VbtspjcyjsI`} />
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              <div className="desktop">
                <YouTube
                  className="cusmobilevideo"
                  videoId="B6z11ed2c8k"
                  opts={opts}
                  onReady={_onReady}
                />
              </div>
              <div className="mobile">
                <Video src={`https://www.youtube.com/embed/B6z11ed2c8k`} />
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              <div className="desktop">
                <YouTube
                  className="cusmobilevideo"
                  videoId="BE0BOMXR7hY"
                  opts={opts}
                  onReady={_onReady}
                />
              </div>
              <div className="mobile">
                <Video src={`https://www.youtube.com/embed/BE0BOMXR7hY`} />
              </div>
            </div> */}
          </Card>
        </Col>
        <Col sm={6}>
          <Card className="profileCard">
            <div className="OtherDetailTabContainer">
              <Education />
              <Internship />
              <Certifications />
              <Languages />
            </div>
          </Card>
        </Col>
        <Col sm={3}>
          <Card className="profileCard mobileMargin">
            <Skills />
          </Card>
          <Card
            style={{ marginTop: "10px" }}
            className="profileCard mobileMargin"
          >
            {/* <div>
              <p className="profileTitle">Rewards</p>
              <p
                className="profileTitle"
                style={{
                  paddingTop: "5px",
                  color: "#635450",
                  fontSize: "12px",
                }}
              >
                Total Reward Balance:
              </p>
            </div> */}
            {/* <div style={{ marginTop: 10 }}>
              <div className="desktop">
                <YouTube
                  className="cusmobilevideo"
                  videoId="AjNq8BVKOFc"
                  opts={opts}
                  onReady={_onReady}
                />
              </div>
              <div className="mobile">
                <Video src={`https://www.youtube.com/embed/AjNq8BVKOFc`} />
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              <div className="desktop">
                <YouTube
                  className="cusmobilevideo"
                  videoId="wfKMJMqITwg"
                  opts={opts}
                  onReady={_onReady}
                />
              </div>
              <div className="mobile">
                <Video src={`https://www.youtube.com/embed/wfKMJMqITwg`} />
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              <div className="desktop">
                <YouTube
                  className="cusmobilevideo"
                  videoId="L6MlSveKvc0"
                  opts={opts}
                  onReady={_onReady}
                />
              </div>
              <div className="mobile">
                <Video src={`https://www.youtube.com/embed/L6MlSveKvc0`} />
              </div>
            </div> */}
          </Card>
        </Col>
      </Row>
    </BootContainer>
  );
};

export default DetailInfo;
