import Card from "react-bootstrap/Card";
import YouTube from "react-youtube";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import "./homepaymentpage.css";
const Video = styled.iframe`
  display: flex;
  align-items: center;
  border: none;
  max-width: 490px;
  width: 100%;
  height: 100%;
  max-height: 276px;
  min-height: 276px;
  box-shadow: 9px 14px 15px rgba(0, 0, 0, 0.25);
`;

const ReviewVideoGallery = () => {
  const opts = {
    width: "100%",
    height: "400",

    playerVars: {
      autoplay: 0,
    },
  };

  let _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  const [currentvidetoplay, setCurrentvideotoplay] = useState({
    videoCode: "FLrSxW9v9OA",
  });
  // const setSeletedvideotoplay = (item) => {
  //   console.log("item", item);
  //   if (item == "Student1") {
  //     setCurrentvideotoplay({
  //       videoCode: "FLrSxW9v9OA",
  //     });
  //   }
  //   if (item == "Student2") {
  //     setCurrentvideotoplay({
  //       videoCode: "QqDya24v0R4",
  //     });
  //   }
  //   if (item == "GHIntro") {
  //     setCurrentvideotoplay({
  //       videoCode: "HQLEWpibxUY",
  //     });
  //   }
  // };

  return (
    <>
      <Card>
        <Card.Body>
          <div className="section__title-wrapper mb-45"></div>
          <div className="col-md-12">
            <div className="text-center">
              <div className="desktop">
                <YouTube
                  className="cusmobilevideo"
                  videoId={currentvidetoplay.videoCode}
                  opts={opts}
                  onReady={_onReady}
                />
              </div>
              <div className="mobile">
                <Video src={`https://www.youtube.com/embed/QqDya24v0R4`} />
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ReviewVideoGallery;
