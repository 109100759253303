import { AssessmentType } from '../../generic-constant'

export enum AptitudeQuestionDifficulty {
  HARD = 'HARD',
  MODERATE = 'MODERATE',
  EASY = 'EASY',
}
export enum SoftSkillQuestionType {
  // SOFT-SKILL
  SELF_AWARENESS = 'SELF_AWARENESS',
  COMMUNICATION = 'COMMUNICATION',
  PEOPLE_COLLABORATION = 'PEOPLE_COLLABORATION',
  STRESS_HANDLING = 'STRESS_HANDLING',
  // OTHER
}
export enum AptitudeQuestionType {
  // APTITUDE
  VERBAL = 'VERBAL',
  LOGICAL = 'LOGICAL',
  QUANTITATIVE = 'QUANTITATIVE',
  DATA_INTERPRETATION = 'DATA_INTERPRETATION',
  // OTHER
  // OTHER
}
export enum QuestionType {
  // APTITUDE
  VERBAL = 'Verbal',
  LOGICAL = 'Logical',
  QUANTITATIVE = 'Quantitative',
  DATA_INTERPRETATION = 'Data Interpretation',
  // OTHER
  MISCELLANEOUS = 'Miscellaneous',
}
export interface AptitudeQuestionSchema {
  questionId: string
  questionType: AptitudeQuestionType
  questionText: string
  questiontitle:string
  questionImg?: string
  options: string[]
  key?: boolean
}

export interface AptitudeTestSchema {
  questions: AptitudeQuestionSchema[]
  answerSheet: Record<string, number>
  testId: string
  assessmentType: AssessmentType
}
export interface AnswerResponse {
  attemptedQues: number
  totalQues: number
  rightAns: number
}
export interface SoftSkillAnswerResponse {
  attemptedQues: number
  totalQues: number
  score: number
}
export interface AptitudeResponse {
  difficulty: AptitudeQuestionDifficulty
  result: {
    attemptedQues: number
    rightAns: number
    totalQues: number
    type: Record<string, AnswerResponse>
  }
  testId: string
}
export interface SoftSkillResponse {
  result: {
    attemptedQues: number
    totalQues: number
    score: number
    type: Record<string, SoftSkillAnswerResponse>
  }
  testId: string
}
