import { createSlice } from "@reduxjs/toolkit";
import config from "../../services/config";
import { getDataFromSessionStorage } from "../../browser-storage";
import { SessionStorageConstants } from "../../services/localstorageconstants";
const assessmentDetails = getDataFromSessionStorage(
  SessionStorageConstants.ASSESSMENT_TEST
);

const initialState = {
  questions: assessmentDetails?.questions,
  answerSheet: assessmentDetails?.answerSheet,
  testId: assessmentDetails?.testId,
  assessmentType: assessmentDetails.assessmentType,
};

export const assessmentSlice = createSlice({
  name: "assessment",
  initialState,
  reducers: {
    ASSESSMENT_TEST_START(state, action) {
      return {
        ...state,
        questions: action.payload.questions,
        answerSheet: action.payload.answerSheet,
        testId: action.payload.testId,
        assessmentType: action.payload.assessmentType,
      };
    },
    UPDATE_ANSWER_SHEET(state, action) {
      return {
        ...state,
        answerSheet: action.payload.answerSheet,
      };
    },
    UPDATE_QUESTIONS(state, action) {
      return {
        ...state,
        questions: action.payload.questions,
      };
    },
  },
});

export const { ASSESSMENT_TEST_START, UPDATE_ANSWER_SHEET, UPDATE_QUESTIONS } =
  assessmentSlice.actions;

export default assessmentSlice.reducer;
