import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isOpen: false,
}

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    menu_bar: (state,{payload}) => {
      state.isOpen = payload
    },

  },
})

export const { menu_bar } = headerSlice.actions;
export const selectMenuBar = state => state.header.isOpen;

export default headerSlice.reducer