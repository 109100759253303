import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getDataFromLocalStorage } from "../../browser-storage";
import { toast } from "react-toastify";
import {
  GetMentorProfile,
  GetCourseByMentorId,
} from "../../services/mentor-service";
import { useLocation } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const CareerPathDetails = ({ instructor }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [SelectedCareerPath, setSelectedCareerPath] = useState("");
  useEffect(() => {
    console.log(location, " useLocation Hook");

    const CareerPathresp = location.state?.data;
    setSelectedCareerPath(CareerPathresp);
  });

  //const [isLoggedInStatus, setisLoggedInStatus] = useState(false);

  // let checkLoginStatus = async () => {
  //   const userDetials = getDataFromLocalStorage("user-details");
  //   console.log(userDetials);
  //   let role = userDetials.role;
  //   console.log("Role", role);
  //   if (role) {
  //     if (role == "STUDENT") {
  //       navigate("/student-dashboard", { replace: true });
  //     }
  //     if (role == "EMPLOYER") {
  //       console.log("employerr");
  //       navigate("/recuiter-dashboard", { replace: true });
  //     }
  //     if (role == "MENTOR") {
  //       console.log("mentor");
  //       navigate("/mentor-dashboard", { replace: true });
  //     }
  //     if (role == "ADMIN") {
  //       console.log("ADMIN");
  //       navigate("admin-dashboard", { replace: true });
  //     }
  //     if (role == "CAMPUS") {
  //       navigate("campus-dashboard", { replace: true });
  //     }
  //   }
  //   console.log("isLoggedInStatus", isLoggedInStatus);
  // };

  // useEffect(() => {
  //   checkLoginStatus();
  // }, []);

  return (
    <Wrapper>
      <>
        <section className="teacher__area pt-100 pb-110">
          <div className="page__title-shape">
            <img
              className="page-title-shape-5 d-none d-sm-block"
              src="/assets/img/page-title/page-title-shape-1.png"
              alt=""
            />
            <img
              className="page-title-shape-6"
              src="/assets/img/page-title/page-title-shape-6.png"
              alt=""
            />
            <img
              className="page-title-shape-3"
              src="/assets/img/page-title/page-title-shape-3.png"
              alt=""
            />
            <img
              className="page-title-shape-7"
              src="/assets/img/page-title/page-title-shape-4.png"
              alt=""
            />
          </div>
          <div className="container">
            {SelectedCareerPath == "HR" ? (
              <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                  <div className="teacher__details-thumb p-relative w-img pr-30">
                    <img src="/assets/img/hero/27.png" alt="" />
                    <div className="teacher__details-shape">
                      <img
                        className="teacher-details-shape-1"
                        src="/assets/img/teacher/details/shape/shape-1.png"
                        alt=""
                      />
                      <img
                        className="teacher-details-shape-2"
                        src="/assets/img/teacher/details/shape/shape-2.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xxl-8 col-xl-8 col-lg-8">
                  <div className="section__title-wrapper mb-45">
                    <h2 className="section__title">
                      {/* Which field is */}
                      What to
                      <span className="yellow-bg">
                        {" "}
                        Expect{" "}
                        <img src="/assets/img/shape/yellow-bg-2.png" alt="" />
                      </span>
                      {/* for you? */}
                    </h2>
                  </div>
                  <div className="about__list mb-35">
                    {/* <ul>
                    {about_info.about_list.map((list, index) => (
                      <li key={index} className="d-flex align-items-center">
                        <i className="icon_check"></i> {list}
                      </li>
                    ))}
                  </ul> */}
                  </div>
                  <div className="about__list mb-35">
                    <ul>
                      <li className="d-flex align-items-center">
                        <i className="icon_check"></i>Degree: Any undergraduate
                        or graduate degree is eligible
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="icon_check"></i>Skillsets required:
                        Communication, problem-solving, multi-tasking, Microsoft
                        office
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="icon_check"></i> Personality type
                        required: Someone who is outgoing and loves interacting
                        with new people
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="icon_check"></i>Your typical day in job:
                        Solve employee concerns, hire for new roles, generate
                        reports, arrange events
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="icon_check"></i>Career options: HR
                        Generalist, HR Recruiter, HR Operations Specialist
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="icon_check"></i>Salary range: Typically 4
                        to 6 lakhs
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
            {SelectedCareerPath == "Business Operations" ? (
              <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                  <div className="teacher__details-thumb p-relative w-img pr-30">
                    <img src="/assets/img/hero/19.png" alt="" />
                    <div className="teacher__details-shape">
                      <img
                        className="teacher-details-shape-1"
                        src="/assets/img/teacher/details/shape/shape-1.png"
                        alt=""
                      />
                      <img
                        className="teacher-details-shape-2"
                        src="/assets/img/teacher/details/shape/shape-2.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xxl-8 col-xl-8 col-lg-8">
                  <div className="section__title-wrapper mb-45">
                    <h2 className="section__title">
                      What to
                      <span className="yellow-bg">
                        {" "}
                        Expect{" "}
                        <img src="/assets/img/shape/yellow-bg-2.png" alt="" />
                      </span>
                    </h2>
                  </div>
                  <div className="about__list mb-35">
                    {/* <ul>
                    {about_info.about_list.map((list, index) => (
                      <li key={index} className="d-flex align-items-center">
                        <i className="icon_check"></i> {list}
                      </li>
                    ))}
                  </ul> */}
                  </div>
                  <div className="about__list mb-35">
                    <ul>
                      <li className="d-flex align-items-center">
                        <i className="icon_check"></i>Degree: Any undergraduate
                        or graduate degree is eligible
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="icon_check"></i>Skillsets required:
                        Communication, client relationship, detail-oriented,
                        Microsoft Excel, process oriented, data analysis
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="icon_check"></i> Personality type
                        required: Someone who can speak to clients politely and
                        solve their problems. Someone who is good with numbers
                        and can remain calm under pressure
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="icon_check"></i>Your typical day in job:
                        Solve customer queries, generate reports, manage
                        processes, documentation
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="icon_check"></i> Career options: Customer
                        Care, Finance, Billing, Accounting, Data Processing
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="icon_check"></i> Salary range: Typically 3
                        to 5 lakhs
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
            {SelectedCareerPath == "Sales" ? (
              <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                  <div className="teacher__details-thumb p-relative w-img pr-30">
                    <img src="/assets/img/hero/30.png" alt="" />
                    <div className="teacher__details-shape">
                      <img
                        className="teacher-details-shape-1"
                        src="/assets/img/teacher/details/shape/shape-1.png"
                        alt=""
                      />
                      <img
                        className="teacher-details-shape-2"
                        src="/assets/img/teacher/details/shape/shape-2.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xxl-8 col-xl-8 col-lg-8">
                  <div className="section__title-wrapper mb-45">
                    <h2 className="section__title">
                      What to
                      <span className="yellow-bg">
                        {" "}
                        Expect{" "}
                        <img src="/assets/img/shape/yellow-bg-2.png" alt="" />
                      </span>
                    </h2>
                  </div>
                  <div className="about__list mb-35">
                    {/* <ul>
                    {about_info.about_list.map((list, index) => (
                      <li key={index} className="d-flex align-items-center">
                        <i className="icon_check"></i> {list}
                      </li>
                    ))}
                  </ul> */}
                  </div>
                  <div className="about__list mb-35">
                    <ul>
                      <li className="d-flex align-items-center">
                        <i className="icon_check"></i>Degree: Any undergraduate
                        or graduate degree is eligible
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="icon_check"></i>Skillsets required:
                        Communication, confidence, client relationship, active
                        listening
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="icon_check"></i> Personality type
                        required: Someone who can genuinely solve a client’s
                        needs. Someone who is mentally tough, can learn about
                        new products quickly and can engage in 3-4 hours of
                        calls every day
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="icon_check"></i>Your typical day in job:
                        Generate leads, cold calling, field visits, product
                        demonstration, closing sales
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="icon_check"></i>Career options: Inside
                        Sales, Field Sales, Customer Success, Relationship
                        Management
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="icon_check"></i>Salary range: Typically 4
                        to 8 lakhs
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </section>
      </>
    </Wrapper>
  );
};

export default CareerPathDetails;
