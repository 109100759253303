import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";

import Carousel from "react-multi-carousel";
import { Link, useNavigate } from "react-router-dom";
const MentorSliderMenu = () => {
  const navigate = useNavigate();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
      slidesToSlide: 7, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <></>
    // <Carousel
    //   swipeable={false}
    //   draggable={false}
    //   showDots={true}
    //   responsive={responsive}
    //   ssr={true} // means to render carousel on server-side.
    //   infinite={false}
    //   autoPlay={false}
    //   autoPlaySpeed={4000}
    //   keyBoardControl={true}
    //   customTransition="all .5"
    //   transitionDuration={500}
    //   containerClass="carousel-container"
    //   dotListClass="custom-dot-list-style"
    //   itemClass="carousel-item-padding-40-px"
    // >
    //   <div
    //     className="icons-container"
    //     onClick={() => navigate("/mentor-profile")}
    //   >
    //     <img
    //       className=""
    //       width="100px"
    //       src="/assets/img/ghimages/graphic-icons/user-browser-login.svg"
    //       alt="Profile Icon"
    //     />
    //     <p>Profile</p>
    //   </div>
    //   <div
    //     className="icons-container"
    //     onClick={() => navigate("/manage-course")}
    //   >
    //     <img
    //       className=""
    //       width="100px"
    //       src="/assets/img/ghimages/graphic-icons/user-browser-login.svg"
    //     />
    //     <p>Manage Course</p>
    //   </div>
    // </Carousel>
  );
};

export default MentorSliderMenu;
