import { memo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  UserProfileEvent,
  AppConfigEvent,
} from "../../../../../redux/types/redux-event";
import { updateUserProfile } from "../../../../../services/user-service";
import styled from "styled-components";
import { toast } from "react-toastify";
import { UPDATE_USER_PROFILE } from "../../../../../redux/features/profile-slice";
import {
  ModalBackground,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "../../common/modal";
import {
  RequiredText,
  FieldGroupContainer,
  InputFiled,
  Error,
  ButtonGroup,
  ActionButton,
  RemoveAction,
} from "../../common";

const RangeInput = styled.input`
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
  :focus {
    outline: none;
  }
  ::-webkit-slider-runnable-track {
    cursor: pointer;
    background: #c4c4c4;
    border-radius: 5px;
    height: 9px;
  }
  ::-webkit-slider-thumb {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background: #11703b;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -14px;
  }
  ::-moz-range-thumb {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background: #11703b;
    cursor: pointer;
    -moz-appearance: none;
    margin-top: -14px;
  }
  ::-moz-range-track {
    cursor: pointer;
    background: #c4c4c4;
    border-radius: 5px;
    height: 9px;
  }
`;

const AddEditSkillsModal = ({ closeAction, forEdit, selectedIndex }) => {
  const dispatch = useDispatch();

  const skills = useSelector((state) => state.profile.skills);
  const [selectedSkillData, setSelectedSkillData] = useState(
    !skills?.length || selectedIndex < 0 || !skills[selectedIndex]
      ? { name: "", rating: 1, mandatory: false }
      : [...skills][selectedIndex]
  );

  const [formError, setFormError] = useState({
    name: "",
  });
  const [showErrors, setShowErrors] = useState(false);
  const validateData = () => {
    const formErrorData = { ...formError };
    formErrorData.name = selectedSkillData.name
      ? ""
      : "Please enter skill name.";
    setFormError(formErrorData);
    return !formErrorData.name;
  };

  const addSkill = () => {
    setShowErrors(true);
    if (!validateData()) {
      return;
    }
    const skillsData = [...skills, selectedSkillData];
    dispatch({ type: AppConfigEvent.START_LOADING });
    updateUserProfile({ skills: skillsData })
      .then((data) => {
        toast.success(`Skill added successfully`, {
          position: "top-left",
        });

        dispatch(UPDATE_USER_PROFILE({ skills: data?.skills }));
      })
      .catch(() =>
        toast.error(`Unable to add skill`, {
          position: "top-left",
        })
      )
      .finally(() => dispatch({ type: AppConfigEvent.STOP_LOADING }));
    closeAction();
  };

  const updateSkill = () => {
    setShowErrors(true);
    if (!validateData()) {
      return;
    }
    const skillsData = [...skills];
    skillsData[selectedIndex] = selectedSkillData;
    dispatch({ type: AppConfigEvent.START_LOADING });
    updateUserProfile({ skills: skillsData })
      .then((data) => {
        toast.success(`Skill updated successfully`, {
          position: "top-left",
        });
        dispatch(UPDATE_USER_PROFILE({ skills: data?.skills }));
      })
      .catch(() =>
        toast.error(`Unable to update skill`, {
          position: "top-left",
        })
      )
      .finally(() => dispatch({ type: AppConfigEvent.STOP_LOADING }));
    closeAction();
  };

  const deleteSkill = () => {
    const skillsData = [...skills];
    skillsData.splice(selectedIndex, 1);
    dispatch({ type: AppConfigEvent.START_LOADING });
    updateUserProfile({ skills: skillsData })
      .then((data) => {
        toast.success(`Skill deleted successfully`, {
          position: "top-left",
        });
        dispatch(UPDATE_USER_PROFILE({ skills: data?.skills }));
      })
      .catch(() =>
        toast.error(`Unable to delete skill`, {
          position: "top-left",
        })
      )
      .finally(() => dispatch({ type: AppConfigEvent.STOP_LOADING }));
    closeAction();
  };

  return (
    <ModalBackground>
      <Modal>
        <ModalHeader>
          Skill{" "}
          <img
            src={"/assets/img/ghimages/close-icon.svg"}
            alt="X"
            onClick={closeAction}
          />
        </ModalHeader>
        <ModalBody>
          <RequiredText>* Indicates required</RequiredText>
          <FieldGroupContainer>
            Skill/ Software *
            <InputFiled
              type="text"
              value={selectedSkillData.name}
              disabled={selectedSkillData.mandatory}
              onChange={({ target: { value } }) => {
                setSelectedSkillData({ ...selectedSkillData, name: value });
                setShowErrors(false);
              }}
            />
            {showErrors && formError.name && <Error>{formError.name}</Error>}
          </FieldGroupContainer>
          <FieldGroupContainer>
            Expertise *
            <RangeInput
              value={selectedSkillData.rating}
              type="range"
              min="1"
              max="100"
              onChange={({ target: { value } }) =>
                setSelectedSkillData({
                  ...selectedSkillData,
                  rating: Number(value),
                })
              }
            />
          </FieldGroupContainer>
        </ModalBody>
        <ModalFooter>
          {forEdit ? (
            <ButtonGroup forEdit={true}>
              <RemoveAction
                disabled={selectedSkillData.mandatory}
                onClick={() => {
                  if (!selectedSkillData.mandatory) {
                    deleteSkill();
                  }
                }}
              >
                Delete skill
              </RemoveAction>
              <ActionButton onClick={updateSkill}>Update</ActionButton>
            </ButtonGroup>
          ) : (
            <ButtonGroup forEdit={false}>
              <ActionButton onClick={addSkill}>Add</ActionButton>
            </ButtonGroup>
          )}
        </ModalFooter>
      </Modal>
    </ModalBackground>
  );
};

export default memo(AddEditSkillsModal);
