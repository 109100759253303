import { memo, useEffect } from "react";
import Intro from "./intro";
import DetailInfo from "./detail-info";
import BootContainer from "react-bootstrap/Container";
import { getUserProfile } from "../../../services/user-service";
import { useDispatch } from "react-redux";
import {
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
} from "../../../redux/features/profile-slice";
const Profile = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const ProfileResponse = getUserProfile()
      .then((resp) => {
        console.log(resp);
        dispatch(GET_USER_PROFILE(resp));
        dispatch(UPDATE_USER_PROFILE(resp));
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("ProfileResponse", ProfileResponse);
  }, []);
  return (
    <>
      <BootContainer className="MainProfileContainer">
        <Intro />
        <DetailInfo />
      </BootContainer>
    </>
  );
};

export default memo(Profile);
