import React from "react";

const ResumeBuilderFormChild = ({
  onBasicDetailsChange,
  basicDetails,
  onWorkExperienceChange,
  workExperience,
}) => {
  const handleBasicDetailsChange = (field, value) => {
    onBasicDetailsChange(field, value);
  };

  const handleWorkExperienceChange = (index, field, value) => {
    onWorkExperienceChange(index, field, value);
  };

  const basicDetailFields = [
    { label: "First Name", name: "firstname" },
    { label: "Middle Name", name: "middlename" },
    { label: "Last Name", name: "middlename" },
    { label: "Degree", name: "middlename" },
    { label: "Phone Number", name: "phonenumber" },
    { label: "Profile Image URL", name: "profileImgUrl" },
    { label: "Email", name: "email" },
    { label: "City", name: "city" },
    { label: "Pin Code", name: "pincode" },
    { label: "Linkedin", name: "linkedin" },
  ];
  return (
    <div>
      {/* Basic Details */}
      {basicDetailFields.map((field) => (
        <input
          key={field.name}
          type="text"
          value={basicDetails[field.name]}
          onChange={(e) => handleBasicDetailsChange(field.name, e.target.value)}
          placeholder={field.label}
        />
      ))}
      {/* Add more input fields for other basic details properties */}

      {/* Work Experience */}
      {workExperience.map((experience, index) => (
        <div key={index}>
          <input
            type="text"
            value={experience.companyName}
            onChange={(e) =>
              handleWorkExperienceChange(index, "companyName", e.target.value)
            }
            placeholder="Company Name"
          />
          {/* Add more input fields for other work experience properties */}
        </div>
      ))}

      {/* Add button to add more work experience entries */}
    </div>
  );
};

export default ResumeBuilderFormChild;
