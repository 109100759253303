import React, { useState } from "react";
import Wrapper from "../../layout/wrapper";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  AdminSetStudentAccess,
  AdminSetStudentAccessIsUserPremium_AI,
  AdminSetStudentAccessIsUserPremium_IP,
  AdminSetStudentAccessIsUserPremium_ASS_SOFT,
  AdminSetStudentAccessIsUserPremium_ASS_APTI,
  AdminSetStudentAccessIsUserPremium_RB,
  AdminSetStudentAccessIsUserPremium_G1,
  AdminSetStudentAccessIsUserPremium,
} from "../../services/coupon-service";

const AdminManageStudentAccess = () => {
  const [emailList, setEmailList] = useState([]);
  const [emails, setEmails] = useState([]);

  const handleInputChange = (event) => {
    setEmailList(event.target.value);
  };

  const handleAddEmails = () => {
    const newEmails = emailList.split("\n").map((email) => ({
      email: email.trim(),
      isPremium: true,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");
  };

  const handleSetFalse = () => {
    const newEmails = emailList.split("\n").map((email) => ({
      email: email.trim(),
      isPremium: false,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");
  };

  const handleSubmit = () => {
    const result = AdminSetStudentAccess(emails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleAddEmailsIsUserPremium_AI = () => {
    console.log("AI", emailList);
    const newEmails = emailList.split("\n").map((email) => ({
      email: email.trim(),
      IsUserPremium_AI: true,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");
  };

  const handleSetFalseIsUserPremium_AI = () => {
    const newEmails = emails.map((emailObj) => ({
      ...emailObj,
      IsUserPremium_AI: false,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");
  };

  const handleSubmitIsUserPremium_AI = () => {
    const result = AdminSetStudentAccessIsUserPremium_AI(emails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleAddEmailsIsUserPremium_IP = () => {
    const newEmails = emailList.split("\n").map((email) => ({
      email: email.trim(),
      IsUserPremium_IP: true,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");
  };

  const handleSetFalseIsUserPremium_IP = () => {
    const updatedEmails = emails.map((emailObj) => ({
      ...emailObj,
      IsUserPremium_IP: false,
    }));
    setEmails(updatedEmails);
  };

  const handleSubmitIsUserPremium_IP = () => {
    const result = AdminSetStudentAccessIsUserPremium_IP(emails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleAddEmailsIsUserPremium_ASS_SOFT = () => {
    const newEmails = emailList.split("\n").map((email) => ({
      email: email.trim(),
      IsUserPremium_ASS_SOFT: true,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");
  };

  const handleSetFalseIsUserPremium_ASS_SOFT = () => {
    const updatedEmails = emails.map((emailObj) => ({
      ...emailObj,
      IsUserPremium_ASS_SOFT: false,
    }));
    setEmails(updatedEmails);
  };

  const handleSubmitIsUserPremium_ASS_SOFT = () => {
    const result = AdminSetStudentAccessIsUserPremium_ASS_SOFT(emails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleAddEmailsIsUserPremium_ASS_APTI = () => {
    const newEmails = emailList.split("\n").map((email) => ({
      email: email.trim(),
      IsUserPremium_ASS_APTI: true,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");
  };

  const handleSetFalseIsUserPremium_ASS_APTI = () => {
    const updatedEmails = emails.map((emailObj) => ({
      ...emailObj,
      IsUserPremium_ASS_APTI: false,
    }));
    setEmails(updatedEmails);
  };

  const handleSubmitIsUserPremium_ASS_APTI = () => {
    const result = AdminSetStudentAccessIsUserPremium_ASS_APTI(emails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleAddEmailsIsUserPremium_RB = () => {
    const newEmails = emailList.split("\n").map((email) => ({
      email: email.trim(),
      IsUserPremium_RB: true,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");
  };

  const handleSetFalseIsUserPremium_RB = () => {
    const updatedEmails = emails.map((emailObj) => ({
      ...emailObj,
      IsUserPremium_RB: false,
    }));
    setEmails(updatedEmails);
  };

  const handleSubmitIsUserPremium_RB = () => {
    const result = AdminSetStudentAccessIsUserPremium_RB(emails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleAddEmailsIsUserPremium = () => {
    const newEmails = emailList.split("\n").map((email) => ({
      email: email.trim(),
      IsUserPremium: true,
    }));
    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setEmailList("");
  };

  const handleSetFalseIsUserPremium = () => {
    const updatedEmails = emails.map((emailObj) => ({
      ...emailObj,
      IsUserPremium: false,
    }));
    setEmails(updatedEmails);
  };

  const handleSubmitIsUserPremium = () => {
    const result = AdminSetStudentAccessIsUserPremium(emails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  return (
    <Wrapper>
      <div className="container mt-4">
        <div className="row">
          <h2 className="mb-4">Email List with Premium</h2>
          <div className="col-md-4">
            {" "}
            <div className="form-group">
              <textarea
                style={{ minHeight: 300 }}
                className="form-control"
                value={emailList}
                onChange={handleInputChange}
                placeholder="Enter email IDs (one per line)"
                rows={15}
              />
            </div>
          </div>
          <div className="col-md-8">
            <table className="table table-striped carddesign">
              <tr className="thead-dark">
                <th scope="col">No.</th>
                <th scope="col">Email</th>
                <th scope="col">Action</th>
              </tr>
              <tbody>
                {emails.map((emailObj, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{emailObj.email}</td>
                    <td>
                      {emailObj.isPremium ? emailObj.isPremium : null}
                      {emailObj.IsUserPremium_AI
                        ? emailObj.IsUserPremium_AI
                        : null}
                      {emailObj.IsUserPremium_IP
                        ? emailObj.IsUserPremium_IP
                        : null}
                      {emailObj.IsUserPremium_ASS_SOFT
                        ? emailObj.IsUserPremium_ASS_SOFT
                        : null}
                      {emailObj.IsUserPremium_ASS_APTI
                        ? emailObj.IsUserPremium_ASS_APTI
                        : null}
                      {emailObj.IsUserPremium_RB
                        ? emailObj.IsUserPremium_RB
                        : null}
                      {emailObj.IsUserPremium_G1
                        ? emailObj.IsUserPremium_G1
                        : null}
                      {emailObj.IsUserPremium_G2
                        ? emailObj.IsUserPremium_G2
                        : null}
                      {emailObj.IsUserPremium ? emailObj.IsUserPremium : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div style={{ padding: 20 }} className="carddesign">
          <div className="row">
            <div className="col-md-3">
              <h3 style={{ fontSize: 18, fontWeight: 600 }}>
                Confidence Builder
              </h3>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-primary mr-2"
                onClick={handleAddEmails}
              >
                Add Premium Emails
              </button>
            </div>
            <div className="col-md-3">
              <button className="btn btn-danger mr-2" onClick={handleSetFalse}>
                Set Premium to False
              </button>
            </div>
            <div className="col-md-3">
              <button className="btn btn-success" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
          <div style={{ marginTop: 10 }} className="row">
            <div className="col-md-3">
              <h3 style={{ fontSize: 18, fontWeight: 600 }}>AI</h3>
            </div>

            <div className="col-md-3">
              <button
                className="btn btn-primary mr-2"
                onClick={handleAddEmailsIsUserPremium_AI}
              >
                Add AI Premium Emails
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-danger mr-2"
                onClick={handleSetFalseIsUserPremium_AI}
              >
                Set AI Premium to False
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-success"
                onClick={handleSubmitIsUserPremium_AI}
              >
                Submit AI
              </button>
            </div>
          </div>
          <div style={{ marginTop: 10 }} className="row">
            <div className="col-md-3">
              <h3 style={{ fontSize: 18, fontWeight: 600 }}>Interview Prep</h3>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-primary mr-2"
                onClick={handleAddEmailsIsUserPremium_IP}
              >
                Add IP Premium Emails
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-danger mr-2"
                onClick={handleSetFalseIsUserPremium_IP}
              >
                Set IP Premium to False
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-success"
                onClick={handleSubmitIsUserPremium_IP}
              >
                Submit IP
              </button>
            </div>
          </div>
          <div style={{ marginTop: 10 }} className="row">
            <div className="col-md-3">
              <h3 style={{ fontSize: 18, fontWeight: 600 }}>Soft Test</h3>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-primary mr-2"
                onClick={handleAddEmailsIsUserPremium_ASS_SOFT}
              >
                Add AI Premium Emails
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-danger mr-2"
                onClick={handleSetFalseIsUserPremium_ASS_SOFT}
              >
                Set AI Premium to False
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-success"
                onClick={handleSubmitIsUserPremium_ASS_SOFT}
              >
                Submit AI
              </button>
            </div>
          </div>
          <div style={{ marginTop: 10 }} className="row">
            <div className="col-md-3">
              <h3 style={{ fontSize: 18, fontWeight: 600 }}>Apti Test</h3>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-primary mr-2"
                onClick={handleAddEmailsIsUserPremium_ASS_APTI}
              >
                Add AI Premium Emails
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-danger mr-2"
                onClick={handleSetFalseIsUserPremium_ASS_APTI}
              >
                Set AI Premium to False
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-success"
                onClick={handleSubmitIsUserPremium_ASS_APTI}
              >
                Submit AI
              </button>
            </div>
          </div>
          <div style={{ marginTop: 10 }} className="row">
            <div className="col-md-3">
              <h3 style={{ fontSize: 18, fontWeight: 600 }}>Resume Builder</h3>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-primary mr-2"
                onClick={handleAddEmailsIsUserPremium_RB}
              >
                Add AI Premium Emails
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-danger mr-2"
                onClick={handleSetFalseIsUserPremium_RB}
              >
                Set AI Premium to False
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-success"
                onClick={handleSubmitIsUserPremium_RB}
              >
                Submit AI
              </button>
            </div>
          </div>
          <div style={{ marginTop: 10 }} className="row">
            <div className="col-md-3">
              <h3 style={{ fontSize: 18, fontWeight: 600 }}>
                Make User Premium
              </h3>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-primary mr-2"
                onClick={handleAddEmailsIsUserPremium}
              >
                Make User Premium True
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-danger mr-2"
                onClick={handleSetFalseIsUserPremium}
              >
                Make User Premium False
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-success"
                onClick={handleSubmitIsUserPremium}
              >
                Submit AI
              </button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default AdminManageStudentAccess;
