enum LocalStorageConstants {
  USER_DETAILS = "user-details",
  TOKEN = "token",
  IS_LOGGED_IN = "is-logged-in",
  COMPANY_DETIALS = "company-details",
}
enum SessionStorageConstants {
  ASSESSMENT_TEST = "assessment-test",
  ASSESSMENT_TEST_SUBMITTED = "assessment-test-submitted",
}
enum UserRole {
  STUDENT = "STUDENT",
  MENTOR = "MENTOR",
}

export { LocalStorageConstants, SessionStorageConstants, UserRole };
