import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import { AddCompanyForm } from "../../services/user-service";
import "./enquiryform.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styled from "styled-components";
import YouTube from "react-youtube";
const Video = styled.iframe`
  display: flex;
  align-items: center;
  border: none;
  max-width: 490px;
  width: 100%;
  height: 100%;
  max-height: 276px;
  min-height: 276px;
  box-shadow: 9px 14px 15px rgba(0, 0, 0, 0.25);
`;
const RecuiterEnquiryForm = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const CurrentDate = new Date()
    .toJSON()
    .slice(0, 10)
    .split("-")
    .reverse()
    .join("/");
  const [details, setDetails] = useState({
    fullname: "",
    mobile: "",
    companyname: "",
    submitteddate: CurrentDate,
  });

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!details.fullname || !details.mobile || !details.companyname) {
      return;
    }

    const SubmitResponse = await AddCompanyForm(details)
      .then((resp) => {
        setDetails({ fullname: "", mobile: "", companyname: "" });
        toast.success(`Submitted Successfully`, {
          position: "top-left",
        });
      })
      .catch((err) =>
        toast.error(`Error ${err}`, {
          position: "top-left",
        })
      );
  };
  const opts = {
    width: "100%",
    height: "420",

    playerVars: {
      autoplay: 0,
    },
  };

  let _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  const [currentvidetoplay, setCurrentvideotoplay] = useState({
    videoCode: "FLrSxW9v9OA",
  });
  const setSeletedvideotoplay = (item) => {
    console.log("item", item);
    if (item == "Student1") {
      setCurrentvideotoplay({
        videoCode: "FLrSxW9v9OA",
      });
    }
    if (item == "Student2") {
      setCurrentvideotoplay({
        videoCode: "QqDya24v0R4",
      });
    }
    if (item == "GHIntro") {
      setCurrentvideotoplay({
        videoCode: "pwnnUnwDIko",
      });
    }
  };
  return (
    <Wrapper>
      <SEO pageTitle={"Recuiter Enquiry Form"} />
      <div className="row">
        <div className="col-md-8">
          <div className="text-center">
            <div className="desktop">
              <YouTube
                className="cusmobilevideo"
                videoId={currentvidetoplay.videoCode}
                opts={opts}
                onReady={_onReady}
              />
            </div>
            <div className="mobile">
              <Video src={`https://www.youtube.com/embed/QqDya24v0R4`} />
            </div>
            <div className="container">
              <div className="row">
                <div
                  style={{ paddingLeft: 2, paddingRight: 2 }}
                  className="col-md-4"
                >
                  <img
                    onClick={() => setSeletedvideotoplay("Student1")}
                    src="/assets/img/ghimages/studentreviewimg.png"
                  />
                </div>
                <div
                  style={{ paddingLeft: 2, paddingRight: 2 }}
                  className="col-md-4"
                >
                  <img
                    onClick={() => setSeletedvideotoplay("Student2")}
                    src="/assets/img/ghimages/studentreviewing2.png"
                  />
                </div>
                <div
                  style={{ paddingLeft: 2, paddingRight: 2 }}
                  className="col-md-4"
                >
                  <img
                    onClick={() => setSeletedvideotoplay("GHIntro")}
                    src="/assets/img/ghimages/platformintro.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="padding20">
            <form onSubmit={submitHandler}>
              <div className="form-inner">
                <div className="form-group p-b-15">
                  <input
                    type="text"
                    name="fullname"
                    id="fullname"
                    placeholder="Full Name"
                    onChange={(e) =>
                      setDetails({ ...details, fullname: e.target.value })
                    }
                    className="form-control "
                    value={details.fullname}
                    autoComplete="off"
                  />
                </div>
                <div className="form-group p-b-15">
                  <input
                    type="text"
                    name="companyname"
                    placeholder="Company Name"
                    autoComplete="off"
                    className="form-control"
                    onChange={(e) =>
                      setDetails({ ...details, companyname: e.target.value })
                    }
                    value={details.companyname}
                  />
                </div>
                <div className="form-group p-b-15">
                  <input
                    type="number"
                    name="mobile"
                    placeholder="Mobile No."
                    autoComplete="off"
                    className="form-control "
                    onChange={(e) =>
                      setDetails({ ...details, mobile: e.target.value })
                    }
                    value={details.mobile}
                  />
                </div>
              </div>
              {error != "" ? <div className="error">{error}</div> : ""}
              <input
                type="submit"
                value="Submit"
                style={{ marginTop: "20px" }}
                className="e-btn"
              />
            </form>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default RecuiterEnquiryForm;
