import React, { useState, useEffect } from "react";
import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import { getDataFromLocalStorage } from "../../browser-storage";
import {
  getNewAssessmentListing,
  getNewAssessmentScorebyId,
} from "../../services/assestments-service";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
const AssessmentsTestAvailable = () => {
  const [assessmentsavailable, setAssessmentsAvailable] = useState([]);
  const [assessmentTakenByStudent, setassessmentTakenByStudent] = useState([]);
  let CheckTestGivenArray = [];

  let getUserAssistmentsById = async () => {
    const userDetials = getDataFromLocalStorage("user-details");
    console.log(userDetials);
    const username = userDetials.userName;
    const resp = await getNewAssessmentScorebyId(username)
      .then((res) => {
        console.log(res);
        setassessmentTakenByStudent(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let CheckIsTestTaken = async () => {
    console.log(assessmentsavailable);
    for (let i = 0; i < assessmentsavailable.length; i++) {
      console.log(assessmentsavailable[i].testId);
      for (let j = 0; j < assessmentTakenByStudent.length; j++) {
        console.log(assessmentTakenByStudent[j].testId);
        if (
          assessmentsavailable[i].testId === assessmentTakenByStudent[j].testId
        ) {
          assessmentsavailable[i].isTestTaken = true;
          console.log("true");
        } else {
          assessmentsavailable[i].isTestTaken = false;
          console.log("false");
        }
      }
    }
    console.log(assessmentsavailable);
  };

  useEffect(() => {
    getNewAssessmentListingfunction();
    getUserAssistmentsById();
    CheckIsTestTaken();
  }, []);
  let getNewAssessmentListingfunction = async () => {
    const SubmitResponse = await getNewAssessmentListing()
      .then((resp) => {
        console.log(resp);
        setAssessmentsAvailable(resp);
      })
      .catch((err) => console.log(err));
    console.log("SubmitResponse", SubmitResponse);
  };

  return (
    <Wrapper>
      <SEO pageTitle={"Assessments"} />
      <>
        <Card className="carddesign">
          <Container className="jobcontainer">
            <Row>
              <Col>
                <p className="testtitlebox">Test Name</p>
              </Col>
              <Col>
                <p className="testtitlebox">Test Category</p>
              </Col>
              <Col>
                <p className="testtitlebox">Test Difficulty</p>
              </Col>
              <Col>
                <p className="testtitlebox">Action</p>
              </Col>
            </Row>
          </Container>
          {assessmentsavailable.map((item) => {
            return (
              <>
                <Card className="carddesign">
                  <Container className="jobcontainer">
                    <Row>
                      <Col>
                        <p className="testtitlebox">{item.test_name}</p>
                      </Col>
                      <Col>
                        <p className="testtitlebox">{item.test_category}</p>
                      </Col>
                      <Col>
                        {" "}
                        <p>
                          {item.test_difficulty_level == "Easy" ? (
                            <p className="easytest testtitlebox">
                              {item.test_difficulty_level}
                            </p>
                          ) : null}
                        </p>
                      </Col>
                      <Col>
                        <Link
                          to="/assessments"
                          state={{ data: item }}
                          className="link"
                        >
                          <button
                            style={{ marginTop: 10 }}
                            className="e-btn testtitlebox"
                          >
                            Start Test
                          </button>
                        </Link>
                      </Col>
                    </Row>
                  </Container>
                </Card>
              </>
            );
          })}
        </Card>
      </>
    </Wrapper>
  );
};

export default AssessmentsTestAvailable;
