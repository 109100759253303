import React, { useState, useEffect } from "react";
import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import { getDataFromLocalStorage } from "../../browser-storage";
import { SubmitNewAssessmentScore } from "../../services/assestments-service";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { useTimer } from "react-timer-hook";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "./placementtools.css";

const renderTime = ({ remainingTime }) => {
  if (remainingTime === 0) {
    return <div className="timer">Time Over</div>;
  }

  return (
    <div className="timer">
      <div className="text">Remaining</div>
      <div className="value">{remainingTime}</div>
      <div className="text">seconds</div>
    </div>
  );
};

const Assessments = () => {
  const location = useLocation();
  const [showResults, setShowResults] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);

  useEffect(() => {
    console.log(location, " useLocation Hook");

    const SingleTest = location.state?.data;
    console.log("SingleTest", SingleTest);
    const SingleTestQuestions = SingleTest.test_questions;
    console.log("SingleTestQuestions", SingleTestQuestions);

    setQuestion(SingleTestQuestions);
    SettestDetails(SingleTest);
    //   setCourseDetails({
    //     courseTitle: SingleCourse.course_title,
    //     courseDescription: SingleCourse.course_description,
    //     courseCreatedBy: SingleCourse.course_createdby_mentor,
    //     courseCategory: SingleCourse.course_category,
    //     courseDate: SingleCourse.course_created_date,
    //     coursePrice: SingleCourse.course_price,
    //     courseLiked: SingleCourse.course_liked,
    //     courseShared: SingleCourse.course_shared,
    //   });
    //   console.log("resyoutubePlaylist", resyoutubePlaylist);
    //   for (let video of resyoutubePlaylist) {
    //     setCurrentvideotoplay({
    //       title: video.video_title,
    //       videoCode: video.video_embed_code,
    //     });
    //     break;
    //   }
    //   setyoutubePlaylist(resyoutubePlaylist);
  }, []);

  const submitScoreIntialState = {
    testId: "",
    userId: "",
    userEmailId: "",
    test_taken_by: "",
    student_name: "",
    test_name: "",
    test_category: "",
    test_difficulty_level: "",
    score: "",
  };

  const [submitscore, setSubmitScore] = useState(submitScoreIntialState);

  const [testDetails, SettestDetails] = useState();

  const [questions, setQuestion] = useState([
    { questionText: "", questionImgUrl: "", options: [] },
  ]);
  // useEffect(() => {
  //   getNewAssessmentListingfunction();
  // }, []);
  // let getNewAssessmentListingfunction = async () => {
  //   const SubmitResponse = await getNewAssessmentListing()
  //     .then((resp) => {
  //       console.log(resp);
  //       const respdata = resp;
  //       console.log("respdata", respdata);
  //       SettestDetails(respdata);
  //       const questionData = resp[0].test_questions;
  //       console.log("questionData", questionData);
  //       setQuestion(questionData);
  //     })
  //     .catch((err) => console.log(err));
  //   console.log("SubmitResponse", SubmitResponse);
  // };

  // Helper Functions

  /* A possible answer was clicked */
  const optionClicked = (isCorrect) => {
    // Increment the score
    if (isCorrect) {
      setScore(score + 1);
    }

    if (currentQuestion + 1 < questions.length) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowResults(true);
      submitResult();
    }
  };

  let submitResult = async () => {
    const userDetials = getDataFromLocalStorage("user-details");
    console.log(userDetials);

    let payload = {
      testId: testDetails.testId,
      userId: "",
      userEmailId: "",
      test_taken_by: userDetials.userName,
      student_name: userDetials.name,
      test_name: testDetails.test_name,
      test_category: testDetails.test_category,
      test_difficulty_level: testDetails.test_difficulty_level,
      score: score,
    };

    console.log(testDetails);
    console.log(submitscore);
    const resp = await SubmitNewAssessmentScore(payload)
      .then((res) => {
        console.log(res);
        toast.success(`Score Submitted Successfully`, {
          position: "top-left",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /* Resets the game back to default */
  const restartGame = () => {
    setScore(0);
    setCurrentQuestion(0);
    setShowResults(false);
  };

  // const expiryTimestamp = new Date();
  // expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 5); // 10 minutes timer

  // const {
  //   seconds,
  //   minutes,
  //   hours,
  //   days,
  //   isRunning,
  //   start,
  //   pause,
  //   resume,
  //   restart,
  // } = useTimer({
  //   expiryTimestamp,
  //   onExpire: () => TimeExpiredSubmit(),
  // });

  // let TimeExpiredSubmit = () => {
  //   setShowResults(true);
  //   // submitResult();
  // };

  let RingTimeExpiredSubmit = () => {
    setShowResults(true);
    console.log("from ring timer");
    submitResult();
  };

  return (
    <Wrapper>
      <SEO pageTitle={"Assessments"} />
      <div className="row">
        <div className="col-md-8">
          <h1 style={{ paddingTop: "50px" }}>Aptitude Test</h1>
        </div>
        <div className="col-md-4">
          <div className="timer-wrapper">
            <CountdownCircleTimer
              size={140}
              isPlaying
              duration={10}
              //colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
              colors="url(#your-unique-id)"
              //colorsTime={[10, 6, 3, 0]}
              onComplete={() => RingTimeExpiredSubmit()}
            >
              {renderTime}
            </CountdownCircleTimer>
          </div>
        </div>
      </div>

      {/* <div style={{ textAlign: "center" }}>
        <h1>react-timer-hook </h1>
        <p>Timer Demo</p>
        <div style={{ fontSize: "100px" }}>
          <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:
          <span>{seconds}</span>
        </div>
        <p>{isRunning ? "Running" : "Not running"}</p>
        <button onClick={start}>Start</button>
        <button onClick={pause}>Pause</button>
        <button onClick={resume}>Resume</button>
        <button
          onClick={() => {
            // Restarts to 5 minutes timer
            const time = new Date();
            time.setSeconds(time.getSeconds() + 300);
            restart(time);
          }}
        >
          Restart
        </button>
      </div> */}
      <>
        {questions !== [] ? (
          <div className="AssistmentApp">
            {/* 1. Header  */}

            {/* 2. Current Score  */}
            {/* <h2>Score: {score}</h2> */}

            {/* 3. Show results or show the question game  */}
            {showResults ? (
              /* 4. Final Results */
              <div className="final-results">
                <h1>Final Results</h1>
                <h2>
                  {score} out of {questions.length} correct - (
                  {(score / questions.length) * 100}%)
                </h2>
                <Link to="/assessments-available" className="link">
                  <button className="e-btn">Back To Assessment</button>
                </Link>
              </div>
            ) : (
              /* 5. Question Card  */
              <div className="question-card">
                {/* Current Question  */}
                <h2>
                  Question: {currentQuestion + 1} out of {questions.length}
                </h2>
                <h3 className="question-text">
                  {questions[currentQuestion].questionText}
                  {questions[currentQuestion].questionImgUrl ? (
                    <img
                      width={500}
                      src={questions[currentQuestion].questionImgUrl}
                    />
                  ) : null}
                </h3>

                {/* List of possible answers  */}
                <div className="row">
                  {questions[currentQuestion].options.map((option) => {
                    return (
                      <div
                        className="col-md-6"
                        key={option.id}
                        onClick={() => optionClicked(option.isCorrect)}
                      >
                        <div className="e-btn customtextbtn">{option.text}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        ) : null}
        <svg>
          <defs>
            <linearGradient id="your-unique-id" x1="1" y1="0" x2="0" y2="0">
              <stop offset="5%" stopColor="gold" />
              <stop offset="95%" stopColor="red" />
            </linearGradient>
          </defs>
        </svg>
      </>
    </Wrapper>
  );
};

export default Assessments;
