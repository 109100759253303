import { useDispatch } from "react-redux";
import styled from "styled-components";

//import UserControl from "./user-control";
import { useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import { LOGO_URL } from "../../../../layout/headers/header";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  width: 100%;
  height: 76px;
  padding: 10px 30px;
  margin-bottom: 21px;
  background: #ffffff;
  box-shadow: 10px 30px 40px rgba(221, 219, 214, 0.46);
  position: sticky;
  top: 0;
  z-index: 99;
`;

const ItemContainer = styled.div`
  display: flex;
  flex: ${({ hasSearchBar }) => (hasSearchBar ? 2 : 1)};
  flex-direction: row;
  align-items: center;
`;
const TimeTicker = styled.div`
  display: flex;
  font-size: 20px;
`;

const GhLogo = styled.img`
  margin-right: 53px;
  cursor: pointer;
`;

const SearchBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 692px;
  height: 56px;
  padding: 20px 16px;
  border-radius: 4px;
  gap: 12px;
  background: #ffffff;
  font-family: Poppins-Regular;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04),
    0px 4px 16px rgba(51, 51, 51, 0.08);

  img {
    width: 16px;
    height: 16px;
  }

  input {
    color: rgba(17, 17, 17, 0.48);
    font-size: 16px;
    line-height: 24px;
    outline: none;
    border: none;
    width: 90%;
  }
`;

const TestHeader = ({ pageNum, onTestSubmit, headerConfig }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <Row>
        <Card>
          <Row>
            <Col>
              <img
                width="200px"
                src={LOGO_URL}
                onClick={() => navigate("/student-dashboard")}
              />
            </Col>
            <Col>
              {/* complete later */}
              {/* <UserControl headerConfig={headerConfig} /> */}
            </Col>
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default TestHeader;
