import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import React, { useEffect, useState } from "react";

import {
  razorpayCreateOrder,
  razorpayCreateOrderWithSplitPayment,
} from "../../services/payment-service";
import Alert from "react-bootstrap/Alert";
import { addserviceScheduler } from "../../services/scheduler-service";
import {
  setDataInLocalStorage,
  getDataFromLocalStorage,
} from "../../browser-storage";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

import {
  AddStudentCouponRecordService,
  checkIsCouponValid,
} from "../../services/coupon-service";
import "./homepaymentpage.css";
import JobsPopupLoginForm from "../../pages/jobs/jobs-popup-login-form";
import Modal from "react-modal";
import ReviewVideoGallery from "./review-video-gallery";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";

const HomeCustomPagePayment = () => {
  //Model
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "600px",
    },
  };
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [selectedpackageprice, setselectedpackageprice] = useState({
    amount: 0,
  });
  const location = useLocation();
  const [isLoggedInStatus, setisLoggedInStatus] = useState(false);
  const [currentStudentDetails, setCurrentStudentDetails] = useState();
  const [selectedActivatePackage, setActivatePackage] = useState("");
  let checkLoginStatus = async () => {
    const userDetials = getDataFromLocalStorage("user-details");
    console.log(userDetials);
    setCurrentStudentDetails(userDetials);
    let token = userDetials.token;
    console.log("token", token);
    if (token) {
      setisLoggedInStatus(true);
    }
    console.log("isLoggedInStatus", isLoggedInStatus);
  };

  useEffect(() => {
    checkLoginStatus();
    console.log("current href", window.location.host);
    const Data = location.state?.data;
    //const Data = location;
    console.log("Data", Data);
    setCurrentSource(window.location.host);
    setActivatePackage(Data.ActivatePackage);
    console.log(selectedActivatePackage);
  }, []);

  useEffect(() => {
    // setselectedpackageprice(selectedprice);
  }, []);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    console.log("selectedpackageprice", selectedpackageprice);

    console.log("inside loop");
    //Step 5 Mentor Share
    //dont add razorpay value in this

    const submittedNumber = parseFloat(inputValue);

    const customMentorShare = submittedNumber * 0.882;
    console.log("customMentorShare", customMentorShare);
    const calculatedResult = submittedNumber + submittedNumber * 0.027; // 2.7%
    console.log("calculatedResult", calculatedResult);
    const payload = {
      amount: Number(calculatedResult) * 100,
      discount_code_used: coupondetails.coupon_code_submitted,
      mygreenhorn_amount: "",
      mentor_amount: Number(customMentorShare) * 100,
      //amount: 100,
    };
    console.log("payload", payload);
    const result = await razorpayCreateOrderWithSplitPayment(payload)
      .then((res) => {
        console.log(res);
        let response = res;
        const id = response.id;
        const options = {
          currency: "INR",
          name: "GreenHorn",
          order_id: id,
          description: "GreenHorn Transaction",
          handler: async function (response) {
            const data = {
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            };
            console.log(response);
            const userDetials = getDataFromLocalStorage("user-details");
            console.log(userDetials);
            const email = userDetials.email;
            const payload = {
              username: userDetials.userName,
              servicebooked: "Homepage Payment Packages",
              bookingDate: new Date(),
              amount: selectedpackageprice,
              source: currentSource,
              discount_code_used: coupondetails.coupon_code_submitted,
              mentor_amount: customMentorShare,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            };
            const schedulerResult = await addserviceScheduler(payload)
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });
            const coupon_payload = {
              mentor_coupon_code: coupondetails.coupon_code_submitted,
              mentor_name: "",
              mentorEmailId: "",
              generation_date: new Date(),
              StudentEmailId: currentStudentDetails.userName,
              StudentName: currentStudentDetails.name,
              PackageSelected: selectedpackageprice,
              ActivatePackage: selectedActivatePackage,
            };
            const Couponrecordresult = await AddStudentCouponRecordService(
              coupon_payload
            )
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });

            console.log(schedulerResult);
            console.log("Couponrecordresult", Couponrecordresult);
            if (response) {
              toast.success(
                `Payment Successfully Completed, Please check your mail for more detials `,
                {
                  position: "top-left",
                }
              );
            }
          },
          theme: {
            color: "#61dafb",
          },
        };

        const paymentObject = new window.Razorpay(options);

        paymentObject.open();
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(result);
  }

  const [coupondetails, setCouponDetails] = useState({
    coupon_code_submitted: "",
  });

  let couponDetailsonChange = (evt) => {
    console.log("evt", evt.target.value);
    setCouponDetails({
      ...coupondetails,
      [evt.target.name]: evt.target.value,
    });
  };

  const [mentorreferral, setMentorReferral] = useState();

  const checkIsCouponValidfunction = async () => {
    const couponobj = {
      studentEmailId: "",
      coupon_code: coupondetails.coupon_code_submitted,
      package_selected: "",
    };
    const checkresult = await checkIsCouponValid(couponobj)
      .then((resp) => {
        console.log(resp);
        setMentorReferral(true);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const [currentSource, setCurrentSource] = useState();

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
  };

  const calculateResult = (number) => {
    if (number !== "") {
      const submittedNumber = parseFloat(number);
      const calculatedResult = submittedNumber + submittedNumber * 0.027; // 2.7%
      return calculatedResult.toFixed(2);
    }
    return "";
  };
  return (
    <Wrapper>
      <SEO pageTitle={"Homepage Payment"} />
      <>
        <Container style={{ backgroundColor: "white" }}>
          <Row style={{ marginBottom: "20px" }}>
            <Card className="carddesign">
              <Card.Body>
                <Row>
                  <Col sm={8}>
                    <img src="/assets/img/ghimages/1.jpg" />
                  </Col>
                  <Col>
                    <div
                      className="price__item grey-bg mb-30 p-relative"
                      style={{ padding: "20px" }}
                    >
                      <div className="price__head">
                        <h3>Custom Payment Package</h3>
                      </div>

                      {/* <div className="price__features mb-40">
                        <ul>
                          <li>
                            {" "}
                            <i className="far fa-check"></i>Personal branding
                          </li>
                          <li>
                            {" "}
                            <i className="far fa-check"></i>Communication and
                            confidence
                          </li>
                          <li>
                            {" "}
                            <i className="far fa-check"></i>Interview
                            preparation
                          </li>
                          <li>
                            {" "}
                            <i className="far fa-check"></i>Soft skills
                            evaluation
                          </li>
                          <li>
                            {" "}
                            <i className="far fa-check"></i>Aptitude assessment
                          </li>
                          <li>
                            {" "}
                            <i className="far fa-check"></i>ATS resume building
                          </li>
                          <li>
                            {" "}
                            <i className="far fa-check"></i>Personality
                            assessment
                          </li>
                          <li>
                            {" "}
                            <i className="far fa-check"></i>AI interview
                          </li>
                          <li>
                            {" "}
                            <i className="far fa-check"></i>Mock interview
                          </li>
                        </ul>
                      </div> */}

                      {isLoggedInStatus === true ? (
                        <>
                          {mentorreferral ? (
                            <>
                              {" "}
                              <div className="text-center">
                                <button
                                  className="e-btn btn-success"
                                  style={{
                                    marginTop: "5px",
                                    background: "green",
                                  }}
                                  onClick={displayRazorpay}
                                >
                                  Book Now
                                </button>
                              </div>
                            </>
                          ) : (
                            <div className="text-center">
                              <OverlayTrigger
                                delay={{ hide: 450, show: 300 }}
                                overlay={(props) => (
                                  <Tooltip
                                    {...props}
                                    className="custom-tooltip"
                                  >
                                    please use mentor coupon code to proceed
                                  </Tooltip>
                                )}
                                placement="bottom"
                              >
                                <Button className="e-btn e-btn-border">
                                  Book Now
                                </Button>
                              </OverlayTrigger>
                            </div>
                          )}
                          <div>
                            <label>Enter Price:</label>
                            <input
                              className="form-control"
                              type="number"
                              value={inputValue}
                              onChange={handleInputChange}
                            />
                            {inputValue && (
                              <p>
                                Final Price: {inputValue} + ({inputValue} * 2.7%
                                Transcation fee) = {calculateResult(inputValue)}
                              </p>
                            )}
                          </div>
                        </>
                      ) : null}
                      <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                        overlayClassName="Overlay"
                      >
                        <JobsPopupLoginForm />
                      </Modal>
                      {isLoggedInStatus === false ? (
                        <>
                          <div className="text-center">
                            <button
                              className="e-btn e-btn-border"
                              style={{ marginTop: "5px" }}
                              onClick={openModal}
                            >
                              Login
                            </button>
                          </div>
                          <p style={{ color: "red" }}>
                            * Please Login to Book Package
                          </p>
                        </>
                      ) : null}

                      {isLoggedInStatus === true ? (
                        <>
                          <p>Have a Coupon code ?</p>
                          <input
                            placeholder="Coupon Code"
                            className="form-control marginTop5"
                            type="text"
                            name="coupon_code_submitted"
                            value={coupondetails.coupon_code_submitted}
                            onChange={couponDetailsonChange}
                          />

                          <button
                            className="e-btn e-btn-border"
                            style={{ marginTop: "5px" }}
                            onClick={checkIsCouponValidfunction}
                          >
                            Apply Now
                          </button>
                        </>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Row>
        </Container>
      </>
    </Wrapper>
  );
};

export default HomeCustomPagePayment;
