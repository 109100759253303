import { memo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//import { RootState } from 'redux/store'
import { toast } from "react-toastify";
import {
  UserProfileEvent,
  AppConfigEvent,
} from "../../../redux/types/redux-event";
import { updateUserProfile, addCoverPic } from "../../../services/user-service";
import styled from "styled-components";

import {
  ModalBackground,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "./common/modal";
import { ButtonGroup, ActionButton, RemoveAction, Error } from "./common";
import Dropzone from "react-dropzone";
import { toBase64 } from "../../../helpers";
import {
  REMOVE_COVER_PIC,
  UPDATE_USER_PROFILE,
} from "../../../redux/features/profile-slice";

const CustomModal = styled(Modal)`
  max-width: 963px;
`;

const Container = styled.div`
  width: 100%;
  max-width: 740px;
  margin: 28px auto;
  div:nth-child(3) {
    margin-top: 6px;
    text-align: center;
  }
`;

const UploadButton = styled(ActionButton)`
  font-size: 18px;
  background: linear-gradient(270deg, #0c6d3b 0%, #026639 100%);
`;

const DragDropContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  border: 1px dashed #c4c4c4;
  border-radius: 5px;
  gap: 8px;
  div:nth-child(1) {
    font-family: Poppins-Bold;
    font-size: 24px;
    color: rgba(61, 41, 36, 0.8);
  }
  div:nth-child(2) {
    font-family: Poppins-Regular;
    font-size: 18px;
    color: rgba(61, 41, 36, 0.8);
  }
  img {
    height: 90%;
    border-radius: 5px;
  }
`;

const PhotoUploadDescription = styled.div`
  font-family: Poppins-Regular;
  font-size: 18px;
  color: rgba(61, 41, 36, 0.8);
  width: 100%;
  max-width: 510px;
  margin: 16px auto 0 auto;
  text-align: center;
`;

// interface Props {
//   closeAction: VoidFunction;
//   forEdit?: boolean;
// }

const AddEditBgPhotoModal = ({ closeAction, forEdit }) => {
  const dispatch = useDispatch();

  const [coverPic, setCoverPic] = useState({
    uploadedFile: undefined,
    fileUrl: "",
  });
  const [showErrors, setShowErrors] = useState(false);
  const setupCoverPic = async (file) => {
    if (file?.length && file[0]?.size > 4_00_000) {
      setShowErrors(true);
      return;
    }
    const base64 = await toBase64(file[0]).catch(() =>
      toast.error(`Unable to upload image`, {
        position: "top-left",
      })
    );
    if (base64) {
      setCoverPic({ uploadedFile: file[0], fileUrl: base64 });
    }
    setShowErrors(false);
  };

  const uploadImage = () => {
    if (!coverPic.uploadedFile || showErrors) {
      return;
    }
    dispatch({ type: AppConfigEvent.START_LOADING });
    addCoverPic(coverPic.uploadedFile)
      .then((data) => {
        toast.success(`Cover picture updated successfully`, {
          position: "top-left",
        });
        // dispatch({
        //   type: UserProfileEvent.UPDATE_USER_PROFILE,
        //   data: { coverImgUrl: data?.coverImgUrl || "" },
        // });
        dispatch(UPDATE_USER_PROFILE({ coverImgUrl: data?.coverImgUrl || "" }));
      })
      .catch(() =>
        toast.error(`Unable to update your cover picture`, {
          position: "top-left",
        })
      )
      .finally(() => dispatch({ type: AppConfigEvent.STOP_LOADING }));
    closeAction();
  };

  const deleteImage = () => {
    dispatch({ type: AppConfigEvent.START_LOADING });
    updateUserProfile({ coverImgUrl: "" })
      .then(() => {
        toast.success(`Profile picture deleted successfully`, {
          position: "top-left",
        });
        // dispatch({ type: UserProfileEvent.REMOVE_COVER_PIC });
        dispatch(REMOVE_COVER_PIC);
      })
      .catch(() =>
        toast.error(`Unable to delete your profile picture`, {
          position: "top-left",
        })
      )
      .finally(() => dispatch({ type: AppConfigEvent.STOP_LOADING }));
    closeAction();
  };
  return (
    <ModalBackground>
      <CustomModal>
        <ModalHeader>
          Background photo{" "}
          <img
            src={"/assets/img/ghimages/close-icon.svg"}
            alt="X"
            onClick={closeAction}
          />
        </ModalHeader>
        <ModalBody>
          <Container>
            <Dropzone accept="image/*" onDrop={(file) => setupCoverPic(file)}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <DragDropContainer>
                    {coverPic.fileUrl ? (
                      <img src={coverPic.fileUrl} alt="" />
                    ) : (
                      <>
                        <div>Drag & Drop</div>
                        <div>Or</div>
                        <UploadButton>Upload</UploadButton>
                      </>
                    )}
                  </DragDropContainer>
                </div>
              )}
            </Dropzone>
            <PhotoUploadDescription>
              Showcase your personality, interests, team moments or notable
              milestones
            </PhotoUploadDescription>
            {showErrors && <Error>Image size should not exceed 400KB.</Error>}
          </Container>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup forEdit={forEdit}>
            {forEdit && (
              <RemoveAction onClick={deleteImage}>
                Delete background photo
              </RemoveAction>
            )}
            <ActionButton onClick={uploadImage}>
              {forEdit ? "Update" : "Add"}
            </ActionButton>
          </ButtonGroup>
        </ModalFooter>
      </CustomModal>
    </ModalBackground>
  );
};
export default memo(AddEditBgPhotoModal);
