import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Options } from "../../../redux/types";
import { useDispatch, useSelector } from "react-redux";

import ConfirmationModal from "../assessment/common/confirmationModal";
import { AssessmentType } from "../../../redux/types/generic-constant";
import {
  createModifiedMixSkillTest,
  submitMixSkillTest,
} from "../../../services/assessment";
import {
  removeDataFromSessionStorage,
  setDataInSessionStorage,
} from "../../../browser-storage";
import { SessionStorageConstants } from "../../../redux/types/storage-constant";
import { AssessmentTest } from "../../../redux/types/redux-event/assessment";
import { AppConfigEvent } from "../../../redux/types/redux-event";
import {
  ASSESSMENT_TEST_START,
  UPDATE_ANSWER_SHEET,
} from "../../../redux/features/assessment-slice";
import {
  PageBlock,
  Container,
  PageHeader,
  PageContainer,
  AnswerTrack,
  QuestionTracking,
  NumberOfQuestions,
  RoundAnswer,
  SpanText,
  AnswerTrackBlock,
  QuestionTrackBlock,
  Btn,
  Answer,
  RadioBtn,
  AnswerPanel,
  QuestionImg,
  Answers,
  AnswerBlock,
  Question,
  BtnBlock,
  ProgressBarChildDiv,
  ProgressBarParentDiv,
} from "../assessment/skill-page-css-functions";

const MIXSkillPage = (props) => {
  console.log("on skill page");
  const dispatch = useDispatch();
  const [isOpen, togglePopup] = useState(false);
  const [isFullscreen, fullscreenPopup] = useState(true);
  const [isAnswerAll, setPopup] = useState(false);
  const [queNumber, changeQuestionNumber] = useState(0);
  const [screenResize, setScreenResize] = useState(0);

  const answerSheet = useSelector((state) => state.assessment.answerSheet);
  const testId = useSelector((state) => state.assessment.testId);
  const questions = useSelector((state) => state.assessment.questions);
  console.log("answerSheet", answerSheet);
  console.log("testId", testId);
  console.log("questions", questions);
  const onTogglePopup = () => {
    togglePopup((prevOpenState) => !prevOpenState);
  };
  const setSoftAnswerPopup = () => {
    setPopup((prevState) => !prevState);
  };
  const startTest = async () => {
    dispatch({ type: AppConfigEvent.START_LOADING });

    const res = await createModifiedMixSkillTest(props.assessmentId);

    if (!res) return;
    dispatch({ type: AppConfigEvent.STOP_LOADING });

    console.log("res", res);
    dispatch(
      ASSESSMENT_TEST_START({
        questions: res?.questions,
        answerSheet: res.answerSheet,
        testId: res.testId,
        assessmentType: props.assessmentType,
      })
    );

    setDataInSessionStorage(res, SessionStorageConstants.ASSESSMENT_TEST);
  };
  useEffect(() => {
    startTest();
  }, []);
  useEffect(() => {
    checkScreenResize();
  }, [screenResize]);
  const radioButton = (index) => {
    let newAnswerSheet = { ...answerSheet };
    newAnswerSheet[selectedQuestion.questionId] = index;

    dispatch(UPDATE_ANSWER_SHEET({ answerSheet: newAnswerSheet }));
  };
  const answerValidation = () => {
    if (props.assessmentType === AssessmentType.SOFT_SKILL) {
      for (let i = 0; i <= Object.keys(answerSheet).length; i++) {
        if (answerSheet[i] === -1) {
          setPopup((prevState) => !prevState);
          return false;
        }
      }
      return true;
    }
    return true;
  };
  const onTestSubmit = async () => {
    if (!answerValidation()) return onTogglePopup();
    dispatch({ type: AppConfigEvent.START_LOADING });

    const res = await submitMixSkillTest({
      testId: testId,
      answerSheet,
    });

    if (!res) return;
    dispatch({ type: AppConfigEvent.STOP_LOADING });
    dispatch({
      type: "MIX_SKILL_TEST_SUBMITTED",
      data: { result: res.result, testId: res.testId },
    });
    removeDataFromSessionStorage(SessionStorageConstants.ASSESSMENT_TEST);
    props.setPage(testId, props.assessmentType, 2);
    // return true
  };

  const checkScreenResize = () => {
    if (myStateRef.current >= 3) onTestSubmit();
    fullscreenPopup(true);
  };
  const setMyState = (data) => {
    myStateRef.current = data;
    setScreenResize(data);
  };
  const myStateRef = React.useRef(screenResize);
  const nextQuestion = () => {
    if (questions.length === queNumber + 1) return onTogglePopup();
    changeQuestionNumber(queNumber + 1);
    let newQueArray = [...questions];
    newQueArray[queNumber].key = true;
    dispatch({
      type: AssessmentTest.UPDATE_QUESTIONS,
      data: { questions: newQueArray },
    });
  };
  const reachedQuestions = queNumber + 1;
  const fullscreen = () => {
    document.body
      .requestFullscreen()
      .then((response) => console.log("response", response))
      .catch((err) => console.log("response", err));
  };
  useEffect(() => {
    const fullscreen = () => {
      if (document.fullscreenElement) return fullscreenPopup(false);
      else {
        setMyState(myStateRef.current + 1);
        fullscreenPopup(true);
      }
    };
    document.addEventListener("fullscreenchange", fullscreen);
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    return () => {
      document.removeEventListener("fullscreenchange", fullscreen);
    };
  }, []);
  useEffect(() => {
    const beforeunloads = (e) => {
      e.preventDefault();
      e.returnValue = "";
      setMyState(myStateRef.current + 1);
      onTestSubmit();
    };
    window.addEventListener("beforeunload", beforeunloads);
    return () => {
      window.removeEventListener("beforeunload", beforeunloads);
    };
  }, []);
  const selectedQuestion = questions && questions[queNumber];
  return (
    <PageBlock id={"goFS"} onClick={fullscreen}>
      <ConfirmationModal
        isOkBtnShow={true}
        headerText={
          "If you minimized your window more than twice,you will not allowed to continue test further and your answer will be submitted instantly."
        }
        isOpen={isFullscreen}
        onOkBtnClick={fullscreen}
      />
      <ConfirmationModal
        isOkBtnShow={true}
        isCancelBtnShow={true}
        headerText={"Are you sure you want to submit your answers? "}
        isOpen={isOpen}
        onCancelClick={onTogglePopup}
        onOkBtnClick={onTestSubmit}
      />
      <ConfirmationModal
        isOkBtnShow={true}
        isCancelBtnShow={false}
        headerText={"You need to answer all questions mandatorily."}
        isOpen={isAnswerAll}
        onOkBtnClick={setSoftAnswerPopup}
      />
      <Container>
        <PageContainer>
          <PageHeader>Personality Assessment</PageHeader>
          <PageHeader>
            {" "}
            Question {reachedQuestions} of {questions && questions?.length}
          </PageHeader>
          <ProgressBarParentDiv>
            <ProgressBarChildDiv
              widthPer={`${
                (reachedQuestions / (questions && questions?.length)) * 100
              }%`}
            >
              {" "}
            </ProgressBarChildDiv>
          </ProgressBarParentDiv>
          <>
            <Question>{selectedQuestion?.questionText}</Question>
            <AnswerBlock>
              <Answers>
                {selectedQuestion?.options?.map((option, index) => (
                  <AnswerPanel>
                    <RadioBtn
                      key={index}
                      onClick={() => radioButton(index)}
                      isChecked={
                        answerSheet[selectedQuestion.questionId] === index
                      }
                    />
                    <Answer>
                      {Options[index] + "."} {option}
                    </Answer>
                  </AnswerPanel>
                ))}
              </Answers>
              {selectedQuestion?.questionImg && (
                <QuestionImg
                  src={selectedQuestion.questionImg}
                  alt={"question"}
                />
              )}
            </AnswerBlock>
            <BtnBlock>
              <Btn
                onClick={() =>
                  changeQuestionNumber(
                    queNumber === 0 ? queNumber : queNumber - 1
                  )
                }
              >
                Back
              </Btn>
              <Btn onClick={() => nextQuestion()}>
                {queNumber === (questions && questions.length) - 1
                  ? "Finish"
                  : "Next"}
              </Btn>
            </BtnBlock>
          </>
        </PageContainer>
        <QuestionTrackBlock>
          <QuestionTracking>
            <AnswerTrackBlock>
              <RoundAnswer answerType={AnswerTrack.ANSWERED_COLOR} />
              <SpanText> Answered</SpanText>
            </AnswerTrackBlock>
            <AnswerTrackBlock>
              <RoundAnswer answerType={AnswerTrack.NOT_VISITED_COLOR} />
              <SpanText>Not Visited</SpanText>
            </AnswerTrackBlock>
            <AnswerTrackBlock>
              <RoundAnswer answerType={AnswerTrack.NOT_ANSWERED_COLOR} />
              <SpanText>Not Answered</SpanText>
            </AnswerTrackBlock>
          </QuestionTracking>
          <NumberOfQuestions>
            {questions &&
              questions.map((obj, index) => (
                <RoundAnswer
                  key={index}
                  onClick={() => changeQuestionNumber(index)}
                  answerType={
                    answerSheet[obj.questionId] !== -1
                      ? AnswerTrack.ANSWERED_COLOR
                      : answerSheet[obj.questionId] === -1 && obj.key
                      ? AnswerTrack.NOT_ANSWERED_COLOR
                      : AnswerTrack.NOT_VISITED_COLOR
                  }
                >
                  {index + 1}
                </RoundAnswer>
              ))}
          </NumberOfQuestions>
        </QuestionTrackBlock>
      </Container>
    </PageBlock>
  );
};
export default MIXSkillPage;
