import { Col, Row } from "react-bootstrap";
import Dropzone from "react-dropzone";
import Modal from "react-modal";
import { toBase64 } from "../../helpers/index";
import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { addResumeProfilePic } from "../../services/user-service";
import config from "../../services/config";
const DragDropContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 232px;
  border: 1px dashed #c4c4c4;
  border-radius: 5px;
  gap: 8px;
  div:nth-child(1) {
    font-family: Poppins-Bold;
    font-size: 24px;
    color: rgba(61, 41, 36, 0.8);
  }
  div:nth-child(2) {
    font-family: Poppins-Regular;
    font-size: 18px;
    color: rgba(61, 41, 36, 0.8);
  }
  img {
    width: 220px;
    height: 220px;
    border-radius: 5px;
  }
`;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "550px",
  },
};

const ResumeProfileImg = ({ handleChange }) => {
  const [BasicDetails, setBasicDetails] = useState({
    firstname: "",
    middlename: "",
    lastname: "",
    degree: "",
    phonenumber: "",
    profileImgUrl: "",
    email: "",
    linkedin: "",
  });
  // Model Requirements
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  const [profilePic, setProfilePic] = useState({
    uploadedFile: undefined,
    fileUrl: "",
  });
  const [showErrors, setShowErrors] = useState(false);
  const setupProfilePic = async (file) => {
    console.log("file", file);
    console.log(file[0]);
    const base64 = await toBase64(file[0]).catch(() =>
      toast.error(`Unable to Upload Image`, {
        position: "top-left",
      })
    );
    console.log("base64", base64);
    if (base64) {
      setProfilePic({ uploadedFile: file[0], fileUrl: base64 });
    }
    console.log("profilePic", profilePic.fileUrl);
    setShowErrors(false);
  };

  const uploadImage = () => {
    if (!profilePic.uploadedFile || showErrors) {
      return;
    }

    addResumeProfilePic(profilePic.uploadedFile)
      .then((data) => {
        console.log(data);
        toast.success(`Profile picture updated successfully`, {
          position: "top-left",
        });
        setABCState("Image Uploaded");
        handleChange("Image Uploaded");

        closeModal();
        //GetMentorProfileById(mentorprofiledetials.userEmailId);
      })
      .catch(() =>
        toast.error(`Unable to update your profile picture`, {
          position: "top-left",
        })
      );
  };

  const [abcState, setABCState] = useState("");

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        contentClassName="myModel"
      >
        <Row>
          <Col sm={11}>
            <h3>Upload Profile Image</h3>
          </Col>
          <Col sm={1}>
            <button
              style={{ color: "red", background: "#ffffff" }}
              onClick={closeModal}
            >
              <h3 style={{ color: "red", background: "#ffffff" }}>X</h3>
            </button>
          </Col>
        </Row>

        <Dropzone accept="" onDrop={(file) => setupProfilePic(file)}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <DragDropContainer>
                {profilePic.fileUrl ? (
                  <img src="/assets/img/ghimages/uploadimg.png" alt="" />
                ) : (
                  <>
                    <p>Drag & Drop</p>
                    <p>Or</p>
                    <button className="e-btn">Upload</button>
                  </>
                )}
              </DragDropContainer>
            </div>
          )}
        </Dropzone>
        <Row>
          <Col sm={9}>
            <p style={{ color: "red" }}>Note : Maximum Upload Size is 400 KB</p>
          </Col>
          <Col className="text-center" sm={3}>
            <button
              style={{ marginTop: 20 }}
              className="e-btn"
              onClick={uploadImage}
            >
              Submit
            </button>
          </Col>
        </Row>
      </Modal>
      <div className="col-md-3">
        <div className="">
          <div style={{ margin: 20 }}>
            {BasicDetails.profileImgUrl ? (
              <img
                src={`${config.PROTOCOL}${config.IMAGE_HOST}/${BasicDetails.profileImgUrl}`}
                alt=""
              />
            ) : (
              <img src="/assets/img/ghimages/blankprofile.png" alt="" />
            )}
            <div className="text-center pt-10">
              <button className="e-btn" onClick={openModal}>
                Set Profile Picture
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResumeProfileImg;
