import styled from "styled-components";

const RequiredText = styled.div`
  font-family: Poppins-Regular;
  font-size: 18px;
  color: rgba(61, 41, 36, 0.77);
  padding-bottom: 30px;
`;

const FieldGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: Poppins-Regular;
  font-size: 20px;
  color: #3d2924d9;
  gap: 10px;
`;

const DateGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 36px;
`;

const InputFiled = styled.input<{ disabled?: boolean }>`
  width: 100%;
  padding: 10px 20px;
  outline: none;
  border: 1px solid #c4c4c4;
  border-radius: 30px;
  font-family: Poppins-Regular;
  font-size: 20px;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "auto")};
`;

const Error = styled.div`
  font-family: Poppins-Regular;
  font-size: 12px;
  color: red;
  margin-top: -5px;
`;

const ButtonGroup = styled.div<{ forEdit?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ forEdit }) => (forEdit ? "space-between" : "flex-end")};
`;

const ActionButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Poppins-Regular;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  outline: none;
  background: linear-gradient(270deg, #8abf4a 0%, #026639 100%);
  border-radius: 30px;
  padding: 0 25px;
  height: 44px;
  border: none;
  cursor: pointer;
`;

const RemoveAction = styled.div<{ disabled?: boolean }>`
  font-family: Poppins-Regular;
  font-weight: 500;
  font-size: 18px;
  color: rgba(61, 41, 36, 0.8);
  padding: 10px 0;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
`;

const ModalBackground = styled.div`
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
`;

const Modal = styled.div`
  background: #ffffff;
  box-shadow: 10px 30px 40px rgba(221, 219, 214, 0.46);
  border-radius: 3px;
  width: 100%;
  max-width: 950px;
  margin: 20px;
`;

const ModalHeader = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.18);
  border-radius: 3px;
  font-family: Poppins-Regular;
  font-size: 22px;
  color: rgba(61, 41, 36, 0.8);
  padding: 14px 31px 22px 31px;
  gap: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  img {
    cursor: pointer;
  }
`;

const ModalFooter = styled.div`
  padding: 18px 32px;
  background: #ffffff;
  border-top: 0.9px solid rgba(0, 0, 0, 0.14);
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 100%;
  gap: 14px;
  max-height: 70vh;
  overflow-y: auto;
`;

export {
  ModalBackground,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  RequiredText,
  FieldGroupContainer,
  DateGroupContainer,
  InputFiled,
  Error,
  ButtonGroup,
  ActionButton,
  RemoveAction,
};
