import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";

import { useEffect, useState, useRef } from "react";

import { getDataFromLocalStorage } from "../../browser-storage";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import * as DOMPurify from "dompurify";
import config from "../../services/config";
import MentorSliderMenu from "./mentorslidermenu";
import { v4 as uuid } from "uuid";

import {
  AddMentorDynamicTabs,
  getDynamicTabsDatabyMentorId,
  UpdateDynamicCourseListing,
  UpdateDynamicTabsListing,
} from "../../services/dynamictabs-service";
const MentorManageDynamicTabs = () => {
  const [dynamictabslisting, setDynamicTabListing] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [coursecreated, setcoursecreated] = useState("false");
  const MentorDetials = getDataFromLocalStorage("user-details");
  useEffect(() => {
    getDynamicTabsListingByMentorId();
  }, []);
  useEffect(() => {
    getDynamicTabsDatabyMentorId();
  }, []);

  let getDynamicTabsListingByMentorId = async () => {
    console.log(MentorDetials);

    const currentemail = MentorDetials.userName;
    console.log("currentemail", currentemail);
    setDynamicTabsDetails({
      dynamicTabForMentorId: currentemail,
    });

    const SubmitResponse = await getDynamicTabsDatabyMentorId(currentemail)
      .then((resp) => {
        console.log(resp);
        const respreverse = resp.reverse();

        setDynamicTabListing(respreverse);
        if (resp.length > 0) {
          setcoursecreated("true");

          // Perform additional actions if needed
        }

        //GetMentorProfileById(currentemail);
      })
      .catch((err) => console.log(err));
  };

  const [DynamicTabsDetails, setDynamicTabsDetails] = useState({
    dynamicTabForMentorId: "",
    dynamicTabsArray: [],
  });
  let onChange = (evt) => {
    const value = evt.target.value;
    setDynamicTabsDetails({
      ...DynamicTabsDetails,
      [evt.target.name]: value,
    });
    console.log(DynamicTabsDetails);
  };

  let EditHandler = (item) => {
    console.log(item);
    console.log(item.dynamicTabForMentorId);
    setEditMode(true);
    setDynamicTabsDetails({
      dynamicTabForMentorId: item.dynamicTabForMentorId,
      dynamicTabsArray: item.dynamicTabsArray,
    });
    setDynamicTabs(item.dynamicTabsArray);
  };

  //   let DeleteHandler = (item) => {
  //     console.log(item);
  //     const dynamicTabForMentorId = item.course_code;
  //     DeleteCourseListing(course_code).then((resp) => {
  //       console.log(resp);
  //       getCourseListingByMentor();
  //     });
  //   };

  const [DynamicTabs, setDynamicTabs] = useState([
    {
      dynamicTabId: "",
      dynamicTabTitle: "",
      dynamicTabCondition: "",
      dynamicTabOrder: "",
      //   dynamicTabCourseId: "",
      //   dynamicTabLink: "",
      //   dynamicTabIcon: "",
      //   dynamicTabAlt: "",
    },
  ]);
  useEffect(() => {
    // This code will run when the component mounts
    const initialDynamicTab = {
      dynamicTabId: "",
      dynamicTabTitle: "",
      dynamicTabCondition: generateRandomId(),
      dynamicTabOrder: "",
    };

    setDynamicTabs([initialDynamicTab]);
  }, []); // The empty dependency array ensures this effect runs only once on mount

  let DynamicTabshandleChange = (i, e) => {
    let value = [...DynamicTabs];
    value[i][e.target.name] = e.target.value;
    DynamicTabs[i][e.target.name] = value[i][e.target.name];
    setDynamicTabs(DynamicTabs);
    setDynamicTabs([...DynamicTabs]);
    console.log(DynamicTabs);
  };

  const generateRandomId = () => {
    // Function to generate a random unique 8-character string
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomId = "";
    for (let i = 0; i < 8; i++) {
      randomId += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    let finalcode = MentorDetials.userName + "_" + randomId;
    return finalcode;
  };

  let addDynamicTabsFields = () => {
    setDynamicTabs([
      ...DynamicTabs,
      {
        dynamicTabId: "",
        dynamicTabTitle: "",
        dynamicTabCondition: generateRandomId(),
        dynamicTabOrder: "",
        //   dynamicTabCourseId: "",
        //   dynamicTabLink: "",
        //   dynamicTabIcon: "",
        //   dynamicTabAlt: "",
      },
    ]);
  };

  let removeDynamicTabsFields = (i) => {
    let value = [...DynamicTabs];
    value.splice(i, 1);
    setDynamicTabs(value);
  };

  let SubmitDynamicTabsDetials = async () => {
    console.log("DynamicTabs", DynamicTabs);
    DynamicTabsDetails.dynamicTabsArray = DynamicTabs;
    console.log(DynamicTabsDetails);

    const SubmitResponse = await AddMentorDynamicTabs(DynamicTabsDetails)
      .then((resp) => {
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
        window.location.reload();
      })
      .catch((err) =>
        toast.error(`Error ${err} `, {
          position: "top-left",
        })
      );
  };

  let SubmitEditDynamicTabsDetails = async () => {
    DynamicTabsDetails.dynamicTabsArray = DynamicTabs;
    console.log(DynamicTabsDetails);
    const SubmitResponse = await UpdateDynamicTabsListing(DynamicTabsDetails)
      .then((resp) => {
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
      })
      .catch((err) =>
        toast.error(`Error ${err} `, {
          position: "top-left",
        })
      );
  };

  const switchtoaddmode = () => {};
  return (
    <Wrapper>
      <SEO pageTitle={"Manage Courses"} />
      <MentorSliderMenu />

      <Container>
        <Row style={{ marginTop: "50px" }}>
          <Col className="divscroll">
            <Card className="MainCard">
              <div className="section__title-wrapper mb-45">
                <h2 className="section__title">
                  All{" "}
                  <span className="yellow-bg">
                    Courses{" "}
                    <img src="/assets/img/shape/yellow-bg-2.png" alt="" />
                  </span>
                  Listing
                </h2>
              </div>
              {dynamictabslisting.map((item) => {
                return (
                  <Card className="carddesign">
                    <Container className="jobcontainer">
                      <Row>
                        <p className="jobtitle">
                          <b className="boldtitle">
                            Courses for Mentor Id: &nbsp;&nbsp;
                            {item.dynamicTabForMentorId}
                          </b>
                        </p>
                      </Row>

                      <Row>
                        <Col>
                          <button
                            className="e-btn"
                            onClick={() => EditHandler(item)}
                          >
                            Edit
                          </button>
                        </Col>
                        {/* <Col>
                          <button
                            className="e-btn"
                            onClick={() => DeleteCourseHandler(item)}
                          >
                            Delete
                          </button>
                        </Col> */}
                      </Row>
                      <Row></Row>
                    </Container>
                  </Card>
                );
              })}
            </Card>
          </Col>
          <Col>
            <Card className="MainCard">
              {editMode == false ? (
                <>
                  <Col>
                    <div className="section__title-wrapper mb-45">
                      <h2 className="section__title">
                        <span className="yellow-bg">
                          Add{" "}
                          <img src="/assets/img/shape/yellow-bg-2.png" alt="" />
                        </span>
                        Course
                      </h2>
                    </div>
                  </Col>
                </>
              ) : (
                <>
                  <Container>
                    <Row>
                      <Col sm={8}>
                        <div className="section__title-wrapper mb-45">
                          <h2 className="section__title">
                            Edit{" "}
                            <span className="yellow-bg">
                              Course{" "}
                              <img
                                src="/assets/img/shape/yellow-bg-2.png"
                                alt=""
                              />
                            </span>
                            Listing
                          </h2>
                        </div>
                      </Col>
                      {/* <Col sm={4}>
                        <button className="e-btn" onClick={switchtoaddmode}>
                          Switch To Add Mode
                        </button>
                      </Col> */}
                    </Row>
                  </Container>
                </>
              )}

              <Container>
                <div className="MobTextStyle">
                  <input
                    placeholder="Mentor Email Id"
                    className="form-control marginTop5"
                    type="text"
                    name="dynamicTabForMentorId"
                    value={DynamicTabsDetails.dynamicTabForMentorId}
                    disabled
                  />
                </div>
                <Card className="carddesign marginTop5">
                  <Card.Body>
                    <Card.Title>Courses</Card.Title>
                    <Card.Text>
                      {editMode == true || coursecreated == "false" ? (
                        <>
                          {DynamicTabs.map((element, index) => (
                            <div className="form-inline" key={index}>
                              <input
                                placeholder="Course Name"
                                className="form-control"
                                type="text"
                                name="dynamicTabTitle"
                                value={element.dynamicTabTitle}
                                onChange={(e) =>
                                  DynamicTabshandleChange(index, e)
                                }
                              />
                              <input
                                placeholder="Unique Keyword for Course"
                                className="form-control"
                                type="text"
                                name="dynamicTabCondition"
                                value={element.dynamicTabCondition}
                                onChange={(e) =>
                                  DynamicTabshandleChange(index, e)
                                }
                              />

                              {index ? (
                                <button
                                  type="button"
                                  className="btn btn-primary button remove"
                                  onClick={() => removeDynamicTabsFields(index)}
                                >
                                  Remove
                                </button>
                              ) : null}
                              <hr />
                            </div>
                          ))}
                          <div className="button-section">
                            <button
                              className="e-btn"
                              type="button"
                              onClick={() => addDynamicTabsFields()}
                            >
                              Add
                            </button>
                          </div>
                        </>
                      ) : null}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Container>
              {editMode == false ? (
                <>
                  {coursecreated == "false" ? (
                    <>
                      <button
                        className="e-btn"
                        onClick={SubmitDynamicTabsDetials}
                      >
                        Add Submit
                      </button>
                    </>
                  ) : (
                    <>
                      You have already created course, please use edit to create
                      more courses
                    </>
                  )}
                </>
              ) : (
                <button
                  className="e-btn marginTop5"
                  onClick={SubmitEditDynamicTabsDetails}
                >
                  Edit Submit
                </button>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default MentorManageDynamicTabs;
