import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link, useNavigate } from "react-router-dom";
import JobSlice from "../jobs/jobslice";
import "./recuiter-portal.css";
const RecuiterLandingPage = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <SEO pageTitle={"RecuiterLandingPage"} />
      <>
        {/* CONTENT START */}
        <div class="page-content">
          <div class="twm-home4-banner-section site-bg-light-purple">
            <div class="row">
              {/*Left Section*/}
              <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="twm-bnr-left-section">
                  <div class="twm-bnr-title-large">
                    Partner with us to{" "}
                    {/* <span class="site-text-primary">Greatly Expedite </span> */}
                    expedite your hiring process!
                  </div>
                  <div class="twm-bnr-discription">
                    Select amazing talent for your entry level positions from
                    our database of candidates across all major locations in
                    India.
                  </div>
                  <div class="form-group col-xl-4 col-lg-4 col-md-4">
                    <Link to="/recuiter-signup" className="site-button">
                      Get started
                    </Link>
                  </div>
                </div>
              </div>

              {/*right Section*/}
              <div class="col-xl-6 col-lg-12 col-md-12">
                <div
                  class="twm-bnr-right-section anm"
                  data-wow-delay="1000ms"
                  data-speed-x="2"
                  data-speed-y="2"
                >
                  <div class="twm-graphics-h3 twm-bg-line">
                    <img src="/assets/img/ghjob/banner/bg-line.png" alt="" />
                  </div>

                  <div class="twm-graphics-user twm-user">
                    <img src="/assets/img/ghjob/banner/user.png" alt="" />
                  </div>

                  <div class="twm-graphics-h3 twm-bg-plate">
                    <img src="/assets/img/ghjob/banner/bg-plate.png" alt="" />
                  </div>

                  <div class="twm-graphics-h3 twm-checked-plate">
                    <img
                      src="/assets/img/ghjob/banner/checked-plate.png"
                      alt=""
                    />
                  </div>

                  <div class="twm-graphics-h3 twm-blue-block">
                    <img src="/assets/img/ghjob/banner/blue-block.png" alt="" />
                  </div>

                  <div class="twm-graphics-h3 twm-color-dotts">
                    <img
                      src="/assets/img/ghjob/banner/color-dotts.png"
                      alt=""
                    />
                  </div>

                  <div
                    class="twm-graphics-h3 twm-card-large anm"
                    data-speed-y="-2"
                    data-speed-scale="-15"
                    data-speed-opacity="50"
                  >
                    <img src="/assets/img/ghjob/banner/card.png" alt="" />
                  </div>

                  <div
                    class="twm-graphics-h3 twm-card-s1 anm"
                    data-speed-y="2"
                    data-speed-scale="15"
                    data-speed-opacity="50"
                  >
                    <img src="/assets/img/ghjob/banner/card-s1.png" alt="" />
                  </div>

                  <div
                    class="twm-graphics-h3 twm-card-s2 anm"
                    data-speed-x="-4"
                    data-speed-scale="-25"
                    data-speed-opacity="50"
                  >
                    <img src="/assets/img/ghjob/banner/card-s2.png" alt="" />
                  </div>

                  <div class="twm-graphics-h3 twm-white-dotts">
                    <img
                      src="/assets/img/ghjob/banner/white-dotts.png"
                      alt=""
                    />
                  </div>

                  <div
                    class="twm-graphics-h3 twm-top-shadow anm"
                    data-speed-x="-16"
                    data-speed-y="2"
                    data-speed-scale="50"
                    data-speed-rotate="25"
                  >
                    <img src="/assets/img/ghjob/banner/top-shadow.png" alt="" />
                  </div>

                  <div
                    class="twm-graphics-h3 twm-bottom-shadow anm"
                    data-speed-x="16"
                    data-speed-y="2"
                    data-speed-scale="20"
                    data-speed-rotate="25"
                  >
                    <img
                      src="/assets/img/ghjob/banner/bottom-shadow.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* JOBS CATEGORIES SECTION START */}
          <div class="section-full p-t120 p-b90 site-bg-white twm-job-categories-area3">
            <div class="container">
              <div class="wt-separator-two-part">
                <div class="row wt-separator-two-part-row">
                  <div class="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-left">
                    {/* TITLE START*/}
                    <div class="section-head left wt-small-separator-outer">
                      <div class="wt-small-separator site-text-primary">
                        <div>Jobs by Categories</div>
                      </div>
                      {/* <h2 class="wt-title">Choose Your Desire Category</h2> */}
                    </div>
                    {/* TITLE END*/}
                  </div>
                  {/* <div class="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-right text-right">
                    <a href="job-list.html" class=" site-button">
                      All Categories
                    </a>
                  </div> */}
                </div>
              </div>

              <div class="twm-job-categories-section-3 m-b30">
                <div class="job-categories-style3">
                  <div class="row">
                    {/* COLUMNS 1 */}
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 m-b30">
                      <div class="job-categories-3-wrap">
                        <div class="job-categories-3">
                          <div class="twm-media">
                            <div className="">
                              <img src="/assets/img/ghjob/jobicon/business.png" />
                            </div>
                          </div>
                          <div class="twm-content">
                            {/* <div class="twm-jobs-available">9,185 Jobs</div> */}

                            <div>Business Operations</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* COLUMNS 2 */}
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 m-b30">
                      <div class="job-categories-3-wrap">
                        <div class="job-categories-3">
                          <div class="twm-media">
                            <div className="">
                              <img src="/assets/img/ghjob/jobicon/operation.png" />
                            </div>
                          </div>
                          <div class="twm-content">
                            {/* <div class="twm-jobs-available">3,205 Jobs</div> */}

                            <div>Core Engineering</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* COLUMNS 3 */}
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 m-b30">
                      <div class="job-categories-3-wrap">
                        <div class="job-categories-3">
                          <div class="twm-media">
                            <div className="">
                              <img src="/assets/img/ghjob/jobicon/it.png" />
                            </div>
                          </div>
                          <div class="twm-content">
                            {/* <div class="twm-jobs-available">2,100 Jobs</div> */}

                            <div>Information Technology</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* COLUMNS 4 */}
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 m-b30">
                      <div class="job-categories-3-wrap">
                        <div class="job-categories-3">
                          <div class="twm-media">
                            <div className="">
                              <img src="/assets/img/ghjob/jobicon/hr.png" />
                            </div>
                          </div>
                          <div class="twm-content">
                            {/* <div class="twm-jobs-available">1,500 Jobs</div> */}

                            <div>Human Resources</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* COLUMNS 5 */}
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 m-b30">
                      <div class="job-categories-3-wrap">
                        <div class="job-categories-3">
                          <div class="twm-media">
                            <div className="">
                              <img src="/assets/img/ghjob/jobicon/marketing.png" />
                            </div>
                          </div>
                          <div class="twm-content">
                            {/* <div class="twm-jobs-available">9,185 Jobs</div> */}

                            <div>Marketing</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* COLUMNS 6 */}
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 m-b30">
                      <div class="job-categories-3-wrap">
                        <div class="job-categories-3">
                          <div class="twm-media">
                            <div className="">
                              <img src="/assets/img/ghjob/jobicon/sale.png" />
                            </div>
                          </div>
                          <div class="twm-content">
                            {/* <div class="twm-jobs-available">3,205 Jobs</div> */}

                            <div>Sales</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* COLUMNS 7 */}
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 m-b30">
                      <div class="job-categories-3-wrap">
                        <div class="job-categories-3">
                          <div class="twm-media">
                            <div className="">
                              <img src="/assets/img/ghjob/jobicon/other.png" />
                            </div>
                          </div>
                          <div class="twm-content">
                            {/* <div class="twm-jobs-available">3,205 Jobs</div> */}

                            <div>Others</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* JOBS CATEGORIES SECTION END */}

          {/* ABOUT SECTION START */}
          <div
            style={{ paddingBottom: 40 }}
            class="section-full p-t120 p-b90 site-bg-gray twm-about-1-area"
          >
            <div class="container">
              <div class="twm-about-1-section-wrap">
                <div class="row">
                  <div class="col-lg-6 col-md-12">
                    <div class="twm-about-1-section">
                      <div class="twm-media">
                        <img src="/assets/img/ghjob/shape/ab-1.png" alt="" />
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-12">
                    <div class="twm-about-1-section-right">
                      {/* TITLE START*/}
                      <div class="section-head left wt-small-separator-outer">
                        <div class="wt-small-separator site-text-primary">
                          <div>Hire </div>
                        </div>
                        <h2 class="wt-title">
                          Best and brightest profiles to grow your company
                        </h2>
                      </div>
                      {/* TITLE END*/}
                      <ul class="description-list">
                        <li>
                          <i class="feather-check"></i>
                          Unlimited applications
                        </li>
                        <li>
                          <i class="feather-check"></i>
                          Profiles with Employability Scores
                        </li>
                        <li>
                          <i class="feather-check"></i>
                          No limit in number of job postings
                        </li>
                        <li>
                          <i class="feather-check"></i>
                          Zero fees
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div class="twm-about-1-bottom-wrap">
                <div class="row">
                  <div class="col-lg-4 col-md-6">
                  
                    <div class="twm-card-blocks">
                      <div class="twm-icon pink">
                        <img
                          src="images/main-slider/slider2/icon-2.png"
                          alt=""
                        />
                      </div>
                      <div class="twm-content">
                        <div class="tw-count-number text-clr-pink">
                          <span class="counter">98</span> +
                        </div>
                        <p class="icon-content-info">Job For Countries </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6">
              
                    <div class="twm-card-blocks-2">
                      <div class="twm-pics">
                        <span>
                          <img
                            src="images/main-slider/slider2/user/u-1.jpg"
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            src="images/main-slider/slider2/user/u-2.jpg"
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            src="images/main-slider/slider2/user/u-3.jpg"
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            src="images/main-slider/slider2/user/u-4.jpg"
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            src="images/main-slider/slider2/user/u-5.jpg"
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            src="images/main-slider/slider2/user/u-6.jpg"
                            alt=""
                          />
                        </span>
                      </div>
                      <div class="twm-content">
                        <div class="tw-count-number text-clr-green">
                          <span class="counter">3</span>K+
                        </div>
                        <p class="icon-content-info">Jobs Done</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6">
         
                    <div class="twm-card-blocks">
                      <div class="twm-icon">
                        <img
                          src="images/main-slider/slider2/icon-1.png"
                          alt=""
                        />
                      </div>
                      <div class="twm-content">
                        <div class="tw-count-number text-clr-sky">
                          <span class="counter">12</span>K+
                        </div>
                        <p class="icon-content-info">Companies Jobs</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* ABOUT SECTION END */}

          {/* How It Work START */}
          <div
            style={{ margin: 0 }}
            class="section-full site-bg-primary twm-how-it-work-1-area"
          >
            <div class="container">
              <div class="section-content">
                <div class="twm-how-it-work-1-content">
                  <div class="row">
                    <div class="col-xl-5 col-lg-12 col-md-12">
                      <div class="twm-how-it-work-1-left">
                        <div class="twm-how-it-work-1-section">
                          {/* TITLE START*/}
                          <div class="section-head left wt-small-separator-outer">
                            <div class="wt-small-separator">
                              <div></div>
                            </div>
                            <h2>How it works</h2>
                          </div>
                          {/* TITLE END*/}

                          <div class="twm-step-section-4">
                            <ul>
                              <li>
                                <div class="twm-step-count bg-clr-sky-light">
                                  01
                                </div>
                                <div class="twm-step-content">
                                  <h4 class="twm-title pt-25">
                                    REGISTER FOR FREE
                                  </h4>
                                  {/* <p>
                                    You need to create an account to find the
                                    best and preferred job.
                                  </p> */}
                                </div>
                              </li>

                              <li>
                                <div class="twm-step-count bg-clr-yellow-light">
                                  02
                                </div>
                                <div class="twm-step-content">
                                  <h4 class="twm-title pt-25">
                                    POST YOUR REQUIREMENTS
                                  </h4>
                                  {/* <p>
                                    After creating an account, search for your
                                    favorite job.
                                  </p> */}
                                </div>
                              </li>

                              <li>
                                <div class="twm-step-count bg-clr-pink-light">
                                  03
                                </div>
                                <div class="twm-step-content">
                                  <h4 class="twm-title pt-25">
                                    GET TOP CANDIDATES
                                  </h4>
                                  {/* <p>
                                    After creating the account, you have to
                                    apply for the desired job.
                                  </p> */}
                                </div>
                              </li>

                              <li>
                                <div class="twm-step-count bg-clr-green-light">
                                  04
                                </div>
                                <div class="twm-step-content">
                                  <h4 class="twm-title pt-25">
                                    DOWNLOAD RESUMES
                                  </h4>
                                  {/* <p>
                                    Upload your resume after filling all the
                                    relevant information.
                                  </p> */}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-7 col-lg-12 col-md-12">
                      <div class="twm-how-it-right-section">
                        <div class="twm-media">
                          <div class="twm-bg-circle">
                            <img
                              src="/assets/img/ghjob/how-it-work/bg-circle-large.png"
                              alt=""
                            />
                          </div>
                          <div
                            class="twm-block-left anm"
                            data-speed-x="-4"
                            data-speed-scale="-25"
                          >
                            <img
                              src="/assets/img/ghjob/how-it-work/block-left.png"
                              alt=""
                            />
                          </div>
                          <div
                            class="twm-block-right anm"
                            data-speed-x="-4"
                            data-speed-scale="-25"
                          >
                            <img
                              src="/assets/img/ghjob/how-it-work/block-right.png"
                              alt=""
                            />
                          </div>
                          <div
                            class="twm-main-bg anm"
                            data-wow-delay="1000ms"
                            data-speed-x="2"
                            data-speed-y="2"
                          >
                            <img
                              src="/assets/img/ghimages/signup_op.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* How It Work END */}

          {/* JOB POST START */}
          <div class="section-full p-t120 p-b90 site-bg-gray twm-bg-ring-wrap2">
            <div class="container">
              <div class="wt-separator-two-part">
                <div class="row wt-separator-two-part-row">
                  <div class="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-left">
                    {/* TITLE START*/}
                    <div class="section-head left wt-small-separator-outer">
                      {/* <div class="wt-small-separator site-text-primary">
                        <div>View Latest Jobs</div>
                      </div> */}
                      {/* <h2 class="wt-title">Current Jobs</h2> */}
                    </div>
                    {/* TITLE END*/}
                  </div>
                  {/* <div class="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-right text-right">
                    <a href="job-list.html" class=" site-button">
                      Browse All Jobs
                    </a>
                  </div> */}
                </div>
              </div>

              {/* <JobSlice />
              <div className="text-center m-b30">
                <a
                  onClick={() => navigate("/job-listings")}
                  className="site-button"
                >
                  View All Jobs
                </a>
              </div> */}

              {/* <div class="section-content">
                <div class="twm-jobs-grid-wrap">
                  <div class="row">
                    <div class="col-lg-4 col-md-6">
                      <div class="twm-jobs-featured-style1 m-b30">
                        <div class="twm-media">
                          <img
                            src="/assets/img/ghjob/jobs-company/pic1.jpg"
                            alt="#"
                          />
                        </div>

                        <div class="twm-jobs-category green">
                          <span class="twm-bg-green">New</span>
                        </div>
                        <div class="twm-mid-content">
                          <a href="job-detail.html" class="twm-job-title">
                            <h4>Senior Web Designer</h4>
                          </a>
                        </div>
                        <div class="twm-bot-content">
                          <p class="twm-job-address">
                            <i class="feather-map-pin"></i>London, UK
                          </p>
                          <span class="twm-job-post-duration">1 days ago</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                      <div class="twm-jobs-featured-style1 m-b30">
                        <div class="twm-media">
                          <img
                            src="/assets/img/ghjob/jobs-company/pic2.jpg"
                            alt="#"
                          />
                        </div>

                        <div class="twm-jobs-category green">
                          <span class="twm-bg-brown">Intership</span>
                        </div>
                        <div class="twm-mid-content">
                          <a href="job-detail.html" class="twm-job-title">
                            <h4>Senior Stock Technician</h4>
                          </a>
                        </div>
                        <div class="twm-bot-content">
                          <p class="twm-job-address">
                            <i class="feather-map-pin"></i>Florida, US
                          </p>
                          <span class="twm-job-post-duration">15 days ago</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                      <div class="twm-jobs-featured-style1 m-b30">
                        <div class="twm-media">
                          <img
                            src="/assets/img/ghjob/jobs-company/pic3.jpg"
                            alt="#"
                          />
                        </div>

                        <div class="twm-jobs-category green">
                          <span class="twm-bg-purple">Fulltime</span>
                        </div>
                        <div class="twm-mid-content">
                          <a href="job-detail.html" class="twm-job-title">
                            <h4 class="twm-job-title">IT Department Manager</h4>
                          </a>
                        </div>
                        <div class="twm-bot-content">
                          <p class="twm-job-address">
                            <i class="feather-map-pin"></i>Alaska, US
                          </p>
                          <span class="twm-job-post-duration">6 Month ago</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                      <div class="twm-jobs-featured-style1 m-b30">
                        <div class="twm-media">
                          <img
                            src="/assets/img/ghjob/jobs-company/pic4.jpg"
                            alt="#"
                          />
                        </div>

                        <div class="twm-jobs-category green">
                          <span class="twm-bg-sky">Freelancer</span>
                        </div>
                        <div class="twm-mid-content">
                          <a href="job-detail.html" class="twm-job-title">
                            <h4 class="twm-job-title">
                              Art Production Specialist
                            </h4>
                          </a>
                        </div>
                        <div class="twm-bot-content">
                          <p class="twm-job-address">
                            <i class="feather-map-pin"></i>Texas, US
                          </p>
                          <span class="twm-job-post-duration">2 days ago</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                      <div class="twm-jobs-featured-style1 m-b30">
                        <div class="twm-media">
                          <img
                            src="/assets/img/ghjob/jobs-company/pic5.jpg"
                            alt="#"
                          />
                        </div>

                        <div class="twm-jobs-category green">
                          <span class="twm-bg-golden">Temporary</span>
                        </div>
                        <div class="twm-mid-content">
                          <a href="job-detail.html" class="twm-job-title">
                            <h4 class="twm-job-title">Recreation & Fitness</h4>
                          </a>
                        </div>
                        <div class="twm-bot-content">
                          <p class="twm-job-address">
                            <i class="feather-map-pin"></i>Georgia US
                          </p>
                          <span class="twm-job-post-duration">1 days ago</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                      <div class="twm-jobs-featured-style1 m-b30">
                        <div class="twm-media">
                          <img
                            src="/assets/img/ghjob/jobs-company/pic1.jpg"
                            alt="#"
                          />
                        </div>

                        <div class="twm-jobs-category green">
                          <span class="twm-bg-green">New</span>
                        </div>
                        <div class="twm-mid-content">
                          <a href="job-detail.html" class="twm-job-title">
                            <h4>Senior Web Developer</h4>
                          </a>
                        </div>
                        <div class="twm-bot-content">
                          <p class="twm-job-address">
                            <i class="feather-map-pin"></i> Arizona, US
                          </p>
                          <span class="twm-job-post-duration">1 days ago</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* JOB POST END */}
        </div>
        {/* CONTENT END */}
      </>
    </Wrapper>
  );
};

export default RecuiterLandingPage;
