import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import "./resumebuilder.css";
const ResumeBuilderToolOptionTwo = () => {
  const MonthArray = [
    { name: "January", value: "Jan" },
    { name: "February", value: "Feb" },
    { name: "March", value: "Mar" },
    { name: "April", value: "Apr" },
    { name: "May", value: "May" },
    { name: "June", value: "Jun" },
    { name: "July", value: "Jul" },
    { name: "August", value: "Aug" },
    { name: "September", value: "Sep" },
    { name: "October", value: "Oct" },
    { name: "November", value: "Nov" },
    { name: "December", value: "Dec" },
  ];

  const YearArray = [
    "2025",
    "2024",
    "2023",
    "2022",
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
    "2016",
    "2015",
    "2014",
    "2013",
    "2012",
    "2011",
    "2010",
  ];
  const [descriptionfontsize, setdescriptionfontsize] = useState(16);
  const [InternshipformValues, setInternshipFormValues] = useState([
    {
      startYear: "",
      startMonth: "",
      endYear: "",
      endMonth: "",
      companyName: "",
      designationName: "",
      duration: "",
      description: [],
    },
  ]);

  const InternshiphandleInputChange = (index, event) => {
    const values = [...InternshipformValues];
    values[index][event.target.name] = event.target.value;
    setInternshipFormValues(values);
  };

  const InternshiphandleSubInputChange = (parentIndex, childIndex, event) => {
    const values = [...InternshipformValues];
    values[parentIndex].description[childIndex].value = event.target.value;
    setInternshipFormValues(values);
  };

  //Not Being Used
  const InternshipaddInputField = (index) => {
    const values = [...InternshipformValues];
    values[index].description.push({ value: "" });
    setInternshipFormValues(values);
  };

  const InternshipaddSubInputField = (parentIndex) => {
    const values = [...InternshipformValues];
    values[parentIndex].description.push({ value: "" });
    setInternshipFormValues(values);
  };
  const InternshipremoveInputField = (index) => {
    const values = [...InternshipformValues];
    values.splice(index, 1);
    setInternshipFormValues(values);
  };

  const InternshipremoveSubInputField = (parentIndex, childIndex) => {
    const values = [...InternshipformValues];
    values[parentIndex].description.splice(childIndex, 1);
    setInternshipFormValues(values);
  };

  const [WorkExprienceformValues, setWorkExprienceFormValues] = useState([
    {
      startYear: "",
      startMonth: "",
      endYear: "",
      endMonth: "",
      companyName: "",
      designationName: "",
      duration: "",
      description: [],
    },
  ]);

  const WorkExphandleInputChange = (index, event) => {
    const values = [...InternshipformValues];
    values[index][event.target.name] = event.target.value;
    setInternshipFormValues(values);
  };

  const WorkExphandleSubInputChange = (parentIndex, childIndex, event) => {
    const values = [...InternshipformValues];
    values[parentIndex].description[childIndex].value = event.target.value;
    setInternshipFormValues(values);
  };

  //Not Being Used
  const WorkExpaddInputField = (index) => {
    const values = [...InternshipformValues];
    values[index].description.push({ value: "" });
    setInternshipFormValues(values);
  };

  const WorkExpaddSubInputField = (parentIndex) => {
    const values = [...InternshipformValues];
    values[parentIndex].description.push({ value: "" });
    setInternshipFormValues(values);
  };
  const WorkExpremoveInputField = (index) => {
    const values = [...InternshipformValues];
    values.splice(index, 1);
    setInternshipFormValues(values);
  };

  const WorkExpremoveSubInputField = (parentIndex, childIndex) => {
    const values = [...InternshipformValues];
    values[parentIndex].description.splice(childIndex, 1);
    setInternshipFormValues(values);
  };

  return (
    <>
      <form>
        {InternshipformValues.map((field, index) => (
          <div key={index}>
            <div className="row">
              <div className="col-md-3">
                <div class="d-flex flex-row bd-highlight mb-3">
                  <div class="p-2 bd-highlight">
                    <select
                      name="startMonth"
                      className="form-control dateHeight"
                      value={field.startMonth}
                      onChange={(event) =>
                        InternshiphandleInputChange(index, event)
                      }
                      placeholder="StartMonth"
                    >
                      <option name="" value="" style={{ display: "none" }}>
                        Start Month
                      </option>
                      {MonthArray.map((element) => {
                        return (
                          <>
                            <option name={element.name} value={element.value}>
                              {element.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  <div class="p-2 bd-highlight">
                    <span
                      style={{ height: 35, marginTop: 8 }}
                      className="dateHeight"
                    >
                      /
                    </span>
                  </div>
                  <div class="p-2 bd-highlight">
                    <select
                      name="startYear"
                      className="form-control dateHeight"
                      value={field.startYear}
                      onChange={(event) =>
                        InternshiphandleInputChange(index, event)
                      }
                      placeholder="StartYear"
                    >
                      <option name="" value="" style={{ display: "none" }}>
                        Start Year
                      </option>
                      {YearArray.map((element) => {
                        return (
                          <>
                            <option name={element} value={element}>
                              {element}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div class="d-flex flex-row bd-highlight mb-3">
                  <div class="p-2 bd-highlight">
                    <select
                      name="endMonth"
                      className="form-control"
                      value={field.endMonth}
                      onChange={(event) =>
                        InternshiphandleInputChange(index, event)
                      }
                      placeholder="EndMonth"
                    >
                      <option name="" value="" style={{ display: "none" }}>
                        End Month
                      </option>
                      {MonthArray.map((element) => {
                        return (
                          <>
                            <option name={element.name} value={element.value}>
                              {element.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  <div class="p-2 bd-highlight">
                    <span
                      style={{ height: 35, marginTop: 8 }}
                      className="dateHeight"
                    >
                      /
                    </span>
                  </div>
                  <div class="p-2 bd-highlight">
                    <select
                      name="endYear"
                      className="form-control"
                      value={field.endYear}
                      onChange={(event) =>
                        InternshiphandleInputChange(index, event)
                      }
                      placeholder="EndYear"
                    >
                      <option name="" value="" style={{ display: "none" }}>
                        End Year
                      </option>
                      {YearArray.map((element) => {
                        return (
                          <>
                            <option name={element} value={element}>
                              {element}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <input
              placeholder="Company Name"
              className="form-control"
              type="text"
              name="companyName"
              value={field.companyName}
              onChange={(event) => InternshiphandleInputChange(index, event)}
            />
            <input
              placeholder="Designation Name"
              className="form-control"
              type="text"
              name="designationName"
              value={field.designationName}
              onChange={(event) => InternshiphandleInputChange(index, event)}
            />
            {field.description.map((subField, subIndex) => (
              <div key={subIndex}>
                <div className="row">
                  <div className="col-md-6">
                    {" "}
                    <textarea
                      placeholder="Add Intership Tasks"
                      className="form-control"
                      id="description"
                      name="description"
                      rows="10"
                      cols="35"
                      value={subField.description}
                      onChange={(event) =>
                        InternshiphandleSubInputChange(index, subIndex, event)
                      }
                    ></textarea>
                  </div>
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="e-btn"
                      style={{ margin: 15, color: "white", background: "red" }}
                      onClick={() =>
                        InternshipremoveSubInputField(index, subIndex)
                      }
                    >
                      Remove Internship Task
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <button
              className="e-btn"
              style={{ margin: 15 }}
              type="button"
              onClick={() => InternshipaddSubInputField(index)}
            >
              Add Internship Task
            </button>
            <button
              className="e-btn"
              style={{ margin: 15, color: "white", background: "red" }}
              type="button"
              onClick={() => InternshipremoveInputField(index)}
            >
              Remove Field
            </button>
          </div>
        ))}
        <button
          type="button"
          className="e-btn"
          style={{ margin: 15 }}
          onClick={() =>
            setInternshipFormValues([
              ...InternshipformValues,
              {
                startYear: "",
                startMonth: "",
                endYear: "",
                endMonth: "",
                companyName: "",
                designationName: "",
                duration: "",
                description: [],
              },
            ])
          }
        >
          Add Field
        </button>
        {WorkExprienceformValues.map((field, index) => (
          <div key={index}>
            <div className="row">
              <div className="col-md-3">
                <div class="d-flex flex-row bd-highlight mb-3">
                  <div class="p-2 bd-highlight">
                    <select
                      name="startMonth"
                      className="form-control dateHeight"
                      value={field.startMonth}
                      onChange={(event) =>
                        WorkExphandleInputChange(index, event)
                      }
                      placeholder="StartMonth"
                    >
                      <option name="" value="" style={{ display: "none" }}>
                        Start Month
                      </option>
                      {MonthArray.map((element) => {
                        return (
                          <>
                            <option name={element.name} value={element.value}>
                              {element.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  <div class="p-2 bd-highlight">
                    <span
                      style={{ height: 35, marginTop: 8 }}
                      className="dateHeight"
                    >
                      /
                    </span>
                  </div>
                  <div class="p-2 bd-highlight">
                    <select
                      name="startYear"
                      className="form-control dateHeight"
                      value={field.startYear}
                      onChange={(event) =>
                        WorkExphandleInputChange(index, event)
                      }
                      placeholder="StartYear"
                    >
                      <option name="" value="" style={{ display: "none" }}>
                        Start Year
                      </option>
                      {YearArray.map((element) => {
                        return (
                          <>
                            <option name={element} value={element}>
                              {element}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div class="d-flex flex-row bd-highlight mb-3">
                  <div class="p-2 bd-highlight">
                    <select
                      name="endMonth"
                      className="form-control"
                      value={field.endMonth}
                      onChange={(event) =>
                        WorkExphandleInputChange(index, event)
                      }
                      placeholder="EndMonth"
                    >
                      <option name="" value="" style={{ display: "none" }}>
                        End Month
                      </option>
                      {MonthArray.map((element) => {
                        return (
                          <>
                            <option name={element.name} value={element.value}>
                              {element.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  <div class="p-2 bd-highlight">
                    <span
                      style={{ height: 35, marginTop: 8 }}
                      className="dateHeight"
                    >
                      /
                    </span>
                  </div>
                  <div class="p-2 bd-highlight">
                    <select
                      name="endYear"
                      className="form-control"
                      value={field.endYear}
                      onChange={(event) =>
                        WorkExphandleInputChange(index, event)
                      }
                      placeholder="EndYear"
                    >
                      <option name="" value="" style={{ display: "none" }}>
                        End Year
                      </option>
                      {YearArray.map((element) => {
                        return (
                          <>
                            <option name={element} value={element}>
                              {element}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <input
              placeholder="Company Name"
              className="form-control"
              type="text"
              name="companyName"
              value={field.companyName}
              onChange={(event) => WorkExphandleInputChange(index, event)}
            />
            <input
              placeholder="Designation Name"
              className="form-control"
              type="text"
              name="designationName"
              value={field.designationName}
              onChange={(event) => WorkExphandleInputChange(index, event)}
            />
            {field.description.map((subField, subIndex) => (
              <div key={subIndex}>
                <div className="row">
                  <div className="col-md-6">
                    {" "}
                    <textarea
                      placeholder="Add Intership Tasks"
                      className="form-control"
                      id="description"
                      name="description"
                      rows="10"
                      cols="35"
                      value={subField.description}
                      onChange={(event) =>
                        WorkExphandleSubInputChange(index, subIndex, event)
                      }
                    ></textarea>
                  </div>
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="e-btn"
                      style={{ margin: 15, color: "white", background: "red" }}
                      onClick={() =>
                        WorkExpremoveSubInputField(index, subIndex)
                      }
                    >
                      Remove Internship Task
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <button
              className="e-btn"
              style={{ margin: 15 }}
              type="button"
              onClick={() => WorkExpaddSubInputField(index)}
            >
              Add Internship Task
            </button>
            <button
              className="e-btn"
              style={{ margin: 15, color: "white", background: "red" }}
              type="button"
              onClick={() => WorkExpremoveInputField(index)}
            >
              Remove Field
            </button>
          </div>
        ))}
        <button
          type="button"
          className="e-btn"
          style={{ margin: 15 }}
          onClick={() =>
            setWorkExprienceFormValues([
              ...WorkExprienceformValues,
              {
                startYear: "",
                startMonth: "",
                endYear: "",
                endMonth: "",
                companyName: "",
                designationName: "",
                duration: "",
                description: [],
              },
            ])
          }
        >
          Add Field
        </button>
      </form>
      <h1>Preview</h1>
      {InternshipformValues.map((item) => {
        console.log(item);
        return (
          <div style={{ marginBottom: "10px" }}>
            <div className="row">
              <p
                style={{
                  color: "#04355f",
                  marginBottom: "0px!important",
                  marginTop: "0px",
                  fontSize: `${descriptionfontsize}px`,
                  fontWeight: 600,
                }}
              >
                {item.designationName}
                {item.designationName ? <>,&nbsp;</> : null}
                {item.companyName}
              </p>
              <p
                style={{
                  fontSize: `${descriptionfontsize}px`,
                }}
              >
                {item.startMonth} {item.startYear}{" "}
                {item.startYear !== "" ? "-" : null} {item.endMonth}{" "}
                {item.endYear}
              </p>

              {item.description.map((subField, subIndex) => (
                <div key={subIndex}>
                  <div class="d-flex flex-row bd-highlight mb-3">
                    <div class="p-2 bd-highlight">
                      {" "}
                      <img
                        width="16"
                        src="https://cdn.pixabay.com/photo/2012/04/24/13/58/arrow-40166_1280.png"
                      />
                    </div>
                    <div class="p-2 bd-highlight">{subField.value}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
      {WorkExprienceformValues.map((item) => {
        console.log(item);
        return (
          <div style={{ marginBottom: "10px" }}>
            <div className="row">
              <p
                style={{
                  color: "#04355f",
                  marginBottom: "0px!important",
                  marginTop: "0px",
                  fontSize: `${descriptionfontsize}px`,
                  fontWeight: 600,
                }}
              >
                {item.designationName}
                {item.designationName ? <>,&nbsp;</> : null}
                {item.companyName}
              </p>
              <p
                style={{
                  fontSize: `${descriptionfontsize}px`,
                }}
              >
                {item.startMonth} {item.startYear}{" "}
                {item.startYear !== "" ? "-" : null} {item.endMonth}{" "}
                {item.endYear}
              </p>

              {item.description.map((subField, subIndex) => (
                <div key={subIndex}>
                  <div class="d-flex flex-row bd-highlight mb-3">
                    <div class="p-2 bd-highlight">
                      {" "}
                      <img
                        width="16"
                        src="https://cdn.pixabay.com/photo/2012/04/24/13/58/arrow-40166_1280.png"
                      />
                    </div>
                    <div class="p-2 bd-highlight">{subField.value}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ResumeBuilderToolOptionTwo;
