import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const MentorDashboard = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/mentor-profile", { replace: true });
  }, []);
  return <> </>;
};

export default MentorDashboard;
