import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { getDataFromLocalStorage } from "../../browser-storage";
import { toast } from "react-toastify";
import {
  AddMentorPDFEntry,
  AddMentorProfile,
  DeletePDFListing,
  GetMentorPDFbyIdService,
  GetMentorProfile,
  UpdateMentorProfileById,
  UpdateMentorPDFEntry,
} from "../../services/mentor-service";
import MentorSliderMenu from "./mentorslidermenu";
import { Col, Row } from "react-bootstrap";
import Dropzone from "react-dropzone";
import Modal from "react-modal";
import { toBase64 } from "../../helpers/index";
import styled from "styled-components";
import { UploadMentorPDF } from "../../services/user-service";
import PdfViewer from "./PdfViewer";
//import { useDropzone } from "react-dropzone";
const DragDropContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 232px;
  border: 1px dashed #c4c4c4;
  border-radius: 5px;
  gap: 8px;
  div:nth-child(1) {
    font-family: Poppins-Bold;
    font-size: 24px;
    color: rgba(61, 41, 36, 0.8);
  }
  div:nth-child(2) {
    font-family: Poppins-Regular;
    font-size: 18px;
    color: rgba(61, 41, 36, 0.8);
  }
  img {
    width: 220px;
    height: 220px;
    border-radius: 5px;
  }
`;

const PdfUploader = () => {
  const [mentorpdfdetials, setMentorPDFDetials] = useState({
    userEmailId: "",
    userId: "",
    pdfEntryUniqueCode: "",
    MentorEmailId: "",
    pdfName: "",
    pdfDescription: "",
    pdfOrder: "",
    pdfLink: "",
    pdf_linked_to_course: "",
  });
  const [mentorpdfdetialsArray, setMentorPDFDetialsArray] = useState([]);

  const UpdatePdf = (item) => {
    console.log(item);
    setedit("true");
    setMentorPDFDetials({
      ...mentorpdfdetials,
      pdfName: item.pdfName,
      pdfDescription: item.pdfDescription,
      pdfOrder: item.pdfOrder,
      pdf_linked_to_course: item.pdf_linked_to_course,
      pdfEntryUniqueCode: item.pdfEntryUniqueCode,
    });
  };

  const userDetials = getDataFromLocalStorage("user-details");

  useEffect(() => {
    console.log(userDetials);

    const currentemail = userDetials.userName;

    setMentorPDFDetials({ userEmailId: currentemail });
    GetMentorPDFById(currentemail);
  }, []);

  //Uploader Code
  //const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [showErrors, setShowErrors] = useState(false);

  const [isedit, setedit] = useState("false");

  //Using Old login
  const setupPDF = async (file) => {
    console.log("file", file);
    console.log(file[0]);
    const base64 = await toBase64(file[0]).catch(() =>
      toast.error(`Unable to Upload Image`, {
        position: "top-left",
      })
    );
    console.log("base64", base64);
    if (base64) {
      setUploadedFile({ uploadedFile: file[0], fileUrl: base64 });
    }
    console.log("uploadedFile", uploadedFile.fileUrl);
    setShowErrors(false);
  };

  const uploadPDF = () => {
    if (!uploadedFile.uploadedFile || showErrors) {
      return;
    }

    UploadMentorPDF(uploadedFile.uploadedFile)
      .then((data) => {
        console.log(data);
        toast.success(`Profile picture updated successfully`, {
          position: "top-left",
        });
        const resp = data.pdfLink;
        setMentorPDFDetials({
          ...mentorpdfdetials,
          pdfLink: resp,
        });
      })
      .catch(() =>
        toast.error(`Unable to update your profile picture`, {
          position: "top-left",
        })
      );
  };

  let ProfileDetailsonChange = (evt) => {
    const value = evt.target.value;
    setMentorPDFDetials({
      ...mentorpdfdetials,
      [evt.target.name]: value,
    });
    console.log(mentorpdfdetials);
  };

  let SubmitMentorPDFEntry = async () => {
    //logic to get uploaded pdf and mix ad upload
    setMentorPDFDetials({
      ...mentorpdfdetials,
      pdfLink:
        "https://s3.ap-south-1.amazonaws.com/qa.mygreenhorn.com/assets/img/ghimages/blankprofile.png",
    });
    console.log(mentorpdfdetials);
    //final entry with pdf link
    const SubmitResponse = await AddMentorPDFEntry(mentorpdfdetials)
      .then((resp) => {
        console.log(resp);
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
        //window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Error ${err.response.data} `, {
          position: "top-left",
        });
      });
  };

  //Fetch
  let GetMentorPDFById = async (currentemail) => {
    const SubmitResponse = await GetMentorPDFbyIdService(currentemail)
      .then((resp) => {
        console.log(resp);
        const currentResp = resp;
        setMentorPDFDetialsArray(currentResp);
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Error ${err.response.data} `, {
          position: "top-left",
        });
      });
  };

  const deletePDF = async (item) => {
    console.log("item", item);
    const SubmitResponse = await DeletePDFListing(item)
      .then((resp) => {
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Error ${err.response.data} `, {
          position: "top-left",
        });
      });
  };

  const SubmitUpdateMentorPDFEntry = async () => {
    const SubmitResponse = await UpdateMentorPDFEntry(mentorpdfdetials)
      .then((resp) => {
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
        GetMentorPDFById(userDetials.userName);
      })
      .catch((err) =>
        toast.error(`Error ${err} `, {
          position: "top-left",
        })
      );
  };
  const switchtoaddmode = () => {
    setedit("false");
    setMentorPDFDetials({
      pdfName: "",
      pdfDescription: "",
      pdfOrder: "",
      pdf_linked_to_course: "",
    });
  };
  return (
    <Wrapper>
      <SEO pageTitle={"Mentor Profile"} />
      <MentorSliderMenu />
      <Row>
        <Col sm={9}></Col>
        <Col sm={3}>
          <button className="e-btn" onClick={switchtoaddmode}>
            Switch To Add New PDF Mode
          </button>
        </Col>
      </Row>
      {isedit == "false" ? (
        <>
          {" "}
          <div className="MobTextStyle">
            <div className="container">
              <div style={{ marginTop: 20 }} className="row">
                <div className="col-md-3">
                  {uploadedFile !== null ? (
                    <>
                      <img src="/assets/img/ghimages/uploadimg.png" />
                      <p
                        style={{
                          color: "green",
                          fontSize: 18,
                          fontWeight: 900,
                        }}
                      >
                        PDF ready to Upload
                      </p>
                    </>
                  ) : (
                    <Dropzone accept="" onDrop={(file) => setupPDF(file)}>
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <DragDropContainer>
                            <>
                              <p>Drag & Drop</p>
                              <p>Or</p>
                              <button className="e-btn">Upload</button>
                            </>
                          </DragDropContainer>
                        </div>
                      )}
                    </Dropzone>
                  )}

                  <div className="row text-center">
                    <div className="button-section mt-10 mb-20">
                      <button
                        className="btn btn-primary button add"
                        type="button"
                        onClick={uploadPDF}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="fieldcontainer">
                    <p>PDF Title</p>
                    <input
                      placeholder="PDF Title"
                      className="form-control marginTop5"
                      type="text"
                      name="pdfName"
                      value={mentorpdfdetials.pdfName}
                      onChange={ProfileDetailsonChange}
                    />
                    <p>Course Key</p>
                    <input
                      placeholder="PDF Keyword"
                      className="form-control marginTop5"
                      type="text"
                      name="pdf_linked_to_course"
                      value={mentorpdfdetials.pdf_linked_to_course}
                      onChange={ProfileDetailsonChange}
                    />
                  </div>
                  <div className="fieldcontainer">
                    <p>PDF Description</p>
                    <textarea
                      className="form-control"
                      name="pdfDescription"
                      value={mentorpdfdetials.pdfDescription}
                      onChange={ProfileDetailsonChange}
                    />
                  </div>

                  <div className="fieldcontainer">
                    <p>PDF Order</p>
                    <input
                      placeholder="PDF Order"
                      className="form-control marginTop5"
                      type="number"
                      name="pdfOrder"
                      value={mentorpdfdetials.pdfOrder}
                      onChange={ProfileDetailsonChange}
                    />
                  </div>
                  <div style={{ marginTop: 20 }} className="fieldcontainer">
                    {mentorpdfdetials.pdfLink ? (
                      <>
                        <p
                          style={{
                            color: "green",
                            fontSize: 18,
                            fontWeight: 900,
                          }}
                        >
                          PDF ready to Upload
                        </p>
                      </>
                    ) : (
                      <p
                        style={{ color: "red", fontSize: 18, fontWeight: 900 }}
                      >
                        please upload PDF before Adding PDF details
                      </p>
                    )}
                  </div>
                  <div className="row text-center">
                    <div className="button-section mt-10 mb-20">
                      {mentorpdfdetials.pdfLink ? (
                        <>
                          <button
                            className="btn btn-primary button add"
                            type="button"
                            onClick={SubmitMentorPDFEntry}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <button
                          className="btn btn-primary button add"
                          type="button"
                          disabled
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {isedit == "true" ? (
        <>
          {" "}
          <div className="MobTextStyle">
            <div className="container">
              <div style={{ marginTop: 20 }} className="row">
                <div className="col-md-9">
                  <div className="fieldcontainer">
                    <p>PDF Title</p>
                    <input
                      placeholder="PDF Title"
                      className="form-control marginTop5"
                      type="text"
                      name="pdfName"
                      value={mentorpdfdetials.pdfName}
                      onChange={ProfileDetailsonChange}
                    />
                    <p>Course Key</p>
                    <input
                      placeholder="PDF Keyword"
                      className="form-control marginTop5"
                      type="text"
                      name="pdf_linked_to_course"
                      value={mentorpdfdetials.pdf_linked_to_course}
                      onChange={ProfileDetailsonChange}
                    />
                  </div>
                  <div className="fieldcontainer">
                    <p>PDF Description</p>
                    <textarea
                      className="form-control"
                      name="pdfDescription"
                      value={mentorpdfdetials.pdfDescription}
                      onChange={ProfileDetailsonChange}
                    />
                  </div>

                  <div className="fieldcontainer">
                    <p>PDF Order</p>
                    <input
                      placeholder="PDF Order"
                      className="form-control marginTop5"
                      type="number"
                      name="pdfOrder"
                      value={mentorpdfdetials.pdfOrder}
                      onChange={ProfileDetailsonChange}
                    />
                  </div>
                  <div style={{ marginTop: 20 }} className="fieldcontainer">
                    {mentorpdfdetials.pdfLink ? (
                      <>
                        <p
                          style={{
                            color: "green",
                            fontSize: 18,
                            fontWeight: 900,
                          }}
                        >
                          PDF ready to Upload
                        </p>
                      </>
                    ) : (
                      <p
                        style={{ color: "red", fontSize: 18, fontWeight: 900 }}
                      >
                        please upload PDF before Adding PDF details
                      </p>
                    )}
                  </div>
                  <div className="row text-center">
                    <div className="button-section mt-10 mb-20">
                      <button
                        className="btn btn-primary button add"
                        type="button"
                        onClick={SubmitUpdateMentorPDFEntry}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <div style={{ width: "100%" }}>
        <h1>Course PDF Listing</h1>
      </div>
      <hr />
      <table
        border={0}
        style={{
          tableLayout: "fixed",
          width: "100%",
          borderCollapse: "collapse",
        }}
      >
        <colgroup>
          <col style={{ width: "25%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "5%" }} />
          <col style={{ width: "10%" }} />
        </colgroup>
        <tr>
          <td style={{ padding: "5px", border: "1px solid gray" }}>
            PDF Preview
          </td>
          <td style={{ padding: "5px", border: "1px solid gray" }}>
            Course Key
          </td>
          <td style={{ padding: "5px", border: "1px solid gray" }}>Title</td>
          <td style={{ padding: "5px", border: "1px solid gray" }}>
            Description
          </td>
          <td style={{ padding: "5px", border: "1px solid gray" }}>Order</td>
          <td style={{ padding: "5px", border: "1px solid gray" }}>Action</td>
        </tr>
        {mentorpdfdetialsArray.map((item) => {
          return (
            <>
              <tr>
                <td style={{ padding: "5px", border: "1px solid gray" }}>
                  <div className="row">
                    <div className="col-md-4">
                      <PdfViewer pdfUrl={item.PDFLink} />
                    </div>
                  </div>
                </td>
                <td
                  style={{
                    padding: "5px",
                    border: "1px solid gray",
                    maxWidth: "100px",
                    wordWrap: "break-word",
                  }}
                >
                  {item.pdf_linked_to_course}
                </td>
                <td
                  style={{
                    padding: "5px",
                    border: "1px solid gray",
                    maxWidth: "100px",
                    wordWrap: "break-word",
                  }}
                >
                  {item.pdfName}
                </td>
                <td
                  style={{
                    padding: "5px",
                    border: "1px solid gray",
                    maxWidth: "100px",
                    wordWrap: "break-word",
                  }}
                >
                  {item.pdfDescription}
                </td>
                <td style={{ padding: "5px", border: "1px solid gray" }}>
                  {item.pdfOrder}
                </td>
                <td style={{ padding: "5px", border: "1px solid gray" }}>
                  <button className="e-btn" onClick={() => UpdatePdf(item)}>
                    Edit
                  </button>
                  <button
                    className="e-btn mt-5"
                    onClick={() => deletePDF(item)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </>
          );
        })}
      </table>
    </Wrapper>
  );
};

export default PdfUploader;
