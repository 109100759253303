const setDataInLocalStorage = (key: string, data: any) => {
  localStorage[key] = JSON.stringify(data)
}

const getDataFromLocalStorage = (key: string) => {
  try {
    return localStorage[key] ? JSON.parse(localStorage[key]) : false
  } catch (err) {
    return false
  }
}

const removeDataFromLocalStorage = (key: string) => {
  return localStorage.removeItem(key)
}

export { setDataInLocalStorage, getDataFromLocalStorage, removeDataFromLocalStorage }
