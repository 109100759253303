import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const whitelabelConfigSlice = createSlice({
  name: "whitelabel_config",
  initialState,
  reducers: {
    GET_WHITELABEL_CONFIG(state = [], action) {
      return [...action.payload];
    },
  },
});

export const { GET_WHITELABEL_CONFIG } = whitelabelConfigSlice.actions;

export default whitelabelConfigSlice.reducer;
