import { createContext, useState, useEffect } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  // Save to localStorage
  useEffect(() => {
    localStorage.setItem("Auth", JSON.stringify(auth));
  }, [auth]);

  // Retrieve from localStorage
  const localauth = JSON.parse(localStorage.getItem("Auth")) || {};

  console.log("AuthProvider", auth);
  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
