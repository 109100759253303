const recuiter_menu_data = [
  {
    id: 1,
    title: "Dashboard",
    link: "/recuiter-dashboard",
  },
  {
    id: 2,
    title: "Company Profile ",
    link: "/company-profile",
  },
  {
    id: 3,
    title: "Manage Jobs",
    link: "/recuiter-add-job",
  },
  {
    id: 4,
    title: "Applied Students",
    link: "/applied-students-list",
  },
];

export default recuiter_menu_data;
