import SEO from "../../components/seo";
import { useState } from "react";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import styled from "styled-components";
import YouTube from "react-youtube";
const Video = styled.iframe`
  display: flex;
  align-items: center;
  border: none;
  max-width: 490px;
  width: 100%;
  height: 100%;
  max-height: 276px;
  min-height: 276px;
  box-shadow: 9px 14px 15px rgba(0, 0, 0, 0.25);
`;
const QRPaymentPage = () => {
  const opts = {
    width: "100%",
    height: "265",

    playerVars: {
      autoplay: 0,
    },
  };

  let _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  const [currentvidetoplay, setCurrentvideotoplay] = useState({
    videoCode: "FLrSxW9v9OA",
  });
  const setSeletedvideotoplay = (item) => {
    console.log("item", item);
    if (item == "Student1") {
      setCurrentvideotoplay({
        videoCode: "FLrSxW9v9OA",
      });
    }
    if (item == "Student2") {
      setCurrentvideotoplay({
        videoCode: "QqDya24v0R4",
      });
    }
    if (item == "GHIntro") {
      setCurrentvideotoplay({
        videoCode: "pwnnUnwDIko",
      });
    }
  };
  return (
    <Wrapper>
      <SEO pageTitle={"QR Payment Page"} />
      <div className="container text-center">
        <img width={950} src="/assets/img/ghimages/payment_op.png" />
      </div>
    </Wrapper>
  );
};

export default QRPaymentPage;
