import styled from "styled-components";
import { useNavigate } from "react-router-dom";
//import Header from "components/dashboard-header";
//import AptitudeTestIconImg from "assets/images/aptitude-test-icon.png";
//import SoftSkillTestIconImg from "assets/images/soft-skill-test-icon.png";

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 72px;
  margin-top: 70px;
`;

const HeaderText = styled.div`
  font-family: Poppins-Bold;
  color: #3e3d43;
  font-size: 30px;
  line-height: 83px;
`;

const TestContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 60px;
`;

const TestTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 44px;
  cursor: pointer;
  :hover {
    img {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
  }
  div {
    font-family: Poppins-Regular;
    font-weight: 600;
    font-size: 15px;
    line-height: 47px;
    text-align: center;
    color: #3e3d43;
  }
`;

const AvailableTest = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* 
      to do
      <Header
        headerConfig={{
          ghLogo: true,
          searchBar: false,
          home: false,
          msg: false,
          notification: false,
          avatarControl: true,
          timeTicker: false,
        }}
      /> */}
      <PageContainer>
        <HeaderText>Your available online test series</HeaderText>
        <TestContainer>
          <TestTypeContainer onClick={() => navigate("/assessment/aptitude")}>
            <img
              src={"/assets/img/ghimages/aptitude-test-icon.png"}
              alt="aptitude-test"
            />
            <div>Aptitude Assessment</div>
          </TestTypeContainer>
          <TestTypeContainer onClick={() => navigate("/assessment/soft-skill")}>
            <img
              src={"/assets/img/ghimages/soft-skill-test-icon.png"}
              alt="soft-skill-test"
            />
            <div>Soft Skill Assessment</div>
          </TestTypeContainer>
        </TestContainer>
      </PageContainer>
    </>
  );
};

export default AvailableTest;
