export const banner_one = [
  {
    bg_img: "/assets/img/shape/banner-bg-2.jpg",
    tag: "New",
    title: (
      <>
        View Latest <br /> Jobs
      </>
    ),
    btn_text: "View Jobs",
    img: "/assets/img/hero/2r.png",
    navigateto: "/jobs",
  },
  {
    bg_img: "/assets/img/shape/banner-bg-1.jpg",
    tag: "Free",
    title: (
      <>
        Career Oriented <br />
        Learning
      </>
    ),
    btn_text: "View Mentors",
    img: "/assets/img/hero/6r.png",
    navigateto: "/mentors",
  },
];
