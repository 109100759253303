import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import YouTube from "react-youtube";

import styled from "styled-components";
import Accordion from "react-bootstrap/Accordion";
import "./placementtools.css";

import React, { useState } from "react";

import { useCalendlyEventListener, InlineWidget } from "react-calendly";

import { razorpayCreateOrder } from "../../services/payment-service";
import Alert from "react-bootstrap/Alert";
import { addserviceScheduler } from "../../services/scheduler-service";
import {
  setDataInLocalStorage,
  getDataFromLocalStorage,
} from "../../browser-storage";
import { toast } from "react-toastify";

const SpeedMentoring = () => {
  const [isscheduledone, setscheduledone] = useState(false);
  const [isDoneScheduling, setDoneScheduling] = useState(false);
  const [isTimeSelected, setTimeSelected] = useState(false);

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => {
      if (e) {
        setscheduledone(true);
        setscheduledone(true);
      }
    },
  });

  const pageSettings = {
    primaryColor: "417658",
    textColor: "251e35",
    backgroundColor: "f7f5f4",
    hideLandingPageDetails: true,
    hideEventTypeDetails: true,
  };

  function getCalendlyHeightToEnsureNoCrop() {
    if (!isTimeSelected) return { height: 600 };
    if (isDoneScheduling) return { height: 450 };
    return { height: 660 };
  }

  function handleDateAndTimeSelected() {
    setTimeSelected(true);
  }

  function handleEventScheduled() {
    setDoneScheduling(true);
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const payload = { amount: 14900 };

    const result = await razorpayCreateOrder(payload)
      .then((res) => {
        console.log(res);
        let response = res;
        const id = response.id;
        const options = {
          currency: "INR",
          name: "GreenHorn",
          order_id: id,
          description: "GreenHorn Transaction",
          handler: async function (response) {
            const data = {
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            };
            console.log(response);

            const userDetials = getDataFromLocalStorage("user-details");
            console.log(userDetials);
            const email = userDetials.email;
            const payload = {
              username: userDetials.userName,
              servicebooked: "Speed Mentoring Interview",
              bookingDate: new Date(),
              amount: "149",
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            };
            const schedulerResult = await addserviceScheduler(payload)
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });

            console.log(schedulerResult);

            if (response) {
              toast.success(
                `Payment Successfully Completed, Please check your mail for more detials `,
                {
                  position: "top-left",
                }
              );
            }
          },
          theme: {
            color: "#61dafb",
          },
        };

        const paymentObject = new window.Razorpay(options);

        paymentObject.open();
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(result);
  }

  function dummystatechange() {
    setscheduledone(true);
    setscheduledone(true);
  }

  return (
    <Wrapper>
      <SEO pageTitle={"Speed Mentoring"} />
      <>
        <Container style={{ backgroundColor: "white" }}>
          {/* <div className="resumeTitle">&nbsp;Tech Interview Schedule</div> */}

          <Row style={{ marginBottom: "20px" }}>
            <Card className="carddesign">
              <Card.Body>
                <Row>
                  <Col>
                    <img
                      width="350px"
                      src={"/assets/img/ghimages/collab.svg"}
                      alt={"img"}
                    />

                    <p>
                      Do you want to have an Informal <b>interaction</b> with an{" "}
                      <b>Industry Expert</b> who is working in your{" "}
                      <b>dream company</b>. They will share with you tips that
                      can get you into those companies. What skills you need,
                      which certifications will give you the edge, what do they
                      love and hate about their job – they will share
                      everything! for <b>Just ₹ 149</b>{" "}
                    </p>
                  </Col>
                  <Col>
                    {isscheduledone == false ? (
                      <InlineWidget
                        url={"https://calendly.com/mygreenhorn/interview"}
                        pageSettings={pageSettings}
                        styles={getCalendlyHeightToEnsureNoCrop()}
                      />
                    ) : null}

                    {isscheduledone == true ? (
                      <div>
                        <Alert style={{ marginTop: "10px" }} variant="warning">
                          <p>
                            <b>
                              Scheduling Successfully Done. Please Complete
                              Payment To Book Your Slot
                            </b>
                          </p>
                        </Alert>
                        <div className="text-center">
                          <button
                            className="btn btn-success"
                            style={{ marginTop: "5px" }}
                            onClick={displayRazorpay}
                          >
                            Book Now
                          </button>
                        </div>
                        <p style={{ marginTop: "10px" }}>
                          Any refunds resulting from cancellation of sessions
                          will be settled manually within 3 working days.
                        </p>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Row>
        </Container>
      </>
    </Wrapper>
  );
};

export default SpeedMentoring;
