import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
`;
const ProgressBarContainer = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
  font-weight: 600;
  max-width: 600px;
  min-width: 400px;
  border-radius: 2px;
  background-color: #c4c4c4;
`;
const ProgressBarBlock = styled.div`
  display: flex;
  width: ${(props) => (props.scorePer ? `${props.scorePer}%` : "0%")};
  height: 6px;
  border-radius: 2px;
  background-color: ${(props) =>
    props.scorePer <= 30
      ? "#EA5252"
      : props.scorePer > 31 && props.scorePer <= 50
      ? "#F9A825"
      : props.scorePer > 51 && props.scorePer <= 70
      ? "#90EE90"
      : "#1C8D39"};
  flex-direction: column;
  align-items: center;
`;
const PercentageNumber = styled.span`
  color: #3e3d43;
  font-size: 15px;
  padding-right: 15px;
`;

const ProgressBar = (props) => {
  return (
    <Container>
      {props.isPrcntShow && (
        <PercentageNumber>
          {isFinite(props.scorePer) ? props.scorePer.toFixed(2) : 0}%
        </PercentageNumber>
      )}
      <ProgressBarContainer>
        <ProgressBarBlock
          scorePer={isFinite(props.scorePer) ? props.scorePer : 0}
        />
      </ProgressBarContainer>
    </Container>
  );
};
export default ProgressBar;
