import React from "react";
import { Link } from "react-router-dom";
import { HeroOne } from "../../data";
import YouTube from "react-youtube";
import styled from "styled-components";
const Video = styled.iframe`
  display: flex;
  align-items: center;
  border: none;
  max-width: 490px;
  width: 100%;
  height: 100%;
  max-height: 276px;
  min-height: 276px;
  box-shadow: 9px 14px 15px rgba(0, 0, 0, 0.25);
`;
const HeroArea = () => {
  const opts = {
    width: "444",
    height: "250",

    playerVars: {
      autoplay: 0,
    },
  };

  let _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  return (
    <>
      <section className="hero__area hero__height d-flex align-items-center grey-bg-2 p-relative">
        <div className="hero__shape">
          <img
            className="hero-1-circle"
            src="/assets/img/shape/hero/hero-1-circle.png"
            alt=""
          />
          <img
            className="hero-1-circle-2"
            src="/assets/img/shape/hero/hero-1-circle-2.png"
            alt=""
          />
          <img
            className="hero-1-dot-2"
            src="/assets/img/shape/hero/hero-1-dot-2.png"
            alt=""
          />
        </div>
        <div className="container">
          <div className="hero__content-wrapper mt-90">
            <div className="row align-items-center">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <div className="hero__content p-relative z-index-1">
                  <div className="section__title-wrapper mb-45">
                    <h3 className="hero__title">
                      <span style={{ fontSize: "30px" }}>
                        {HeroOne.sm_text}
                      </span>
                    </h3>
                  </div>
                  <h3 className="hero__title">
                    <span style={{ fontSize: "30px" }}>{HeroOne.sm_text}</span>
                    <span style={{ fontSize: "30px" }} className="yellow-shape">
                      Accelerate your career
                      <img
                        src="/assets/img/shape/yellow-bg.png"
                        alt="yellow-shape"
                      />
                    </span>
                    <span style={{ fontSize: "30px" }}></span>
                  </h3>
                  <p
                    className="mt-5"
                    style={{ fontSize: 28, fontWeight: 300, color: "#f18300" }}
                  >
                    Discover your strengths, build your life skills, and
                    transform your career through our AI enabled solutions,
                    assessments, and goal-oriented coaching programs
                  </p>
                  <Link to="/student-enquiry-form" className="e-btn mt-10">
                    {HeroOne.btn_text}
                  </Link>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <div className="hero__thumb d-flex p-relative">
                  <div className="hero__thumb-shape">
                    <img
                      className="hero-1-dot"
                      src="/assets/img/shape/hero/hero-1-dot.png"
                      alt=""
                    />
                    <img
                      className="hero-1-circle-3"
                      src="/assets/img/shape/hero/hero-1-circle-3.png"
                      alt=""
                    />
                    <img
                      className="hero-1-circle-4"
                      src="/assets/img/shape/hero/hero-1-circle-4.png"
                      alt=""
                    />
                  </div>
                  <div className="hero__thumb-big mr-30">
                    <div
                      style={{
                        border: "20px solid white",
                        borderRadius: "40px 4px 40px 4px",
                      }}
                      className="desktop"
                    >
                      <YouTube
                        className="cusmobilevideo"
                        videoId="pwnnUnwDIko"
                        opts={opts}
                        onReady={_onReady}
                      />
                    </div>
                    <div className="mobile">
                      <div
                        style={{
                          marginTop: 20,
                          border: "20px solid white",
                          borderRadius: "40px 4px 40px 4px",
                        }}
                        className="mobile-div-position"
                      >
                        <Video
                          src={`https://www.youtube.com/embed/pwnnUnwDIko`}
                        />
                      </div>
                    </div>
                    <div className="hero__quote hero__quote-animation">
                      <span>{HeroOne.hero__quote}</span>
                      <h4>{HeroOne.hero__quote_2}</h4>
                    </div>
                  </div>
                  <div className="hero__thumb-sm d-none d-lg-block">
                    <img src={HeroOne.hero_sm_img} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroArea;
