import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ModalType } from "../../../../../redux/types/generic-constant";
import { formatDate } from "../../../../../helpers";
import { DetailTab, OtherDetailTabContent } from "../../common";

import AddEditEducationModal from "./add-edit-education-modal";

const Education = () => {
  const academics = useSelector((state) => state.profile.academics);
  console.log("academics", academics);
  const [openModalType, setOpenModalType] = useState(undefined);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  return (
    <>
      <DetailTab
        title="Education"
        actionIcon={"/assets/img/ghimages/add-icon.svg"}
        actionMethod={() => setOpenModalType(ModalType.ADD)}
        children={
          academics.length > 0 ? (
            <>
              {academics.map(
                ({ instituteName, degree, startDate, endDate }, idx) => (
                  <OtherDetailTabContent idx={idx}>
                    <img
                      src={"/assets/img/ghimages/education-icon.svg"}
                      alt=""
                    />
                    <div>
                      <div>{instituteName}</div>
                      <div>{degree}</div>
                      <div>
                        {formatDate(startDate)} to {formatDate(endDate)}
                      </div>
                    </div>
                    <span>
                      <img
                        src={"/assets/img/ghimages/edit-icon.svg"}
                        alt="edit"
                        onClick={() => {
                          setSelectedIndex(idx);
                          setOpenModalType(ModalType.EDIT);
                        }}
                      />
                    </span>
                  </OtherDetailTabContent>
                )
              )}
            </>
          ) : null
        }
      />
      {openModalType && (
        <AddEditEducationModal
          closeAction={() => {
            setOpenModalType(undefined);
            setSelectedIndex(-1);
          }}
          forEdit={openModalType === ModalType.EDIT}
          selectedIndex={selectedIndex}
        />
      )}
    </>
  );
};

export default Education;
