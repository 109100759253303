import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  AddStudentEnquiryForm,
  AddStudentEnquiryFormWithResume,
} from "../../services/user-service";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Card, Col, Row, Badge, Button, Container } from "react-bootstrap";

import Modal from "react-modal";
import Dropzone from "react-dropzone";
import { toBase64 } from "../../helpers/index";
import styled from "styled-components";
import { getJoblisting, applyJobListing } from "../../services/jobs-service";
import { uploadresumeBuilderforGuest } from "../../services/resume-builder";
const InfoText = styled.div`
  align-items: center;
`;

const DragDropContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 232px;
  border: 1px dashed #c4c4c4;
  border-radius: 5px;
  gap: 8px;
  div:nth-child(1) {
    font-family: Poppins-Bold;
    font-size: 24px;
    color: rgba(61, 41, 36, 0.8);
  }
  div:nth-child(2) {
    font-family: Poppins-Regular;
    font-size: 18px;
    color: rgba(61, 41, 36, 0.8);
  }
  img {
    width: 220px;
    height: 220px;
    border-radius: 5px;
  }
`;
const UploadResumeByGuestEnquiry = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const CurrentDate = new Date()
    .toJSON()
    .slice(0, 10)
    .split("-")
    .reverse()
    .join("/");
  const [details, setDetails] = useState({
    fullname: "",
    mobile: "",
    preferreddomain: "",
    submitteddate: CurrentDate,
    student_resume_url: "",
  });

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!details.fullname || !details.mobile) {
      return;
    }

    const SubmitResponse = await AddStudentEnquiryForm(details)
      .then((resp) => {
        setDetails({ fullname: "", mobile: "", preferreddomain: "" });
        toast.success(`Submit Successfully`, {
          position: "top-left",
        });
      })
      .catch((err) =>
        toast.error(`Error ${err}`, {
          position: "top-left",
        })
      );
  };

  const [resume, setResume] = useState({
    uploadedFile: undefined,
    fileUrl: "",
    fullname: "",
    username: "",
  });

  const [showErrors, setShowErrors] = useState(false);

  const setupResume = async (file) => {
    console.log("setupresume", file);
    if (file?.length && file[0]?.size > 4_00_00000) {
      setShowErrors(true);
      return;
    }
    const base64 = await toBase64(file[0]).catch(() =>
      //add toast fail here
      console.log("err")
    );
    if (base64) {
      setResume({
        uploadedFile: file[0],
        fileUrl: base64,
        fullname: "",
        username: "",
      });
    }
    console.log("fileUrl", resume.fileUrl);
    setShowErrors(false);
  };

  const uploadImage = async () => {
    if (!resume.uploadedFile || showErrors) {
      return;
    }
    //this is a service function which is responsible for upload
    const uploadresume = uploadresumeBuilderforGuest(resume.uploadedFile)
      .then(async (data) => {
        setDetails({ student_resume_url: data });
        setDetails({ student_resume_url: data });
        //commenting on 03052023 need to test once
        // if (!details.fullname || !details.mobile || !details.preferreddomain) {
        //   return;
        // }

        const SubmitResponse = await AddStudentEnquiryFormWithResume(details)
          .then((resp) => {
            setDetails({
              fullname: "",
              mobile: "",
              preferreddomain: "",
              student_resume_url: "",
            });
            toast.success(`Submit Successfully`, {
              position: "top-left",
            });
          })
          .catch((err) =>
            toast.error(`Error ${err}`, {
              position: "top-left",
            })
          );
      })
      .catch(() =>
        toast.error(`Unable to Apply for the Job`, {
          position: "top-left",
        })
      );
  };

  return (
    <Wrapper>
      <SEO pageTitle={"Student Enquiry Form"} />
      <div className="row">
        <div className="col-md-7">
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={false}
            autoPlay={false}
            autoPlaySpeed={4000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            <div>
              <img
                onClick={() =>
                  navigate("/student-enquiry-form", { replace: true })
                }
                className="classBanner"
                width="280px"
                src="/assets/img/classes/1.png"
              />
            </div>
            <div>
              <img
                onClick={() =>
                  navigate("/student-enquiry-form", { replace: true })
                }
                className="classBanner"
                width="280px"
                src="/assets/img/classes/2.png"
              />
            </div>
            <div>
              <img
                onClick={() =>
                  navigate("/student-enquiry-form", { replace: true })
                }
                className="classBanner"
                width="280px"
                src="/assets/img/classes/3.png"
              />
            </div>
            <div>
              <img
                onClick={() =>
                  navigate("/student-enquiry-form", { replace: true })
                }
                className="classBanner"
                width="280px"
                src="/assets/img/classes/4.png"
              />
            </div>
            <div>
              <img
                onClick={() =>
                  navigate("/student-enquiry-form", { replace: true })
                }
                className="classBanner"
                width="280px"
                src="/assets/img/classes/5.png"
              />
            </div>
            <div>
              <img
                onClick={() =>
                  navigate("/student-enquiry-form", { replace: true })
                }
                className="classBanner"
                width="280px"
                src="/assets/img/classes/6.png"
              />
            </div>
            <div>
              <img
                onClick={() =>
                  navigate("/student-enquiry-form", { replace: true })
                }
                className="classBanner"
                width="280px"
                src="/assets/img/classes/7.png"
              />
            </div>
            <div>
              <img
                onClick={() =>
                  navigate("/student-enquiry-form", { replace: true })
                }
                className="classBanner"
                width="280px"
                src="/assets/img/classes/8.png"
              />
            </div>
          </Carousel>
        </div>
        <div className="col-md-5">
          <div className="padding20">
            <form onSubmit={submitHandler}>
              <div className="form-inner">
                <div className="form-group p-b-15">
                  <input
                    type="text"
                    name="fullname"
                    id="fullname"
                    placeholder="Full Name"
                    onChange={(e) =>
                      setDetails({ ...details, fullname: e.target.value })
                    }
                    className="form-control "
                    value={details.fullname}
                    autoComplete="off"
                  />
                </div>
                <div className="form-group p-b-15">
                  <input
                    type="number"
                    name="mobile"
                    placeholder="Mobile No."
                    autoComplete="off"
                    className="form-control "
                    onChange={(e) =>
                      setDetails({ ...details, mobile: e.target.value })
                    }
                    value={details.mobile}
                  />
                </div>
                <div className="form-group">
                  <select
                    name="job_function"
                    className="form-control"
                    value={details.preferreddomain}
                    onChange={(e) =>
                      setDetails({
                        ...details,
                        preferreddomain: e.target.value,
                      })
                    }
                    placeholder="Job Function (Categories)"
                  >
                    <option name="" value="" style={{ display: "none" }}>
                      Choose Category
                    </option>
                    <option
                      name="Business Operations"
                      value="Business Operations"
                    >
                      Business Operations
                    </option>
                    <option name="Core Engineering" value="Core Engineering">
                      Core Engineering
                    </option>
                    <option
                      name="Information Technology"
                      value="Information Technology"
                    >
                      Information Technology
                    </option>
                    <option name="Human Resources" value="Human Resources">
                      Human Resources
                    </option>
                    <option name="Marketing" value="Marketing ">
                      Marketing
                    </option>
                    <option name="Sales" value="Sales">
                      Sales
                    </option>
                    <option name="Others" value="Others">
                      Others
                    </option>
                  </select>
                </div>
                <div className="form-group">
                  <Row>
                    <Col sm={11}>
                      <h3>Upload Resume</h3>
                    </Col>
                    <Col sm={1}></Col>
                  </Row>
                  <Dropzone accept="" onDrop={(file) => setupResume(file)}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <DragDropContainer>
                          {resume.fileUrl ? (
                            <img
                              src="/assets/img/ghimages/uploadimg.png"
                              alt=""
                            />
                          ) : (
                            <>
                              <InfoText>Drag & Drop</InfoText>
                              <InfoText>Or</InfoText>
                              <button className="e-btn">Upload</button>
                            </>
                          )}
                        </DragDropContainer>
                      </div>
                    )}
                  </Dropzone>
                  <Row>
                    <Col sm={9}></Col>
                    <Col className="text-center" sm={3}>
                      <button
                        style={{ marginTop: 20 }}
                        className="e-btn"
                        onClick={uploadImage}
                      >
                        Submit
                      </button>
                    </Col>
                  </Row>

                  {resume.fileUrl && (
                    <img
                      src="/assets/img/ghimages/close.svg"
                      alt="X"
                      onClick={() =>
                        setResume({
                          uploadedFile: undefined,
                          fileUrl: "",
                          fullname: "",
                          username: "",
                        })
                      }
                    />
                  )}
                </div>
              </div>
              {error != "" ? <div className="error">{error}</div> : ""}
              <input
                type="submit"
                value="Submit"
                style={{ marginTop: "20px" }}
                className="e-btn"
              />
            </form>
          </div>
        </div>
      </div>
      <></>
    </Wrapper>
  );
};

export default UploadResumeByGuestEnquiry;
